import {makeStyles} from "@material-ui/core";
import {designEditorStyle} from "../designEditorStyle";
import React from "react";
import Grid from "@material-ui/core/Grid";
import {useTranslation} from "react-i18next";
import Section from "../../../../form/Components/Section/Section";
import ImageUpload from "../../../../../Components/ImageUpload/ImageUpload";
import useForm from "../../../../form/useForm";
import {BackgroundRecord, BackgroundSchema} from "../../Data/Records/Background";
import FormProvider from "../../../../form/Components/FormProvider/FormProvider";
import ValidatedSubmit from "../../../../form/Components/ValidatedSubmit/ValidatedSubmit";
import {setBackground, useBackground} from "../../../ContentEditor/Api/pages";

const useStyles = makeStyles(designEditorStyle);

const BackgroundEditor = () => {

    const classes = useStyles();

    const {t} = useTranslation('contentManagement');

    const {data, loadingStatus} = useBackground();

    const form = useForm({
        recordType: BackgroundRecord,
        recordSchema: BackgroundSchema,
        namespace: 'contentManagement',
        record: new BackgroundRecord(BackgroundSchema.default()).set('picture', loadingStatus ? data : 0),
    });

    return (
        <Section title={t('backgroundChange')}>
            <FormProvider form={form}>
                <Grid container className={classes.panel}>
                    <Grid container item xs={12}>
                        <ImageUpload picture={loadingStatus ? undefined : data}/>
                    </Grid>
                    <Grid container item xs={12}>
                        <ValidatedSubmit view='edit'
                                         api={setBackground}
                                         hideCancel={true}/>
                    </Grid>
                </Grid>
            </FormProvider>
        </Section>
    );
};

export default BackgroundEditor;