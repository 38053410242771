export const types = {
    'INIT_ITEMS': 'INIT_ITEMS',
    'LOAD_SUCCESS': 'LOAD_SUCCESS',
    'MOVE_ITEM': 'MOVE_ITEM',
    'MOVE_ITEM_TO_CHILDREN': 'MOVE_ITEM_TO_CHILDREN',
    'ADD_ITEM': 'ADD_ITEM',
    'REMOVE_ITEM': 'REMOVE_ITEM',
};

export const actions = {

    initItems: (data) => {
        return {
            type: 'INIT_ITEMS',
            data: data
        }
    },
    moveItemToChildren: (sourceId, targetId) => {
        return {
            type: 'MOVE_ITEM_TO_CHILDREN',
            sourceId: sourceId,
            targetId: targetId,
        }
    },
    moveItem: (sourceId, targetId) => {
        return {
            type: 'MOVE_ITEM',
            sourceId: sourceId,
            targetId: targetId,
        }
    },
    addItem: () => {
        return {
            type: 'ADD_ITEM',
        }
    },
    removeItem: () => {
        return {
            type: 'REMOVE_ITEM',
        }
    },

};