export const appFooterStyle = theme => ({
    root: {
        ...theme.footerColors,
        width: '100%',
        height: 110,
        position: 'absolute',
        bottom: 0,
        // backgroundColor: '#00E5FF',
        justifyContent: 'space-around',
        alignContent: 'center',
        boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)',
    },
    footerLinkBox: {
        justifyContent: 'space-around',
        alignContent: 'center'
    },
    footerLink: {
        textDecoration: 'unset',
    },
    footerLinkText: {
        ...theme.typography.h6,
        fontWeight: 'bold',
        color: theme.palette.text.footer,
        textTransform: 'uppercase',
        textAlign: 'center',
        '& a': {
            color: 'inherit',
        },
        '& p': {
            color: 'inherit',
        }
    },
    jumpToTopButton: {
        position: 'absolute',
        right: '10%',
    },
    jumpToTopIcon: {
        color: theme.palette.text.white,
        fontSize: '3.5rem',
        display: 'none'
    }
});