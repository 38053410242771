import React, {memo, useContext} from "react";
import {useTranslation} from "react-i18next";
import useForm from "../../Lib/form/useForm";
import {AccountRecord, AccountSchema} from "../../Data/Account";
import Button from "../../Lib/material-kit/components/CustomButtons/Button";
import ContentBox from "../../Components/ContentBox/ContentBox";
import Section from "../../Lib/form/Components/Section/Section";
import ValidatedInput from "../../Lib/form/Components/ValidatedInput/ValidatedInput";
import ValidatedSubmit from "../../Lib/form/Components/ValidatedSubmit/ValidatedSubmit";
import {A, navigate} from "hookrouter";
import {Lock} from "@material-ui/icons";
import {makeStyles} from "@material-ui/styles";
import accountStyle from "./accountStyle";
import ValidatedCheckbox from "../../Lib/form/Components/ValidatedCheckbox/ValidatedCheckbox";
import ValidateCheckboxGroup from "../../Lib/form/Components/ValidatedCheckbox/ValidatedCheckboxGroup";
import {AuthContext} from "../../Lib/auth/AuthContext";
import {hasRole} from "../../Lib/auth/auth";
import {roles} from "../../Main/roles";

const useStyles = makeStyles(accountStyle);

/**
 * Checks if the password verification is the same as password.
 */
export const passwordValidator = (fieldName, fieldValue, fieldValues) => {
    return fieldValues["credentials"] === fieldValue;
};

export const Form = memo(({view, id, path, record, api}) => {

    const {t} = useTranslation("account");
    const classes = useStyles();

    const form = useForm({
        disabledForm: view === 'remove',
        recordType: AccountRecord,
        recordSchema: AccountSchema,
        namespace: 'account',
        record
    });

    const authContext = useContext(AuthContext);
    const showActive = hasRole(authContext, roles.ADMIN) || hasRole(authContext, roles.SECURITY_OFFICER);

    const onCancel = () => navigate(`/admin/accounts`);

    return (
        <ContentBox path={path} form={form}>

            <Section title={t(view)}>
                <ValidatedInput name='principalName' autoComplete="username"/>
                <ValidatedInput name='emailAddress' autoComplete="email"/>

                {view === "add" ?
                    <React.Fragment>
                        <ValidatedInput name="credentials" password autoComplete="new-password"/>
                        <ValidatedInput name="verification" password validator={passwordValidator}/>
                    </React.Fragment>
                    :
                    <div className={classes.buttonContainer}>
                        <A href={`/admin/accounts/reset/${id}`}>
                            <Button color="primary" className={classes.smallButton}>
                                <Lock className={classes.icons}/>
                                <span style={{marginLeft: 4}}>
                                                    {t('password-change')}
                                                </span>
                            </Button>
                        </A>
                    </div>
                }
                <ValidatedInput name='fullName' autoComplete="name"/>
                <ValidatedInput name='displayName'/>

                <ValidateCheckboxGroup name="roles" label={t('roles')}>
                    <ValidatedCheckbox name="roles"
                                       value={'ADMIN'}
                                       customLabel={t('ADMIN')}
                                       multi/>
                    <ValidatedCheckbox name="roles"
                                       value={'COUNCIL_ADMIN'}
                                       customLabel={t('COUNCIL_ADMIN')}
                                       multi/>
                    <ValidatedCheckbox name="roles"
                                       value={'CM_ADMIN'}
                                       customLabel={t('CM_ADMIN')}
                                       multi/>
                    <ValidatedCheckbox name="roles"
                                       value={'UPLOAD_ADMIN'}
                                       customLabel={t('UPLOAD_ADMIN')}
                                       multi/>
                </ValidateCheckboxGroup>
            </Section>

            {!showActive ? "" :
                <Section title={t("status")}>
                    <ValidatedCheckbox name="active" label={t('active')}/>
                </Section>
            }

            <ValidatedSubmit api={api}
                             view={view}
                             onCancel={onCancel}/>
        </ContentBox>
    );
});