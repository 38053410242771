import Grid from "@material-ui/core/Grid";
import Button from "../../../material-kit/components/CustomButtons/Button";
import {navigate} from "hookrouter";
import React from "react";

const NavigateToContent = ({url, label}) => {

    return (
        <Grid container justify={"center"}>
            <Grid item>
                <Button
                    color="pinterest"
                    onClick={() => navigate(url)}
                >
                    {label}
                </Button>
            </Grid>
        </Grid>
    );

};

export default NavigateToContent