import Button from "@material-ui/core/Button";
import React from "react";
import {makeStyles} from "@material-ui/core";
import {loadMoreBtnStyle} from "./loadMoreBtnStyle";
import Grid from "@material-ui/core/Grid";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles(loadMoreBtnStyle);

const LoadMoreButton = ({handleClick}) => {

    const classes = useStyles();

    const {t} = useTranslation('general');

    return (
        <Grid item container xs={12} justify='center' alignItems='center' className={classes.btnContainer}>
            <Button variant='contained' onClick={handleClick} color='secondary' size='large'>
                {t('loadMore')}
            </Button>
        </Grid>
    )
};

export default LoadMoreButton;