import React from "react";
import Section from "../../../../form/Components/Section/Section";
import Grid from "@material-ui/core/Grid";
import {makeStyles} from "@material-ui/core";
import {cityCouncilPageEditorStyle} from "./cityCouncilPageEditorStyle";
import {useCityCouncilPages} from "../../Api/cityCouncilPages";
import Form from "./Form";

const useStyles = makeStyles(cityCouncilPageEditorStyle);

const CityCouncilPageEditor = () => {
    const classes = useStyles();

    const {data} = useCityCouncilPages();

    return (
        <Section>
            <Grid container>
                <Grid item xs={12} md={8} className={classes.editorBlock}>
                    {data.map(record => <Form data={record} key={record.id} view='edit'/>)}
                </Grid>
            </Grid>
        </Section>
    );

};

export default CityCouncilPageEditor;