import React, {memo, useContext, useEffect, useState} from "react";
import {baseTableStyles} from "../../Lib/simplexion-table/styles/baseStyle";
import debug from "../../Lib/debug";
import {showUpItem, useItems} from "../../Api/items";
import {customActions, customActionsStyle} from "../../Lib/simplexion-table/templates/action-buttons";
import ContentBox from "../../Components/ContentBox/ContentBox";
import {makeStyles} from "@material-ui/core";
import Watcher from "../Watchers/Watchers";
import {AuthContext} from "../../Lib/auth/AuthContext";
import {roles} from "../../Main/roles";
import {translatableField} from "../../Lib/simplexion-table/templates/translatable-field";
import {useTranslation} from "react-i18next";
import PublicList from "./PublicList";
import Section from "../../Lib/form/Components/Section/Section";
import {FilterField} from "../../Lib/Table/Components/FilterField";
import LinearProgress from "@material-ui/core/LinearProgress";
import {SimplexionTable} from "../../Lib/simplexion-table/SimplexionTable";
import Grid from "@material-ui/core/Grid";
import FilteringByEnum from "../../Components/FilteringByStatus";
import {TypeOfAd} from "../../Data/Item";

const styles = () => {
    let classes = baseTableStyles;
    Object.entries(customActionsStyle).forEach(entry => {
        classes[entry[0]] = entry[1];
    });
    return classes;
};

const useStyles = makeStyles(styles);

export const statusColumn = (accessor, record, helperText) => {
    return `<div class="showUp" style="cursor: pointer">${record.status === "PENDING" || record.status === "HIDDEN" ?
        `<span id=${record.id} data-status="APPROVED" style="color:orangered" class="material-icons" title="${helperText.invisible}">visibility_off</span>` :
        `<span id=${record.id} data-status="HIDDEN" style="color:green" class="material-icons" title="${helperText.visible}">visibility</span>`}</div>`
}

const List = memo((props) => {

    const {path} = props;

    const [num, setNum] = useState(0)

    const {t} = useTranslation("items")

    const [type, setType] = useState(undefined);

    const onSelectedType = val => setType(val);

    debug("RENDER", "useForm", () => [" -> List_Of_Items", props]);

    const authContext = useContext(AuthContext);

    const hasAdminRoles = authContext.getIn(['session', 'roles']).some(role => [roles.KSZSZK_ADMIN.toLowerCase(), roles.SECURITY_OFFICER.toLowerCase()].indexOf(role) !== -1);

    const [itemId, setItemId] = useState(0)

    const {loadingStatus, data} = useItems({type: type ? type : undefined}, num);

    const urlNs = path._tail.array[1].url + "/";

    const tableClasses = useStyles();

    const [state, setState] = useState('');

    const handleFilter = val => setState(val);

    const [popup, setPopup] = useState(false)

    useEffect(() => {
    }, [itemId, popup, type])


    const handleClose = () => {
        setItemId(0)
        setPopup(false)
        setNum(num => num + 1)
    };

    const handleOpen = (id) => {
        setItemId(id)
        setPopup(true)
    };

    const handleClick = (event) => {
        if (hasAdminRoles && event.target.parentElement && event.target.parentElement.classList && event.target.parentElement.classList.length > 0) {
            if (event.target.parentElement.classList.contains("toApply")) {
                handleOpen(event.target.id)
            }
            if (event.target.parentElement.classList.contains("showUp")) {
                showUpItem(event.target.id, event.target.getAttribute("data-status"))
                setNum(num => num + 1)
            }
        }
    };

    useEffect(() => {
        window.addEventListener('click', handleClick);
        // cleanup this component
        return () => {
            window.removeEventListener('click', handleClick);
        };
    }, []);

    useEffect(() => {
    }, [num])

    const tableConfig = {
        id: 'items-table',
        nameSpace: 'items',
        stickyHeader: true,
        sortable: true,
        styles: tableClasses,
        columns: [
            {
                accessor: 'name',
            },
            {
                accessor: 'translCategory',
            },
            {
                accessor: 'translTypeOfAd',
            },
            {
                accessor: 'createdAt',
            },
            {
                accessor: 'numberOfWatchers',
            },

            {
                accessor: 'type',
                renderer: (...args) => translatableField(...args),
                sortable: false,
            }, {
                accessor: 'status',
                renderer: (accessor, record) => statusColumn(accessor, record, {
                    visible: t("visible"),
                    invisible: t("invisible")
                })
            },
            {
                accessor: 'action',
                renderer: (...args) => customActions(...args, true, false, urlNs),
                sortable: false,
            }
        ]

    };

    return (
        <ContentBox publicPage={!hasAdminRoles} path={path} add={hasAdminRoles ? urlNs + "add" : null}>
            <div id={"watchers"}>
                {itemId > 0 ?
                    <Watcher hasAdminRoles={hasAdminRoles} itemId={itemId} closeModal={handleClose}
                             open={popup}/> : null}
            </div>
            {hasAdminRoles ? <Section>
                <Grid container direction="row" justify="space-between" alignItems="center">
                    <Grid item={true}>
                        <FilterField handler={handleFilter}/>
                    </Grid>
                    <Grid item={true}>
                        <FilteringByEnum onSelected={onSelectedType} arrayValue={TypeOfAd.concat(null)}
                                         enum={type} name="typeOfAd"/>
                    </Grid>
                </Grid>
                {loadingStatus > 0 ? <LinearProgress/> : null}
                <SimplexionTable tableConfig={tableConfig} data={data} loadingStatus={loadingStatus}
                                 filterValue={state}/>

            </Section> : <PublicList data={data} type={type} onSelectedType={onSelectedType} handleOpen={handleOpen}/>}
        </ContentBox>
    );
});

export default List;


