import React, {useContext, useState} from "react";
import useForm from "../../../../form/useForm";
import {ContentRecord, ContentSchema} from "../../../MenuBuilder/Data/Records/Content";
import FormProvider from "../../../../form/Components/FormProvider/FormProvider";
import Section from "../../../../form/Components/Section/Section";
import {useTranslation} from "react-i18next";
import ValidatedSelect from "../../../../form/Components/ValidatedSelect/ValidatedSelect";
import ValidatedInput from "../../../../form/Components/ValidatedInput/ValidatedInput";
import ValidatedSubmit from "../../../../form/Components/ValidatedSubmit/ValidatedSubmit";
import {addContent, removeContent, showUpContent, updateContent} from "../../../MenuBuilder/Api/content";
import {navigate} from 'hookrouter';
import {Record} from 'immutable';
import Button from "../../../../material-dashboard/components/CustomButtons/Button";
import Grid from "@material-ui/core/Grid";
import RemoveItemDialog from "../../../../../Components/RemoveItemDialog/RemoveItemDialog";
import OpenDialogButton from "../../../../../Components/RemoveItemDialog/OpenDialogButton";
import ContentBox from "../../../../../Components/ContentBox/ContentBox";
import {AuthContext} from "../../../../auth/AuthContext";
import {hasRole} from "../../../../auth/auth";
import {roles} from "../../../../../Main/roles";
import HideOrActivationItemDialog from "../../../../../Components/RemoveItemDialog/HideItemDialog";

const Controllers = ({view, isEditing, editHandler, openModal, isRemovable, isHidable, isActive}) => {

    const {t} = useTranslation('contentManagement');

    const api = view === 'add' ?
        addContent :
        updateContent;

    const onSuccess = data => {
        if (view === 'add') {
            navigate(`/content-management/${data.id}/edit`, true);
        }
        if (isEditing) {
            editHandler(false);
        }
    };

    return (
        <React.Fragment>
            {view === 'add' || (view === 'edit' && isEditing) ?
                <ValidatedSubmit view={view}
                                 api={api}
                                 onSuccess={onSuccess}
                                 hideCancel={view === 'edit'}
                /> :
                <Grid item xs={12}>
                    <Button
                        color={"primary"}
                        onClick={() => navigate('/content-management')}
                    >
                        {t('back')}
                    </Button>
                    <Button color="primary"
                            onClick={() => editHandler(true)}
                    >
                        {t('edit')}
                    </Button>
                    {isRemovable &&
                    <OpenDialogButton openModal={openModal}
                                      text={t('delete')}/>
                    }
                    {isHidable &&
                    <OpenDialogButton openModal={openModal}
                                      text={isActive ? t('hide') : t('activation')} isHide={isHidable}/>
                    }
                </Grid>
            }
        </React.Fragment>
    )
};


const MenuItemForm = ({view, record, parent, itemBefore}) => {

    const {t} = useTranslation("contentManagement");

    const context = useContext(AuthContext)

    const isUploadAdmin = hasRole(context, roles.UPLOAD_ADMIN);

    const [state, setState] = useState({
        isEditing: false,
        showModal: false,
        showHideModal: false
    });

    record = view === 'add' ?
        new ContentRecord({
            parent: parent,
            itemBefore: itemBefore,
            language: 'HU',
        }) :
        record;

    const form = useForm({
        recordType: ContentRecord,
        recordSchema: ContentSchema,
        namespace: 'contentManagement',
        record: record
    });

    const handleEdit = val => setState(prevState => ({
        ...prevState,
        isEditing: val,
    }));

    const handleClose = () => {
        setState(prevState => ({
            ...prevState,
            showModal: false,
            showHideModal: false
        }))
    };

    const handleOpen = () => {
        setState(prevState => ({
            ...prevState,
            showModal: true,
        }))
    };

    const handleHideModal = () => {
        if (!activation) {
            setState(prevState => ({
                ...prevState,
                showHideModal: true,
            }))
        } else {
            showUpApi()
        }
    };

    const removeApi = () => {
        removeContent(record, () => {
            handleClose();
            navigate('/content-management')
        })
    };

    const showUpApi = () => {
        showUpContent(record, activation ? "PUBLIC" : "HIDDEN", () => {
            if (!activation) handleClose()
            navigate('/content-management')
        })
    }

    const isRemovable = record.parent && record.type !== 'KOLORAPP' && record.type !== 'CITY_COUNCIL' && record.type !== 'LIST_OF_ITEM';
    const isHidable = record.parent && record.type === 'LIST_OF_ITEM';
    const activation = record.parent && record.status === "HIDDEN"

    return (
        <FormProvider form={form}>
            <Section title={`${t('navItem')} ${t(view)}`}>
                <ValidatedSelect name="type"
                                 label={t('type', {context: 'navItem'})}
                                 api={() => getTypes(t, view, isUploadAdmin)}
                                 specialId='value'
                                 disabled={view === 'edit'}
                />
                <ValidatedInput name="title"
                                label={t('title', {context: 'navItem'})}
                                disabled={view === 'edit' && !state.isEditing}
                />
                <Controllers view={view}
                             isEditing={state.isEditing}
                             editHandler={handleEdit}
                             openModal={(isHidable) ? handleHideModal : handleOpen}
                             isRemovable={isRemovable}
                             isHidable={isHidable}
                             isActive={!activation}
                />
                {isRemovable &&
                <RemoveItemDialog title={record.title}
                                  ns={'contentManagement'}
                                  api={removeApi}
                                  open={state.showModal}
                                  closeModal={handleClose}
                />
                }
                {isHidable &&
                <HideOrActivationItemDialog title={record.title}
                                            ns={'items'}
                                            api={showUpApi}
                                            open={state.showHideModal}
                                            closeModal={handleClose}
                                            isHide={activation}
                />
                }
            </Section>
        </FormProvider>
    );

};

const MenuItemEditor = (props) => {

    const {t} = useTranslation("contentManagement");

    const path = [
        {label: t('titleNav', {context: 'content'}), url: '/content-management'},
        {label: t('add'), url: ''},
    ];

    return (
        <React.Fragment>
            {props.view === 'add' ?
                <ContentBox path={path}>
                    <MenuItemForm {...props}/>
                </ContentBox> :
                <MenuItemForm {...props}/>
            }
        </React.Fragment>
    );
};

export default MenuItemEditor;

const getTypes = (t, view, isUploadAdmin) => {

    let types = [
        'BLOCK',
        'TEXT',
        'FILE_UPLOAD',
        'KOLORAPP',
        'CITY_COUNCIL',
        "LIST_OF_ITEM"
    ];

    if (view === 'add') {

        types = types.filter(type => type !== 'KOLORAPP' && type !== 'CITY_COUNCIL' && type !== "LIST_OF_ITEM");

        if (isUploadAdmin) types = types.filter(type => type === 'FILE_UPLOAD');

    }

    const data = types.map((type, index) => {
        return new Record({
            id: index,
            value: type,
            name: t(type),
        })();
    });

    return {data: data};
};