import React from "react";
import {useTranslation} from "react-i18next";
import ContentBox from "../../../../Components/ContentBox/ContentBox";
import NavDesignEditor from "../Components/NavDesignEditor/NavDesignEditor";
import BackgroundEditor from "../Components/BackgroundEditor/BackgroundEditor";


const DesignEditor = () => {

    const {t} = useTranslation('contentManagement');

    const path = [{label: t('titleNav', {context: 'design'}), url: ''}];

    return (
        <ContentBox path={path}>
            <NavDesignEditor/>
            <BackgroundEditor/>
        </ContentBox>
    )
};

export default DesignEditor;
