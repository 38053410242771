import React from "react";
import {createRows, getAll, updateData} from "../util";
import {NavColorRecord, NavColorSchema} from "../../ContentManagement/DesignEditor/Data/Records/NavColor";

import {navColorsApiTest} from "../../ContentManagement/DesignEditor/Api/nav-colors";

const record = NavColorRecord(NavColorSchema.default()).withMutations(data => {
    return data.set("lineColor", "#b7b")
        .set("fontColor", "#7bb")
})

const calls = [
    onResponse => getAll("/nav-colors", onResponse),
    onResponse => updateData("/nav-colors/1", record, onResponse),
];

const NavColorsApis = () => {

    return (
        <React.Fragment>
            {createRows(calls, navColorsApiTest)}
        </React.Fragment>
    );
}

export default NavColorsApis;