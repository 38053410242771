import React from "react";
import List from "./List";
import {useRoutes} from "hookrouter";
import {addCommittee, removeCommittee, updateCommittee, useCommittee} from "../../Api/committees";
import {useTranslation} from "react-i18next";
import debug from "../../Lib/debug";
import Form from "./Form";
import {CommitteeRecord, CommitteeSchema} from '../../Data/Committee';

export default () => useRoutes(subRoutes);

const subRoutes = {
  "/": () => <Committees view="list"/>,
  "/add": () => <Committees view="add"/>,
  "/:committeeId/edit": ({committeeId}) => <Committees view="edit" committeeId={committeeId}/>,
  "/:committeeId/remove": ({committeeId}) => <Committees view="remove" committeeId={committeeId}/>,
};

const Committees = ({view, committeeId}) => {

  debug("RENDER", "Committees", () => [view]);

  const {t} = useTranslation('committees');

  const {loadingStatus, data} = useCommittee(committeeId);

  const viewData = (loadingStatus ? new CommitteeRecord(CommitteeSchema.default()) : data);

  const path = [{label: t('titleNav'), url: "/committees"}];

  // noinspection JSCheckFunctionSignatures
  const label = viewData.get('name');

  switch (view) {
    case "list" :

      return <List path={path}/>;

    case "add" :

      path.push({label: label, url: "/committees/add"});
      return <Form committeeId={committeeId} record={viewData} path={path} view={view} api={addCommittee}/>;

    case "remove" :

      path.push({label: label, url: "/committees/" + committeeId + "/remove"});
      return <Form committeeId={committeeId} record={viewData} path={path} view={view} api={removeCommittee}/>;

    case "edit" :

      path.push({label: label, url: "/committees/" + committeeId + "/edit"});
      return <Form committeeId={committeeId} record={viewData} path={path} view={view} api={updateCommittee}/>;

    default :
      return <div>invalid view: {view}</div>
  }
};

