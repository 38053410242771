export const cityCouncilPageHu = {
    "titleNav": "Képviselő testületi anyagok",

    "meeting": "Ülés",
    "meetings": "Ülések",
    "councilMeetings": "Képviselő-testületi ülések",
    "councilMeeting": "Képviselő-testületi ülés",
    "date": "Dátum",
    "time": "Időpont",
    "type": "Típus",
    "place": "Helyszín",
    "committeeName": "Bizottság",
    "committeeMeetings" : "Bizottsági ülések",
    "numberOfElements" :"Napirendi pontok száma",
    "OPEN": "Nyílt",
    "CLOSED": "Zárt",
    "INAUGURAL": "Alakuló ülés",
    "SCHEDULED": "Munkaterv szerinti",
    "EXTRAORDINARY": "Rendkivüli",
    "PUBLIC_HEARING": "Közmeghallgatás",
    "BOARD" : "Testületi",
    "COMMITTEE" : "Bizottsági",

    "item" : "napirendi pont",
    "order" : "Sorszám",
    "subject": "Tárgy",
    "meetingElements": "Napirendi pontok",
    "elementSeq": "Napirend",
    "topic": "Tárgy",
    "presenterName": "Előterjesztő",
    "documents": "Dokumentumok",
    "documentNames": "Dokumentumok",
    "details": "Részletek",
    "committeeNames": "Bizottságok",

    "presenter1" : "Előterjesztő&nbsp;1",
    "presenter2" : "Előterjesztő&nbsp;2",

    "decisions": "Határozatok",
    "numberOfDecision": "Határozat száma",
    "numberOfDecision-invalid": "Hibás: a karakterek száma 1 és 32 között kell legyen.",
    "decisionText": "Határozat szövege",
    "decisionText-invalid" : "Hibás: a határozat szövege legfeljebb 10000 karakter lehet.",
    "title": "Cím",
    "title-invalid" : "Hibás: a karakterek száma 1 és 256 között kell legyen.",

    "meetingRecord": "Jegyzőkönyv",
    "committeeRecord": "Jegyzőkönyv",
    "downloadMeetingRecord": "Jegyzőkönyv letöltése"
};