export const searchResultsPageStyle = theme => ({
    header: {
        marginBottom: '3rem',
    },
    title: {
        marginBottom: '2rem',
        fontWeight: 'bold'
    },
    result: {
        display: 'flex',
        textDecoration: 'none',
        color: theme.palette.text.primary,
        borderLeft: `2px solid ${theme.palette.kolorcity[5]}`,
        paddingLeft: theme.spacing(1),
        cursor: 'pointer',
        wordBreak: 'break-word',
        textOverflow: 'hidden',
        '&:active': {
            color: theme.palette.text.primary,
        },
        '&:focus': {
            color: theme.palette.text.primary,
        }
    },
});