import baseFetch, {useFetchOne} from "../../../baseFetch";
import {ContentRecord} from "../Data/Records/Content";
import {roles} from "../../../../Main/roles";
import {contentsStatusTest, contentStatusTest} from "../../../testing-tools/data-validation-tests";

export function useContent(id) {
    return useFetchOne('/contents', id, ContentRecord);
}

export function addContent(content, onSuccess, onError) {
    baseFetch('POST', '/contents/', content,
        data => {
            if (onSuccess) onSuccess(new ContentRecord(data))
        },
        onError
    );
}

export function getContents(filter, onSuccess, onError) {
    baseFetch('GET', '/contents', filter,
        data => {
            if (onSuccess) onSuccess(data.map(r => new ContentRecord(r)))
        },
        onError
    );
}

export function getContent(id, onSuccess, onError) {
    baseFetch('GET', '/contents/' + id, undefined,
        data => {
            if (onSuccess) onSuccess(new ContentRecord(data))
        },
        onError
    );
}

export function updateContent(content, onSuccess, onError) {
    baseFetch('PATCH', '/contents/' + content.get('id'), content, onSuccess, onError);
}

export function removeContent(content, onSuccess, onError) {
    baseFetch('DELETE', '/contents/' + content.get('id'), undefined, onSuccess, onError);
}

export function moveContent(move, onSuccess, onError) {
    baseFetch('PATCH', '/contents/move-content', move, onSuccess, onError);
}


export function showUpContent(content, status, onSuccess, onError) {
    baseFetch('PATCH', '/contents/' + content.get('id') + "/show-up?status=" + status, undefined, onSuccess, onError);
}


export const contentsApiTest = {
    "/contents": {
        'GET': {
            [roles.ANONYMOUS]: {
                hasResponse: true,
                validate: contentsStatusTest
            },
            [roles.SITE_MEMBER]: {
                hasResponse: true,
                validate: contentsStatusTest
            },
            [roles.COUNCIL_ADMIN]: {
                hasResponse: true,
                validate: contentsStatusTest
            },
            [roles.CM_ADMIN]: {hasResponse: true},
            [roles.UPLOAD_ADMIN]: {hasResponse: true},
            [roles.ADMIN]: {hasResponse: true,},
            [roles.SECURITY_OFFICER]: {hasResponse: true}
        },
        'POST': {
            [roles.ANONYMOUS]: {hasResponse: false},
            [roles.SITE_MEMBER]: {hasResponse: false},
            [roles.COUNCIL_ADMIN]: {hasResponse: false,},
            [roles.CM_ADMIN]: {hasResponse: true},
            [roles.UPLOAD_ADMIN]: {hasResponse: true},
            [roles.ADMIN]: {hasResponse: true,},
            [roles.SECURITY_OFFICER]: {hasResponse: true}
        }
    },
    "/contents/1": {
        'GET': {
            [roles.ANONYMOUS]: {
                hasResponse: true,
                validate: contentStatusTest
            },
            [roles.SITE_MEMBER]: {
                hasResponse: true,
                validate: contentStatusTest
            },
            [roles.COUNCIL_ADMIN]: {
                hasResponse: true,
                validate: contentStatusTest
            },
            [roles.CM_ADMIN]: {hasResponse: true},
            [roles.UPLOAD_ADMIN]: {hasResponse: true},
            [roles.ADMIN]: {hasResponse: true,},
            [roles.SECURITY_OFFICER]: {hasResponse: true}
        },
        'PATCH': {
            [roles.ANONYMOUS]: {hasResponse: false},
            [roles.SITE_MEMBER]: {hasResponse: false},
            [roles.COUNCIL_ADMIN]: {hasResponse: false,},
            [roles.CM_ADMIN]: {hasResponse: true},
            [roles.UPLOAD_ADMIN]: {hasResponse: true},
            [roles.ADMIN]: {hasResponse: true,},
            [roles.SECURITY_OFFICER]: {hasResponse: true}
        },
    },
    "/contents/16": {
        'DELETE': {
            [roles.ANONYMOUS]: {hasResponse: false},
            [roles.SITE_MEMBER]: {hasResponse: false},
            [roles.COUNCIL_ADMIN]: {hasResponse: false,},
            [roles.CM_ADMIN]: {hasResponse: true},
            [roles.UPLOAD_ADMIN]: {hasResponse: true},
            [roles.ADMIN]: {hasResponse: true,},
            [roles.SECURITY_OFFICER]: {hasResponse: true}
        }
    },
    "/contents/move-content": {
        'PATCH': {
            [roles.ANONYMOUS]: {hasResponse: false},
            [roles.SITE_MEMBER]: {hasResponse: false},
            [roles.COUNCIL_ADMIN]: {hasResponse: false},
            [roles.CM_ADMIN]: {hasResponse: true},
            [roles.UPLOAD_ADMIN]: {hasResponse: true},
            [roles.ADMIN]: {hasResponse: true,},
            [roles.SECURITY_OFFICER]: {hasResponse: true}
        }
    },
}