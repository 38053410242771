import {makeStyles} from "@material-ui/core";
import {contentStyle} from "./contentStyle";
import {designEditorStyle} from "../designEditorStyle";
import React, {useCallback, useContext, useEffect, useState} from "react";
import useForm, {FormContext} from "../../../../form/useForm";
import {NavColorRecord, NavColorSchema} from "../../Data/Records/NavColor";
import Grid from "@material-ui/core/Grid";
import FormProvider from "../../../../form/Components/FormProvider/FormProvider";
import ValidatedInput from "../../../../form/Components/ValidatedInput/ValidatedInput";
import ValidatedSubmit from "../../../../form/Components/ValidatedSubmit/ValidatedSubmit";
import {getNavColors, updateNavColor} from "../../Api/nav-colors";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import {useTranslation} from "react-i18next";
import Section from "../../../../form/Components/Section/Section";

const useContentStyles = makeStyles(contentStyle);
const useStyles = makeStyles(designEditorStyle);

const ColorSet = ({record}) => {

    const classes = useStyles();

    const [state, setState] = useState(record);

    const form = useForm({
        recordType: NavColorRecord,
        recordSchema: NavColorSchema,
        namespace: 'contentManagement',
        record: record,
    });

    const onSuccess = data => {
        setState(data)
    };

    return (
        <Grid item container xs={12} className={classes.colorSetContainer}>
            <FormProvider form={form} className={classes.colorPickerForm}>
                <Grid container>
                    <Grid item container xs={3}
                          className={classes.colorPickerFormGrid}>
                        <ContentItem lineColor={state.lineColor} fontColor={state.fontColor} title={state.id}/>
                    </Grid>
                    <Grid item container xs={2}
                          className={classes.colorPickerFormGrid}>
                        <ValidatedInput name={'lineColor'}
                                        formControlClassName={classes.colorPickerFormControl}/>
                    </Grid>
                    <Grid item container xs={2}
                          className={classes.colorPickerFormGrid}>
                        <ValidatedInput name={'fontColor'}
                                        formControlClassName={classes.colorPickerFormControl}/>
                    </Grid>
                    <Grid item container xs={3}
                          className={classes.colorPickerFormGrid}>
                        <Preview record={state}/>
                    </Grid>
                    <Grid item xs={2} className={classes.colorPickerFormGrid}>
                        <ValidatedSubmit view={'edit'}
                                         hideCancel={true}
                                         api={updateNavColor}
                                         onSuccess={onSuccess}/>
                    </Grid>
                </Grid>
            </FormProvider>
        </Grid>
    );
};

const ContentItem = ({lineColor, fontColor, title}) => {

    const classes = useContentStyles();

    return (
        <Box className={classes.navItemInnerContainer}>
            <Box className={classes.navItem}
                 style={{backgroundColor: lineColor}}
            >
                <Box className={classes.navLink}>
                    <Typography variant='h6'
                                className={classes.navLinkText}
                                style={{color: fontColor}}
                    >
                        {title}
                    </Typography>
                </Box>
            </Box>
        </Box>
    );
};

const Preview = ({record}) => {

    const {fieldValues, validationCallbacks, validationStatuses} = useContext(FormContext);

    // eslint-disable-next-line no-unused-vars
    const [revision, setRevision] = useState(0);

    const isLineValid = validationStatuses.current.lineColor;
    const isFontValid = validationStatuses.current.fontColor;

    const lineColor = isLineValid ? fieldValues.current.lineColor : record.lineColor;
    const fontColor = isFontValid ? fieldValues.current.fontColor : record.fontColor;

    const callback = useCallback(() => {
        setRevision(r => r + 1);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [validationStatuses]);

    useEffect(() => {
        let cba = validationCallbacks.current;

        if (cba.indexOf(callback) === -1) {
            cba.push(callback);
        }
        return () => {
            let index = cba.indexOf(callback);
            if (index !== -1) cba.splice(index, 1);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [callback]);

    return <ContentItem lineColor={lineColor} fontColor={fontColor} title={record.id}/>;
};

const NavDesignEditor = () => {

    const classes = useStyles();

    const {t} = useTranslation('contentManagement');

    const [colors, setColors] = useState([]);

    useEffect(() => {
        getNavColors(undefined, data => {
            setColors(data)
        });
    }, []);

    return (
        <Section title={t('navDesignTitle')}>
            <Grid container className={classes.panel}>
                <Grid container item xs={12}>
                    <Grid item container xs={3} justify='center'>
                        <Typography variant={"subtitle1"} className={classes.header}>
                            {t('currentView')}
                        </Typography>
                    </Grid>
                    <Grid item container xs={2} justify='center'>
                        <Typography variant={"subtitle1"} className={classes.header}>
                            {t('lineColor')}
                        </Typography>
                    </Grid>
                    <Grid item container xs={2} justify='center'>
                        <Typography variant={"subtitle1"} className={classes.header}>
                            {t('textColor')}
                        </Typography>
                    </Grid>
                    <Grid item container xs={3} justify='center'>
                        <Typography variant={"subtitle1"} className={classes.header}>
                            {t('preview')}
                        </Typography>
                    </Grid>
                </Grid>
                {colors.map(c => <ColorSet key={c.id} record={c}/>)}
            </Grid>
        </Section>
    );
};

export default NavDesignEditor;