import React, {useEffect, useState} from "react";
import useForm from "../../../../form/useForm";
import FormProvider from "../../../../form/Components/FormProvider/FormProvider";
import Section from "../../../../form/Components/Section/Section";
import {useTranslation} from "react-i18next";
import ValidatedInput from "../../../../form/Components/ValidatedInput/ValidatedInput";
import ValidatedSubmit from "../../../../form/Components/ValidatedSubmit/ValidatedSubmit";
import {BlockPageRecord, BlockPageSchema} from "../../Data/BlockPage";
import ValidatedRichTextEditor from "../../../../form/Components/ValidatedRichTextEditor/ValidatedRichTextEditor";
import Grid from "@material-ui/core/Grid";
import {blockPageEditorStyle} from "./blockPageEditorStyle";
import {makeStyles} from "@material-ui/core";
import {getBlockPage, updateBlockPage, useBlockItems} from "../../Api/blockPages";
import StatusRadios from "../StatusRadios";
import {baseTableStyles} from "../../../../simplexion-table/styles/baseStyle";
import {customActions, customActionsStyle} from "../../../../simplexion-table/templates/action-buttons";
import {SimplexionTable} from "../../../../simplexion-table/SimplexionTable";
import {navigate} from 'hookrouter';
import Button from "@material-ui/core/Button";

const styles = () => {
    let classes = baseTableStyles;
    Object.entries(customActionsStyle).forEach(entry => {
        classes[entry[0]] = entry[1];
    });
    return classes;
};

const useStyles = makeStyles(blockPageEditorStyle);

const useTableStyles = makeStyles(styles);

const Blocks = ({contentId, blockPageId}) => {

    const tableClasses = useTableStyles();
    const classes = useStyles();

    const {t} = useTranslation('contentManagement');

    const {data} = useBlockItems(blockPageId);

    const tableConfig = {
        id: 'table-with-custom-renderer',
        nameSpace: 'contentManagement',
        styles: tableClasses,
        columns: [
            {
                accessor: 'title',
            },
            {
                accessor: 'actions',
                renderer: (...args) => customActions(...args,
                    true,
                    false,
                    `/content-management/${contentId}/block-pages/${blockPageId}/blocks/`),
            }
        ],
    };

    const handleClick = () => {
        navigate(`/content-management/${contentId}/block-pages/${blockPageId}/blocks/add`);
    };

    return (
        <Section title={`${t('blockItems')}`}>
            <Grid container>
                <Grid item xs={12}>
                    <Button variant='contained' color='primary' className={classes.addItemBtn} onClick={handleClick}>
                        {t('add')}
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <SimplexionTable tableConfig={tableConfig} data={data}/>
                </Grid>
            </Grid>
        </Section>
    );
};

const BlockPageEditor = ({contentId, blockPageId}) => {

    const classes = useStyles();

    const {t} = useTranslation("contentManagement");

    const [record, setRecord] = useState(new BlockPageRecord());

    const form = useForm({
        recordType: BlockPageRecord,
        recordSchema: BlockPageSchema,
        namespace: 'contentManagement',
        record: record
    });

    useEffect(() => {
            getBlockPage(blockPageId, data => {
                setRecord(data);
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const api = updateBlockPage;

    return (
        <React.Fragment>
            <FormProvider form={form}>
                <Section title={`${t('subpage')} ${t('edit')}`}>
                    <Grid container>
                        <Grid item xs={12}>
                            <ValidatedInput name='title'
                                            label={t('title', {context: 'subpage'})}
                            />
                        </Grid>
                        <Grid item xs={12} md={8} className={classes.editorBlock}>
                            <Grid item className={classes.descriptionContainer}>
                                <ValidatedRichTextEditor name='description'
                                                         showLabel={true}
                                />
                            </Grid>
                            <StatusRadios/>
                        </Grid>
                    </Grid>
                    <ValidatedSubmit view='edit'
                                     api={api}
                                     hideCancel={true}
                    />
                </Section>
            </FormProvider>
            <Blocks blockPageId={blockPageId} contentId={contentId}/>
        </React.Fragment>
    );

};

export default BlockPageEditor;