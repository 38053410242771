export const kolorAppHu = {
    "type": "Bejelentés tipusa",
    "name": "Név",
    "emailAddress": "Email-cím",
    "location": "Helyszín",
    "textContent": "Bejelentés szövege",
    "pictures": "Képek",
    "addAttachment": "Hozzáadás",
    "add": "Bejelentés küldése",
    "save": "Bejelentés küldése",
};

export const kolorAppEn = {
    "type": "Type of the notification",
    "name": "Name",
    "emailAddress": "Email address",
    "location": "Location",
    "textContent": "Text of the notification",
    "pictures": "Pictures",
    "addAttachment": "Upload picture",
};

export const kolorAppDe = {
    "type": "",
    "name": "",
    "emailAddress": "",
    "location": "",
    "textContent": "",
    "pictures": "",
    "addAttachment": "",
};