/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from "react";
import Grid from "@material-ui/core/Grid";
import {makeStyles, Typography} from "@material-ui/core";
import ContentBox from "../../../../Components/ContentBox/ContentBox";
import {useTranslation} from "react-i18next";
import useNav from "../../../../Lib/useNav";
import {SimplexionTable} from "../../../../Lib/simplexion-table/SimplexionTable";
import {linkAction} from "../../../../Lib/simplexion-table/templates/link-button";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import customInputStyle from "../../../../Lib/material-dashboard/assets/jss/components/customInputStyle";
import {useDecisionsByYear} from "../../../../Api/decisions";
import {cityCouncilPageStyle} from "../../cityCouncilPageStyle";
import {useCityCouncilPageByType} from "../../../../Lib/ContentManagement/ContentEditor/Api/cityCouncilPages";
import Box from "@material-ui/core/Box";
import {themedTableStyles} from "../../../../Lib/simplexion-table/styles/themedTableStyles";

const useStyles = makeStyles(cityCouncilPageStyle);
const useTableStyles = makeStyles(themedTableStyles);
const useFormStyles = makeStyles(customInputStyle);

const Decisions = ({pageId, selectedYear}) => {

    const classes = useTableStyles();

    useNav({path: window.location.pathname});

    const {data} = useDecisionsByYear(selectedYear);

    const tableConfig = {
        id: 'decisions-table',
        nameSpace: 'cityCouncilPage',
        stickyHeader: true,
        sortable: true,
        styles: classes,
        columns: [
            {
                accessor: 'numberOfDecision',
            },
            {
                accessor: 'title',
            },
            {
                accessor: '',
                renderer: (...args) => linkAction(...args, 'details', `/city-council-pages/${pageId}/decisions/`)
            }
        ],
    };
    return (
        <SimplexionTable tableConfig={tableConfig} data={data}/>
    );
};

const YearSelect = ({selectedYear, handleSelect}) => {

    const classes = useFormStyles();
    const {t} = useTranslation('cityCouncilPage');

    const [years, setYears] = useState([]);

    useEffect(() => {
        const arr = getYears();
        setYears(arr);
        handleSelect(arr[0]);
    }, []);

    return (
        <Grid item xs={12} md={4}>
            {selectedYear &&
            <FormControl fullWidth
                         className={classes.selectFormControl}>
                <Select MenuProps={{className: classes.selectMenu}}
                        classes={{select: classes.select}}
                        value={selectedYear}
                        onChange={(e) => handleSelect(e.target.value)}
                        inputProps={{
                            name: t('years'),
                            id: 'year',
                            value: selectedYear,
                        }}>
                    {years.map(item =>
                        <MenuItem classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected
                        }}
                                  key={item}
                                  value={item}>
                            {`${item}`}
                        </MenuItem>
                    )}
                </Select>
            </FormControl>
            }
        </Grid>
    );
};

const DecisionList = ({pageId, path}) => {

    useNav({path: window.location.pathname});

    const classes = useStyles();
    const {t, i18n} = useTranslation('cityCouncilPage');

    const [selectedYear, setSelectedYear] = useState(null);
    const [revision, setRevision] = useState();

    const {data, loadingStatus} = useCityCouncilPageByType('DECISIONS_PUBLIC', revision);

    const handleSelect = val => {
        setSelectedYear(val);
    };

    useEffect(() => {
        setRevision(prev => prev + 1)
    }, [i18n.language]);

    if (loadingStatus) return null;

    return (
        <ContentBox publicPage={true} path={path}>
            <Grid container className={classes.root} component='div'>
                <Grid item xs={12} className={classes.header}>
                    <Typography variant='h5' className={classes.title}>
                        {t('decisions')}
                    </Typography>
                    <Box className={classes.description + " ql-display"} dangerouslySetInnerHTML={{__html: data.text}}/>
                </Grid>
                <Grid container className={classes.selectContainer}>
                    <YearSelect selectedYear={selectedYear} handleSelect={handleSelect}/>
                </Grid>
                {selectedYear &&
                <Decisions selectedYear={selectedYear} pageId={pageId}/>
                }
            </Grid>
        </ContentBox>
    );
};

const getYears = () => {
    const date = new Date();
    const currentYear = date.getFullYear();
    let years = [];
    for (let i = currentYear; i >= 2003; i--) {
        years.push(i);
    }
    return years;
};

export default DecisionList;