import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import useForm from "../../Lib/form/useForm";
import ValidatedInput from "../../Lib/form/Components/ValidatedInput/ValidatedInput";
import Card from "../../Lib/material-dashboard/components/Card/Card";
import CardBody from "../../Lib/material-dashboard/components/Card/CardBody";
import ValidatedSubmit from "../../Lib/form/Components/ValidatedSubmit/ValidatedSubmit";
import {useSnackbar} from "notistack";
import Grid from "@material-ui/core/Grid";

import accountStyle from "./accountStyle";
import {makeStyles} from "@material-ui/styles";
import {PasswordRecord, PasswordSchema} from "../../Data/Account";
import {resetCredentials} from "../../Api/accounts";
import Section from "../../Lib/form/Components/Section/Section";
import ContentBox from "../../Components/ContentBox/ContentBox";
import {LoginButton} from "./Accounts";
import {closeSnack} from "../../Main/util";
import {useBackground} from "../../Lib/ContentManagement/ContentEditor/Api/pages";
import Box from "@material-ui/core/Box";

const useStyles = makeStyles(accountStyle);

/**
 * Checks if the password verification is the same as password.
 */
export const passwordValidator = (fieldName, fieldValue, fieldValues) => {
    return fieldValues["newPassword"] === fieldValue;
};

const ResetFinish = ({id, token}) => {

    const [success, setSuccess] = useState(false);

    const {enqueueSnackbar, closeSnackbar} = useSnackbar();

    const {data, loadingStatus} = useBackground();

    const api = (record, onSuccess, onError) => {
        resetCredentials(id, record, onSuccess, onError)
    };

    const cbSuccess = () => {
        setSuccess(true);
    };

    const cbError = () => {
        enqueueSnackbar(
            t('reset-finish-error'),
            {variant: 'warning', persist: true, action: (key) => closeSnack(t, closeSnackbar, key)});
    };

    const {t} = useTranslation("account");

    // noinspection JSPotentiallyInvalidConstructorUsage
    const form = useForm({
        recordType: PasswordRecord,
        recordSchema: PasswordSchema,
        namespace: 'account',
        record: new PasswordRecord({oldPassword: token})
    });

    const classes = useStyles();

    if (success) {
        return (
            <ContentBox publicPage={true} title={t("reset-finish-success")}>
                <Section title={t("reset-finish-success")}>
                    <br/>
                    {t('reset-finish-success-explanation')}
                    <LoginButton t={t} classes={classes}/>
                </Section>
            </ContentBox>
        )
    }

    return (
        <ContentBox publicPage={true} form={form} formClassName={classes.form}>

            <Box className={classes.panel}>
                <Box className={classes.paperContainer}
                     style={loadingStatus ? {} : {backgroundImage: `url(/api/1.0.0/images/${data})`}}>
                    <Card className={classes.container}>
                        <CardBody>
                            <Grid container justify="center">

                                <Grid item xs={12}>
                                    <div className={classes.title}>{t('reset-finish-title')}</div>
                                    <p>{t('reset-finish-explanation')}</p>
                                </Grid>

                                <Grid item xs={12}>
                                    <ValidatedInput name="oldPassword" password label="secret"/>
                                    <ValidatedInput name="newPassword" password/>
                                    <ValidatedInput name="verification" password validator={passwordValidator}/>

                                    <Grid item container justify="space-between">
                                        <Grid item style={{marginTop: 40}}>
                                            <ValidatedSubmit
                                                api={api}
                                                hideCancel
                                                submitLabel={t('reset-finish-button')}
                                                onSuccess={cbSuccess}
                                                onError={cbError}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>

                            </Grid>
                        </CardBody>
                    </Card>
                </Box>
            </Box>
        </ContentBox>
    );
};

export default ResetFinish;