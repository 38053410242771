import React, {memo, useContext} from "react";
import {useTranslation} from "react-i18next";
import ContentBox from "../../Components/ContentBox/ContentBox";
import Section from "../../Lib/form/Components/Section/Section";
import {A, useRoutes} from "hookrouter";
import Button from "../../Lib/material-dashboard/components/CustomButtons/Button";
import {Lock} from "@material-ui/icons";
import {makeStyles, Typography} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import {AuthContext} from "../../Lib/auth/AuthContext";
import {profileStyle} from "./profileStyle";
import {useCityCouncilPageByType} from "../../Lib/ContentManagement/ContentEditor/Api/cityCouncilPages";
import Box from "@material-ui/core/Box";
import {useAccount, usePositionsByAccount} from "../../Api/accounts";
import PasswordChange from "../Account/PasswordChange";

const useStyles = makeStyles(profileStyle);

const subRoutes = {
    "/": () => <Profile/>,
    "/password/:id": ({id}) => <PasswordChange id={id}/>,
};

export default () => useRoutes(subRoutes);

const Greeting = () => {

    const classes = useStyles();

    const {data, loadingStatus} = useCityCouncilPageByType('COUNCIL_MEMBER_NON_PUBLIC');

    if (loadingStatus) return null;

    return (
        <Grid item xs={12} className={classes.header}>
            <Box className={classes.description} dangerouslySetInnerHTML={{__html: data.text}}/>
        </Grid>
    );
};

const Positions = ({accountId}) => {

    const {t} = useTranslation('councilMembers');

    const classes = useStyles();

    const {data, loadingStatus} = usePositionsByAccount(accountId);

    if (loadingStatus) return null;

    return (
        <Grid item container xs={12} className={classes.dataRow}>
            <Grid item xs={5}>
                <Typography variant='body1' className={classes.fieldName}>
                    {t('positionsByCommittee')}:
                </Typography>
            </Grid>
            <Grid item xs={7}>
                {data.map(position => {
                    return (
                        <Grid item xs={12} key={position.id}>
                            <Typography variant='body1' className={classes.fieldData}>
                                {position.committeeName}: {position.name}
                            </Typography>
                        </Grid>
                    );
                })}
            </Grid>
        </Grid>
    );
};

const Roles = ({roles}) => {

    const {t} = useTranslation('account');

    const classes = useStyles();


    return (
        <Grid item container xs={12} className={classes.dataRow}>
            <Grid item xs={5}>
                <Typography variant='body1' className={classes.fieldName}>
                    {t('roles')}:
                </Typography>
            </Grid>
            <Grid item xs={7}>
                {roles.map(role => {
                    return (
                        <Grid item xs={12} key={role}>
                            <Typography key={role} variant='body1' className={classes.fieldData}>
                                {t(role)}
                            </Typography>
                        </Grid>
                    )
                })}
            </Grid>
        </Grid>
    );
};

const Profile = memo(() => {

    const {t} = useTranslation('profile');

    const classes = useStyles();

    const authContext = useContext(AuthContext);

    const accountId = authContext.getIn(['session', 'accountId']);

    const {data, loadingStatus} = useAccount(accountId);

    if (loadingStatus) return null;

    const path = [{label: t('titleNav'), url: "/admin/personal-account"}];

    return (
        <ContentBox path={path}>
            <Section>
                <Grid container item xs={12}>
                    <Greeting/>
                </Grid>
            </Section>
            <Section title={t('personalData')}>
                <Grid container item xs={12} lg={5} className={classes.data}>
                    <Grid item container xs={12} className={classes.dataRow}>
                        <Grid item xs={5}>
                            <Typography variant='body1' className={classes.fieldName}>
                                {t('fullName')}:
                            </Typography>
                        </Grid>
                        <Grid item xs={7}>
                            <Typography variant='body1' className={classes.fieldData}>
                                {data.fullName}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item container xs={12} className={classes.dataRow}>
                        <Grid item xs={5}>
                            <Typography variant='body1' className={classes.fieldName}>
                                {t('displayName')}:
                            </Typography>
                        </Grid>
                        <Grid item xs={7}>
                            <Typography variant='body1' className={classes.fieldData}>
                                {data.displayName}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item container xs={12} className={classes.dataRow}>
                        <Grid item xs={5}>
                            <Typography variant='body1' className={classes.fieldName}>
                                {t('emailAddress')}:
                            </Typography>
                        </Grid>
                        <Grid item xs={7}>
                            <Typography variant='body1' className={classes.fieldData}>
                                {data.emailAddress}
                            </Typography>
                        </Grid>
                    </Grid>
                    {data.organisationName &&
                    <Grid item container xs={12} className={classes.dataRow}>
                        <Grid item xs={5}>
                            <Typography variant='body1' className={classes.fieldName}>
                                {t('organisationName')}:
                            </Typography>
                        </Grid>
                        <Grid item xs={7}>
                            <Typography variant='body1' className={classes.fieldData}>
                                {data.organisationName}
                            </Typography>
                        </Grid>
                    </Grid>
                    }
                    {data.roles.includes('SITE_MEMBER') ?
                        <Positions accountId={accountId}/> :
                        <Roles roles={data.roles}/>
                    }
                    <Grid item container className={classes.buttonContainer} xs={12}>
                        <A href={"/profile/password/" + accountId}>
                            <Button color="primary" className={classes.smallButton}>
                                <Lock className={classes.icons}/>
                                <span style={{marginLeft: 4}}>
                                    {t('account:password-change')}
                                </span>
                            </Button>
                        </A>
                    </Grid>
                </Grid>
            </Section>
        </ContentBox>
    );
});