import ContentBox from "../../Components/ContentBox/ContentBox";
import React from "react";
import Grid from "@material-ui/core/Grid";
import {makeStyles} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import {useTranslation} from "react-i18next";
import useNav from "../../Lib/useNav";
import {useCityCouncilPageByType} from "../../Lib/ContentManagement/ContentEditor/Api/cityCouncilPages";
import {impressumStyle} from "./impressumStyle";
import {useLanguageRevision} from "../../Lib/useLanguageRevision";


const useStyles = makeStyles(impressumStyle);

const Impressum = () => {

    useNav({path: window.location.pathname});

    const classes = useStyles();
    const {t} = useTranslation('general');

    const revision = useLanguageRevision();

    const {data, loadingStatus} = useCityCouncilPageByType('IMPRESSUM', revision);

    if (loadingStatus) return null;

    const path = [{label: t('Impressum'), url: '/impressum'}];

    return (
        <ContentBox publicPage={true} path={path}>
            <Grid container>
                <Grid item container>
                    <Grid item xs={12} md={9}>
                        <Box className={classes.content + " ql-display"} dangerouslySetInnerHTML={{__html: data.text}}/>
                    </Grid>
                </Grid>
            </Grid>
        </ContentBox>
    );
};

export default Impressum;