import React, {memo} from "react";
import ContentBox from "../../Components/ContentBox/ContentBox";
import {useTemplates} from "../../Api/templates";
import {baseTableStyles} from "../../Lib/simplexion-table/styles/baseStyle";
import {customActions, customActionsStyle} from "../../Lib/simplexion-table/templates/action-buttons";
import {makeStyles} from "@material-ui/core";
import Section from "../../Lib/form/Components/Section/Section";
import {SimplexionTable} from "../../Lib/simplexion-table/SimplexionTable";

const styles = () => {
    let classes = baseTableStyles;
    Object.entries(customActionsStyle).forEach(entry => {
        classes[entry[0]] = entry[1];
    });
    return classes;
};

const useStyles = makeStyles(styles);

const List = memo((props) => {

    const {path} = props;

    const classes = useStyles();

    const { data, loadingStatus } = useTemplates();

    const tableConfig = {
        id: 'notifications-table',
        nameSpace: 'notifications',
        stickyHeader: true,
        sortable: true,
        styles: classes,
        columns: [
            {
                accessor: 'name',
            },
            {
                accessor: 'title',
            },
            {
                accessor: 'actions',
                renderer: (...args) => customActions(...args, true, false, "/admin/notifications/"),
            }
        ],
    };

    if (loadingStatus) return null;

    const notificationsData = data.filter(template => {
        const content = template.content;
        return !content.startsWith('<html');
    });

    return (
        <ContentBox path={path}>
            <Section>
                <SimplexionTable tableConfig={tableConfig} data={notificationsData}/>
            </Section>
        </ContentBox>
    );
});

export default List;