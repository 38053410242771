import {useRoutes} from "hookrouter";
import React from "react";
import BlockPage from "../BlockPage/BlockPage";
import FileUploadPage from "../FileUploadPage/FileUploadPage";
import TextPage from "../TextPage/TextPage";
import KolorApp from "../KolorApp/KolorApp";
import CityCouncilPage from "../CityCouncilPage/Public/CityCouncilPage";
import Items from "../Items/Items";


const subRoutes = {
    "/:type/:id": ({type, id}) => props => <Actuality type={type} pageId={id} {...props}/>,
    "/:type/:id/blocks*": ({type, id}) => props => <Actuality type={type} pageId={id} {...props}/>,
};

export default props => useRoutes(subRoutes)(props);

const Actuality = (props) => {
    const type = props.type;

    switch (type) {
        case 'block-pages':
            return <BlockPage {...props}/>;
        case 'text-pages':
            return <TextPage {...props}/>;
        case 'file-upload-pages':
            return <FileUploadPage {...props}/>;
        case  'kolorapp-pages':
            return <KolorApp {...props}/>;
        case  'city-council-pages':
            return <CityCouncilPage {...props}/>;
        case  'list_of_item':
            return <Items {...props}/>;
        default:
            return <div/>;
    }


};
