import baseFetch, {useFetchOne} from "../Lib/baseFetch";
import {MeetingRecordRecord, MeetingRecordSchema} from "../Data/MeetingRecord";
import {AttachmentRecord} from "../Lib/ContentManagement/ContentEditor/Data/Attachment";
import {roles} from "../Main/roles";

export function useMeetingRecord(recordId) {
    return useFetchOne('/meeting-records', recordId, MeetingRecordRecord, MeetingRecordSchema);
}

export function addMeetingRecord(meetingId, record, onSuccess, onError) {
    baseFetch('POST', '/meetings/' + meetingId + '/meeting-records', record,
        data => {
            if (onSuccess) onSuccess(new MeetingRecordRecord(data))
        },
        onError
    );
}

export function updateMeetingRecord(record, onSuccess, onError) {
    baseFetch('PATCH', '/meeting-records/' + record.get('id'), record, onSuccess, onError);
}

export function removeMeetingRecord(record, onSuccess, onError) {
    baseFetch('DELETE', '/meeting-records/' + record.get('id'), undefined, onSuccess, onError);
}

export function getAttachment(id, onSuccess, onError) {
    baseFetch('GET', '/meeting-record/' + id + '/attachment', undefined,
        data => {
            const record = new AttachmentRecord(data);
            if (onSuccess) onSuccess([record])
        },
        onError
    );
}

export const meetingRecordsApiTest = {
    '/meetings/1/meeting-records': {
        'POST': {
            [roles.ANONYMOUS]: {hasResponse: false},
            [roles.SITE_MEMBER]: {hasResponse: false},
            [roles.COUNCIL_ADMIN]: {hasResponse: true,},
            [roles.CM_ADMIN]: {hasResponse: false},
            [roles.UPLOAD_ADMIN]: {hasResponse: false},
            [roles.ADMIN]: {hasResponse: true,},
            [roles.SECURITY_OFFICER]: {hasResponse: true}
        },
    },
    '/meeting-records/1': {
        'GET': {
            [roles.ANONYMOUS]: {hasResponse: true},
            [roles.SITE_MEMBER]: {hasResponse: true},
            [roles.COUNCIL_ADMIN]: {hasResponse: true},
            [roles.CM_ADMIN]: {hasResponse: true},
            [roles.UPLOAD_ADMIN]: {hasResponse: true},
            [roles.ADMIN]: {hasResponse: true},
            [roles.SECURITY_OFFICER]: {hasResponse: true}
        },
        'PATCH': {
            [roles.ANONYMOUS]: {hasResponse: false},
            [roles.SITE_MEMBER]: {hasResponse: false},
            [roles.COUNCIL_ADMIN]: {hasResponse: true},
            [roles.CM_ADMIN]: {hasResponse: false},
            [roles.UPLOAD_ADMIN]: {hasResponse: false},
            [roles.ADMIN]: {hasResponse: true},
            [roles.SECURITY_OFFICER]: {hasResponse: true}
        },
    },
    '/meeting-records/3': {
        'DELETE': {
            [roles.ANONYMOUS]: {hasResponse: false},
            [roles.SITE_MEMBER]: {hasResponse: false},
            [roles.COUNCIL_ADMIN]: {hasResponse: true},
            [roles.CM_ADMIN]: {hasResponse: false},
            [roles.UPLOAD_ADMIN]: {hasResponse: false},
            [roles.ADMIN]: {hasResponse: true},
            [roles.SECURITY_OFFICER]: {hasResponse: true}
        },
    },
    "/meeting-records/1/attachment": {
        'GET': {
            [roles.ANONYMOUS]: {hasResponse: false},
            [roles.SITE_MEMBER]: {hasResponse: false},
            [roles.COUNCIL_ADMIN]: {hasResponse: true,},
            [roles.CM_ADMIN]: {hasResponse: false},
            [roles.UPLOAD_ADMIN]: {hasResponse: false},
            [roles.ADMIN]: {hasResponse: true,},
            [roles.SECURITY_OFFICER]: {hasResponse: true}
        }
    }
}
