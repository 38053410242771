import React, {memo} from "react";
import {useTranslation} from "react-i18next";
import useForm from "../../Lib/form/useForm";
import {MeetingRecord, MeetingSchema} from '../../Data/Meeting';
import ContentBox from "../../Components/ContentBox/ContentBox";
import Grid from "@material-ui/core/Grid";
import Section from "../../Lib/form/Components/Section/Section";
import ValidatedSubmit from "../../Lib/form/Components/ValidatedSubmit/ValidatedSubmit";
import debug from "../../Lib/debug";
import ValidatedDate from "../../Lib/form/Components/ValidatedDateTime/ValidatedDate";
import ValidatedTime from "../../Lib/form/Components/ValidatedDateTime/ValidatedTime";
import ValidatedSelect from "../../Lib/form/Components/ValidatedSelect/ValidatedSelect";
import {Record} from "immutable";
import {makeStyles} from "@material-ui/core";
import {navigate} from "hookrouter";
import Button from "../../Lib/material-dashboard/components/CustomButtons/Button";
import {useCommittees} from "../../Api/committees";
import {Navigation} from "./Navigation";

const useStyles = makeStyles({
    meetingElementsBtn: {
        float: 'right',
    }
});


export default memo(({meetingId, record, path, view, api}) => {

    debug("RENDER", "Meeting", () => ["Form", meetingId, record, path, view, api]);

    const classes = useStyles();

    const {t} = useTranslation('meetings');

    const form = useForm({
        disabledForm: view === 'remove',
        recordType: MeetingRecord,
        recordSchema: MeetingSchema,
        namespace: 'meetings',
        record: record
    });

    const onCancel = () => navigate(`/meetings`);
    const onSuccess = () => navigate(`/meetings`);

    return (
        <ContentBox path={path} form={form}>
            {view === "add" ? null : <Navigation meetingId={meetingId} recordId={record.meetingRecord}/>}
            <Section title={t(view)}>
                <ValidatedDate name='date'/>
                <ValidatedTime name='time'/>
                <ValidatedSelect name='type'
                                 label={t('type')}
                                 api={() => getTypes(t)}
                                 specialId="value"/>
                <ValidatedSelect name='committee'
                                 label={t('committee')}
                                 api={useCommittees}
                                 />
            </Section>
            <Grid container>
                <Grid item xs={12} md={8}>
                    <ValidatedSubmit view={view} api={api} onSuccess={onSuccess} onCancel={onCancel}/>
                </Grid>
                {view === "edit" &&
                <Grid item xs={12} md={4}>
                    <Button color="primary"
                            className={classes.meetingElementsBtn}
                            onClick={() => navigate(`/meeting-elements/${record.id}`)}>
                        {t('addMeetingElements')}
                    </Button>
                </Grid>
                }
            </Grid>
        </ContentBox>
    );
})

const getTypes = (t) => {
    const types = [
        'SCHEDULED',
        'INAUGURAL',
        'EXTRAORDINARY',
        'PUBLIC_HEARING',
    ];

    const data = types.map((type, index) => {
        return new Record({
            id: index,
            value: type,
            name: t(type),
        })();
    });

    return {data: data};
};