import {makeStyles} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import Grid from "@material-ui/core/Grid";
import PreviewWrapper from "../PreviewWrapper/PreviewWrapper";
import {imageUploadStyle} from "./imageUploadStyle";
import ValidatedFileUploader from "../../Lib/form/Components/ValidatedFileUpload/ValidatedFileUpload";

const useStyles = makeStyles(imageUploadStyle);

const ImageUpload = ({picture}) => {

    const classes = useStyles();

    const [preview, setPreview] = useState({show: true, id: picture});

    const onSuccess = id => {
        setPreview({id: id, show: true});
    };

    useEffect(() => {
        if (!preview.id) {
            setPreview(prev => ({
                ...prev,
                id: picture,
            }))
        }
    }, [picture, preview.id]);

    return (
        <Grid item container xs={12} className={classes.imageContainer} justify='center' alignItems='center'>
            <PreviewWrapper name='picture'>
                <ValidatedFileUploader name='picture'
                                       apiPath='/images'
                                       acceptedFileTypes={['image/*']}
                                       onSuccess={onSuccess}
                />
                <Grid container className={classes.imagePreview}>
                    {preview.id &&
                    <img className={classes.image} src={`/api/1.0.0/images/${preview.id}`} alt=''/>
                    }
                </Grid>
            </PreviewWrapper>
        </Grid>
    );
};


export default ImageUpload;
