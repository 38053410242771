export const types = {
    CREST_SET: 'CREST_SET',
    RELOAD_NAV: 'RELOAD_NAV',
};

export default {

    crestSet: path => ({
        type: types.CREST_SET,
        path: path
    }),

}