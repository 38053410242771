import React from "react";
import {kolorLineNewsStyle as style} from "./kolorLineNewsStyle";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import useNav from "../../Lib/useNav";
import {makeStyles} from "@material-ui/core";
import {useKolorLineNewsOne} from "../../Api/kolorline-news";
import ContentBox from "../../Components/ContentBox/ContentBox";
import {useRoutes} from "hookrouter";
import Box from "@material-ui/core/Box";
import {useTranslation} from "react-i18next";
import {KolorLineNewsRecord, KolorLineNewsSchema} from "../../Data/KolorLineNews";

const useStyles = makeStyles(style);

const subRoutes = {
    "/:id": ({id}) => <KolorLineNews newsId={id}/>
};

export default () => useRoutes(subRoutes);


const KolorLineNewsContent = ({data}) => {

    const classes = useStyles();
    
    const {t} = useTranslation('general');

    return (
        <React.Fragment>
            <Grid container
                  className={classes.kolorLineNews}
                  component='div'>
                <Grid item container
                      xs={12}
                      component='div'
                      className={classes.header}>
                    <Grid item xs={12} sm={9} component='div'>
                        <Typography variant='h4'
                                    className={classes.title}>
                            {data.title}
                        </Typography>
                        <Typography variant='subtitle1'
                                    className={classes.subtitleAuthor}>
                            {`${t('Author')}: ${data.author}`}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Typography variant='subtitle1'
                                    className={classes.subtitle}>
                            {data.updated_at}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item container>
                    <Grid xs={12} md={3} item component='div'>
                        <Grid item className={classes.imageContainer}>
                            <img className={classes.image} src={data.image} alt=''/>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={9}>
                        <Typography variant='subtitle1'
                                    className={classes.lead}>
                            {data.lead_long}
                        </Typography>
                        <Box className={classes.content} dangerouslySetInnerHTML={{__html: data.content}}/>
                    </Grid>
                </Grid>
            </Grid>
        </React.Fragment>
    );
};

function KolorLineNews({newsId, breadCrumbs}) {

    let url = window.location.pathname;

    useNav({path: url});

    const {data, loadingStatus} = useKolorLineNewsOne(newsId);

    return (
        <React.Fragment>
            <ContentBox publicPage={true}>
                <KolorLineNewsContent data={loadingStatus ? new KolorLineNewsRecord(KolorLineNewsSchema.default()) : data}/>
            </ContentBox>
        </React.Fragment>
    );
}

// export default KolorLineNews;