export const indicatorRowStyle = theme => ({
    indicatorRow: {
        height: 12,
    },
    indicatorCell: {
        minHeight: 12,
        backgroundColor: 'transparent',
        width: 224,
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '&:hover': {
            '& a': {
                display: 'flex',
                height: 22,
                width: '100%',
                justifyContent: 'center',
                alignItems: 'center',
                fontSize: 14,
                fontWeight: 600,
                color: theme.palette.text.primary,
                textDecoration: 'unset',
                borderRadius: 3,
            },
        },
    },
    indicatorCellBranch: {
        '&:after': {
            content: '""',
            position: 'absolute',
            top: -23,
            left: -112,
            display: 'block',
            height: 35,
            width: 112,
            borderRadius: '0 0 0 4px',
            borderTop: 0,
            borderRight: 0,
            borderBottom: 2,
            borderBottomStyle: 'solid',
            borderBottomColor: theme.palette.grey.light,
            borderLeft: 2,
            borderLeftStyle: 'solid',
            borderLeftColor: theme.palette.grey.light,
            zIndex: -1,
        },
    },
    indicatorCellIsOver: {
        height: 22,
    },
    indicatorCellCanDrop: {
        borderColor: 'yellowgreen',
        borderWidth: 1,
        borderStyle: 'solid',
        borderRadius: 3,
        backgroundColor: 'lightgreen',
    },
    indicatorCellCannotDrop: {
        borderColor: 'orangered',
        borderWidth: 1,
        borderStyle: 'solid',
        borderRadius: 3,
        backgroundColor: 'tomato',
    }
});