import React from "react";
import {addData, createRows} from "../util";

import {languagesApiTest} from "../../../Api/language";


const calls = [
    onResponse => addData("/session/language/HU", undefined, onResponse),
];

const LanguagesApis = () => {

    return (
        <React.Fragment>
            {createRows(calls, languagesApiTest)}
        </React.Fragment>
    );
}

export default LanguagesApis;