import React from "react";

import {useRoutes} from "hookrouter";

import {useMeeting} from "../../../../Api/meetings";
import {MeetingRecord, MeetingSchema} from "../../../../Data/Meeting";
import MeetingDetails from "./MeetingDetails";
import ElementDetails from "./ElementDetails";
import debug from "../../../../Lib/debug";
import MeetingList from "./MeetingList";

const subRoutes = {
    "/": () => props => <Meetings view='meetingList' {...props}/>,
    "/:meetingId": ({meetingId}) => props => <Meetings view='meetingDetails' meetingId={meetingId} {...props}/>,
    "/:meetingId/elements/:elementId": ({meetingId, elementId}) => props => <Meetings view='elementDetails'
                                                                                      meetingId={meetingId}
                                                                                      elementId={elementId}
                                                                                      {...props}/>
};

export default props => useRoutes(subRoutes)(props);

const Meetings = (props) => {

    const {view, path, meetingId, elementId} = props;

    debug("RENDER", "Documents", () => [view]);

    const {data, loadingStatus} = useMeeting(meetingId);

    const viewData = (loadingStatus ? new MeetingRecord(MeetingSchema.default()) : data);

    if (loadingStatus) return null;

    switch (view) {
        case "meetingList" :
            return <MeetingList path={path}/>;


        case "meetingDetails" :

            path.push({
                label: `${viewData.date}, ${viewData.committeeName}`,
                url: "/city-council/meetings/" + meetingId
            });

            return <MeetingDetails view='meetingDetails'
                                   meetingId={meetingId}
                                   data={data}
                                   {...props}/>;

        case "elementDetails" :

            path.push({
                label: `${viewData.date}, ${viewData.committeeName}`,
                url: "/city-council/meetings/" + meetingId
            });

            return <ElementDetails view='elementDetails'
                                   meetingId={meetingId} elementId={elementId} {...props}/>;

        default :
            return <div>invalid view: {view}</div>
    }
};