import React from "react";
import Grid from "@material-ui/core/Grid";
import {makeStyles, Typography} from "@material-ui/core";
import ContentBox from "../../../../Components/ContentBox/ContentBox";
import useNav from "../../../../Lib/useNav";
import {baseTableStyles} from "../../../../Lib/simplexion-table/styles/baseStyle";
import {customActionsStyle} from "../../../../Lib/simplexion-table/templates/action-buttons";
import {SimplexionTable} from "../../../../Lib/simplexion-table/SimplexionTable";
import Section from "../../../../Lib/form/Components/Section/Section";
import {useMeetingItems} from "../../../../Api/meeting-items";
import {linkAction} from "../../../../Lib/simplexion-table/templates/link-button";
import {useMeetingRecord} from "../../../../Api/meeting-records";
import Box from "@material-ui/core/Box";
import {useTranslation} from "react-i18next";
import {cityCouncilPageStyle} from "../../cityCouncilPageStyle";

const styles = () => {
    let classes = baseTableStyles;
    Object.entries(customActionsStyle).forEach(entry => {
        classes[entry[0]] = entry[1];
    });
    return classes;
};

const useTableStyles = makeStyles(styles);
const useStyles = makeStyles(cityCouncilPageStyle);


const List = ({meetingId}) => {

    const {t} = useTranslation('cityCouncilPage');

    const tableClasses = useTableStyles();
    const classes = useStyles();

    const {data} = useMeetingItems(meetingId);

    const tableConfig = {
        id: 'meeting-elements',
        nameSpace: 'cityCouncilPage',
        sortable: true,
        styles: tableClasses,
        columns: [
            {
                accessor: 'elementSeq',
            },
            {
                accessor: 'topic',
            },
            {
                accessor: 'presenterName',
            },
            {
                accessor: '',
                renderer: (...args) => linkAction(...args, 'details', `/city-council/meetings/${meetingId}/elements/`)

            }
        ],
    };

    return (
        <Box>
            <Box className={classes.subtitleBox}>
                <Typography variant='body1'>
                    {t('meetingElements')}
                </Typography>
            </Box>
            <SimplexionTable tableConfig={tableConfig} data={data}/>
        </Box>
    );
};

const MeetingRecordDownload = ({meetingData}) => {

    const classes = useStyles();

    const {t} = useTranslation('cityCouncilPage');

    const meetingRecordId = meetingData.meetingRecord;

    const {data, loadingStatus} = useMeetingRecord(meetingRecordId ? meetingRecordId : null);

    if (loadingStatus) return null;

    return (
        <Grid item xs={12} className={classes.record}>
            <Box className={classes.subtitleBox}>
                <Typography variant='body1'>
                    {t('meetingRecord')}:
                    <span>
                        <a href={loadingStatus ? '/#' : `/api/1.0.0/attachments/${data.attachmentId}`}
                           target='_blank'
                           rel='noopener noreferrer'
                           className={classes.meetingRecord}>
                            {data.title}
                        </a>
                    </span>
                </Typography>
            </Box>
        </Grid>
    );
};

const MeetingDetails = ({meetingId, path, data}) => {

    useNav({path: window.location.pathname});

    return (
        <ContentBox path={path}>
            <Section>
                <Grid container component='div'>
                    <MeetingRecordDownload meetingData={data}/>
                    <Grid item xs={12}>
                        <List meetingId={meetingId}/>
                    </Grid>
                </Grid>
            </Section>
        </ContentBox>
    )
};

export default MeetingDetails;