import Immutable from 'immutable';
import {types} from './Actions'

export const menuInitialState = Immutable.Map({
    // data: Immutable.Map(),
    map: Immutable.Map(),
});

export function menuReducer(state, action) {

    switch (action.type) {
        case types.INIT_ITEMS:
            return initItems(state, action);
        case types.MOVE_ITEM:
            return moveItem(state, action);
        case types.MOVE_ITEM_TO_CHILDREN:
            return moveItemToChildren(state, action);
        case types.ADD_ITEM:
            return addItem(state, action);
        case types.REMOVE_ITEM:
            return removeItem(state, action);
        default:
            throw new Error();
    }

    function initItems(state, action) {

        const map = state.get('map').withMutations(map => {
            action.data.forEach(data => {
                const children = data.children ?
                    data.children.split(',').map(id => parseInt(id)).filter(item => item) :
                    undefined;
                data = data.set('children', children);
                map.set(data.id, data);
            });
        });
        return state.set('map', map);
    }

    function moveItemToChildren(state, action) {

        const {sourceId, targetId} = action;

        const map = state.get('map');

        const source = map.get(sourceId);

        const newMap = map.withMutations(map => {
            map.setIn([sourceId, 'parent'], targetId)
                .updateIn([source.parent, 'children'], arr => {
                    const newArr = arr.filter(id => id !== sourceId);
                    return newArr.length ? newArr : undefined;
                })
                .updateIn([targetId, 'children'], arr => {
                    if (!arr) return [sourceId];
                    arr.unshift(sourceId);
                    return arr;
                })
        });

        return state.set('map', newMap);
    }

    function moveItem(state, action) {

        const {sourceId, targetId} = action;

        const map = state.get('map');

        const source = map.get(sourceId);
        const target = map.get(targetId);

        let targetIndex = map.getIn([target.parent, 'children']).indexOf(targetId) + 1;

        if (source.parent === target.parent) {
            const sourceIndex = map.getIn([target.parent, 'children']).indexOf(sourceId);
            targetIndex -= sourceIndex > targetIndex ? 0 : 1;
        }

        const newMap = map.withMutations(map => {
            map.setIn([sourceId, 'parent'], target.parent)
                .updateIn([source.parent, 'children'], arr => {
                    const newArr = arr.filter(id => id !== sourceId);
                    return newArr.length ? newArr : undefined;
                })
                .updateIn([target.parent, 'children'], arr => {
                    arr.splice(targetIndex, 0, sourceId);
                    return arr;
                })
        });


        return state.set('map', newMap);
    }

    function addItem(state, action) {
    }

    function removeItem(state, action) {
    }
}

