import React from "react";
import {navigate, useRoutes} from "hookrouter";
import TextPageEditor from "../TextPageEditor/TextPageEditor";
import debug from "../../../../debug";
import {useTranslation} from "react-i18next";
import {useContent} from "../../../MenuBuilder/Api/content";

export default (props) => useRoutes(subRoutes)(props);

const subRoutes = {
    "/:blockPageId/blocks/add": ({blockPageId}) => props => <BlockItemEditor view='add'
                                                                             blockPageId={blockPageId} {...props}/>,
    "/:blockPageId/blocks/:blockId/edit": ({blockPageId, blockId}) => props => <BlockItemEditor view='edit'
                                                                                                blockPageId={blockPageId}
                                                                                                blockId={blockId} {...props}/>
};

const BlockItemEditor = (props) => {

    const {view, contentId, blockPageId, blockId} = props;

    debug("RENDER", "BlockItemEditor", () => [view]);

    const {data, loadingStatus} = useContent(contentId);

    const {t} = useTranslation('contentManagement');

    if (loadingStatus) return null;

    const path = [
        {label: t('titleNav', {context: 'content'}), url: '/content-management/'},
        {label: data.title, url: `/content-management/${contentId}/edit`}
    ];

    const onSuccess = data => {

        const addViewUrl = `/content-management/${contentId}/block-pages/${blockPageId}/blocks/add`;

        if(window.location.pathname === addViewUrl) {
            navigate(`/content-management/${contentId}/block-pages/${blockPageId}/blocks/${data.id}/edit`)
        }
    };
    const onCancel = () => {
        navigate(`/content-management/${contentId}/edit`)
    };

    switch (view) {
        case "add" :

            return <TextPageEditor view='add'
                                   blockPageId={blockPageId}
                                   handleSubmit={onSuccess}
                                   handleCancel={onCancel}
                                   {...props}
                                   path={path}/>;

        case "edit" :

            return <TextPageEditor view='edit'
                                   blockPageId={blockPageId}
                                   textPageId={blockId}
                                   handleSubmit={onSuccess}
                                   handleCancel={onCancel}
                                   {...props}
                                   path={path}/>;

        default :
            return <div>invalid view: {view}</div>
    }


};
