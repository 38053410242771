import {Record} from 'immutable';
import {number, object, string} from "yup";

export const ActualitiesRecord = Record({
    id: 0,
    title: '',
    description: '',
    picture: 0,
    altText: '',
    type: '',
    createdAt: '',
    modifiedAt: '',
});

export const ActualitiesSchema = object().shape({
    id: number().required().min(0).default(0),
    title: string().required().min(1).max(200).default(''),
    description: string().min(0).max(2500).default(''),
    picture: number().min(0).default(0),
    altText: string().min(0).max(200).default(''),
    type: string().min(0).oneOf(['BLOCK', 'TEXT', 'FILE_UPLOAD', 'KOLORAPP', 'CITY_COUNCIL', 'LIST_OF_ITEM']).default(''),
});