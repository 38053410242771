import {Record} from 'immutable';
import {number, object, string} from "yup";

export const KolorLineNewsThumbnailRecord = Record({
    id: 0,
    title: '',
    lead: '',
    author: '',
    category: '',
    category_color: '',
    image: '',
    image_sm: '',
    posted_at: '',
});

export const KolorLineNewsThumbnailSchema = object().shape({
    id: number().default(0),
    title:  string().required().min(1).max(100).default(''),
    lead: string().default(''),
    author: string().default(''),
    category: string().default(''),
    category_color: string().default(''),
    image: string().default(''),
    image_sm: string().default(''),
    posted_at: string().default(''),
});
