import {Record} from 'immutable';
import {array, bool, mixed, number, object, string} from "yup";
import {WatcherType} from "./Watcher";

export const ItemStatus = [
    "PENDING",
    "APPROVED",
    "HIDDEN"
];

export const ItemCategory = [
    "CLOTHES", "FURNITURE", "BABY", "GAME", "SCHOOL_EQUIPMENT", "HOUSEHOLD_GOODS", "OTHER"
];


export const TypeOfAd = [
    "LOOK_FOR",
    "OFFER"
];

export const Delivery = [
    "GO_FOR",
    "UNDERTAKE_DELIVERY"
];

export const ItemRecord = Record({
    id: 0,
    name: "",
    nameOfCreator: "",
    phoneNumberOfCreator: "",
    emailAddressOfCreator: "",
    category: "OTHER",
    description: "",
    images: "",
    createdAt: "",
    publishedBy: 0,
    watcherIds: [],
    numberOfWatchers: 0,
    status: "",
    translStatus: "",
    type: "PUBLIC_USER",
    termsAccepted: false,
    delivery: "GO_FOR",
    typeOfAd: "LOOK_FOR",
    translCategory: "",
    translTypeOfAd: "",
    translDelivery: ""
});

export const ItemSchema = object().shape({
    id: number().min(0).default(0),
    name: string().required().min(5).max(100).default(''),
    nameOfCreator: string().required().min(5).max(100).default(''),
    phoneNumberOfCreator: string().required().min(7).max(30).default(''),
    emailAddressOfCreator: string().required().email().default(''),
    category: mixed().required().oneOf(ItemCategory).default("OTHER"),
    delivery: mixed().required().oneOf(Delivery).default("GO_FOR"),
    typeOfAd: mixed().required().oneOf(TypeOfAd).default("LOOK_FOR"),
    description: string().nullable().max(2000).default(''),
    images: string().matches(/^\d+(\/\d+)?$/, {
        message: 'Must be exactly 5 numbers',
        excludeEmptyString: true
    }).nullable().max(100000).default(''),
    createdAt: string().nullable().default(''),
    publishedBy: number().nullable().default(0),
    watcherIds: array().of(number()).min(0).nullable().default([]),
    numberOfWatchers: number().nullable().default(0),
    status: mixed().required().oneOf(ItemStatus).default("PENDING"),
    translStatus: string().nullable().default(''),
    type: mixed().required().oneOf(WatcherType).default("PUBLIC_USER"),
    termsAccepted: bool().required().oneOf([true]).default(false),
    recaptchaInstance: string().required().min(10).default(""),
    translTypeOfAd: string().nullable().default(''),
    translCategory: string().nullable().default(''),
    translDelivery: string().nullable().default('')


});