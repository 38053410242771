import NavigationIcon from "@material-ui/icons/Navigation";
import Fab from "@material-ui/core/Fab";
import React from "react";
import {makeStyles} from "@material-ui/core";
import {scrollToTopBtnStyle} from "./scrollToTopBtnStyle";
import {useTranslation} from "react-i18next";


const useStyles = makeStyles(scrollToTopBtnStyle);

const ScrollToTopBtn = () => {

    const classes = useStyles();

    const {t} = useTranslation('general');

    const handleClick = () => document.documentElement.scrollTo(0, 0);

    return (
        <Fab color="primary"
             onClick={handleClick}
             aria-label={t('scrollToTop')}
             className={classes.scrollToTopBtn}>
            <NavigationIcon/>
        </Fab>
    );
};

export default ScrollToTopBtn;