import {Record} from 'immutable';
import {number, object} from "yup";

export const BackgroundRecord =  Record({
    picture: 0,
});

export const BackgroundSchema = object().shape({
    picture: number().required().min(1).default(0),
});
