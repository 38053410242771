export const profileHu = {
    'titleNav': 'Profil',
    'password-change': 'Jelszó változtatás',
    'fullName': 'Teljes név',
    'displayName': 'Megjelenítendő név',
    'emailAddress': 'E-mail cím',
    'roles': 'Jogosultságok',
    'positions': 'Beosztások',
    'organisationName': 'Jelölő szervezet',
    'personalData': 'Személyes adatok'
};