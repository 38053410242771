export const contentManagementHu = {
    "titleNav_design": "Design management",
    "titleNav_content": "Oldalak és tartalom kezelése",
    "mainMenu": "Főmenü",
    "subMenu": "Almenü",
    "downloadMaterials": "Letölthető anyagok",

    "add": "Létrehozás",
    "edit": "Szerkesztés",
    "save": "Mentés",
    "back": "Vissza",
    "cancel": "Mégse",
    "delete": "Törlés",
    "remove": "Törlés",

    "removeContent": "törlése",
    "confirmRemoveText_warning": "A menüpont törlésével a hozzá kapcsolódó oldal, illetve az almenük és azok oldalai is törlésre kerülnek!",
    "confirmRemoveText_question": "Biztos törölni szeretné?",
    "confirmRemove": "Törlés megerősítése",

    "navItem": "Menüelem",
    "title_navItem": "Név",
    "type_navItem": "Aloldal típusa",
    "BLOCK": "Blokk",
    "TEXT": "Szöveg",
    "FILE_UPLOAD": "Fájl",
    "KOLORAPP": "KolorApp",
    "LIST_OF_ITEM": "KSZSZK",
    "CITY_COUNCIL": "Városvezetés",

    "subpage": "Aloldal",
    "title_subpage": "Cím",

    "blockItems": "Blokk elemek",
    "list": "Feltöltések listája",
    "name": "Név",
    "removeBlockItem": "Törlés",

    "title": "Cím",
    "picture": "Kép feltöltése",
    "addImg": "Hozzáadás",
    "changeImg": "Módosítás",
    "textContent": "Tartalom",
    "description": "Összefoglaló",
    "attachments": "Csatolmány",
    "altText": "Kép leírása",
    "preparation": "Előkészítés alatt",
    "status": "Státusz",
    "HIDDEN": "Rejtett",
    "PUBLIC": "Publikus",
    "ACTUALITY": "Aktualitások között megjelenjen",
    "tags": "Címkék",
    "addNewTag": "Új címke",
    "saveTag": "Címke mentése",
    "addAttachment": "Hozzáadás",

    'type': 'Típus',
    'actions': 'Műveletek',
    'action': 'Művelet',
    'COUNCIL_MEMBER_NON_PUBLIC': 'Bejelentkezési képernyő képviselők részére',
    'CITY_COUNCIL_NON_PUBLIC': 'Képviselő testületi anyagok, nem publikus',
    'DECISIONS_PUBLIC': 'Publikált határozatok',
    'MEETINGS_PUBLIC': 'Képviselő testületi anyagok, publikus',
    'IMPRESSUM': 'Impresszum',
    'CONTACT': 'Kapcsolat',
    'dataPolicy': 'Adatvédelmi tájékoztató',

    "text": "Szöveg",
    "pictures": "Képek",
    "attachment": "Fájl",

    "lineColor": "Sávszín",
    "textColor": "Szövegszín",
    "currentView": "Jelenlegi nézet",
    "preview": "Előnézet",
    "navDesignTitle": "Navigációs menü színeinek módosítása",
    "backgroundChange": "Főoldal hátterének módosítása",
    "hide": "Elrejtés",
    "activation": "Aktiválás"
};