import baseFetch, {useFetchOne} from "../../../baseFetch";
import {TextPageRecord, TextPageSchema} from "../Data/TextPage";
import {AttachmentRecord} from "../Data/Attachment";
import {roles} from "../../../../Main/roles";
import {contentStatusTest} from "../../../testing-tools/data-validation-tests";

export function useTextPage(id, revision) {
    return useFetchOne('/text-pages', id, TextPageRecord, TextPageSchema, revision);
}

export function updateTextPage(textPage, onSuccess, onError) {
    baseFetch('PATCH', '/text-pages/' + textPage.get('id'), textPage, onSuccess, onError);
}

export function getAttachments(id, onSuccess, onError) {
    baseFetch('GET', '/text-pages/' + id + '/attachments' , undefined,
        data => {
            if (onSuccess) onSuccess(data.map(r => new AttachmentRecord(r)));
        },
        onError
    );
}


export const textPagesApiTest = {
    '/text-pages/5': {
        'GET': {
            [roles.ANONYMOUS]: {
                hasResponse: false,
                validate: contentStatusTest
            },
            [roles.SITE_MEMBER]: {
                hasResponse: true,
                validate: contentStatusTest
            },
            [roles.COUNCIL_ADMIN]: {
                hasResponse: true,
                validate: contentStatusTest
            },
            [roles.CM_ADMIN]: {hasResponse: true},
            [roles.UPLOAD_ADMIN]: {hasResponse: true},
            [roles.ADMIN]: {hasResponse: true},
            [roles.SECURITY_OFFICER]: {hasResponse: true}
        },
        'PATCH': {
            [roles.ANONYMOUS]: {hasResponse: false},
            [roles.SITE_MEMBER]: {hasResponse: false},
            [roles.COUNCIL_ADMIN]: {hasResponse: false,},
            [roles.CM_ADMIN]: {hasResponse: true},
            [roles.UPLOAD_ADMIN]: {hasResponse: true},
            [roles.ADMIN]: {hasResponse: true,},
            [roles.SECURITY_OFFICER]: {hasResponse: true}
        },
    },
    '/text-pages/5/attachments': {
        'GET': {
            [roles.ANONYMOUS]: {hasResponse: true},
            [roles.SITE_MEMBER]: {hasResponse: true},
            [roles.COUNCIL_ADMIN]: {hasResponse: true},
            [roles.CM_ADMIN]: {hasResponse: true},
            [roles.UPLOAD_ADMIN]: {hasResponse: true},
            [roles.ADMIN]: {hasResponse: true},
            [roles.SECURITY_OFFICER]: {hasResponse: true}
        },
    },
}

