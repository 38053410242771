export const textPageStyle = theme => ({
    textPage: {
        color: theme.palette.text.primary,
    },
    header: {
        alignItems: 'baseline',
        marginBottom: theme.spacing(3),
        [theme.breakpoints.up('md')] :{
            marginBottom: theme.spacing(6),
        }
    },
    title: {
        color: theme.palette.text.primary,
        fontWeight: 'bold',
    },
    subtitle: {
        fontWeight: 'bold',
        color: theme.palette.text.primary,
        [theme.breakpoints.up('md')]: {
            paddingLeft: theme.spacing(3),
            textAlign: 'right',
        },
    },

    imageContainer: {
        margin: 0,
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'center',
        marginBottom: theme.spacing(3),
        [theme.breakpoints.up('sm')]: {
            marginBottom: theme.spacing(6),
        },

    },

    image: {
        ...theme.imgFluid
    },

    description: {
        color: theme.palette.text.primary,
        textAlign: 'justify',
        marginBottom: theme.spacing(6),
        [theme.breakpoints.up('md')]: {
            paddingLeft: theme.spacing(6),
        },
        '& *:first-child': {
            marginTop: 0,
            paddingTop: 0,
        },
    },

    content: {
        color: theme.palette.text.primary,
        textAlign: 'justify',
        marginBottom: theme.spacing(6),
        [theme.breakpoints.up('md')]: {
            paddingLeft: theme.spacing(6),
        },
        '& *:first-child': {
            marginTop: 0,
            paddingTop: 0,
        },
    },

    tagContainer: {
        marginBottom: theme.spacing(6),
        paddingLeft: theme.spacing(1),
    },

    tagText: {
        paddingLeft: theme.spacing(1),
        marginBottom: theme.spacing(2),
        fontWeight: 500
    },

    attachmentBoxTitle: {
        paddingLeft: theme.spacing(2),
        fontWeight: 500
    },

    attachmentsBox: {
        marginBottom: theme.spacing(6),
    },

    attachmentText: {

    },

    attachment: {
        display: 'flex',
        textDecoration: 'none',
        color: theme.palette.text.primary,
        borderLeft: `2px solid ${theme.palette.kolorcity[5]}`,
        paddingLeft: theme.spacing(1),
        cursor: 'pointer',
        wordBreak: 'break-word',
        textOverflow: 'hidden',
        '&:active': {
            color: theme.palette.text.primary,
        },
        '&:focus': {
            color: theme.palette.text.primary,
        }
    },

    btnMore: {
        textDecoration: 'none',
        paddingLeft: theme.spacing(1),
        float: 'right'
    }
});