export const themedTableStyles = theme => ({
    stWrapper: {
        '&.sticky': {
            perspective: '1px',
        }
    },
    stTableWrapper: {
        '&.sticky': {
            overflowX: 'unset',
            overflowY: 'auto',
            height: '85vh',
        },
    },
    stStickyHeaderWrapper: {
        position: 'fixed',
        top: 0,
        left: 0,
    },
    stSticky: {
        borderBottomLeftRadius: 'unset',
        borderBottomRightRadius: 'unset',
    },
    stTable: {
        width: '100%',
        textAlign: 'left',
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.white,
        borderCollapse: 'separate',
        borderSpacing: 0,
    },
    stCaption: {
        textAlign: 'center',
        padding: '1.25rem',
        backgroundColor: theme.palette.white,
        fontSize: '1.125rem',
        fontWeight: 600,
        color: theme.palette.text.primary,
    },
    stHeader: {
        fontWeight: 'medium',
    },
    stBody: {},
    stHeaderRow: {
        height: '3.5rem',
        borderRadius: '.25rem',
    },
    stRow: {
        height: '3.25rem',
    },
    stHeaderCell: {
        padding: '0 1rem',
        display: 'relative',
        borderTopStyle: 'solid',
        borderTopWidth: 1,
        borderBottomStyle: 'solid',
        borderBottomWidth: 1,
        borderColor: theme.palette.text.primary,
        '&.stSortable': {
            cursor: 'pointer',
        }
    },
    stCell: {
        padding: '0 1rem',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        borderTopStyle: 'solid',
        borderTopWidth: 1,
        borderBottomStyle: 'solid',
        borderBottomWidth: 1,
        borderColor: theme.palette.text.primary,
    },
    customActions: {
        display: 'flex',
    },
    customActionLabel: {
        margin: 'auto',
        display: 'inherit',
        alignItems: 'inherit',
        justifyContent: 'inherit',
        padding: '.25rem .5rem'
    },
    customLink: {
        borderStyle: 'none',
        display: 'flex',
        margin: '.25rem',
        height: 30,
        minWidth: 30,
        backgroundColor: theme.palette.white,
        borderRadius: '.2rem',
        textDecoration: 'none',
        fontSize: '.875rem',
        textTransform: 'uppercase',
        lineHeight: 'inherit',
        color: theme.palette.text.primary,
        '&:hover': {
            backgroundColor: theme.palette.white,
            color: theme.palette.text.primary,
        },
        '&:active': {
            color: theme.palette.white,
        }
    },
    customList: {
        listStyle: 'none',
    }
});