import React, {memo} from "react";
import {useTranslation} from "react-i18next";
import useForm from "../../Lib/form/useForm";
import {OrganisationRecord, OrganisationSchema} from '../../Data/Organisation';
import ContentBox from "../../Components/ContentBox/ContentBox";
import Section from "../../Lib/form/Components/Section/Section";
import ValidatedSubmit from "../../Lib/form/Components/ValidatedSubmit/ValidatedSubmit";
import ValidatedInput from "../../Lib/form/Components/ValidatedInput/ValidatedInput";
import {navigate} from "hookrouter";

export default memo(({record, path, view, api}) => {

    const {t} = useTranslation('organisations');

    const form = useForm({
        disabledForm: view === 'remove',
        recordType: OrganisationRecord,
        recordSchema: OrganisationSchema,
        namespace: 'organisations',
        record: record
    });

    const onSuccess = () => navigate(`/organisations`);
    const onCancel = () => navigate(`/organisations`);

    return (
        <ContentBox path={path} form={form}>
            <Section title={t(view)}>
                <ValidatedInput name='name'/>
            </Section>
            <ValidatedSubmit view={view} api={api} onSuccess={onSuccess} onCancel={onCancel}/>
        </ContentBox>
    );
})