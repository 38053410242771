import {useTranslation} from "react-i18next";
import React from "react";
import {makeStyles} from "@material-ui/core";
import {menuBuilderStyle} from "../styles/menuBuilderStyle";

const useStyles = makeStyles(menuBuilderStyle);

const THead = () => {
    const {t} = useTranslation("contentManagement");
    const classes = useStyles();

    return (
        <thead className={classes.tableHead}>
        <tr className={classes.tableHeadRow}>
            <td className={classes.tableHeadCell}>{t("mainMenu")}</td>
            <td className={classes.tableHeadCell}>{t("subMenu") + '1'}</td>
            <td className={classes.tableHeadCell}>{t("subMenu") + '2'}</td>
            <td className={classes.tableHeadCell}>{t("subMenu") + '3'}</td>
            {/*<td>{t("type")}</td>*/}
        </tr>
        </thead>
    )

};

export default THead;