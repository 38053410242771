import {makeStyles} from "@material-ui/core";
import {tagsStyle} from "./tagsStyle";
import {useFormField} from "../../../../form/useFormField";
import React, {useEffect, useState} from "react";
import Card from "../../../../material-dashboard/components/Card/Card";
import CardBody from "../../../../material-dashboard/components/Card/CardBody";
import Chip from "@material-ui/core/Chip";
import {useTranslation} from "react-i18next";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import AddRoundedIcon from "@material-ui/icons/AddRounded";
import Grid from "@material-ui/core/Grid";
import CustomInput from "../../../../material-dashboard/components/CustomInput/CustomInput";
import {TagSchema} from "../../Data/TextPage";

const useStyles = makeStyles(tagsStyle);

const Tag = ({name, id, handleDelete}) => {

    const classes = useStyles();

    return (
        <Chip component='div'
              label={name}
              id={id}
              onDelete={() => handleDelete(name)}
              className={classes.tag}
              color='primary'/>
    );
};

const TagInput = ({handleAdd}) => {

    const classes = useStyles();
    const {t} = useTranslation('contentManagement');

    const [tag, setTag] = useState({
        showInput: false,
        input: '',
    });

    const handleSave = () => {
        handleAdd(tag.input);
        setTag({input: '', showInput: false});
    };

    let status = 'valid';
    try {
        TagSchema.validateSyncAt('tag', {tag: tag.input});
    } catch (validationErrors) {
        status = 'invalid';
    }

    const adornment = (
        <InputAdornment position='end'>
            <IconButton edge='end'
                        size='small'
                        aria-label={t('addTag')}
                        disabled={status === 'invalid'}
                        onClick={handleSave}>
                <AddRoundedIcon/>
            </IconButton>
        </InputAdornment>
    );

    return (
        <Grid item xs={12} md={4} className={classes.newTagInputBox}>
            <CustomInput id='new-tag'
                         success={status === "valid"}
                         // error={status === "invalid"}
                         labelText={t('addNewTag')}
                // helperText={ff.helperText}
                         formControlProps={{
                             fullWidth: true,
                         }}
                         inputProps={{
                             endAdornment: adornment,
                             value: tag.input,
                             onChange: e => setTag({...tag, input: e.target.value}),
                         }}
                         labelProps={{
                             shrink: true,
                         }}
            />
        </Grid>
    )
};


const Tags = () => {
    const classes = useStyles();

    const ff = useFormField({name: 'tags'});

    const [tagNames, setTagNames] = useState([]);

    useEffect(() => {
        if (ff.value) setTagNames(ff.value.split(','))
    }, [ff.value]);

    const handleDelete = name => {
        const filtered = tagNames.filter(str => str !== name);
        const tagsStr = filtered.join(',');
        setTagNames(filtered);
        ff.onChange(tagsStr);
    };

    const handleAdd = name => {
        if (name) {
            tagNames.push(name);
            const tagsStr = tagNames.join(',');
            ff.onChange(tagsStr);
        }
    };

    const tags = tagNames.map((value, key) => <Tag key={key} chipId={key} name={value} handleDelete={handleDelete}/>);

    return (
        <React.Fragment>
            <TagInput handleAdd={handleAdd}/>
            <Card className={classes.tagContainer}>
                <CardBody className={classes.tagContainerCardBody}>
                    {tags}
                </CardBody>
            </Card>
        </React.Fragment>
    )
};

export default Tags;