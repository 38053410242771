export const mobileNavStyle = theme => ({
    mobileNav: {
        position: 'fixed',
        top: 85,
        width: '100%',
        maxHeight: 'calc(100vh - 85px)',
        overflow: 'scroll',
    },
    mobileNavWrapper: {
        position: 'relative',
        flexDirection: 'column',
        borderTopColor: theme.palette.kolorcity[3],
        borderTopWidth: 2,
        borderTopStyle: 'solid',
        borderBottomColor: theme.palette.kolorcity[3],
        borderBottomWidth: 2,
        borderBottomStyle: 'solid',
        backgroundColor: theme.palette.white,
        color: theme.palette.text.primary,
        boxShadow: '0px 5px 4px -4px rgba(0,0,0,0),0px 7px 5px -3px rgba(0,0,0,0.14),0px 4px 10px -3px rgba(0,0,0,0.12)',
    },
    expandBtnBox: {
        width: theme.gridWidths[2],
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        paddingRight: theme.spacing(2),
    },
    expandBtn: {
        // borderColor: theme.palette.text.primary,
        // borderStyle: 'solid',
        // borderWidth: 2,
        color: theme.palette.text.primary,
    },
    nestedCollapse: {
      width: theme.gridWidths[12],
    },
    nestedList: {
        margin: 0,
        padding: 0,
    },


    link: {
        height: '100%',
        width: theme.gridWidths[10],
        textDecoration: 'unset',
        color: theme.palette.text.primary,
        display: 'flex',
        alignItems: 'center',
        paddingLeft: theme.spacing(2),
    },
    linkText: {
        paddingLeft: theme.spacing[2],
        paddingRight: theme.spacing[2],
        // color: theme.palette.text.white,
    },

    item: {
        paddingBottom: 1,
        height: 48,
    },

    itemInner: {
        height: '100%',
        width: '100%',
        backgroundColor: theme.palette.white,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        color: theme.palette.text.white,
    },

    //
    //  Color styles
    //

    itemBlue: {
        backgroundColor: theme.palette.kolorcity_alt.blue.dark,
    },
    itemRed: {
        backgroundColor: theme.palette.kolorcity_alt.red.dark,
    },
    itemGrey: {
        backgroundColor: theme.palette.kolorcity_alt.grey.dark,
    },
    itemYellow: {
        backgroundColor: theme.palette.kolorcity_alt.yellow.dark,
    },
});