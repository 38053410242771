import {Record} from 'immutable';
import {number, object, string} from "yup";

export const StoredFileRecord = Record({
    id: 0,
    attachmentId: 0,
    type: '',
    description: '',
    url: '',
});

export const StoredFileSchema = object().shape({
    id: number().nullable().default(0),
    attachmentId: number().nullable().default(0),
    type: string().min(0).default('attachments'),
    description: string().min(0).max(512).default(''),
    url: string().min(0).default(''),
});