import Chip from "@material-ui/core/Chip";
import React from "react";
import {makeStyles} from "@material-ui/styles";
import {anchorTagStyle} from "./anchorTagStyle";

const useStyles = makeStyles(anchorTagStyle);

const AnchorTag = ({tag}) => {

    const classes = useStyles();

    const href = '/search/' + encodeURIComponent(tag);

    return (
        <Chip color='primary'
              component='a'
              href={href}
              key={tag}
              clickable
              label={tag}
              className={classes.tag}/>
    )
};

export default AnchorTag;