import {Record} from 'immutable';
import {array, mixed, number, object, string} from "yup";

export const DecisionRecord = Record({
    id: 0,
    meetingId: 0,
    year: 0,
    numberOfDecision: '',
    title: '',
    decisionText: '',
    presenterName: '',
    type: '',
    attachments: '',
    attachmentNames: []
});

export const DecisionSchema = object().shape({
    id: number().min(0).default(0),
    meetingId: number().required().min(1).default(0),
    year: number().min(0).default(0),
    numberOfDecision: string().required().min(1).max(32).default(''),
    title: string().required().min(1).max(256).default(''),
    decisionText: string().required().min(1).max(10000).default(''),
    presenterName: string().required().min(1).max(100).default(''),
    type: mixed().required().oneOf(['OPEN', 'CLOSED']).default('CLOSED'),
    attachments: string().required().min(0).default(''),
    attachmentNames: array().of(string()).min(0).nullable().default([])
});
