/*!

=========================================================
* Material Dashboard PRO React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import {defaultFont, grayColor} from "../material-dashboard-pro-react.jsx";

const customInputStyle = theme => ({
  disabled: {
    "&:before": {
      borderColor: "transparent !important"
    }
  },
  underline: {
    "&:hover:not($disabled):before,&:before": {
      borderColor: theme.palette.grey.light + "!important",
      borderWidth: "1px !important"
    },
    "&:after": {
      borderColor: theme.palette.primary.light
    },
    "& + p": {
      fontWeight: "300"
    }
  },
  underlineError: {
    "&:after": {
      borderColor: theme.palette.error.main
    }
  },
  underlineSuccess: {
    "&:after": {
      borderColor: theme.palette.success.light
    }
  },
  labelRoot: {
    ...defaultFont,
    color: theme.palette.text.primary + " !important",
    fontWeight: "400",
    fontSize: "14px",
    //lineHeight: "1.42857",
    top: "10px",
    letterSpacing: "unset",
    "& + $underline": {
      marginTop: "0px"
    }
  },
  labelRootError: {
    color: theme.palette.error.main + " !important"
  },
  labelRootSuccess: {
    color: theme.palette.success.main + " !important"
  },
  formControl: {
    margin: "0px 0 0px 0",
    paddingTop: "27px",
    position: "relative",
    // no idea why this was added, it screws up everything... verticalAlign: "unset",
    "& svg,& .fab,& .far,& .fal,& .fas,& .material-icons": {
      color: grayColor[14]
    },
    minHeight: 56,
    '& .quill .ql-editor': {
      minHeight: 500,
    },
  },
  whiteUnderline: {
    "&:hover:not($disabled):before,&:before": {
      backgroundColor: theme.palette.common.white
    },
    "&:after": {
      backgroundColor: theme.palette.common.white
    }
  },
  input: {
    color: theme.palette.text.main,
    "&,&::placeholder": {
      fontSize: "14px",
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: "400",
      //lineHeight: "1.42857",
      opacity: "1"
    },
    "&::placeholder": {
      color: theme.palette.text.secondary
    }
  },
  dateTimeInput: {
    padding: "0 !important",
    height: "29px !important"
  },
  whiteInput: {
    "&,&::placeholder": {
      color: theme.palette.common.white,
      opacity: "1"
    }
  }
});

export default customInputStyle;
