import {Record} from 'immutable';
import {bool, mixed, number, object, string} from "yup";

export const WatcherType = [
    "ADMIN",
    "PUBLIC_USER"
];

export const WatcherRecord = Record({
    id: 0,
    name: "",
    phoneNumber: "",
    emailAddress: "",
    type: "PUBLIC_USER",
    translType: "",
    termsAccepted: false,
    recaptchaInstance: ""

});

export const WatcherSchema = object().shape({
    id: number().min(0).default(0),
    name: string().required().min(5).max(100).default(''),
    phoneNumber: string().required().min(7).max(30).default(''),
    emailAddress: string().required().email().default(""),
    type: mixed().required().oneOf(WatcherType).default('PUBLIC_USER'),
    translType: string().nullable().default(''),
    termsAccepted: bool().required().oneOf([true]).default(false),
    recaptchaInstance: string().required().min(10).default("")

});
