import {makeStyles} from "@material-ui/core";
import React, {memo, useState} from "react";
import ContentBox from "../../Components/ContentBox/ContentBox";
import Section from "../../Lib/form/Components/Section/Section";
import {SimplexionTable} from "../../Lib/simplexion-table/SimplexionTable";
import {baseTableStyles} from "../../Lib/simplexion-table/styles/baseStyle";
import {useStoredFiles} from "../../Api/search";
import SearchField from "../../Components/SearchField/SearchField";
import Box from "@material-ui/core/Box";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles(baseTableStyles);

const fileRenderer = (accessor, record) => {

    const url = `/api/1.0.0/${record.type}/${record.attachmentId}`;
    const style = "color: #03a9f4; text-decoration: none";

    return `<a href="${url}" target="_blank" style="${style}">${record[accessor]}</a>`;
};

const StoredFiles = memo(() => {

    const classes = useStyles();

    const {t} = useTranslation('storedFiles');

    const {data, loadingStatus} = useStoredFiles();

    const [searchTerm, setSearchTerm] = useState(undefined);

    if (loadingStatus) return null;

    const tableConfig = {
        id: 'stored-files-table',
        nameSpace: 'storedFiles',
        stickyHeader: true,
        sortable: true,
        styles: classes,
        columns: [
            {
                accessor: 'description',
                renderer: (accessor, record) => fileRenderer(accessor, record)
            },
            {
                accessor: 'url',
            },
        ],
    };

    const handleSearch = val => {
        setSearchTerm(val)
    };

    const path = [{url: '/stored-files', label: t('titleNav')}];

    return (
        <ContentBox path={path}>
            <Section>
                <Box style={{marginBottom: '1rem'}}>
                    <SearchField handleClick={handleSearch} searchTerm={searchTerm}/>
                </Box>
                <SimplexionTable tableConfig={tableConfig} data={data} filterValue={searchTerm}/>
            </Section>
        </ContentBox>
    );
});

export default StoredFiles;