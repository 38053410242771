import i18next from "i18next";

export const labelFactory = () => {

    const lang = i18next.language;
    const t = i18next.getFixedT(lang, 'filePondLabels');

    if (lang !== 'hu') return {};

    return ({
        labelIdle: t('labelIdle'),
        labelInvalidField: t('labelInvalidField'),
        labelFileWaitingForSize: t('labelFileWaitingForSize'),
        labelFileSizeNotAvailable: t('labelFileSizeNotAvailable'),
        labelFileLoading: t('labelFileLoading'),
        labelFileLoadError: t('labelFileLoadError'),
        labelFileProcessing: t('labelFileProcessing'),
        labelFileProcessingComplete: t('labelFileProcessingComplete'),
        labelFileProcessingAborted: t('labelFileProcessingAborted'),
        labelFileProcessingError: t('labelFileProcessingError'),
        labelFileProcessingRevertError: t('labelFileProcessingRevertError'),
        labelFileRemoveError: t('labelFileRemoveError'),
        labelTapToCancel: t('labelTapToCancel'),
        labelTapToRetry: t('labelTapToRetry'),
        labelTapToUndo: t('labelTapToUndo'),
        labelButtonRemoveItem: t('labelButtonRemoveItem'),
        labelButtonAbortItemLoad: t('labelButtonAbortItemLoad'),
        labelButtonRetryItemLoad: t('labelButtonRetryItemLoad'),
        labelButtonAbortItemProcessing: t('labelButtonAbortItemProcessing'),
        labelButtonUndoItemProcessing: t('labelButtonUndoItemProcessing'),
        labelButtonRetryItemProcessing: t('labelButtonRetryItemProcessing'),
        labelButtonProcessItem: t('labelButtonProcessItem')

    })
};

export const filePondLabelsHu = {
    'labelIdle': 'Húzza és dobja rá a kivánt fájlokat vagy nyissa meg a <span class=\"filepond--label-action\"> fájlkezelőből </span>',
    'labelInvalidField': 'A mező érvénytelen fájlokat tartalmaz',
    'labelFileWaitingForSize': 'A fájl méretének feldolgozása',
    'labelFileSizeNotAvailable': 'A fájl mérete nem megfelelő',
    'labelFileProcessingAborted': 'A feltöltés megszakítva',
    'labelFileProcessingRevertError': 'Hiba a feltöltés visszavonása közben',
    'labelFileRemoveError': 'Hiba a fájl eltávolítása közben',
    'labelButtonRemoveItem': 'Visszavonás',
    'labelButtonAbortItemLoad': 'Megszakítás',
    'labelButtonRetryItemLoad': 'Újra',
    'labelButtonAbortItemProcessing': 'Megszakítás',
    'labelButtonUndoItemProcessing': 'Visszavonás',
    'labelButtonRetryItemProcessing': 'Újra',
    'labelButtonProcessItem': 'Feltöltés',
    'labelFileLoading': "Feltöltés",
    'labelFileProcessing': "Feltöltés...",
    'labelFileTypeNotAllowed': "Érvénytelen fájl formátum",
    'labelMaxFileSizeExceeded': "A fálj túl nagy",
    'labelMaxFileSize': "Megengedett méret: maximum 1MB",
    'labelFileLoadError': 'Hiba lépett fel a fájl beltöltése közben',
    'labelFileProcessingComplete': 'A fájl sikeresen fel lett töltve',
    'labelFileProcessingError': 'Hiba lépett fel a fájl feltöltése közben',
    'abelFileProcessingRevertError': 'Sikertelen visszaállítás',
    'labelTapToRetry': 'újraküldés',
    'labelTapToCancel': 'törlés',
    'labelTapToUndo': 'visszavonás',
    'imageFileValidateTypeLabelExpectedTypes': 'Megengedett fájltípusok: ".gif", ".jpeg", ".jpg", ".png", ".raw", ".tiff"',
    'fileValidateTypeLabelExpectedTypes': 'Megengedett fájltípusok: ',
};