import React, {useContext} from "react";
import HighContrast from "../../Assets/img/high-contrast-icon.png";
import Box from "@material-ui/core/Box";
import {useTranslation} from "react-i18next";
import {makeStyles} from "@material-ui/core";
import {style} from "../Navbars/appNavBarStyle";
import {AppContext} from "../../Main/AppContext";
import {appActions as Actions} from "../../Data/Actions/AppActions";

const useStyles = makeStyles(style);

const ChangeThemeBtn = () => {

    const {t} = useTranslation('general');

    const classes = useStyles();

    const appContext = useContext(AppContext);

    const dispatch = appContext.get('dispatch');

    const handleClick= () => {
        dispatch(Actions.changeTheme());
    };

    return (
        <Box onClick={handleClick}>
            <img alt={t('High Contrast Mode')} src={HighContrast} className={classes.highContrastIcon}/>
        </Box>
    )
};

export default ChangeThemeBtn;