import baseFetch from "../Lib/baseFetch";
import {roles} from "../Main/roles";

export function addReport(report, onSuccess, onError) {
    baseFetch('POST', '/post-issue', report,
        () => {
            if (onSuccess) onSuccess()
        },
        onError
    );
}


export const reportsApiTest = {
    '/post-issue': {
        'POST': {
            [roles.ANONYMOUS]: {hasResponse: true},
            [roles.SITE_MEMBER]: {hasResponse: true},
            [roles.COUNCIL_ADMIN]: {hasResponse: true,},
            [roles.CM_ADMIN]: {hasResponse: true},
            [roles.UPLOAD_ADMIN]: {hasResponse: true},
            [roles.ADMIN]: {hasResponse: true,},
            [roles.SECURITY_OFFICER]: {hasResponse: true}
        },
    },
};

