import {Record} from 'immutable';
import {number, object, string} from "yup";

export const TemplateRecord = Record({
    id: 0,
    name: '',
    title: '',
    content: '',
});

export const TemplateSchema = object().shape({
    id: number().min(0).default(0),
    name: string().required().min(1).max(50).default(''),
    title: string().required().min(0).max(50).default(''),
    content: string().required().min(0).max(10000000).default(''),
});

export const CreatePdfTemplateRecord = Record({
    invitationName: '',
    meetingId: 0,
    positionId: 0,
});

export const CreatePdfTemplateSchema = object().shape({
    invitationName: string().required().min(1).max(50).default(''),
    meetingId: number().required().min(1).default(0),
    positionId: number().required().min(1).default(0),
});