export const languageSelectorNavBarStyle = theme => ({

    languageSelector: {
        ...theme.typography.caption,
        color: theme.palette.text.secondary,
        fontWeight: 300,
        margin: theme.spacing(.5),
        paddingLeft: theme.spacing(1),
        borderLeft: `solid 1px ${theme.palette.text.secondary}`,
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: 'unset',
        },
        '&:active': {
            backgroundColor: 'unset',
        },
        '&:first-child': {
            borderLeftColor: 'rgba(0,0,0,0)',
        },
        '&:last-child': {
            paddingLeft: theme.spacing(1),
        },
        [theme.breakpoints.down('sm')]: {
            borderLeft: 'unset',
        },
    },
    languageSelectorWrapper: {
        justifyContent:'flex-end',
        alignItems:'center',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            alignItems: 'flex-end'
        },
    },
});