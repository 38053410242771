import {sortColumn} from "./table-sort";
import {handleResize} from "./resize";

export const createTable = tableStorage => {

    const tableConfig = tableStorage.tableConfig;
    const {id, sortable, styles, stickyHeader} = tableConfig;
    const tableClasses = styles && styles.stTable ? ` class="${styles.stTable}" ` : ``;
    const bodyClasses = styles && styles.stBody ? ` class="${styles.stBody}" ` : ``;

    const wrapper = document.getElementById(`simplexion-table-${id}`);

    const rows = makeRows(tableStorage, styles).join('');

    let caption = '';
    if (tableConfig.caption) caption = makeCaption(tableConfig);

    const head = makeHeader(tableConfig);
    const body = `<tbody${bodyClasses} id="${id}-tbody">${rows}</tbody>`;

    wrapper.innerHTML = `<table id=${id} ${tableClasses}>${caption}${head}${body}</table>`;

    if (tableConfig.tbodyEvent) addTbodyEvent(tableStorage);

    if (stickyHeader && rows) createStickyHeader(tableStorage);

    if (sortable && !stickyHeader) addSortEventListener(tableStorage);
};

const makeCaption = tableConfig => {
    const {caption, styles} = tableConfig;
    const text = caption.captionText;
    const visible = caption.visible ? '' : ' style="display: none" ';
    const classes = styles && styles.stCaption ? ` class="${styles.stCaption}" ` : ``;

    return `<caption${visible}${classes}>${text}</caption>`;
};

const makeHeader = (tableConfig) => {

    const {columns, styles} = tableConfig;

    const headerClasses = styles && styles.stRow ? ` class="${styles.stHeader}" ` : ``;
    const rowClasses = styles && styles.stHeaderRow ? ` class="${styles.stHeaderRow}" ` : ``;
    const cellClasses = styles && styles.stHeaderCell ? ` class="${styles.stHeaderCell}" ` : ``;

    const cells = columns.map(col => {

        const {accessor, header, headerRenderer} = col;

        if (headerRenderer) return headerRenderer(col);

        return `<th${cellClasses} data-accessor="${accessor}">${header}</th>`;

    }).join('');

    return `<thead${headerClasses}><tr${rowClasses}>${cells}</tr></thead>`;
};

const makeRows = tableStorage => {

    return tableStorage.data.map(record => {

        const row = makeRow(record, tableStorage);
        tableStorage.rows[record.id] = row;
        return row;
    });
};

const makeRow = (record, tableStorage) => {

    const {styles, columns} = tableStorage.tableConfig;

    const rowClasses = styles && styles.stRow ? ` class="${styles.stRow}" ` : ``;
    const cellClasses = styles && styles.stCell ? ` class="${styles.stCell}" ` : ``;

    const cells = columns.map(col => {

            const {accessor, renderer} = col;
            let val = record[accessor] ? record[accessor] : (record[accessor] === 0 ? 0 : '');

            if (typeof val === 'string') val = replaceUnsafeChars(val);

            let cellContent = renderer ? renderer(accessor, record, tableStorage) : val;

            return `<td${cellClasses}>${cellContent}</td>`;
        }
    ).join('');

    return `<tr${rowClasses} data-record="${record.id}">${cells}</tr>`;
};

const addTbodyEvent = tableStorage => {
    const id = tableStorage.tableConfig.id;
    const {types, listener} = tableStorage.tableConfig.tbodyEvent;
    const body = document.getElementById(`${id}-tbody`);
    types.forEach(type => body.addEventListener(type, e => listener(e, tableStorage)));
};

const addSortEventListener = (tableStorage, sticky) => {

    const {columns, id} = tableStorage.tableConfig;

    const table = sticky ? document.getElementById(`sticky-header-${id}`) : document.getElementById(id);
    const headerCells = table.querySelectorAll('th');

    columns.forEach((col, i) => {

        if (col.sortable === false) return;

        const classes = headerCells[i].getAttribute('class');
        headerCells[i].addEventListener('click', e => sortColumn(e, tableStorage, sticky));
        headerCells[i].setAttribute('class', `${classes || ''} stSortable`)
    });

};

const replaceUnsafeChars = val => {
    return val.replace(/[<]/g, '&lt;').replace(/[>]/g, '&gt;')
};

const createStickyHeader = tableStorage => {

    const {id, styles, sortable} = tableStorage.tableConfig;

    const wrapper = document.getElementById(`main-wrapper-${id}`);
    const tableWrapper = document.getElementById(`simplexion-table-${id}`);
    const stickyWrapper = document.getElementById(`sticky-header-${id}`);

    if (stickyWrapper.hasChildNodes()) {
        handleResize(id);
        return;
    }

    const table = document.getElementById(id);
    const tableBody = table.getElementsByTagName('tbody')[0];
    const tableBodyRow = tableBody.getElementsByTagName('tr');
    const tableBodyCells = tableBodyRow[0].getElementsByTagName('td');
    const tableHead = table.getElementsByTagName('thead')[0];
    const tableHeaderCells = tableHead.getElementsByTagName('th');

    // Creates a new table to be used as a sticky header
    const stickyTable = document.createElement('table');
    const stickyHeader = document.createElement('thead');
    const stickyHeaderRow = document.createElement('tr');

    // Gets the widths and heights of the <th> elements of the original <thead>, then clones them and sets their widths and heights on the cloned elements.
    // Finally it appends the cloned element to the <tr> of the sticky header element.
    for (let i = 0; i < tableHeaderCells.length; i++) {
        const th = tableHeaderCells[i];
        const td = tableBodyCells[i];
        const thWidth = th.getBoundingClientRect().width;
        const thHeight = th.getBoundingClientRect().height;
        const tdWidth = td ? td.getBoundingClientRect().width : 0;
        const width = thWidth > tdWidth ? thWidth : tdWidth;

        const clone = th.cloneNode(true);
        clone.setAttribute('style', `min-width: ${width}px; height: ${thHeight}px`);

        stickyHeaderRow.append(clone);
    }

    // Style settings that makes the sticky header work
    const wrapperClasses = wrapper.getAttribute('class');
    const tableWrapperClasses = tableWrapper.getAttribute('class');

    wrapper.setAttribute('class', wrapperClasses + ' sticky');

    tableWrapper.setAttribute('class', tableWrapperClasses + ' sticky');
    stickyTable.setAttribute('class', `${styles.stTable} ${styles.stSticky}`);
    stickyHeader.setAttribute('class', styles.stHeader);

    // Adding the html elements together
    stickyHeader.append(stickyHeaderRow);
    stickyTable.append(stickyHeader);
    stickyWrapper.prepend(stickyTable);

    // Finally, if the table is sortable, attaching the event listeners for sorting
    if (sortable) addSortEventListener(tableStorage, true);

    handleResize(id);

    // const isScrollable = table.getBoundingClientRect().height > tableWrapper.getBoundingClientRect().height;

    // wrapper.setAttribute('style', `min-width: ${stickyWrapper.getBoundingClientRect().width + (isScrollable ? 17 : 0)}px`);


    window.onresize = () => handleResize(id);

};
//
// const handleResize = (id) => {
//
//     const wrapper = document.getElementById(`main-wrapper-${id}`);
//     const tableWrapper = document.getElementById(`simplexion-table-${id}`);
//
//     const table = document.getElementById(id);
//
//     const tableBody = table.getElementsByTagName('tbody')[0];
//     const tableBodyRow = tableBody.getElementsByTagName('tr');
//     const tableBodyCells = tableBodyRow[0].getElementsByTagName('td');
//
//     const tableHead = table.getElementsByTagName('thead')[0];
//     const tableHeaderCells = tableHead.getElementsByTagName('th');
//
//     const stickyWrapper = document.getElementById(`sticky-header-${id}`);
//     const stickyHeaderCells = stickyWrapper.getElementsByTagName('th');
//
//     for (let i = 0; i < tableHeaderCells.length; i++) {
//         const th = tableHeaderCells[i];
//         const td = tableBodyCells[i];
//         const stickyTh = stickyHeaderCells[i];
//         const thWidth = th.getBoundingClientRect().width;
//         const thHeight = th.getBoundingClientRect().height;
//         const tdWidth = td ? td.getBoundingClientRect().width : 0;
//         const width = thWidth > tdWidth ? thWidth : tdWidth;
//
//         stickyTh.setAttribute('style', `min-width: ${width}px; height: ${thHeight}px`);
//     }
//
//     const isScrollableOnY = table.getBoundingClientRect().height > tableWrapper.getBoundingClientRect().height;
//     const isScrollableOnX = wrapper.parentElement.getBoundingClientRect().width >= wrapper.getBoundingClientRect().width;
//
//     if (isScrollableOnX) {
//         wrapper.setAttribute('style', `min-width: ${table.getBoundingClientRect().width + (isScrollableOnY ? 17 : 0)}px`);
//     } else {
//         wrapper.removeAttribute('style');
//     }
// };


