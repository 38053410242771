import {useDrag} from "react-dnd";
import React, {useContext} from "react";
import {makeStyles} from "@material-ui/core";
import classNames from "classnames";
import {dataRowStyle} from "./dataRowStyle";
import {useTranslation} from "react-i18next";
import {navigate} from "hookrouter";
import {AuthContext} from "../../../../auth/AuthContext";
import {checkAdminAccess} from "../../../util";

const useStyles = makeStyles(dataRowStyle);

const DataCell = ({children, branchPath, mainBranchEnded, secondaryBranchEnded}) => {

    const classes = useStyles();

    const position = branchPath.size;

    const isSubLevelTwo = position === 2;
    const isSubLevelThree = position === 3;

    const className = classNames({
        [classes.dataCell]: children,
        [classes.cell]: !children,
        [classes.dataCellBranch]: position > 1,
        [classes.mainBranchOnSubTwo]: isSubLevelTwo && !mainBranchEnded && children,
        [classes.mainBranchOnSubThree]: isSubLevelThree && !mainBranchEnded && children,
        [classes.secondaryBranch]: isSubLevelThree && !secondaryBranchEnded && children,
        [classes.mainAndSecondaryBranch]: isSubLevelThree && !secondaryBranchEnded && !mainBranchEnded && children,
    });


    return (
        <td className={className}>
            {children}
        </td>
    );
};

const Data = ({record, branchPath}) => {

    const context = useContext(AuthContext)

    const roles = context.getIn(['session', 'roles']);

    const hasAccess = checkAdminAccess(roles, record.type)

    const classes = useStyles();
    const position = branchPath.size;

    const {t} = useTranslation('contentManagement');

    // eslint-disable-next-line no-empty-pattern
    const [{}, drag] = useDrag({
        item: {type: 'cell', id: record.id, branchPath: branchPath, parent: record.parent},
        canDrag: position !== 0,
        collect: monitor => ({
            isDragging: monitor.isDragging(),
        }),
    });

    const dataClasses = classNames({
        [classes.data]: true,
        [classes.dataBranch]: position,
    });

    const dataBlockClasses = classNames({
        [classes.dataBox]: true,
        [classes.dataBoxDisabled]: !hasAccess
    });

    const handleClick = () => {
        if (!hasAccess) return;
        navigate(`/content-management/${record.id}/edit`)
    };

    return (
        <div className={dataClasses} onClick={handleClick}>
            <div ref={drag} className={dataBlockClasses}>
                <p>{record.title}</p>
                <p className={classes.dataBoxSecondaryRow}>
                    {t(record.type)}
                    {/*{!isDragging && <EditItemButton record={record}/>}*/}
                </p>
            </div>
        </div>
    )
};

const DataRow = props => {

    const classes = useStyles();
    const {record, branchPath} = props;

    const position = branchPath.size;

    let tds = [];

    for (let i = 0; i < 4; i++) {

        const content = position === i ?
            <Data key={i} record={record} branchPath={branchPath}/> :
            null;

        const td = (
            <DataCell {...props} key={`data-cell-${i}`}>
                {content}
            </DataCell>
        );
        tds.push(td);
    }

    return (
        <tr key={`data-row-${record.id}`}
            className={classes.tableBodyRow}>
            {tds}
        </tr>
    );

};


export default DataRow;