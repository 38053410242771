import baseFetch from "../../../baseFetch";
import {NavColorRecord} from "../Data/Records/NavColor";
import {roles} from "../../../../Main/roles";

export function getNavColors(filter, onSuccess, onError) {
    baseFetch('GET', '/nav-colors', filter,
        data => {
            if (onSuccess) onSuccess(data.map(r => new NavColorRecord(r)))
        },
        onError
    );
}

export function updateNavColor(navColor, onSuccess, onError) {
    baseFetch('PATCH', '/nav-colors/' + navColor.get('id'), navColor, onSuccess, onError);
}

export const navColorsApiTest = {
    "/nav-colors": {
        'GET': {
            [roles.ANONYMOUS]: {hasResponse: true},
            [roles.SITE_MEMBER]: {hasResponse: true},
            [roles.COUNCIL_ADMIN]: {hasResponse: true},
            [roles.CM_ADMIN]: {hasResponse: true},
            [roles.UPLOAD_ADMIN]: {hasResponse: true},
            [roles.ADMIN]: {hasResponse: true},
            [roles.SECURITY_OFFICER]: {hasResponse: true}
        },
    },
    "/nav-colors/1": {
        'PATCH': {
            [roles.ANONYMOUS]: {hasResponse: false},
            [roles.SITE_MEMBER]: {hasResponse: false},
            [roles.COUNCIL_ADMIN]: {hasResponse: false},
            [roles.CM_ADMIN]: {hasResponse: false},
            [roles.UPLOAD_ADMIN]: {hasResponse: false},
            [roles.ADMIN]: {hasResponse: true},
            [roles.SECURITY_OFFICER]: {hasResponse: true}
        },
    },
}
