import React, {memo} from "react";
import {useTranslation} from "react-i18next";
import useForm from "../../../Lib/form/useForm";
import {MeetingRecordRecord, MeetingRecordSchema} from '../../../Data/MeetingRecord';
import ContentBox from "../../../Components/ContentBox/ContentBox";
import Grid from "@material-ui/core/Grid";
import Section from "../../../Lib/form/Components/Section/Section";
import ValidatedSubmit from "../../../Lib/form/Components/ValidatedSubmit/ValidatedSubmit";
import debug from "../../../Lib/debug";
import {navigate} from "hookrouter";
import {Navigation} from "../Navigation";
import AttachmentUpload from "../../../Components/AttachmentUpload/AttachmentUpload";
import ValidatedInput from "../../../Lib/form/Components/ValidatedInput/ValidatedInput";
import {getAttachment} from "../../../Api/meeting-records";

export default memo(({meetingId, record, path, view, api}) => {

    debug("RENDER", "MeetingRecord", () => ["Form", meetingId, record, path, view, api]);

    const {t} = useTranslation('meetingRecord');

    const form = useForm({
        disabledForm: view === 'remove',
        recordType: MeetingRecordRecord,
        recordSchema: MeetingRecordSchema,
        namespace: 'meetingRecord',
        record: record
    });

    const onCancel = () => navigate(`/meetings/${meetingId}/edit`);

    return (
        <ContentBox path={path} form={form}>
            {view === "add" ? null : <Navigation meetingRecordId={meetingId}/>}
            <Section title={t(view)}>
                <ValidatedInput name='title'/>
                <AttachmentUpload id={record.id}
                                  ns='meetingRecord'
                                  name='attachmentId'
                                  attachments={record.attachmentId.toString()}
                                  api={getAttachment}
                                  apiPath={'/attachments'}/>
            </Section>
            <Grid container>
                <Grid item xs={12} md={8}>
                    <ValidatedSubmit view={view} api={api} onCancel={onCancel}/>
                </Grid>
            </Grid>
        </ContentBox>
    );
})
