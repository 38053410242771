import {Record} from 'immutable';
import {number, object, string} from "yup";

export const MeetingRecordRecord = Record({
    id: 0,
    title: '',
    attachmentId: 0,
});

export const MeetingRecordSchema = object().shape({
    id: number().required().min(0).default(0),
    title: string().required().min(1).max(100).default(''),
    attachmentId: number().min(1).required().default(0),
});
