import React, {memo, useState} from "react";
import {useRoles} from "../../Api/roles";
import ContentBox from "../../../../Components/ContentBox/ContentBox";
import {SimplexionTable} from "../../../simplexion-table/SimplexionTable";
import {makeStyles} from "@material-ui/core";
import {customActions, customActionsStyle} from "../../../simplexion-table/templates/action-buttons";
import debug from "../../../debug";
import LinearProgress from "@material-ui/core/LinearProgress";
import Section from "../../../form/Components/Section/Section";
import {FilterField} from "../../../Table/Components/FilterField";
import {baseTableStyles} from "../../../simplexion-table/styles/baseStyle";

const styles = () => {
    let classes = baseTableStyles;
    Object.entries(customActionsStyle).forEach(entry => {
        classes[entry[0]] = entry[1];
    });
    return classes;
};

const useStyles = makeStyles(styles);

const List = memo((props) => {

    debug("RENDER", "useForm", () => [" -> List_Of_Roles", props]);

    const {path, hasAdminRoles} = props;

    const tableClasses = useStyles();

    const urlNs = path[0].url + "/";

    const [state, setState] = useState('');

    const {loadingStatus, data} = useRoles();

    const handleFilter = val => setState(val);

    const tableConfig = {
        id: 'roles-table',
        nameSpace: 'roles',
        stickyHeader: true,
        sortable: true,
        styles: tableClasses,
        columns: [
            {
                accessor: 'id',
            },
            {
                accessor: 'name',
            },
            {
                accessor: 'grantCount',
            },
            {
                accessor: 'description',
            },
            {
                accessor: 'locked',
                renderer: (accessor, record) => record.locked ? `<span><i class="material-icons">lock</i></span>` : `<span><i class="material-icons">lock_open</i></span>`,
            },
            {
                accessor: 'action',
                renderer: (...args) => customActions(...args, true, false, urlNs),
                sortable: false,
            },
        ]
    };

    return (
        <ContentBox path={path}>
            <Section>
                <FilterField handler={handleFilter}/>
                {loadingStatus > 0 ? <LinearProgress/> : null}
                <SimplexionTable tableConfig={tableConfig} data={data} loadingStatus={loadingStatus}
                                 filterValue={state}/>
            </Section>
        </ContentBox>
    );
});

export default List;
