import React from "react";
import ContentBox from "../../../../Components/ContentBox/ContentBox";
import {useAttachments, useDecision} from "../../../../Api/decisions";
import Grid from "@material-ui/core/Grid";
import {makeStyles, Typography} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import {cityCouncilPageStyle} from "../../cityCouncilPageStyle";
import useNav from "../../../../Lib/useNav";
import Box from "@material-ui/core/Box";

const useStyles = makeStyles(cityCouncilPageStyle);

const DownloadItem = ({id, name}) => {

    const classes = useStyles();

    return (
        <Grid item xs={12}>
            <a href={`/api/1.0.0/attachments/${id}`} rel='noopener noreferrer' target='_blank' className={classes.docItem}>
                {name}
            </a>
        </Grid>
    );
};

const DownloadList = ({decisionId}) => {

    const {data, loadingStatus} = useAttachments(decisionId);

    if (loadingStatus) return null;

    return (
        <Grid container direction='column'>
            {data.map(record => <DownloadItem key={record.id} id={record.id} name={record.name}/>)}
        </Grid>
    );
};

const DecisionDetails = ({path, pageId, decisionId}) => {

    const {t} = useTranslation('cityCouncilPage');

    useNav({path: window.location.pathname});

    const classes = useStyles();

    const {data, loadingStatus} = useDecision(decisionId);

    if (loadingStatus) return null;

    const lineColor = path[0] ? path[0].color : undefined;

    path.push({label: data.numberOfDecision, url: `/city-council-pages/${pageId}/decisions/${decisionId}`, color: lineColor});

    let updatedPath = path;

    const url = `/city-council-pages/${pageId}/decisions/${decisionId}`;

    if (!path.find(p => p.url === url)) {

        updatedPath = path.withMutations(p => {

            const currentPath = {
                label: data.numberOfDecision,
                url: `/city-council-pages/${pageId}/decisions/${decisionId}`,
                color: lineColor
            };

            p.push(currentPath);

        });
    }

    return (
        <ContentBox publicPage={true} path={updatedPath}>
            <Grid container>
                <Grid container item xs={12} md={6} xl={4}>
                    <Grid item container className={classes.dataLineBox}>
                        <Grid item xs={12} md={4}>
                            <Typography variant='body1' className={classes.detailName}>
                                {t('numberOfDecision')}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <Typography>
                                {data.numberOfDecision}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item container className={classes.dataLineBox}>
                        <Grid item xs={12} md={4}>
                            <Typography variant='body1' className={classes.detailName}>
                                {t('title')}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <Typography>
                                {data.title}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item container className={classes.dataLineBox}>
                        <Grid item xs={12} md={4}>
                            <Typography variant='body1' className={classes.detailName}>
                                {t('presenterName')}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <Typography>
                                {data.presenterName}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item container className={classes.dataLineBox}>
                        <Grid item xs={12} md={4}>
                            <Typography variant='body1' className={classes.detailName}>
                                {t('type')}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <Typography>
                                {t(data.type)}
                            </Typography>
                        </Grid>
                    </Grid>
                    {data.decisionText &&
                    <Grid item container className={classes.dataLineBox}>
                        <Grid item xs={12} md={4}>
                            <Typography variant='body1' className={classes.detailName}>
                                {t('decisionText')}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <Typography>
                                <Box className={"ql-display"} dangerouslySetInnerHTML={{__html: data.decisionText}}/>
                            </Typography>
                        </Grid>
                    </Grid>
                    }
                    {data.type === 'OPEN' &&
                    <Grid item container className={classes.dataLineBox}>
                        <Grid item xs={12} md={4}>
                            <Typography variant='body1' className={classes.detailName}>
                                {t('documents')}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <DownloadList decisionId={decisionId}/>
                        </Grid>
                    </Grid>
                    }
                </Grid>
            </Grid>
        </ContentBox>
    );
};

export default DecisionDetails;