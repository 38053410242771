import React, {memo, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import useForm from "../../Lib/form/useForm";
import {MeetingItemRecord, MeetingItemSchema} from '../../Data/MeetingItem';
import ContentBox from "../../Components/ContentBox/ContentBox";
import Grid from "@material-ui/core/Grid";
import Section from "../../Lib/form/Components/Section/Section";
import ValidatedSubmit from "../../Lib/form/Components/ValidatedSubmit/ValidatedSubmit";
import ValidatedRadioGroup from "../../Lib/form/Components/ValidatedRadio/ValidatedRadioGroup";
import ValidatedRadio from "../../Lib/form/Components/ValidatedRadio/ValidatedRadio";
import ValidatedSelect from "../../Lib/form/Components/ValidatedSelect/ValidatedSelect";
import ValidatedInput from "../../Lib/form/Components/ValidatedInput/ValidatedInput";
import {useMeeting, useMeetings} from "../../Api/meetings";
import ValidatedCheckbox from "../../Lib/form/Components/ValidatedCheckbox/ValidatedCheckbox";
import {useAccounts} from "../../Api/accounts";
import debug from "../../Lib/debug";
import {navigate} from "hookrouter";
import {Navigation} from "./Navigation";
import ValidatedCheckboxGroup from "../../Lib/form/Components/ValidatedCheckbox/ValidatedCheckboxGroup";

const MeetingCheckBoxGroup = ({meetingData}) => {

    const {t} = useTranslation('meetingElements');

    const {data, loadingStatus} = useMeetings();

    const [meetings, setMeetings] = useState([]);

    const year = meetingData ? meetingData.date.substring(0, 4) : '';

    useEffect(() => {
        if (!loadingStatus && year) {
            const meetingsOfCurrentYear = data.filter(meeting => meeting.date.substring(0, 4) === year);
            setMeetings(meetingsOfCurrentYear);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingStatus, year]);

    if (loadingStatus) return null;

    return (
        <ValidatedCheckboxGroup name="meetings" label={t('meetings')}>
            {meetings.map(record => {
                const label = `${record.date} ${record.time}, ${record.committeeName}, ${t(record.type)}`;
                return <ValidatedCheckbox key={record.id}
                                          name="meetings"
                                          value={record.id}
                                          customLabel={label}
                                          multi/>;
            })}
        </ValidatedCheckboxGroup>
    );
};

export default memo(({elementId, meetingId, record, path, view, api}) => {

    debug("RENDER", "Meeting element", () => ["Form", meetingId, elementId, record, path, view, api]);

    const {t} = useTranslation('meetingElements');

    const form = useForm({
        disabledForm: view === 'remove',
        recordType: MeetingItemRecord,
        recordSchema: MeetingItemSchema,
        namespace: 'meetingElements',
        record: record
    });

    const {data} = useMeeting(meetingId);

    const onSuccess = () => navigate(`/meeting-elements/${meetingId}`);

    const onCancel = () => navigate(`/meeting-elements/${meetingId}`);

    return (
        <ContentBox path={path} form={form}>

            {view === "add" ? null : <Navigation meetingId={meetingId} elementId={elementId}/>}

            <Grid container direction="row" justify="center">
                <Section title={t(view)}>
                    <ValidatedSelect name="presenter" recordFieldName="fullName"
                                     specialId='principalId' //fixme change this to accountId
                                     api={useAccounts}/>
                    <ValidatedInput name="topic"/>
                    <ValidatedRadioGroup name="type">
                        <ValidatedRadio name="type" value="OPEN"/>
                        <ValidatedRadio name="type" value="CLOSED"/>
                    </ValidatedRadioGroup>
                    <MeetingCheckBoxGroup meetingData={data}/>
                    <ValidatedCheckboxGroup name="public" label='status'>
                        <Grid container>
                            <ValidatedCheckbox name="public" label='public'/>
                        </Grid>
                    </ValidatedCheckboxGroup>
                </Section>
                <ValidatedSubmit view={view} api={api} onSuccess={onSuccess} onCancel={onCancel}/>
            </Grid>
        </ContentBox>
    );
})