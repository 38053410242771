import React from "react";
import Grid from "@material-ui/core/Grid";
import {makeStyles, Typography} from "@material-ui/core";
import ContentBox from "../../../../Components/ContentBox/ContentBox";
import useNav from "../../../../Lib/useNav";
import {SimplexionTable} from "../../../../Lib/simplexion-table/SimplexionTable";
import {linkActionDirect} from "../../../../Lib/simplexion-table/templates/link-button";
import {themedTableStyles} from "../../../../Lib/simplexion-table/styles/themedTableStyles";
import {cityCouncilPageStyle} from "../../cityCouncilPageStyle";
import {useTranslation} from "react-i18next";
import Box from "@material-ui/core/Box";
import {tanacsUrl} from "../../../../globals";
import {useMeetingItems} from "../../../../Api/meeting-items";


const useTableStyles = makeStyles(themedTableStyles);
const useStyles = makeStyles(cityCouncilPageStyle);

const List = ({meetingId, pageId}) => {

    const {t} = useTranslation('cityCouncilPage');

    const tableClasses = useTableStyles();
    const classes = useStyles();

    const {data} = useMeetingItems(meetingId);

    const tableConfig = {
        id: 'meeting-elements',
        nameSpace: 'cityCouncilPage',
        sortable: true,
        styles: tableClasses,
        columns: [
            {
                accessor: 'order',
            },
            {
                accessor: 'subject',
            },
            {
                accessor: 'type',
                renderer: (accessor, record) => t(record[accessor])
            },
            {
                accessor: 'presenter1'
            },
            {
                accessor: 'presenter2'
            },
            {
                accessor: '',
                renderer: (accessor, record, tableStorage) => linkActionDirect(accessor, record, tableStorage, 'details', `/city-council-pages/${pageId}/meetings/${meetingId}/items/${record['id']}`)
            }
        ],
    };

    return (
        <Box>
            <Box className={classes.subtitleBox}>
                <Typography variant='body1'>
                    {t('meetingElements')}
                </Typography>
            </Box>
            <SimplexionTable tableConfig={tableConfig} data={data}/>
        </Box>
    );
};

const MeetingRecordDownload = ({meetingData}) => {

    const classes = useStyles();

    const {t} = useTranslation('cityCouncilPage');

    if (meetingData.meetingRecord === null || meetingData.meetingRecord === undefined) return <Grid item xs={12}
                                                                                                    className={classes.record}></Grid>;

    return (
        <Grid item xs={12} className={classes.record}>
            <Box className={classes.subtitleBox}>
                <Typography variant='body1'>
                    {t('meetingRecord')}
                </Typography>
            </Box>
            <Box className={classes.record}>
                <a href={`${tanacsUrl}/blob/${meetingData.meetingRecord}`}
                   target='_blank'
                   rel='noopener noreferrer'
                   className={classes.attachment}>
                    {t('meetingRecord')}
                </a>
            </Box>
        </Grid>
    );
};

const MeetingDetails = ({path, meetingId, pageId, data}) => {

    useNav({path: window.location.pathname});

    const classes = useStyles();
    const {t} = useTranslation('cityCouncilPage');

    return (
        <ContentBox publicPage={true} path={path}>
            <div style={{marginTop : "16px"}}>
                <Typography variant='h5' className={classes.title}>
                    {t('councilMeeting')}
                </Typography>
            </div>
            <Grid container component='div' className={classes.detailsContainer}>
                <div style={{
                    "display": "grid",
                    "gridTemplateColumns": "minmax(150px,min-content) 1fr",
                    "gridAutoRows": "28px",
                    "columnGap": "12px"
                }}>
                    <div><b>{t("date")}</b></div>
                    <div>{data.date}</div>
                    <div><b>{t("time")}</b></div>
                    <div>{data.time}</div>
                    <div><b>{t("type")}</b></div>
                    <div>{data.type}</div>
                    <div><b>{t("place")}</b></div>
                    <div>{data.place}</div>
                </div>

                <MeetingRecordDownload meetingData={data}/>

                <div style={{
                    "display": "grid",
                    "gridTemplateColumns": "1fr",
                    "gridTemplateRows": "min-content",
                    overflowX: "auto"
                }}>
                    <List meetingId={meetingId} pageId={pageId}/>
                </div>
            </Grid>
        </ContentBox>
    );

};

export default MeetingDetails;