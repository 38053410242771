import ListGroup from "./ListGroup";
import React from "react";
import {AuthContext} from "../auth/AuthContext";

export const isArray = data => Array.isArray(data);

export const isOwner = (data) => {
    const id = AuthContext["_currentValue"].getIn(["session", "accountId"])
    return id === data.id;
}

export const checkAll = (callback, data) => {
    if (!isArray(data)) return false;
    return data.every(callback);
}

export const hasValue = (key, value, data) => data[key] === value;

export const hasValueIn = (key, value, data) => {
    return data[key].includes(value);
}

export const getAll = (url, onResponse) => {
    baseFetchForTest('GET', url, onResponse)
}

export const getOne = (url, onResponse) => {
    baseFetchForTest('GET', url, onResponse)
}

export const addData = (url, data, onResponse) => {
    baseFetchForTest('POST', url, onResponse, data)
}

export const updateData = (url, data, onResponse) => {
    baseFetchForTest('PATCH', url, onResponse, data)
}

export const removeData = (url, onResponse) => {
    baseFetchForTest('DELETE', url, onResponse)
}

export function baseFetchForTest(method, url, onResponse, data) {

    let fullUrl = '/api/1.0.0' + url;

    if (method === 'GET' && data !== undefined) {
        let params = new URLSearchParams();
        // eslint-disable-next-line no-unused-vars
        for (let key in data) {
            if (data.hasOwnProperty(key)) {
                let value = data[key];
                if (value === undefined) continue;
                if (value instanceof Array) {
                    for (let i = 0; i < value.length; i++) { // intentional, to avoid function-in-loop
                        params.append(key, value[i]);
                    }
                } else {
                    params.append(key, value);
                }
            }
        }

        fullUrl += "?" + params;
    }

    let fetchParams = {
        method: method,
        credentials: "same-origin",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: (method === 'POST' || method === 'PUT' || method === 'PATCH') ? JSON.stringify(data) : undefined
    };

    fetch(fullUrl, fetchParams)

        .then((r) => {

            if (url === '/accounts/reset') console.log(r)

            const contentType = r.headers.get('content-type');

            if (r.ok && contentType === "application/json") {
                return r.json().then(data => ({
                    status: r.status,
                    data: data
                }));
            }
            else if (r.ok) {
                return {
                    status: r.status,
                    data: undefined,
                }
            }
            else throw r;

        })
        .then(responseData => {

            onResponse({
                ...responseData,
                method: method,
                url: url,
                ok: true,
            });
        })
        .catch((error) => {

            onResponse({
                method: method,
                url: url,
                status: error.status,
                ok: false,
                data: undefined,
            });

        });
}

export const createRows = (calls, apiTests) => calls.map((c, i) => <ListGroup key={i} apiCall={c}
                                                                              apiTests={apiTests}/>);
