import {useTranslation} from "react-i18next";
import {updateCityCouncilPage} from "../../Api/cityCouncilPages";
import useForm from "../../../../form/useForm";
import {CityCouncilPageRecord, CityCouncilPageSchema} from "../../Data/CityCouncilPage";
import FormProvider from "../../../../form/Components/FormProvider/FormProvider";
import React from "react";
import ValidatedRichTextEditor from "../../../../form/Components/ValidatedRichTextEditor/ValidatedRichTextEditor";
import ValidatedSubmit from "../../../../form/Components/ValidatedSubmit/ValidatedSubmit";
import Grid from "@material-ui/core/Grid";
import {cityCouncilPageEditorStyle} from "./cityCouncilPageEditorStyle";
import {makeStyles} from "@material-ui/core";
import Box from "@material-ui/core/Box";


const useStyles = makeStyles(cityCouncilPageEditorStyle);

const Form = ({data, view}) => {

    const classes = useStyles();

    const {t} = useTranslation("contentManagement");

    const form = useForm({
        disabledForm: view === 'remove',
        recordType: CityCouncilPageRecord,
        recordSchema: CityCouncilPageSchema,
        namespace: 'contentManagement',
        record: data,
    });

    const api = updateCityCouncilPage;

    return (
        <Grid container className={classes.formBox}>
            <FormProvider form={form}>
                {data.type &&
                <Grid item xs={12}>
                    <Box className={classes.title}>{t(data.type)}</Box>
                </Grid>
                }
                <Grid item className={classes.descriptionContainer}>
                    <ValidatedRichTextEditor name='text'
                                             showLabel={true}
                    />
                </Grid>
                <Grid item xs={12} container>
                    <ValidatedSubmit view={view}
                                     api={api}
                                     hideCancel={true}
                    />
                </Grid>
            </FormProvider>
        </Grid>
    );
};

export default Form;