export const meetingRecordHu = {
    "titleNav": "Jegyzőkönyv",
    "add": "Jegyzőkönyv feltöltése",
    "edit": "Jegyzőkönyv szerkesztése",
    "remove": "Jegyzőkönyv törlése",
    "delete": "Jegyzőkönyv törlése",
    "meetingRecord": "Jegyzőkönyv",
    "title": "Cím",
    "attachmentId": "Fájl",
    "back": "Vissza",
    "save": "Jegyzőkönyv mentése",
};