import React, {useContext} from "react";
import {makeStyles} from "@material-ui/styles";
import {languageSelectorNavBarStyle as styleBase} from "./languageSelectorNavBarStyle";
import {languageSelectorDrawerStyle as styleSmallScreen} from "./languageSelectorDrawerStyle";

import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import {useTranslation} from "react-i18next";
import {AppContext} from "../../Main/AppContext";
import {appActions as Actions} from "../../Data/Actions/AppActions"
import {changeLanguage} from "../../Api/language";
import {getContents} from "../../Lib/ContentManagement/MenuBuilder/Api/content";
import {AuthContext} from "../../Lib/auth/AuthContext";


const useStylesBase = makeStyles(styleBase);
const useStylesSmallScreen = makeStyles(styleSmallScreen);

function LanguageSelector({smallScreen}) {

    const classesBase = useStylesBase();
    const classesSmallScreen = useStylesSmallScreen();

    const appContext = useContext(AppContext);
    const authContext = useContext(AuthContext);

    const dispatch = appContext.get('dispatch');

    const classes = smallScreen ? classesSmallScreen : classesBase;

    const {t, i18n} = useTranslation('general');

    const changeLang = (lang, event) => {
        event.stopPropagation();
        if (lang === i18n.language) return;
        changeLanguage(lang.toUpperCase(), () => {
            getContents(undefined, contents => {
                dispatch(Actions.changeLanguage(lang, contents, authContext.getIn(['session','roles'])));
                return i18n.changeLanguage(lang);
            })
        });
    };

    return (
        <Grid item container xs={smallScreen ? 12 : 2} component='div' className={classes.languageSelectorWrapper}>
            <Typography onClick={event => changeLang('hu', event)}
                        variant='caption'
                        className={classes.languageSelector}>
                {t('hungarian')}
            </Typography>
            <Typography onClick={event => changeLang('en', event)}
                        variant='caption'
                        className={classes.languageSelector}>
                {t('english')}
            </Typography>
            <Typography onClick={event => changeLang('de', event)}
                        variant='caption'
                        className={classes.languageSelector}>
                {t('german')}
            </Typography>
        </Grid>
    );
}

export default LanguageSelector;