import React, {memo, useEffect, useState} from "react";
import ContentBox from "../../../Components/ContentBox/ContentBox";
import Section from "../../../Lib/form/Components/Section/Section";
import {SimplexionTable} from "../../../Lib/simplexion-table/SimplexionTable";
import {customActionsStyle} from "../../../Lib/simplexion-table/templates/action-buttons";
import {baseTableStyles} from "../../../Lib/simplexion-table/styles/baseStyle";
import {makeStyles} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import {removeListOfWatchersForItem, useListOfWatchersForItems} from "../../../Api/list-of-watchers-for-items";
import {useSnackbar} from "notistack";
import {throwsExceptionMessage} from "../../../Api/catchException";
import {closeSnack} from "../../../Main/util";
import LinearProgress from "@material-ui/core/LinearProgress";
import {FilterField} from "../../../Lib/Table/Components/FilterField";

const styles = () => {
    let classes = baseTableStyles;
    Object.entries(customActionsStyle).forEach(entry => {
        classes[entry[0]] = entry[1];
    });
    return classes;
};

const useStyles = makeStyles(styles);

const ListOfWatchersForItem = memo(({itemId, parentName}) => {

    const {t} = useTranslation("listOfWatchersForItems")
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();

    const nameOfParent = parentName ? parentName.replace(/%20/g, " ") : t("**unknown-name**");

    const path = [{
        label: t("items"),
        url: "/items"
    }, {
        label: nameOfParent + " " + t("edit"),
        url: "/items/" + itemId + "/edit"
    }, {
        label: t("watchers"),
        url: "/items/" + itemId + "/list-of-watchers-for-items/" + parentName
    }]

    const tableClasses = useStyles();

    const [num, setNum] = useState(0)

    const {loadingStatus, data} = useListOfWatchersForItems(itemId, num);

    const [state, setState] = useState('');

    const handleFilter = val => setState(val);

    const handleClick = (event) => {
        if (event.target.parentElement.classList.contains("removeElement")) {
            const success = () => {
                setNum(num => num + 1)
                enqueueSnackbar(t("successfulDeletion"), {
                    variant: 'success',
                    autoHideDuration: 1000,
                    action: (key) => closeSnack(t, closeSnackbar, key)
                });
            };

            const error = (error) => {
                enqueueSnackbar(t('catchException:' + throwsExceptionMessage(error)), {
                    variant: 'error',
                    autoHideDuration: 5000,
                    action: (key) => closeSnack(t, closeSnackbar, key)
                });
            };

            removeListOfWatchersForItem(event.target.id, success, error)
        }
    };

    useEffect(() => {
        window.addEventListener('click', handleClick);
        // cleanup this component
        return () => {
            window.removeEventListener('click', handleClick);
        };
    }, []);

    useEffect(() => {
    }, [num])

    const tableConfig = {
        id: 'list-of-watchers-for-items-table',
        nameSpace: 'listOfWatchersForItems',
        stickyHeader: true,
        sortable: true,
        styles: tableClasses,
        columns: [
            {
                accessor: 'watcherName',
            },
            {
                accessor: 'emailOfWatcher',
            },
            {
                accessor: 'phoneOfWatcher',
            },
            {
                accessor: 'remove',
                renderer: (accessor, record) => `<div id=${record.id}  class="removeElement"><span id=${record.id} style="color: red;font-size: 36px;padding-top: 0.4rem" class="material-icons">delete_forever
</span></div>`,
            }
        ],
    };

    return (
        <ContentBox publicPage={false} path={path}>
            <Section>
                <FilterField handler={handleFilter}/>
                {loadingStatus > 0 ? <LinearProgress/> : null}
                <SimplexionTable tableConfig={tableConfig} data={data} loadingStatus={loadingStatus}
                                 filterValue={state}/>
            </Section>
        </ContentBox>
    );
});

export default ListOfWatchersForItem;