import React from "react";
import List from "./List";
import {useRoutes} from "hookrouter";
import Form from "./Form";
import Crud from "../../Lib/Crud";
import {ItemRecord, ItemSchema} from "../../Data/Item";
import {addItem, removeItem, updateItem, useItem} from "../../Api/items";
import ListOfWatchersForItem from "./ListOfWatchersForItems/ListOfWatchersForItem";

export default (props) => useRoutes(subRoutes)(props);

const subRoutes = {
    "/": () => props => <Crud view="list" config={crudConfig} {...props}/>,
    "/add": () => props => <Crud view="add" config={crudConfig} {...props}/>,
    "/:id/edit": ({id}) => props => <Crud view="edit" id={id} config={crudConfig} {...props}/>,
    "/:id/remove": ({id}) => props => <Crud view="remove" config={crudConfig} id={id} {...props}/>,
    "/:id/list-of-watchers-for-items/:parentName?": ({id, parentName}) => props => <ListOfWatchersForItem itemId={id}
                                                                                                          parentName={parentName}/>,
};

const crudConfig = {
    compName: 'items',
    namespace: 'items',
    url: '/items',
    labelField: 'name',
    Record: ItemRecord,
    Schema: ItemSchema,
    ListComponent: List,
    FormComponent: Form,
    api: {
        use: useItem,
        add: addItem,
        edit: updateItem,
        remove: removeItem
    }
};