import React, {memo} from "react";
import ContentBox from "../../Components/ContentBox/ContentBox";
import {useCommittees} from "../../Api/committees";
import {baseTableStyles} from "../../Lib/simplexion-table/styles/baseStyle";
import {customActions, customActionsStyle} from "../../Lib/simplexion-table/templates/action-buttons";
import {makeStyles} from "@material-ui/core";
import Section from "../../Lib/form/Components/Section/Section";
import {SimplexionTable} from "../../Lib/simplexion-table/SimplexionTable";

const urlNs = "/committees/";

const styles = () => {
  let classes = baseTableStyles;
  Object.entries(customActionsStyle).forEach(entry => {
    classes[entry[0]] = entry[1];
  });
  return classes;
};

const useStyles = makeStyles(styles);

const List = memo((props) => {

  const {path} = props;

  const classes = useStyles();

  const { data } = useCommittees();

  const tableConfig = {
    id: 'committees-table',
    nameSpace: 'committees',
    stickyHeader: true,
    sortable: true,
    styles: classes,
    columns: [
      {
        accessor: 'name',
      },
      {
        accessor: 'actions',
        renderer: (...args) => customActions(...args, true, true, urlNs),
      }
    ],
  };

  return (
      <ContentBox path={path} add={urlNs + "add"}>
        <Section>
          <SimplexionTable tableConfig={tableConfig} data={data}/>
        </Section>
      </ContentBox>
  );
});

export default List;