export const kolorLineNewsStyle = theme => ({
    kolorLineNews: {
        color: theme.palette.text.primary,
    },
    header: {
        alignItems: 'baseline',
        marginBottom: theme.spacing(3),
        [theme.breakpoints.up('md')]: {
            marginBottom: theme.spacing(6),
        }
    },
    title: {
        color: theme.palette.text.primary,
        fontWeight: 'bold',
        [theme.breakpoints.up('md')]: {
            marginBottom: theme.spacing(2),
        },
    },
    subtitle: {
        fontWeight: 'bold',
        color: theme.palette.text.primary,
        [theme.breakpoints.up('md')]: {
            paddingLeft: theme.spacing(3),
            textAlign: 'right',
        },
    },
    subtitleAuthor: {
        fontWeight: 'bold',
        color: theme.palette.text.primary,
    },

    imageContainer: {
        margin: 0,
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'center',
        marginBottom: theme.spacing(3),
        [theme.breakpoints.up('sm')]: {
            marginBottom: theme.spacing(6),
        },
    },

    image: {
        ...theme.imgFluid
    },

    lead: {
        color: theme.palette.text.primary,
        marginBottom: theme.spacing(3),
        fontWeight: 500,
        [theme.breakpoints.up('md')]: {
            paddingLeft: theme.spacing(3),
        },
    },

    content: {
        color: theme.palette.text.primary,
        textAlign: 'justify',
        marginBottom: theme.spacing(6),
        [theme.breakpoints.up('md')]: {
            paddingLeft: theme.spacing(3),
        },
        '& *:first-child': {
            marginTop: 0,
            paddingTop: 0,
        },
    },
});