import {Record} from 'immutable';
import {number, object} from "yup";

export const DataPolicyRecord =  Record({
    attachment: 0,
});

export const DataPolicySchema = object().shape({
    attachment: number().required().min(1).default(0),
});
