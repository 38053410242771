import React from "react";
import {makeStyles} from "@material-ui/styles";
import {appFooterStyle as style} from "./appFooterStyle";
import Grid from "@material-ui/core/Grid";
import {Typography} from "@material-ui/core";
import {A} from "hookrouter";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUpRounded"
import IconButton from "@material-ui/core/IconButton";
import {useTranslation} from "react-i18next";
import {useDataPolicy} from "../../Lib/ContentManagement/ContentEditor/Api/pages";

const useStyles = makeStyles(style);

function AppFooter() {

    const {t} = useTranslation('general');

    const {data} = useDataPolicy();

    const classes = useStyles();

    return (
        <Grid container className={classes.root} component='div'>
            <Grid container item xs={12} md={10} className={classes.footerLinkBox}>
                <Grid item component='div' xs={12} md={4}>
                    <a href={data === 0 ? '/#' : `/api/1.0.0/attachments/${data}`} target='_blank' rel='noopener noreferrer' className={classes.footerLink}>
                        <Typography className={classes.footerLinkText}>
                            {t('Privacy Policy')}
                        </Typography>
                    </a>
                </Grid>
                <Grid item component='div' xs={12} md={4}>
                    <A href='/contact' className={classes.footerLink}>
                        <Typography className={classes.footerLinkText}>
                            {t('Contact')}
                        </Typography>
                    </A>
                </Grid>
                <Grid item component='div' xs={12} md={4}>
                    <A href='/impressum' className={classes.footerLink}>
                        <Typography className={classes.footerLinkText}>
                            {t('Impressum')}
                        </Typography>
                    </A>
                </Grid>
            </Grid>
            <IconButton aria-label='Jump to the top of the page.' className={classes.jumpToTopButton}>
                <ArrowDropUpIcon className={classes.jumpToTopIcon}/>
            </IconButton>
        </Grid>
    );
}

export default AppFooter;