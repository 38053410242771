import React, {useContext, useEffect, useState} from "react";
import FormProvider from "../../../../form/Components/FormProvider/FormProvider";
import Section from "../../../../form/Components/Section/Section";
import Grid from "@material-ui/core/Grid";
import ValidatedInput from "../../../../form/Components/ValidatedInput/ValidatedInput";
import ValidatedRichTextEditor from "../../../../form/Components/ValidatedRichTextEditor/ValidatedRichTextEditor";
import StatusRadios from "../StatusRadios";
import ValidatedSubmit from "../../../../form/Components/ValidatedSubmit/ValidatedSubmit";
import {useTranslation} from "react-i18next";
import useForm, {FormContext} from "../../../../form/useForm";
import {makeStyles} from "@material-ui/core";
import {fileUploadEditorStyle} from "./fileUploadEditorStyle";
import {FileUploadPageRecord, FileUploadPageSchema} from "../../Data/FileUploadPage";
import {getFileUploadPage, updateFileUploadPage} from "../../Api/fileUploadPages";
import {customActions, customActionsStyle} from "../../../../simplexion-table/templates/action-buttons";
import {navigate} from "hookrouter";
import Button from "@material-ui/core/Button";
import {SimplexionTable} from "../../../../simplexion-table/SimplexionTable";
import {baseTableStyles} from "../../../../simplexion-table/styles/baseStyle";
import {getListItems} from "../../Api/listItems";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import {selectRow} from "../../../../simplexion-table/templates/util";

const styles = () => {
    let classes = baseTableStyles;
    Object.entries(customActionsStyle).forEach(entry => {
        classes[entry[0]] = entry[1];
    });
    return classes;
};

const useStyles = makeStyles(fileUploadEditorStyle);

const useTableStyles = makeStyles(styles);

const SequenceHandlers = ({changeSeq}) => {

    return (
        <Box>
            <IconButton onClick={() => changeSeq(1)}>
                <KeyboardArrowDownIcon/>
            </IconButton>
            <IconButton onClick={() => changeSeq(-1)}>
                <KeyboardArrowUpIcon/>
            </IconButton>
        </Box>
    );
};

const ListItems = ({contentId, pageId, view}) => {

    const tableClasses = useTableStyles();
    const classes = useStyles();

    const {t} = useTranslation('contentManagement');

    const {fieldValues} = useContext(FormContext);

    const [listItems, setListItems] = useState([]);
    const [disabled, setDisabled] = useState(true);
    const [selected, setSelected] = useState('');

    useEffect(() => {
        if (selected) setDisabled(false);
    }, [selected]);

    useEffect(() => {
        if (pageId) {
            getListItems(pageId, data => setListItems(data));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const tableConfig = {
        id: 'file-uploads',
        nameSpace: 'contentManagement',
        styles: tableClasses,
        columns: [
            {
                accessor: 'name',
            },
            {
                accessor: 'actions',
                renderer: (...args) => customActions(...args, true, false, `/content-management/${contentId}/file-upload-pages/${pageId}/items/`),
            }
        ],
        tbodyEvent: {
            types: ['click'],
            listener: (...args) => selectRow(...args, setSelected),
        }
    };

    const handleClick = () => {
        navigate(`/content-management/${contentId}/file-upload-pages/${pageId}/items/add`);
    };

    const changeSeq = direction => {
        if (selected === undefined || selected === null || selected === "") return;

        setDisabled(true);

        const currentValues = fieldValues.current.items;

        if (currentValues) {

            let updatedValues = currentValues.split(',');
            const index = updatedValues.indexOf(selected)

            if (direction === 1 && index !== updatedValues.length - 1) {
                const nextItem = updatedValues[index + 1];
                const arr1 = updatedValues.slice(0, index);
                const arr2 = updatedValues.slice(index + 2);
                updatedValues = arr1.concat([nextItem, selected]).concat(arr2);
            } else if (direction === -1 && index !== 0) {
                const prevItem = updatedValues[index - 1];
                const arr1 = updatedValues.slice(0, index - 1);
                const arr2 = updatedValues.slice(index + 1);
                updatedValues = arr1.concat([selected, prevItem]).concat(arr2);
            }

            fieldValues.current.items = updatedValues.join(',');

            setListItems(prev => {
                const current = [];

                updatedValues.forEach(id => {
                    current.push(prev.find(item => item.id === parseInt(id)))
                });

                return current;
            })

        }

    };

    return (
        <Section title={`${t('list')} ${t(view)}`}>
            <Grid container>
                <Grid item xs={12}>
                    <Button variant='contained' color='primary' className={classes.addItemBtn} onClick={handleClick}>
                        {t('add')}
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <SequenceHandlers changeSeq={changeSeq}/>
                </Grid>
                <Grid item xs={12}>
                    <SimplexionTable tableConfig={tableConfig} data={listItems}/>
                </Grid>
            </Grid>
        </Section>
    );
};

const FileUploadEditor = ({contentId, fileUploadPageId}) => {

    const classes = useStyles();

    const {t} = useTranslation("contentManagement");

    const [record, setRecord] = useState(new FileUploadPageRecord());

    const form = useForm({
        recordType: FileUploadPageRecord,
        recordSchema: FileUploadPageSchema,
        namespace: 'contentManagement',
        record: record,
    });

    useEffect(() => {
        getFileUploadPage(fileUploadPageId, data => {
            setRecord(data);
        })
    }, [fileUploadPageId]);

    const api = updateFileUploadPage;

    return (
        <React.Fragment>
            <FormProvider form={form}>
                <Section title={`${t('subpage')} ${t('edit')}`}>
                    <Grid container>
                        <Grid item xs={12}>
                            <ValidatedInput name='title' label={t('title', {context: 'subpage'})}/>
                        </Grid>
                        <Grid item xs={12} md={8} className={classes.descriptionContainer}>
                            <ValidatedRichTextEditor name='description' showLabel={true}/>
                        </Grid>
                        <Grid item xs={12}>
                            <StatusRadios/>
                        </Grid>
                        <ValidatedSubmit view='edit' api={api} hideCancel={true}
                        />
                    </Grid>
                </Section>
                <ListItems contentId={contentId} pageId={fileUploadPageId}/>
            </FormProvider>
        </React.Fragment>
    )
};

export default FileUploadEditor;