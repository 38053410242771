import {Record} from 'immutable';
import {number, object, string} from "yup";

export const PositionRecord = Record({
    id: 0,
    name: '',
    committeeId: 0,
    committeeName: '',
    accountId: 0,
    accountFullName: '',
});

export const PositionSchema = object().shape({
    id: number().required().min(0).default(0),
    name: string().required().min(1).max(100).default(""),
    committeeId: number().required().min(1).default(0),
    committeeName: string().nullable().min(0).default(""),
    accountId: number().required().min(1).default(0),
    accountFullName: string().nullable().min(0).default(""),
});
