import React, {memo, useEffect, useState} from "react";
import ContentBox from "../../Components/ContentBox/ContentBox";
import {baseTableStyles} from "../../Lib/simplexion-table/styles/baseStyle";
import {customActions, customActionsStyle} from "../../Lib/simplexion-table/templates/action-buttons";
import {makeStyles} from "@material-ui/core";
import Section from "../../Lib/form/Components/Section/Section";
import {SimplexionTable} from "../../Lib/simplexion-table/SimplexionTable";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import {selectRow} from "../../Lib/simplexion-table/templates/util";
import {useTranslation} from "react-i18next";
import {statusField} from "../../Lib/simplexion-table/templates/status-field";
import {translatableField} from "../../Lib/simplexion-table/templates/translatable-field";
import MeetingSelect from "../../Components/MeetingSelect/MeetingSelect";
import {updateSequence, useMeeting} from "../../Api/meetings";
import FormProvider from "../../Lib/form/Components/FormProvider/FormProvider";
import useForm from "../../Lib/form/useForm";
import ValidatedSelect from "../../Lib/form/Components/ValidatedSelect/ValidatedSelect";
import {generateInvitation, useInvitations} from "../../Api/templates";
import Grid from "@material-ui/core/Grid";
import ValidatedSubmit from "../../Lib/form/Components/ValidatedSubmit/ValidatedSubmit";
import {downloadFile} from "../../Api/attachments";
import {usePositionsOfCommittee} from "../../Api/committees";
import {CreatePdfTemplateRecord, CreatePdfTemplateSchema} from "../../Data/Template";
import {useMeetingItems} from "../../Api/meeting-items";

const styles = () => {
    let classes = baseTableStyles;
    Object.entries(customActionsStyle).forEach(entry => {
        classes[entry[0]] = entry[1];
    });
    return classes;
};

const useTableStyles = makeStyles(styles);

const useStyles = makeStyles({
    invitationFormFieldBox: {
        paddingRight: '2rem',
    }
});

const SequenceHandlers = ({changeSeq, disabled}) => {

    return (
        <Box>
            <IconButton onClick={() => changeSeq(1)} disabled={disabled}>
                <KeyboardArrowDownIcon/>
            </IconButton>
            <IconButton onClick={() => changeSeq(-1)} disabled={disabled}>
                <KeyboardArrowUpIcon/>
            </IconButton>
        </Box>
    );
};

const Elements = ({meetingId, urlNs}) => {
    const classes = useTableStyles();

    const [selected, setSelected] = useState('');
    const [disabled, setDisabled] = useState(true);
    const [revision, setRevision] = useState(0);

    useEffect(() => {
        setRevision(prev => prev + 1);
    }, [meetingId]);

    // todo select row after rerender

    useEffect(() => {
        if (selected) setDisabled(false);
    }, [selected]);

    const {data} = useMeetingItems(meetingId, revision);

    const tableConfig = {
        id: 'meetingElements-table',
        nameSpace: 'meetingElements',
        stickyHeader: true,
        sortable: true,
        styles: classes,
        columns: [
            {
                accessor: 'elementSeq',
            },
            {
                accessor: 'topic',
            },
            {
                accessor: 'presenterName'
            },
            {
                accessor: 'committeeNames'
            },
            {
                accessor: 'type',
                renderer: (...args) => translatableField(...args),
            },
            {
                accessor: 'documentNames',
            },
            {
                accessor: 'public',
                renderer: (...args) => statusField(...args),
            },
            {
                accessor: 'actions',
                renderer: (...args) => customActions(...args, true, true, urlNs),
            },
        ],
        tbodyEvent: {
            types: ['click'],
            listener: (...args) => selectRow(...args, setSelected),
        }
    };

    const changeSeq = direction => {
        setDisabled(true);
        updateSequence(meetingId, selected, direction, () => {
            setRevision(prev => prev + 1);
            setSelected('');
            setDisabled(false);
        });
    };
    
    return (
        <React.Fragment>
            <SequenceHandlers changeSeq={changeSeq} disabled={disabled}/>
            <SimplexionTable tableConfig={tableConfig} data={data}/>
        </React.Fragment>
    );
};

const GenerateInvitation = ({selectedMeeting}) => {

    const {t} = useTranslation('invitations');

    const [revision, setRevision] = useState(0);

    const {data, loadingStatus} = useMeeting(selectedMeeting, revision);

    const record = new CreatePdfTemplateRecord(CreatePdfTemplateSchema.default()).set('meetingId', selectedMeeting);

    const classes = useStyles();

    const form = useForm({
        recordType: CreatePdfTemplateRecord,
        recordSchema: CreatePdfTemplateSchema,
        namespace: 'invitations',
        record: record
    });

    useEffect(() => {
        setRevision(prev => prev + 1);
    },[selectedMeeting]);

    const handleSuccess = data => {
        downloadFile(data.id, 'attachments')
    };

    if (loadingStatus) return null;

    return (
        <Section title={t('generateInvitation')}>
            <FormProvider form={form}>
                <Grid container>
                    <Grid item xs={12} md={4} className={classes.invitationFormFieldBox}>
                        <ValidatedSelect name="invitationName"
                                         label={t('invitationName')}
                                         api={useInvitations}
                                         recordFieldName='name'
                                         specialId='name'
                        />
                    </Grid>
                    <Grid item xs={12} md={4} className={classes.invitationFormFieldBox}>
                        <ValidatedSelect name="positionId"
                                         label={t('positionId')}
                                         api={usePositionsOfCommittee}
                                         filterOfApi={data ? data.committee : 0}
                                         recordFieldName='name'
                                         customItem={record => `${record.accountFullName}, ${record.name}`}
                        />
                    </Grid>
                    <Grid item xs={12} md={3} className={classes.invitationFormFieldBox}>
                        <ValidatedSubmit view='add'
                                         api={generateInvitation}
                                         onSuccess={handleSuccess}
                                         skipSuccessSnack={true}
                                         hideCancel={true}/>
                    </Grid>
                </Grid>
            </FormProvider>
        </Section>
    );
};

const List = memo((props) => {

    const {meetingId, path} = props;

    const {t} = useTranslation('meetingElements');

    const [selectedMeeting, setSelectedMeeting] = useState(meetingId ? meetingId : 0);

    const urlNs = "/meeting-elements/" + selectedMeeting + "/element/";

    return (
        <ContentBox path={path} add={selectedMeeting ? urlNs + "add" : ''}>
            <MeetingSelect handleSelect={setSelectedMeeting} meetingId={selectedMeeting}/>
            <GenerateInvitation selectedMeeting={selectedMeeting}/>
            <Section title={t('listOfMeetingElements')}>
                <Elements meetingId={selectedMeeting} urlNs={urlNs}/>
            </Section>
        </ContentBox>
    );
});

export default List;