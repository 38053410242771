import React, {useEffect, useRef} from "react";
import {useFormField} from "../../useFormField";
import ReCAPTCHA from "react-google-recaptcha";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import {useSystemSettingName} from "../../../auth/Api/system-settings";

function ValidatedReCAPTCHA(props) {

    const {hasAdminRoles} = props

    const recaptchaReference = useRef(ReCAPTCHA);

    const {loadingStatus, data} = useSystemSettingName("ReCAPTCHASiteKey")

    const ff = useFormField(props);

    const makeCaptchaKey = (length) => {
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }

    const handleChange = async () => {
        let recaptchaValue = recaptchaReference.current.getValue();
        ff.onChange(recaptchaValue)
    };

    useEffect(() => {
        if (hasAdminRoles && ff.status.includes("invalid")) {
            ff.onChange(makeCaptchaKey(100))         //TODO It works, but it's awful; (admin doesn't need to click the checkbox)
        }
    }, [hasAdminRoles, ff.status])

    if (loadingStatus) return null;

    return (
        <Grid container justify="center" style={{padding: "1rem"}}>
            {!hasAdminRoles && <Grid item>
                {/*{<Grid item>*/}
                <FormControlLabel
                    control={
                        <ReCAPTCHA
                            theme="dark"
                            size='normal'
                            // hl="hu"  //no need to set ... Auto-detects the user's language if unspecified.
                            ref={recaptchaReference}
                            sitekey={data.value}
                            onChange={handleChange}
                        />
                    }
                    label={""}
                />
            </Grid>}
        </Grid>
    )
}

export default ValidatedReCAPTCHA;