import React from "react";
import {AccountRecord, AccountSchema} from "../../../Data/Account";
import {addData, createRows, getAll, getOne, removeData, updateData} from "../util";

import {accountApiTest} from "../../../Api/accounts";

const record = AccountRecord(AccountSchema.default()).withMutations(data => {
    return data.set("principalName", "site_member_01")
        .set("credentials", "credentials")
        .set("verification", "credentials")
        .set("emailAddress", "site.member.01@simplexion.hu")
        .set("fullName", "Site Member 01")
        .set("roles", ["SITE_MEMBER"])
        .set("displayName", "Site Member 01")
})

const calls = [
    onResponse => getAll("/accounts", onResponse),
    onResponse => addData("/accounts", record, onResponse),
    onResponse => getOne("/accounts/1", onResponse),
    onResponse => updateData("/accounts/1", record, onResponse),
    onResponse => removeData('/accounts/2', onResponse),
    onResponse => updateData("/accounts/1/credentials",
        {
            oldPassword: "oldPassword",
            newPassword: "newPassword",
            verification: "newPassword"
        },
        onResponse),
    onResponse => getAll("/accounts/1/positions", onResponse),
    onResponse => updateData("/accounts/1/reset", record, onResponse),
    onResponse => getOne('/accounts/1/activation-keys/b5ecae2bf95ea720', onResponse),
    onResponse => updateData("/accounts/reset",
        {
            principalName: "site_member_01",
            emailAddress: "site.member.01@simplexion.hu"
        },
        onResponse),
];

const AccountsApis = () => {

    return (
        <React.Fragment>
            {createRows(calls, accountApiTest)}
        </React.Fragment>
    );
}

export default AccountsApis;