import React from "react";
import {useRoutes} from 'hookrouter';
import List from "./List";
import {addGrant, removeGrant, useGrant} from "../../../Api/roles";
import Crud from "../../../../Crud";
import {GrantRecord, GrantSchema} from "../../../Data/Grant";
import Form from "./Form";

export default (props) => useRoutes(subRoutes)(props);


const subRoutes = {
    "/": () => (props) => <Crud config={crudConfig(props)} view="list" {...props}/>,
    "/add": () => (props) => <Crud config={crudConfig(props)} view="add" {...props}/>,
    "/:id/remove": ({id}) => (props) => <Crud config={crudConfig(props)} view="remove" id={id} {...props}/>
};

const crudConfig = props => ({

    compName: 'grants',
    namespace: 'grants',
    Record: GrantRecord,
    Schema: GrantSchema,
    api: {
        use: useGrant,
        add: addGrant,
        remove: removeGrant
    },
    url: props.path[0].url + "/" + props.parentId + '/grants/role-name=' + props.record.name.toUpperCase() + "/",
    labelField: 'roleName',
    ListComponent: List,
    FormComponent: Form,
    setOptRecField: "roleId",
    parentId: props.parentId,
});

