import React from "react";
import {DecisionRecord, DecisionSchema} from "../../../Data/Decision";
import {addData, createRows, getAll, getOne, removeData, updateData} from "../util";

import {decisionApiTest} from "../../../Api/decisions";

const record = DecisionRecord(DecisionSchema.default()).withMutations(data => {
    return data.set("meetingId", 1)
        .set("numberOfDecision", 1)
        .set("title", "Határozat 01")
        .set("decisionText", "Szöveg 01")
        .set("presenterName", "Site Member 1")
        .set("type", "OPEN")
        .set("attachments", "1")
})

const calls = [
    onResponse => getAll("/decisions", onResponse),
    onResponse => addData("/decisions", record, onResponse),
    onResponse => getOne("/decisions/1", onResponse),
    onResponse => updateData("/decisions/1", record, onResponse),
    onResponse => removeData('/decisions/3', onResponse),
    onResponse => getAll("/decisions/1/attachments", onResponse),
    onResponse => getAll("/decisions/previous-decisions/2020", onResponse),
];

const DecisionsApis = () => {

    return (
        <React.Fragment>
            {createRows(calls, decisionApiTest)}
        </React.Fragment>
    );
}

export default DecisionsApis;