import React, {useEffect, useState} from "react";
import Grid from "@material-ui/core/Grid";
import {makeStyles, Typography} from "@material-ui/core";
import ContentBox from "../../../../Components/ContentBox/ContentBox";
import {useTranslation} from "react-i18next";
import {translatableField} from "../../../../Lib/simplexion-table/templates/translatable-field";
import {SimplexionTable} from "../../../../Lib/simplexion-table/SimplexionTable";
import {baseTableStyles} from "../../../../Lib/simplexion-table/styles/baseStyle";
import {customActionsStyle} from "../../../../Lib/simplexion-table/templates/action-buttons";
import {linkAction} from "../../../../Lib/simplexion-table/templates/link-button";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import customInputStyle from "../../../../Lib/material-dashboard/assets/jss/components/customInputStyle";
import {useDecisionsByYear} from "../../../../Api/decisions";
import {cityCouncilPageStyle} from "../../cityCouncilPageStyle";
import Section from "../../../../Lib/form/Components/Section/Section";

const styles = () => {
    let classes = baseTableStyles;
    Object.entries(customActionsStyle).forEach(entry => {
        classes[entry[0]] = entry[1];
    });
    return classes;
};

const useStyles = makeStyles(cityCouncilPageStyle);
const useTableStyles = makeStyles(styles);
const useFormStyles = makeStyles(customInputStyle);

const Decisions = ({selectedYear}) => {

    const classes = useTableStyles();

    const {data} = useDecisionsByYear(selectedYear);

    const tableConfig = {
        id: 'decisions',
        nameSpace: 'cityCouncilPage',
        sortable: true,
        styles: classes,
        columns: [
            {
                accessor: 'numberOfDecision',
            },
            {
                accessor: 'title',
            },
            {
                accessor: 'presenterName',
            },
            {
                accessor: 'type',
                renderer: (...args) => translatableField(...args),
            },
            {
                accessor: '',
                renderer: (...args) => linkAction(...args, 'details', `/city-council/decisions/`)
            }
        ],
    };
    return <SimplexionTable tableConfig={tableConfig} data={data}/>;
};

const YearSelect = ({selectedYear, handleSelect}) => {

    const classes = useFormStyles();
    const {t} = useTranslation('cityCouncilPage');

    const [years, setYears] = useState([]);

    useEffect(() => {
        const arr = getYears();
        setYears(arr);
        handleSelect(arr[0]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Grid item xs={12} lg={2}>
            {selectedYear &&
            <FormControl fullWidth
                         className={classes.selectFormControl}>
                <Select MenuProps={{className: classes.selectMenu}}
                        classes={{select: classes.select}}
                        value={selectedYear}
                        onChange={(e) => handleSelect(e.target.value)}
                        inputProps={{
                            name: t('years'),
                            id: 'year',
                            value: selectedYear,
                        }}>
                    {years.map(item =>
                        <MenuItem classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected
                        }}
                                  key={item}
                                  value={item}>
                            {`${item}`}
                        </MenuItem>
                    )}
                </Select>
            </FormControl>
            }
        </Grid>
    );
};

const DecisionList = ({pageId, path}) => {

    const classes = useStyles();
    const {t} = useTranslation('cityCouncilPage');

    const [selectedYear, setSelectedYear] = useState(null);

    const handleSelect = val => {
        setSelectedYear(val);
    };

    return (
        <ContentBox path={path}>
            <Section>
                <Grid container className={classes.root} component='div'>
                    <Grid item xs={12} className={classes.header}>
                        <Typography variant='h4' className={classes.title}>
                            {t('decisions')}
                        </Typography>
                    </Grid>
                    <Grid container className={classes.selectContainer}>
                        <YearSelect selectedYear={selectedYear} handleSelect={handleSelect}/>
                    </Grid>
                    {selectedYear &&
                    <Decisions selectedYear={selectedYear} pageId={pageId}/>
                    }
                </Grid>
            </Section>
        </ContentBox>
    );
};

const getYears = () => {
    const date = new Date();
    const currentYear = date.getFullYear();
    let years = [];
    for (let i = currentYear; i >= 2003; i--) {
        years.push(i);
    }
    return years;
};

export default DecisionList;