import React from "react";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import styles from "../../../material-dashboard/assets/jss/customSelectStyle.jsx";
import MenuItem from "@material-ui/core/MenuItem";
import {makeStyles} from "@material-ui/styles";
import {useFormField} from "../../useFormField";

const useStyles = makeStyles(styles);

function ValidatedSelectForEnum(props) {

    const {name, enumArray} = props;

    const classes = useStyles();

    const ff = useFormField(props, classes);

    return (
        <FormControl
            fullWidth
            className={classes.selectFormControl}>
            <InputLabel
                htmlFor={ff.id}
                className={ff.labelClasses}>
                {ff.labelText}
            </InputLabel>
            <Select
                MenuProps={{
                    className: classes.selectMenu
                }}
                classes={{
                    select: classes.select
                }}
                disabled={ff.disabled}
                value={ff.value}
                onChange={(e) => ff.onChange(e.target.value)}
                inputProps={{
                    name: name,
                    id: ff.value,
                    value: ff.value
                }}>
                {enumArray.map(item =>
                    <MenuItem
                        classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected
                        }}
                        key={item}
                        value={item}>
                        {ff.t(item)}
                    </MenuItem>
                )}
            </Select>
        </FormControl>
    );
}

export default ValidatedSelectForEnum;