import {useRoutes} from "hookrouter";
import React from "react";
import debug from "../../Lib/debug";
import {useTranslation} from "react-i18next";
import {
    addMeetingElement,
    removeMeetingElement,
    updateMeetingElement,
    useMeetingItem
} from "../../Api/meeting-items";
import {MeetingItemRecord, MeetingItemSchema} from "../../Data/MeetingItem";
import List from "./List";
import Form from "./Form";
import Documents from "../Documents/Documents";

export default () => useRoutes(subRoutes);

const subRoutes = {
    "/": () => <MeetingElements view="list"/>,
    "/:meetingId": ({meetingId}) => <MeetingElements view="list"
                                                     meetingId={meetingId}/>,
    "/:meetingId/element/add": ({meetingId}) => <MeetingElements view="add"
                                                                 meetingId={meetingId}/>,
    "/:meetingId/element/:elementId/edit": ({meetingId, elementId}) => <MeetingElements view="edit"
                                                                                        meetingId={meetingId}
                                                                                        elementId={elementId}/>,
    "/:meetingId/element/:elementId/remove": ({meetingId, elementId}) => <MeetingElements view="remove"
                                                                                          elementId={elementId}
                                                                               meetingId={meetingId}/>,
    "/:meetingId/element/:elementId/documents*": ({meetingId, elementId}) => <MeetingElements view='documents'
                                                                                              meetingId={meetingId}
                                                                                              elementId={elementId}/>,
};

const MeetingElements = ({view, meetingId, elementId}) => {
    debug("RENDER", "MeetingElements", () => [view]);

    const {t} = useTranslation('meetingElements');

    const {loadingStatus, data} = useMeetingItem(elementId);

    const viewData = (loadingStatus ? new MeetingItemRecord(MeetingItemSchema.default()) : data);

    const path = [{label: t('title'), url: `/meeting-elements/${meetingId ? meetingId : ''}`}];

    // noinspection JSCheckFunctionSignatures
    const label = viewData.get('topic') || t('new');

    switch (view) {
        case "list" :

            return <List meetingId={meetingId} path={path}/>;

        case "add" :

            path.push({label: label, url: "/meeting-elements/" + meetingId + "/element/add"});
            return <Form meetingId={meetingId}
                         record={viewData.set('meetings', [parseInt(meetingId, 10)])}
                         path={path}
                         view={view}
                         api={addMeetingElement}/>;

        case "remove" :

            path.push({label: label, url: '/meeting-elements/' + meetingId + "/element/" + elementId + "/remove"});
            return <Form elementId={elementId} meetingId={meetingId} record={viewData} path={path} view={view} api={removeMeetingElement}/>;

        case "edit" :

            path.push({label: label, url: '/meeting-elements/' + meetingId + "/element/" + elementId + "/edit"});
            return <Form elementId={elementId} meetingId={meetingId} record={viewData} path={path} view={view}
                         api={updateMeetingElement}/>;

        case "documents" :

            path.push({label: label, url: '/meeting-elements/' + meetingId + "/element/" + elementId + "/edit"});
            return <Documents path={path} meetingId={meetingId} elementId={elementId}/>;

        default :
            return <div>invalid view: {view}</div>
    }
};