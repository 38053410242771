import React from "react";
import ContentBox from "../../../Components/ContentBox/ContentBox";
import {useCityCouncilPageByType} from "../../../Lib/ContentManagement/ContentEditor/Api/cityCouncilPages";
import {makeStyles} from "@material-ui/styles";

import {A} from "hookrouter";
import {useTranslation} from "react-i18next";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Section from "../../../Lib/form/Components/Section/Section";
import {cityCouncilPageStyle} from "../cityCouncilPageStyle";

const useStyles = makeStyles(cityCouncilPageStyle);

const MainPage = () => {

    const classes = useStyles();

    const {t} = useTranslation('cityCouncilPage');

    const {data, loadingStatus} = useCityCouncilPageByType('CITY_COUNCIL_NON_PUBLIC');

    if (loadingStatus) return null;

    const path = [{label: t('titleNav'), url: '/city-council'}];

    return (
        <ContentBox path={path}>
            <Section>
                <Grid container className={classes.header} component='div'>
                    <Grid item xs={12} className={classes.header}>
                        <Box className={classes.description + " ql-display"} dangerouslySetInnerHTML={{__html: data.text}}/>
                    </Grid>
                    <Grid item xs={12} className={classes.councilLinkBox}>
                        <A href={`/city-council/meetings`} className={classes.councilLink}>
                            {t('meetings:meetings')}
                        </A>
                    </Grid>
                    <Grid item xs={12} className={classes.councilLinkBox}>
                        <A href={`/city-council/decisions`} className={classes.councilLink}>
                            {t('decisions:decisions')}
                        </A>
                    </Grid>
                </Grid>
            </Section>
        </ContentBox>
    )
};

export default MainPage;