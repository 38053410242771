import React from "react";
import {useRoutes} from "hookrouter";
import MenuBuilder from "./MenuBuilder/Views/MenuBuilder"
import MenuItemEditor from "./ContentEditor/Components/MenuItemEditor/MenuItemEditor";
import ContentEditor from "./ContentEditor/Views/ContentEditor";
import ListItemEditor from "./ContentEditor/Components/ListItemEditor/ListItemEditor";
import BlockItemEditor from "./ContentEditor/Components/BlockPageEditor/BlockItemEditor";

const subRoutes = {
    "/": () => props => <MenuBuilder view='tree' {...props}/>,
    "/:newNavPosition/add": ({newNavPosition}) => props => {
        const arr = newNavPosition.split('-');
        const parent = arr[0];
        const itemBefore = arr[1] ? arr[1] : 0;
        return <MenuItemEditor view='add' parent={parent} itemBefore={itemBefore}  {...props}/>
    },
    "/:contentId/edit": ({contentId}) => props => <ContentEditor contentId={contentId}  {...props}/>,
    "/:contentId/block-pages*": ({contentId}) => props => <BlockItemEditor contentId={contentId}  {...props}/>,
    "/:contentId/file-upload-pages*": ({contentId}) => props => <ListItemEditor contentId={contentId} {...props}/>,
};

export default props => useRoutes(subRoutes)(props);