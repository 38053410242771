export const types = {
    'CHANGE_LANGUAGE': 'CHANGE_LANGUAGE',
    'CHANGE_THEME': 'CHANGE_THEME',
    'ADD_ISSUE_COLLECTOR': 'ADD_ISSUE_COLLECTOR'
};

export const appActions = {

    changeLanguage: (lang, contents, roles) => ({
        type: 'CHANGE_LANGUAGE',
        lang: lang,
        contents: contents,
        roles: roles,
    }),
    changeTheme: () => ({
        type: 'CHANGE_THEME',
    })

};