import ContentBox from "../../Components/ContentBox/ContentBox";
import React from "react";
import {
    useNotificationPage,
    useNotificationTypes
} from "../../Lib/ContentManagement/ContentEditor/Api/notificationPages";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core";
import {kolorAppStyle} from "./kolorAppStyle";
import Box from "@material-ui/core/Box";
import useForm from "../../Lib/form/useForm";
import {ReportRecord, ReportSchema} from "../../Data/Report";
import ValidatedInput from "../../Lib/form/Components/ValidatedInput/ValidatedInput";
import ValidatedSelect from "../../Lib/form/Components/ValidatedSelect/ValidatedSelect";
import {useTranslation} from "react-i18next";
import ValidatedSubmit from "../../Lib/form/Components/ValidatedSubmit/ValidatedSubmit";
import AttachmentUpload from "../../Components/AttachmentUpload/AttachmentUpload";
import useNav from "../../Lib/useNav";
import {addReport} from "../../Api/reports";
import {useLanguageRevision} from "../../Lib/useLanguageRevision";


const useStyles = makeStyles(kolorAppStyle);

const KolorApp = ({path}) => {

    useNav({path: window.location.pathname});

    const classes = useStyles();
    const {t} = useTranslation('kolorApp');

    const revision = useLanguageRevision();

    const {data, loadingStatus} = useNotificationPage(1, revision);

    const form = useForm({
        recordType: ReportRecord,
        recordSchema: ReportSchema,
        namespace: 'kolorApp',
        record: new ReportRecord(ReportSchema.default()),
    });

    const api = addReport;

    if (loadingStatus) return null;

    return (
        <ContentBox publicPage={true} path={path} form={form}>
            <Grid container>
                <Grid item container
                      xs={12}
                      component='div'
                      className={classes.header}>
                    <Grid item xs={12} sm={9} component='div'>
                        <Typography variant='h4' className={classes.title}>
                            {data.title}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item container>
                    <Grid item xs={12} md={9}>
                        <Box className={classes.content + " ql-display"} dangerouslySetInnerHTML={{__html: data.textContent}}/>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container justify='center'>
                <Grid item xs={12} md={8}>
                    <ValidatedInput name="textContent"
                                    multiline={true}
                                    rows={5}/>
                    <ValidatedSelect name="type"
                                     label={t('type')}
                                     api={useNotificationTypes}
                    />
                    <ValidatedInput name="name"/>
                    <ValidatedInput name="emailAddress"/>
                    <ValidatedInput name="location"/>
                    <AttachmentUpload name='pictures' ns='contentManagement' apiPath='/images' multiple={true}
                                      maxUploads={3}/>
                    <ValidatedSubmit view={'add'}
                                     api={api}
                                     hideCancel={true}
                    />
                </Grid>
            </Grid>
        </ContentBox>
    );
};

export default KolorApp;