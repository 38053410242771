import React from "react";
import {useTranslation} from "react-i18next";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from '@material-ui/icons/Close';
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import IconButton from '@material-ui/core/IconButton';
import {useSnackbar} from "notistack";
import {throwsExceptionMessage} from "../../Api/catchException";
import {closeSnack} from "../../Main/util";
import {addWatcher} from "../../Api/watchers";
import useForm from "../../Lib/form/useForm";
import {WatcherRecord, WatcherSchema, WatcherType} from "../../Data/Watcher";
import debug from "../../Lib/debug";
import ValidatedInput from "../../Lib/form/Components/ValidatedInput/ValidatedInput";
import ValidatedRadioGroup from "../../Lib/form/Components/ValidatedRadio/ValidatedRadioGroup";
import ValidatedRadio from "../../Lib/form/Components/ValidatedRadio/ValidatedRadio";
import ValidatedCheckbox from "../../Lib/form/Components/ValidatedCheckbox/ValidatedCheckbox";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "../../Lib/material-dashboard/components/CustomButtons/Button";
import FormProvider from "../../Lib/form/Components/FormProvider/FormProvider";
import ValidatedSubmit from "../../Lib/form/Components/ValidatedSubmit/ValidatedSubmit";
import {addListOfWatchersForItem} from "../../Api/list-of-watchers-for-items";
import {ListOfWatchersForItemRecord} from "../../Data/ListOfWatchersForItem";
import ValidatedReCAPTCHA from "../../Lib/form/Components/ValidatedReCAPTCHA/ValidatedReCAPTCHA";
import {useDataPolicy} from "../../Lib/ContentManagement/ContentEditor/Api/pages";

const Watcher = ({hasAdminRoles, itemId, closeModal, open}) => {
    const {t} = useTranslation("watchers");
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();

    const form = useForm({
        recordType: WatcherRecord,
        recordSchema: WatcherSchema,
        namespace: 'watchers',
        record: new WatcherRecord

    });

    const policy = useDataPolicy().data

    debug("RENDER", "useForm", () => [" -> Form_Of_Watcher", "add", form]);

    const processing = (record) => {

        const onSuccess = () => {
            closeModal()
        }

        const success = (data) => {
            addListOfWatchersForItem(new ListOfWatchersForItemRecord({
                id: 0,
                item: itemId,
                watcher: data.id
            }), onSuccess, error)
        };

        const error = (error) => {
            enqueueSnackbar(t('catchException:' + throwsExceptionMessage(error)), {
                variant: 'error',
                autoHideDuration: 5000,
                action: (key) => closeSnack(t, closeSnackbar, key)
            });
        };


        addWatcher(record, success, error);
    };

    return (

        <Dialog variant="outlined" color="primary" open={open}>
            <FormProvider form={form}>
                <MuiDialogTitle disableTypography>
                    <Typography variant="h5"
                                style={{textAlign: "center", fontWeight: 700}}>{t("applicationForm")}</Typography>
                    <IconButton aria-label="close" style={{position: "absolute", right: '1rem', top: '0.5rem'}}
                                onClick={() => closeModal()}>
                        <CloseIcon/>
                    </IconButton>
                </MuiDialogTitle>
                <DialogContent>
                    <ValidatedInput name="name"/>
                    <ValidatedInput name="phoneNumber"/>
                    <ValidatedInput name="emailAddress"/>
                    {hasAdminRoles ?
                        <ValidatedRadioGroup name="type">
                            <Grid container>
                                {WatcherType.map(a => <ValidatedRadio name="type" value={a} key={a}/>)}
                            </Grid>
                        </ValidatedRadioGroup>
                        : null}
                    <ValidatedCheckbox name="termsAccepted" customLabel={<span>{t("termsAccepted")}<a
                        href={policy === 0 ? '/#' : `/api/1.0.0/attachments/${policy}`} target='_blank'
                        rel='noopener noreferrer'>{t("policy")}</a></span>}/>

                    <ValidatedReCAPTCHA name="recaptchaInstance" hasAdminRoles={hasAdminRoles}/>

                    <Grid container justify={"space-around"} style={{padding: "1.5rem"}}>
                        <Grid item>
                            <ValidatedSubmit view={"add"} api={processing} onSuccess={() => closeModal()}
                                             hideCancel={true}/>
                        </Grid>
                        <Grid item>
                            <Button color={"primary"}
                                    onClick={() => closeModal()}>
                                {t('close')}
                            </Button>
                        </Grid>
                    </Grid>

                </DialogContent>
            </FormProvider>
        </Dialog>
    );
}

export default Watcher;
