import React, {useState} from "react";
import useForm from "../../../../form/useForm";
import FormProvider from "../../../../form/Components/FormProvider/FormProvider";
import Section from "../../../../form/Components/Section/Section";
import {useTranslation} from "react-i18next";
import ValidatedInput from "../../../../form/Components/ValidatedInput/ValidatedInput";
import ValidatedSubmit from "../../../../form/Components/ValidatedSubmit/ValidatedSubmit";
import {TextPageRecord, TextPageSchema} from "../../Data/TextPage";
import ValidatedRichTextEditor from "../../../../form/Components/ValidatedRichTextEditor/ValidatedRichTextEditor";
import Grid from "@material-ui/core/Grid";
import {textPageEditorStyle} from "./textPageEditorStyle";
import {makeStyles} from "@material-ui/core";
import {getAttachments, updateTextPage, useTextPage} from "../../Api/textPages";
import StatusRadios from "../StatusRadios";
import ImageUpload from "../../../../../Components/ImageUpload/ImageUpload";
import AttachmentUpload from "../../../../../Components/AttachmentUpload/AttachmentUpload";
import Tags from "../Tags/Tags";
import {addBlockItem, removeBlockItem} from "../../Api/blockPages";
import OpenDialogButton from "../../../../../Components/RemoveItemDialog/OpenDialogButton";
import RemoveItemDialog from "../../../../../Components/RemoveItemDialog/RemoveItemDialog";
import {navigate} from "hookrouter";
import ContentBox from "../../../../../Components/ContentBox/ContentBox";

const useStyles = makeStyles(textPageEditorStyle());

const TextPageForm = ({view, contentId, record, blockPageId, handleSubmit, handleCancel}) => {
    const classes = useStyles();

    const {t} = useTranslation("contentManagement");

    const [open, setOpen] = useState(false);

    const handleClose = () => {
        setOpen(false)
    };

    const handleOpen = () => {
        setOpen(true)
    };

    const form = useForm({
        disabledForm: view === 'remove',
        recordType: TextPageRecord,
        recordSchema: TextPageSchema,
        namespace: 'contentManagement',
        record: record,
    });

    const api = view === 'add' ? (...args) => addBlockItem(blockPageId, ...args) : updateTextPage;

    const removeApi = () => {
        removeBlockItem(blockPageId, record.id, () => {
            handleClose();
            navigate(`/content-management/${contentId}/edit`)
        })
    };

    const onSuccess = handleSubmit ? data => handleSubmit(data) : null;
    const onCancel = handleCancel ?
        () => handleCancel() :
        () => navigate(`/content-management`);

    return (
        <React.Fragment>
            <FormProvider form={form}>
                <Section title={`${t('subpage')} ${t(view)}`}>
                    <Grid container>
                        <Grid item xs={12}>
                            <ValidatedInput name='title'
                                            label={t('title', {context: 'subpage'})}
                            />
                        </Grid>
                        <Grid item xs={12} md={4} className={classes.editorBlock}>
                            <ImageUpload picture={record.picture}/>
                            <ValidatedInput name='altText'
                                            label={t('altText')}
                            />
                            <AttachmentUpload id={record.id}
                                              ns='contentManagement'
                                              name='attachments'
                                              attachments={record.attachments}
                                              multiple={true}
                                              api={getAttachments}
                                              apiPath={'/attachments'}/>
                        </Grid>
                        <Grid item xs={12} md={8} className={classes.editorBlock}>
                            <Grid item className={classes.descriptionContainer}>
                                <ValidatedRichTextEditor name='description'
                                                         showLabel={true}
                                />
                            </Grid>
                            <Grid item className={classes.textContentContainer}>
                                <ValidatedRichTextEditor name='textContent'
                                                         showLabel={true}
                                />
                            </Grid>
                            <Grid container item xs={12}>
                                <Tags/>
                            </Grid>
                            <StatusRadios/>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} container>
                        <Grid item xs={8}>
                            <ValidatedSubmit view={view}
                                             api={api}
                                             onSuccess={onSuccess}
                                             onCancel={onCancel}
                                             hideCancel={!Boolean(blockPageId)}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            {blockPageId && view === 'edit' &&
                            <OpenDialogButton openModal={handleOpen}
                                              text={t('remove')}
                            />
                            }
                        </Grid>
                    </Grid>
                    {blockPageId && view === 'edit' &&
                    <RemoveItemDialog closeModal={handleClose}
                                      title={record.title}
                                      api={removeApi}
                                      ns={'contentManagement'}
                                      open={open}
                    />
                    }
                </Section>
            </FormProvider>
        </React.Fragment>
    );

};

const TextPageEditor = (props) => {

    const {t} = useTranslation('contentManagement');

    const {path, view, textPageId, blockPageId, contentId} = props;

    const {data, loadingStatus} = useTextPage(textPageId);

    if(loadingStatus) return null;

    if (path && view === 'edit') {
        path.push({label: data.title, url: `/content-management/${contentId}/block-pages/${blockPageId}/blocks/${textPageId}/edit`})
    } else if (path && view === 'add') {
        path.push({label: t('add'), url: ``})
    }

    return (
        <React.Fragment>
            {blockPageId ?
                <ContentBox path={path}>
                    <TextPageForm {...props} record={data}/>
                </ContentBox> :
                <TextPageForm {...props} record={data}/>
            }
        </React.Fragment>
    );
};

export default TextPageEditor;