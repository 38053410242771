export const committeeHu = {
    "title": "Bizottságok",
    "titleNav": "Törzsadatok - Bizottságok",
    "committee": "Bizottság",
    "add": "Új bizottság",
    "edit": "Bizottság szerkesztése",
    "back": "Vissza",
    "remove": "Bizottság törlése",
    "delete": "Bizottság törlése",
    "name": "Név",
    "name-invalid": "A bizottság neve érvénytelen",
    "save": "Bizottság mentése",
    "actions": "Műveletek",
};

export const committeeEn = {
    "title": "Committees",
    "titleNav": "Master data - Committees",
    "committee": "Committee",
    "add": "Add Committee",
    "edit": "Edit Committee",
    "remove": "Delete Committee",
    "name": "Name",
    "name-invalid": "The name of the Committee is invalid",
    "save": "Save Committee",
    "actions": "Actions"
};

export const committeeDe = {
    "title": "Komitee",
    "titleNav": "Stammdaten - Komitee",
    "committee": "Komitee",
    "add": "Komitee hinzufügen",
    "edit": "Komitee bearbeiten",
    "remove": "Komitee löschen",
    "name": "Name",
    "name-invalid": "Der Name des Komitees ist ungültig",
    "save": "Komitee speichern",
    "actions": "Aktionen"
};