import {Record} from 'immutable';
import {number, object, string} from "yup";

export const KolorLineNewsRecord = Record({
    id: 0,
    title: '',
    lead_long: '',
    content: '',
    author: '',
    category: '',
    category_color: '',
    image: '',
    image_sm: '',
    posted_at: '',
    updated_at: '',
});

export const KolorLineNewsSchema = object().shape({
    id: number().min(0).default(0),
    title: string().default(''),
    lead_long: string().default(''),
    content: string().default(''),
    author: string().default(''),
    category: string().default(''),
    category_color: string().default(''),
    image: string().default(''),
    image_sm: string().default(''),
    posted_at: string().default(''),
    updated_at: string().default(''),
});
