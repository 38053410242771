import AppBar from "@material-ui/core/AppBar/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import React, {memo, useContext, useRef, useState} from "react";
import {makeStyles} from "@material-ui/styles";
import {style} from "./appNavBarStyle";
import Grid from "@material-ui/core/Grid";
import {AppContext} from "../../Main/AppContext";
import cx from "classnames";
import {A} from "hookrouter";
import Typography from "@material-ui/core/Typography";
import {Box} from "@material-ui/core";
import Popper from "@material-ui/core/Popper";
import Icon from "@material-ui/core/Icon";
import ArrowRight from "@material-ui/icons/ArrowRight";
import Avatar from "@material-ui/core/Avatar";
import Hidden from "@material-ui/core/Hidden";
import MobileNav from "./MobileNav";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu"
import LanguageSelector from "../LanguageSelector/LanguageSelector";
import AccountCircleRoundedIcon from '@material-ui/icons/AccountCircleRounded';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import {AuthContext} from "../../Lib/auth/AuthContext";
import {isLoggedIn} from "../../Lib/auth/auth";
import ChangeThemeBtn from "../ThemeSelector/ThemeSelector";
import SearchIcon from '@material-ui/icons/Search';


const useStyles = makeStyles(style);

export function NavRow({isSmallScreen}) {

    const appContext = useContext(AppContext);

    const navTree = appContext.getIn(['appStore', 'navTree']);
    let topNavs = [];

    const homeViews = navTree[0].views;

    if (homeViews) {
        for (let i = 0; i < homeViews.length; i++) {
            let item = homeViews[i];

            topNavs.push(<NavItem key={`${item.title}-${i}`} item={item} isTopLevel={true}
                                  isSmallScreen={isSmallScreen}/>)
        }
    }

    return (
        <Grid container item component='div'>
            <Grid item container xs={isSmallScreen ? 12 : 5}>
                {topNavs[0]}
                {topNavs[1]}
            </Grid>
            {!isSmallScreen &&
            <Grid item container xs={2}/>
            }
            <Grid item container xs={isSmallScreen ? 12 : 5}>
                {topNavs[2]}
                {topNavs[3]}
            </Grid>
        </Grid>
    )
}

function NavGroup(props) {
    const {views, anchorEl, name, isTopLevel} = props;
    const classes = useStyles();

    let subNavs = views.map((item, index) => {
        if (item.title.indexOf("Kolorapp") !== -1) {
            return null
        } else {
            return <NavItem key={`${item.title}-${index}`} item={item} name={name}/>;
        }
    });

    const width = anchorEl ? anchorEl.getBoundingClientRect().width : 0;

    return (
        <Popper id={name + 'PopUp'}
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                transition
                placement={isTopLevel ? 'bottom-start' : 'right-start'}
                className={classes.navDropdownGroup}
                disablePortal={true}
                style={{minWidth: width}}
        >

            <Box>
                {subNavs}
            </Box>
        </Popper>
    )
}

function NavItem(props) {

    const {item, isTopLevel, isSmallScreen} = props;

    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const ref = useRef(null);

    const appContext = useContext(AppContext);
    const isHighContrast = appContext.getIn(['appStore', 'highContrastTheme']);

    const name = item.title;
    const views = item.views;
    const collapse = item.collapse;

    let navItemStyle;
    let navItemInnerContainerStyle = null;
    let onMdScreen = isTopLevel ? 6 : 12;
    let onMouseEnter = collapse ? (event) => setAnchorEl(event.currentTarget) : null;
    let onMouseLeave = collapse ? () => setAnchorEl(null) : null;

    if (isTopLevel) {
        navItemStyle = classes.navItem;
        navItemInnerContainerStyle = classes.navItemInnerContainer
    } else {
        navItemStyle = classes.navDropdownItem;
    }

    return (
        <Grid item xs={12} md={onMdScreen} component='div'>
            <Box ref={ref}
                 aria-haspopup="true"
                 aria-owns={Boolean(anchorEl) ? name + 'PopUp' : undefined}
                 onMouseEnter={onMouseEnter}
                 onMouseLeave={onMouseLeave}
                 className={navItemInnerContainerStyle}>
                <Box className={navItemStyle} style={{backgroundColor: isHighContrast ? '#FFFF00' : item.lineColor}}>
                    <NavLink isTopLevel={isTopLevel}
                             isHighContrast={isHighContrast}
                             item={item}/>
                </Box>
                {(ref && collapse && !isSmallScreen) &&
                <NavGroup views={views}
                          anchorEl={anchorEl}
                          name={name}
                          isTopLevel={isTopLevel}/>
                }
            </Box>
        </Grid>
    )
}

function NavLink({item, isTopLevel, isHighContrast}) {

    const classes = useStyles();

    const path = item.path;
    const title = item.title;
    const collapse = item.collapse;
    const typographyVariant = isTopLevel ? 'h6' : 'subtitle1';

    const navLinkStyle = isTopLevel ?
        cx(classes.navLink, classes.navLinkMain) :
        cx(classes.navLink, classes.navLinkSub);
    const navLinkTextStyle = isTopLevel ?
        classes.navLinkTextMain :
        classes.navLinkText;

    return (
        <A href={path}
           className={navLinkStyle}
           style={{color: isHighContrast ? '#FFFF00' : item.fontColor}}>
            <Typography variant={typographyVariant}
                        className={navLinkTextStyle}
                        component={'p'}>
                {title}
            </Typography>
            {!isTopLevel && collapse &&
            <Icon className={classes.navLinkIcon}>
                <ArrowRight/>
            </Icon>}
        </A>
    )
}

function Crest() {

    const classes = useStyles();
    const crest = useContext(AppContext).getIn(['headerStore', 'crest']);

    return (
        <Grid item container xs={2} justify='center' component='div'>
            <A href='/' className={classes.crestLink}>
                <Avatar alt='Kazincbarcika'
                        src={crest}
                        className={classes.crest}
                        imgProps={{className: classes.crestImage}}
                        component='div'/>
            </A>
        </Grid>
    )
}

function NavRowSecondary({toggleDrawer}) {

    const classes = useStyles();

    const authContext = useContext(AuthContext);
    const loggedIn = isLoggedIn(authContext);

    return (
        <Grid item container justify='flex-end'>
            <Grid item container justify='flex-start' alignContent='center' xs={5}>
                <Hidden mdUp>
                    <IconButton onClick={toggleDrawer} className={classes.menuIcon}>
                        <MenuIcon/>
                    </IconButton>
                </Hidden>
            </Grid>
            <Crest/>
            <Hidden smDown>
                <LanguageSelector/>
            </Hidden>
            <Hidden smDown>
                <Grid item xs={1} container justify='flex-end' alignContent='center'>
                    <A href='/search'>
                        <SearchIcon color='primary'/>
                    </A>
                </Grid>
            </Hidden>
            <Hidden mdUp>
                <Grid item xs={4}/>
            </Hidden>
            <Grid item container xs={1} justify='flex-end' alignContent='center'>
                <ChangeThemeBtn/>
            </Grid>
            <Grid item container xs={1} justify='flex-end' alignContent='center'>
                {loggedIn ?
                    <A href='/admin' className={classes.loginIcon}><ExitToAppIcon/></A> :
                    <A href='/login' className={classes.loginIcon}><AccountCircleRoundedIcon/></A>
                }
            </Grid>
        </Grid>
    )
}

const AppNavBar = memo(function AppNavBar() {

    const classes = useStyles();
    const [drawerOpen, setDrawerOpen] = useState(false);

    const toggleDrawer = () => setDrawerOpen(!drawerOpen);

    return (
        <AppBar className={classes.root}>
            <Toolbar className={classes.navToolbar}>
                <Grid container item xs={12}
                      justify='center'
                      alignContent='space-around'
                      component='div'
                      className={classes.navToolbarInnerContainer}>
                    <NavRowSecondary toggleDrawer={toggleDrawer}/>
                    <Hidden smDown>
                        <NavRow/>
                    </Hidden>
                </Grid>
            </Toolbar>
            <MobileNav isDrawerOpen={drawerOpen} setDrawerOpen={setDrawerOpen}/>
        </AppBar>
    );
});

export default AppNavBar