import baseFetch from "../Lib/baseFetch";
import {roles} from "../Main/roles";

export function changeLanguage(lang, onSuccess, onError) {
    baseFetch('POST', '/session/language/' + lang, undefined,
        () => {
            if (onSuccess) onSuccess();
        },
        onError, "callback");
}

export const languagesApiTest = {
    '/session/language/HU': {
        'POST': {
            [roles.ANONYMOUS]: {hasResponse: true},
            [roles.SITE_MEMBER]: {hasResponse: true},
            [roles.COUNCIL_ADMIN]: {hasResponse: true,},
            [roles.CM_ADMIN]: {hasResponse: true},
            [roles.UPLOAD_ADMIN]: {hasResponse: true},
            [roles.ADMIN]: {hasResponse: true,},
            [roles.SECURITY_OFFICER]: {hasResponse: true}
        },
    },
}