export const scrollToTopBtnStyle = theme => ({
    scrollToTopBtn: {
        position: 'fixed',
        zIndex: 100,
        bottom: 153,
        right: 37,
        color: theme.palette.common.white,
        [theme.breakpoints.down('md')]: {
            bottom: 100,
            right: 11,
        },
    }
});


