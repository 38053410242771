import React, {useEffect, useReducer} from "react";
import {DndProvider} from "react-dnd";
import {menuInitialState, menuReducer} from "../Data/Store/MenuStore"
import HTML5Backend from "react-dnd-html5-backend";
import {makeStyles} from "@material-ui/core";
import {menuBuilderStyle} from "../Components/styles/menuBuilderStyle";
import THead from "../Components/THead/THead";
import TBody from "../Components/TBody/TBody";
import {MenuBuilderContext} from "../Context/MenuBuilderContext";
import {actions} from "../Data/Store/Actions";
import {getContents} from "../Api/content";
import Section from "../../../form/Components/Section/Section";
import Grid from "@material-ui/core/Grid";
import ContentBox from "../../../../Components/ContentBox/ContentBox";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles(menuBuilderStyle);

const MenuBuilder = () => {

    const classes = useStyles();

    const [store, dispatch] = useReducer(menuReducer, menuInitialState);

    const {t} = useTranslation('contentManagement');

    useEffect(() => {
        getContents(undefined,
            data => dispatch(actions.initItems(data))
        );
    }, []);

    const path = [
        {label: t('titleNav', {context: 'content'}), url: '/content-management'}
    ];

    return (
        <ContentBox path={path}>
            <Section>
                <MenuBuilderContext.Provider value={{
                    store: store,
                    dispatch: dispatch
                }}>
                    <Grid container justify='center'>
                        <table className={classes.table}>
                            <THead/>
                            <DndProvider backend={HTML5Backend}>
                                <TBody/>
                            </DndProvider>
                        </table>
                    </Grid>
                </MenuBuilderContext.Provider>
            </Section>
        </ContentBox>
    )
};

export default MenuBuilder;
