import React from "react";
import {createRows, getAll, getOne, updateData} from "../util";
import {CityCouncilPageRecord, CityCouncilPageSchema} from "../../ContentManagement/ContentEditor/Data/CityCouncilPage";

import {cityCouncilPagesApiTest} from "../../ContentManagement/ContentEditor/Api/cityCouncilPages";

const record = CityCouncilPageRecord(CityCouncilPageSchema.default()).withMutations(data => {
    return data.set("text", "text")
        .set("type", "IMPRESSUM")
})

const calls = [
    onResponse => getAll("/city-council", onResponse),
    onResponse => getOne("/city-council/IMPRESSUM", onResponse),
    onResponse => updateData("/city-council/1", record, onResponse),
];

const CityCouncilPagesApis = () => {

    return (
        <React.Fragment>
            {createRows(calls, cityCouncilPagesApiTest)}
        </React.Fragment>
    );
}

export default CityCouncilPagesApis;