import React from "react";
import List from "./List";
import {useRoutes} from "hookrouter";
import {addDocument, removeDocument, updateDocument, useDocument} from "../../Api/documents";
import {useTranslation} from "react-i18next";
import debug from "../../Lib/debug";
import Form from "./Form";
import {DocumentRecord, DocumentSchema} from '../../Data/Document';

export default (props) => useRoutes(subRoutes)(props);

const subRoutes = {
    "/": () => (props) => <Documents view="list" {...props}/>,
    "/add": () => (props) => <Documents view="add" {...props}/>,
    "/:documentId/edit": ({documentId}) => (props) => <Documents view="edit" documentId={documentId} {...props}/>,
    "/:documentId/remove": ({documentId}) => (props) => <Documents view="remove" documentId={documentId} {...props}/>,
};

const Documents = ({view, path, documentId, meetingId, elementId}) => {

    debug("RENDER", "Documents", () => [view]);

    const {t} = useTranslation('documents');

    const {loadingStatus, data} = useDocument(elementId, documentId);

    const viewData = (loadingStatus ? new DocumentRecord(DocumentSchema.default()) : data);

    path.push({label: t('titleNav'), url: '/meeting-elements/' + meetingId + "/element/" + elementId + '/documents'});

    // noinspection JSCheckFunctionSignatures
    const label = viewData.get('title');

    switch (view) {
        case "list" :

            return <List meetingId={meetingId} elementId={elementId} path={path}/>;

        case "add" :

            path.push({label: label, url: "/documents/add"});
            return <Form documentId={documentId}
                         meetingId={meetingId}
                         elementId={elementId}
                         record={viewData.merge({
                             'meetingElementId': parseInt(elementId, 10),
                             // 'meeting': parseInt(meetingId, 10)
                         })}
                         path={path}
                         view={view}
                         api={addDocument}/>;

        case "remove" :

            path.push({label: label, url: "/documents/" + documentId + "/remove"});
            return <Form documentId={documentId}
                         meetingId={meetingId}
                         elementId={elementId}
                         record={viewData.merge({'meetingElementId': parseInt(elementId, 10)})}
                         path={path}
                         view={view}
                         api={removeDocument}/>;

        case "edit" :

            path.push({label: label, url: "/documents/" + documentId + "/edit"});
            return <Form documentId={documentId}
                         meetingId={meetingId}
                         elementId={elementId}
                         record={viewData.merge({'meetingElementId': parseInt(elementId, 10)})}
                         path={path}
                         view={view}
                         api={updateDocument}/>;

        default :
            return <div>invalid view: {view}</div>
    }
};

