import React from "react";
import {makeStyles} from "@material-ui/styles";
import {blockListItemStyle as style} from "./blockListItemStyle";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import {A} from 'hookrouter';
import {useTranslation} from "react-i18next";
import Box from "@material-ui/core/Box";
import moment from "moment";
import AnchorTag from "../AnchorTag/AnchorTag";

const useStyles = makeStyles(style);

function BlockListItem({blockPageId, block}) {

    const classes = useStyles();
    const {t} = useTranslation('general');
    const id = block.id;
    const title = block.title;
    const subtitle = moment(block.createdAt).format('YYYY. MM. DD.');
    let description = block.description;

    const tags = block.tags ?
        block.tags.split(',').map(tag => <AnchorTag tag={tag} key={tag}/>) :
        [];

    return (
        <Grid container
              className={classes.root}
              component='div'>
            <Grid item container
                  xs={12}
                  component='div'
                  className={classes.header}>
                <Grid item md={3} component='div'>
                    <Typography variant='h2'
                                className={classes.title}>
                        {title}
                    </Typography>
                </Grid>
                <Grid item md={9} container component='div' justify='flex-end'>
                    <Grid item xs={12}>
                        <Typography variant='subtitle2'
                                    className={classes.subtitle}>
                            {subtitle}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid xs={12} md={3} container item component='div' alignContent='flex-start'>
                <Grid item xs={12} className={classes.imageBox}>
                    {block.picture !== "null" && block.picture !== null ?
                    <img className={classes.image}
                         src={`/api/1.0.0/images/${block.picture}`}
                         alt={block.altText}/> : "" }
                </Grid>
                <Grid item xs={12}>
                    {tags}
                </Grid>
            </Grid>
            <Grid item xs={12} md={9}
                  className={classes.content}>
                <Box className={classes.contentText + " ql-display"} dangerouslySetInnerHTML={{__html: description}}/>
                <A href={`/block-pages/${blockPageId}/blocks/${id}`}
                   className={classes.contentMoreBtn}>{t('More')}...</A>
            </Grid>
        </Grid>
    );
}

export default BlockListItem;