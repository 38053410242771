import {useDrop} from "react-dnd";
import React, {useContext} from "react";
import {makeStyles} from "@material-ui/core";
import {actions} from "../../Data/Store/Actions";
import {MenuBuilderContext} from "../../Context/MenuBuilderContext";
import classNames from "classnames";
import {moveContent} from "../../Api/content";
import {MoveRecord} from "../../Data/Records/Move";
import AddItemButton from "../AddItemButton/AddItemButton";
import {indicatorRowStyle} from "./indicatorRowStyle";

const useStyles = makeStyles(indicatorRowStyle);

const IndicatorCell = ({record, indicatorPosition, branchPath}) => {

    const {dispatch} = useContext(MenuBuilderContext);

    const classes = useStyles();
    const position = branchPath.size;
    const hoverConditions = indicatorPosition !== 0 &&
        indicatorPosition >= position &&
        indicatorPosition <= position + 1;

    const newParent = position !== indicatorPosition ? record.id : record.parent;
    const itemBefore = position !== indicatorPosition ? -1 : record.id;

    const newItemPosition = {
        parent: newParent,
        itemBefore: itemBefore,
        color: record.color,
    };

    const [{canDrop, isOver}, drop] = useDrop({
        accept: 'cell',
        drop: item => {

            const move = new MoveRecord({
                id: item.id,
                oldParent: item.parent,
                newParent: newParent,
                itemBefore: itemBefore,
                color: record.color,
            });

            moveContent(move,
                () => {
                    if (position !== indicatorPosition) {
                        dispatch(actions.moveItemToChildren(item.id, record.id));
                    } else {
                        dispatch(actions.moveItem(item.id, record.id))
                    }
                });
        },
        canDrop: item => {
            return hoverConditions &&
                item.id !== record.parent &&
                item.id !== record.id &&
                !branchPath.includes(item.id);
        },
        collect: monitor => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
        }),
    });

    const className = classNames({
        [classes.indicatorCell]: true,
        [classes.indicatorCellBranch]: isOver && canDrop,
        [classes.indicatorCellIsOver]: true, //isOver,
        [classes.indicatorCellCanDrop]: isOver && canDrop,
        [classes.indicatorCellCannotDrop]: isOver && !canDrop,
    });

    return (
        <td>
            <div ref={drop} className={className}>
                {!isOver && hoverConditions &&
                    <AddItemButton newItemPosition={newItemPosition}/>
                }
            </div>
        </td>
    );
};

const IndicatorRow = props => {
    const classes = useStyles();

    let indicatorCells = [];
    const id = props.record.id;

    for (let i = 0; i < 4; i++) {
        indicatorCells.push(<IndicatorCell key={`indicator-cell-${i}`}
                                           indicatorPosition={i}
                                           {...props}/>)
    }

    return (
        <tr key={`indicator-row-${id}`}
            className={classes.indicatorRow}>
            {indicatorCells}
        </tr>
    );

};

export default IndicatorRow;