import React from "react";
import List from "./List";
import {useRoutes} from "hookrouter";
import {addTemplate, removeTemplate, updateTemplate, useTemplate} from "../../Api/templates";
import {useTranslation} from "react-i18next";
import debug from "../../Lib/debug";
import Form from "./Form";
import {TemplateRecord, TemplateSchema} from '../../Data/Template';

export default () => useRoutes(subRoutes);

const subRoutes = {
    "/": () => <Notifications view="list"/>,
    "/add": () => <Notifications view="add"/>,
    "/:notificationId/edit": ({notificationId}) => <Notifications view="edit" notificationId={notificationId}/>,
    "/:notificationId/remove": ({notificationId}) => <Notifications view="remove" notificationId={notificationId}/>,
};

const Notifications = ({view, notificationId}) => {

    debug("RENDER", "Notifications", () => [view]);

    const {t} = useTranslation('notifications');

    const {loadingStatus, data} = useTemplate(notificationId);

    const viewData = (loadingStatus ? new TemplateRecord(TemplateSchema.default()) : data);

    const path = [{label: t('titleNav'), url: "/admin/notifications"}];

    // noinspection JSCheckFunctionSignatures
    const label = `${viewData.name}`;

    switch (view) {
        case "list" :

            return <List path={path}/>;

        case "add" :

            path.push({label: label, url: "/admin/notifications/add"});
            return <Form notificationId={notificationId} record={viewData} path={path} view={view} api={addTemplate}/>;

        case "remove" :

            path.push({label: label, url: "/admin/notifications/" + notificationId + "/remove"});
            return <Form notificationId={notificationId} record={viewData} path={path} view={view} api={removeTemplate}/>;

        case "edit" :

            path.push({label: label, url: "/admin/notifications/" + notificationId + "/edit"});
            return <Form notificationId={notificationId} record={viewData} path={path} view={view} api={updateTemplate}/>;

        default :
            return <div>invalid view: {view}</div>
    }
};

