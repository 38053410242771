export const documentsHu = {
    "titleNav": "Dokumentumok",
    "title": "Cím",
    "status": "Státusz",
    "attachment": "Csatolt fájl",
    "actions": "Műveletek",
    "DRAFT": "Vázlat",
    "PUBLIC": "Publikus",

    "add": "Dokumentum létrehozása",
    "edit": "Dokumentum szerkesztése",
    "save": "Mentés",
    "back": "Vissza",
    "remove": "Dokumentum törlése",
    "delete": "Dokumentum törlése",
};
