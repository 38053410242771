import React, {memo, useContext} from "react";
import {useTranslation} from "react-i18next";
import useForm from "../../Lib/form/useForm";
import ValidatedInput from "../../Lib/form/Components/ValidatedInput/ValidatedInput";
import Card from "../../Lib/material-dashboard/components/Card/Card";
import CardBody from "../../Lib/material-dashboard/components/Card/CardBody";
import ValidatedSubmit from "../../Lib/form/Components/ValidatedSubmit/ValidatedSubmit";
import {assume} from "../../Lib/auth/Api/session";

import recordType from "../../Lib/auth/Records/AuthenticationData";
import recordSchema from "../../Lib/auth/Schemas/AuthenticationData";
import {AppContext} from "../../Main/AppContext";
import Actions from "../../Lib/auth/AuthActions";
import {useSnackbar} from "notistack";
import {A, navigate, useQueryParams} from "hookrouter";
import Grid from "@material-ui/core/Grid";

import loginStyle from "./loginStyle";
import Button from "../../Lib/material-kit/components/CustomButtons/Button";
import {makeStyles} from "@material-ui/styles";
import FormProvider from "../../Lib/form/Components/FormProvider/FormProvider";
import ContentBox from "../../Components/ContentBox/ContentBox";
import useNav from "../../Lib/useNav";
import {useBackground} from "../../Lib/ContentManagement/ContentEditor/Api/pages";
import Box from "@material-ui/core/Box";

const useStyles = makeStyles(loginStyle);

const Login = memo(() => {

    useNav({path: window.location.pathname});

    // stuff specific for login  ----------------------------------

    const appContext = useContext(AppContext);
    const dispatch = appContext.get('dispatch');

    const {enqueueSnackbar} = useSnackbar();

    const {target = "/admin"} = useQueryParams()[0];

    const onSuccess = (sessionData) => {
        dispatch(Actions.sessionAdd(sessionData));
        navigate(target);
    };

    const onError = () => {
        enqueueSnackbar(t('error'), {variant: 'error'});
    };

    // usual form stuff  ----------------------------------

    const {t} = useTranslation("login");

    const {data, loadingStatus} = useBackground();

    // noinspection JSPotentiallyInvalidConstructorUsage
    const form = useForm({
        recordType: recordType,
        recordSchema: recordSchema,
        namespace: 'login',
        record: new recordType(recordSchema.default())
    });

    const classes = useStyles();

    return (
        <ContentBox publicPage={true}>
            <Box className={classes.panel}>
                <Box className={classes.paperContainer} style={loadingStatus ? {} : {backgroundImage: `url(/api/1.0.0/images/${data})`}}>
                    <Card className={classes.container}>
                        <CardBody>
                            <Grid container justify="center">

                                <Grid item xs={12}>
                                    <div className={classes.title}>{t('title')}</div>
                                </Grid>

                                <Grid item xs={12}>
                                    <FormProvider form={form} formClassName={classes.form}>
                                        <ValidatedInput name="principalName"/>
                                        <ValidatedInput name="password" password/>
                                        <Grid item container justify="space-between">
                                            <Grid item style={{marginTop: 40}}>
                                                <ValidatedSubmit
                                                    api={assume}
                                                    hideCancel
                                                    submitLabel={t('login')}
                                                    onSuccess={(sessionData) => onSuccess(sessionData)}
                                                    onError={(error) => onError(error)}
                                                />
                                            </Grid>
                                            <Grid item style={{marginTop: 40}}>
                                                <A href="/accounts/reset/public">
                                                    <Button className={classes.forgottenPasswordBtn}>
                                                        {t('forgottenPassword')}
                                                    </Button>
                                                </A>
                                            </Grid>
                                        </Grid>
                                    </FormProvider>
                                </Grid>

                            </Grid>
                        </CardBody>
                    </Card>
                </Box>
            </Box>
        </ContentBox>
    );
});

export default Login;