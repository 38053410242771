export const cityCouncilPageStyle = theme => ({
    councilLinkBox: {
        marginBottom: '1rem',
    },
    councilLink: {
        // ...theme.typography.button,
        color: theme.palette.text.primary,
        textDecoration: 'unset',
        paddingLeft: '.375rem',
        fontWeight: 500,
        borderLeftWidth: '.1875rem',
        borderLeftStyle: 'solid',
        borderLeftColor: theme.palette.primary.main,
        fontSize: 20,
        '&:hover': {
            color: theme.palette.text.primary,
        },
    },
    header: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        [theme.breakpoints.up('md')]: {
            marginBottom: theme.spacing(6),
        },
    },
    title: {
        marginBottom: theme.spacing(2),
        fontWeight: 'bold',
        [theme.breakpoints.up('md')]: {
            marginBottom: theme.spacing(3),
        },
    },
    selectContainer: {
        marginBottom: theme.spacing(3),
        [theme.breakpoints.up('md')]: {
            marginBottom: theme.spacing(6),
        }
    },
    description: {
        ...theme.typography.subtitle1
    },
    dataLineBox: {
        borderBottomWidth: 2,
        borderBottomStyle: 'solid',
        borderBottomColor: theme.palette.grey.light,
        padding: '.5rem .75rem .125rem',
        // '&:first-child': {
        //     borderTopWidth: 2,
        //     borderTopStyle: 'solid',
        //     borderTopColor: theme.palette.grey.main,
        // }
    },
    dataBox: {
        padding: '.5rem .75rem .125rem',
    },
    detailsContainer: {
        marginTop: theme.spacing(3),
    },
    detailName: {
        fontWeight: 500,
    },
    docItem: {
        color: theme.palette.text.primary,
        marginBottom: '.25rem',
        '&:hover': {
            color: theme.palette.primary.main,
        }
    },

    subtitleBox: {
        marginBottom: theme.spacing(3),
    },

    record: {
        margin: '1rem 0',
    },

    attachment: {
        display: 'flex',
        textDecoration: 'none',
        color: theme.palette.text.primary,
        borderLeft: `2px solid ${theme.palette.kolorcity[5]}`,
        paddingLeft: theme.spacing(1),
        marginLeft: theme.spacing(3),
        cursor: 'pointer',
        wordBreak: 'break-word',
        textOverflow: 'hidden',
        '&:active': {
            color: theme.palette.text.primary,
        },
        '&:hover': {
            color: theme.palette.primary.main,
        }
    },
    meetingRecord: {
        textDecoration: 'none',
        color: theme.palette.primary.dark,
        paddingLeft: theme.spacing(1),
        cursor: 'pointer',
        wordBreak: 'break-word',
        textOverflow: 'hidden',
        '&:active': {
            color: theme.palette.primary.dark,
        },
        '&:hover': {
            color: theme.palette.primary.main,
        }
    },
});