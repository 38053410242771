export const anchorTagStyle = theme => ({
    tag: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(2),
        color: theme.palette.white,
        '&:hover': {
            color: theme.palette.text.white,
        },
        '&:active': {
            color: theme.palette.text.white
        }
    },
});