import React, {useEffect, useState} from "react";
import Grid from "@material-ui/core/Grid";
import useForm from "../../../../form/useForm";
import FormProvider from "../../../../form/Components/FormProvider/FormProvider";
import {useTranslation} from "react-i18next";
import {makeStyles} from "@material-ui/core";
import {notificationEditorStyle} from "./notificationEditorStyle";
import {NotificationPageRecord, NotificationPageSchema} from "../../Data/NotificationPage";
import ValidatedInput from "../../../../form/Components/ValidatedInput/ValidatedInput";
import ValidatedRichTextEditor from "../../../../form/Components/ValidatedRichTextEditor/ValidatedRichTextEditor";
import ValidatedSubmit from "../../../../form/Components/ValidatedSubmit/ValidatedSubmit";
import Section from "../../../../form/Components/Section/Section";
import {TextPageRecord} from "../../Data/TextPage";
import {getNotificationPage, updateNotificationPage} from "../../Api/notificationPages";

const useStyles = makeStyles(notificationEditorStyle);

const NotificationEditor = ({ notificationPageId}) => {

    const classes = useStyles();

    const {t} = useTranslation("contentManagement");

    const [record, setRecord] = useState(new TextPageRecord());

    const form = useForm({
        recordType: NotificationPageRecord,
        recordSchema: NotificationPageSchema,
        namespace: 'contentManagement',
        record: record
    });

    useEffect(() => {
        getNotificationPage(notificationPageId, data => {
            setRecord(data);
        })
    }, [notificationPageId]);

    const api = updateNotificationPage;

    return (
        <Grid container>
            <FormProvider form={form}>
                <Section title={`${t('subpage')} ${t('edit')}`}>
                    <Grid container>
                        <Grid item xs={12}>
                            <ValidatedInput name='title'
                                            label={t('title', {context: 'subpage'})}
                            />
                        </Grid>
                        <Grid item xs={12} md={8} className={classes.editorBlock}>
                            <Grid item className={classes.textContentContainer}>
                                <ValidatedRichTextEditor name='textContent'
                                                         showLabel={true}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <ValidatedSubmit view='edit'
                                     api={api}
                                     hideCancel={true}
                    />
                </Section>
            </FormProvider>
        </Grid>
    )
};

export default NotificationEditor;