import React, {memo, useContext} from "react";
import cx from "classnames";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import {useTranslation} from "react-i18next";
import {makeStyles} from "@material-ui/styles";
import SidebarItem from "./SidebarItem";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import {Icon} from "@material-ui/core";
import {AppContext} from "../../Main/AppContext";
import sidebarStyle from "../../Lib/material-dashboard/assets/jss/components/sidebarStyle";
import {A} from "hookrouter";
import LanguageSelector from "../LanguageSelector/LanguageSelector";
import Box from "@material-ui/core/Box";
import Menu from "@material-ui/icons/Menu";
import Button from "../../Lib/material-dashboard/components/CustomButtons/Button";
import Version from "../Version/Version";


const useStyles = makeStyles(sidebarStyle);

const Sidebar = memo((props) => {

    const {handleDrawerToggle, sidebarMinimize, open, mini} = props;

    const bgColor = "white";
    const logoText = "Kazincbarcika";


    const classes = useStyles();
    const {t} = useTranslation();

    const appContext = useContext(AppContext);
    const navTree = appContext.getIn(['appStore', 'navTree']);
    const administrationNavTree = navTree[1].views;

    const logoNormal = cx(classes.logoNormal, {[classes.logoNormalSidebarMini]: mini});
    const logoClasses = cx(classes.logo, {[classes.whiteAfter]: bgColor === "white"});

    const brand = (
        <div className={logoClasses}>
            {mini ?
                <Icon className={classes.logoMini} onClick={sidebarMinimize}>
                    <ChevronRightIcon/>
                </Icon> :
                <Icon className={classes.logoMini} onClick={sidebarMinimize}>
                    <ChevronLeftIcon/>
                </Icon>
            }
            <A href="/" className={logoNormal}>
                {/*<img src={logo} style={{height: 32, marginRight: '1rem'}} alt="logo"/>*/}
                {t(logoText)}
            </A>
        </div>
    );

    const drawerPaper = cx(classes.drawerPaper, {[classes.drawerPaperMini]: mini});
    const sidebarWrapper = cx(classes.sidebarWrapper, {[classes.drawerPaperMini]: mini});

    return (
        <div>
            <Hidden mdUp implementation="css">
                <Button
                    className={classes.sidebarButton}
                    color="transparent"
                    justIcon
                    aria-label="open drawer"
                    onClick={handleDrawerToggle}
                >
                    <Menu/>
                </Button>
                <Hidden mdUp>
                    <Drawer
                        // variant="temporary"
                        anchor={"right"}
                        open={open}
                        classes={{
                            paper: drawerPaper + " " + classes[bgColor + "Background"]
                        }}
                        onClose={handleDrawerToggle}
                        ModalProps={{
                            keepMounted: true // Better open performance on mobile.
                        }}>
                        {brand}
                        <div className={sidebarWrapper} onClick={handleDrawerToggle}>
                            {administrationNavTree.map(n => <SidebarItem key={n.title} route={n} mini={mini}/>)}
                        </div>
                    </Drawer>
                </Hidden>
            </Hidden>
            <Hidden smDown implementation="css">
                <Drawer
                    anchor="left"
                    variant="permanent"
                    open={true}
                    classes={{
                        paper: drawerPaper + " " + classes[bgColor + "Background"]
                    }}>
                    {brand}
                    <div className={sidebarWrapper}>
                        {administrationNavTree.map(n => <SidebarItem key={n.title} route={n} mini={mini}/>)}
                    </div>
                    <Box style={{position: 'relative', zIndex: 200}}>
                        <LanguageSelector smallScreen={true}/>
                    </Box>
                    <Version/>
                </Drawer>
            </Hidden>
        </div>
    );
});


export default Sidebar;
