import baseFetch, {useFetchMany, useQueryOne} from "../../../baseFetch";
import {CityCouncilPageRecord, CityCouncilPageSchema} from "../Data/CityCouncilPage";
import {roles} from "../../../../Main/roles";

export function useCityCouncilPages(filter, revision) {
    return useFetchMany('/city-council', filter, CityCouncilPageRecord, revision);
}

export function useCityCouncilPageByType(str, revision) {
    return useQueryOne('/city-council/' + str, CityCouncilPageRecord, CityCouncilPageSchema, revision);
}

export function updateCityCouncilPage(cityCouncilPage, onSuccess, onError) {
    baseFetch('PATCH', '/city-council/' + cityCouncilPage.get('id'), cityCouncilPage, onSuccess, onError);
}

export const cityCouncilPagesApiTest = {
    '/city-council': {
        'GET': {
            [roles.ANONYMOUS]: {hasResponse: true},
            [roles.SITE_MEMBER]: {hasResponse: true},
            [roles.COUNCIL_ADMIN]: {hasResponse: true},
            [roles.CM_ADMIN]: {hasResponse: true},
            [roles.UPLOAD_ADMIN]: {hasResponse: true},
            [roles.ADMIN]: {hasResponse: true},
            [roles.SECURITY_OFFICER]: {hasResponse: true}
        },

    },
    '/city-council/IMPRESSUM': {
        'GET': {
            [roles.ANONYMOUS]: {hasResponse: true},
            [roles.SITE_MEMBER]: {hasResponse: true},
            [roles.COUNCIL_ADMIN]: {hasResponse: true,},
            [roles.CM_ADMIN]: {hasResponse: true},
            [roles.UPLOAD_ADMIN]: {hasResponse: true},
            [roles.ADMIN]: {hasResponse: true,},
            [roles.SECURITY_OFFICER]: {hasResponse: true}
        },
    },
    '/city-council/1': {
        'PATCH': {
            [roles.ANONYMOUS]: {hasResponse: false},
            [roles.SITE_MEMBER]: {hasResponse: false},
            [roles.COUNCIL_ADMIN]: {hasResponse: false,},
            [roles.CM_ADMIN]: {hasResponse: true},
            [roles.UPLOAD_ADMIN]: {hasResponse: true},
            [roles.ADMIN]: {hasResponse: true,},
            [roles.SECURITY_OFFICER]: {hasResponse: true}
        },
    }
}
