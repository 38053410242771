export const notificationsHu = {
    "titleNav": "Értesítések",
    "name": "Név",
    "title": "Cím",
    "content": "Tartalom",
    "actions": "Műveletek",
    "edit": "Szerkesztés",
    "back": "Vissza",
    "remove": "Törlés",
    "delete": "Törlés",
    "cancel": "Vissza",
    "save": "Mentés",
    "add": "Mentés"
};
