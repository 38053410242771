import React, {memo, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {makeStyles} from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import GridList from "@material-ui/core/GridList";
import Typography from "@material-ui/core/Typography";
import Button from "../../Lib/material-dashboard/components/CustomButtons/Button";
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import GridListTile from "@material-ui/core/GridListTile";
import {FilterField} from "../../Lib/Table/Components/FilterField";
import {navigate} from "hookrouter";
import FilteringByEnum from "../../Components/FilteringByStatus";
import {TypeOfAd} from "../../Data/Item";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing(0, 3),
    },
    paper: {
        maxWidth: "auto",
        minHeight: "20rem",
        margin: `${theme.spacing(1)}px auto`,
        padding: theme.spacing(3),
    },
    rootOfGridList: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        backgroundColor: theme.palette.background.paper,
    },
    gridList: {
        maxWidth: "50rem",
        maxHeight: "25rem",
    },
    img: {
        width: "97%",
        height: "auto",
    }
}));


export default memo(({data, handleOpen, type, onSelectedType}) => {

    const {t} = useTranslation('items');
    const classes = useStyles();

    const [searchedObject, setSearchedObject] = useState([])

    const showsImage = (e) => {
        window.open(e._targetInst.memoizedProps.src);
    };

    const [state, setState] = useState('');

    const handleFilter = val => setState(val);

    useEffect(() => {
        setSearchedObject([])
        if (!state || state.length < 1) {
            setSearchedObject(data)
        } else {
            data.forEach(record => {
                const obj = record.toObject();
                const isIncludes = Object.values(obj).some(val => val ? val.toString().toLowerCase().includes(state.toLowerCase()) : false);
                if (isIncludes) {
                    setSearchedObject(old => [...old, record])
                }
            });
        }

    }, [state, data])


    return (
        <Grid item>
            <Grid container item direction="row" justify="space-around" alignItems="center">
                <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                    <FilterField handler={handleFilter}/>
                </Grid>
                <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                    <FilteringByEnum onSelected={onSelectedType} arrayValue={TypeOfAd.concat(null)}
                                     enum={type} name="typeOfAd"/>
                </Grid>
                <Grid item xs={12} sm={12} md={2} lg={2} xl={2}> <Button color={"primary"}
                                           onClick={() => navigate("/items/add")}
                >
                    {t('newItem')}
                </Button>
                </Grid>
            </Grid>
            {searchedObject.map((item) => (
                <div className={classes.root} key={item.id}>
                    <Paper className={classes.paper}>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
                                <Grid container direction="column" spacing={2}>
                                    <Grid container item direction="row" justify="flex-start" alignItems="center">
                                        <Grid item xs={12} sm={3} md={3} lg={3} xl={3}><Typography
                                            style={{fontWeight: 600}}>{t("name")}:</Typography></Grid>
                                        <Grid item xs={12} sm={9} md={9} lg={9}
                                              xl={9}><Typography>{item.name}</Typography></Grid>
                                    </Grid>
                                    <Grid container item direction="row" justify="flex-start" alignItems="center">
                                        <Grid item xs={12} sm={3} md={3} lg={3} xl={3}><Typography
                                            style={{fontWeight: 600}}>{t("translCategory")}:</Typography></Grid>
                                        <Grid item xs={12} sm={9} md={9} lg={9}
                                              xl={9}><Typography>{item.translCategory}</Typography></Grid>
                                    </Grid>
                                    <Grid container item direction="row" justify="flex-start" alignItems="center">
                                        <Grid item xs={12} sm={3} md={3} lg={3} xl={3}><Typography
                                            style={{fontWeight: 600}}>{t("translDelivery")}:</Typography></Grid>
                                        <Grid item xs={12} sm={9} md={9} lg={9}
                                              xl={9}><Typography>{item.translDelivery}</Typography></Grid>
                                    </Grid>
                                    <Grid container item direction="row" justify="flex-start" alignItems="center">
                                        <Grid item xs={12} sm={3} md={3} lg={3} xl={3}><Typography
                                            style={{fontWeight: 600}}>{t("translTypeOfAd")}:</Typography></Grid>
                                        <Grid item xs={12} sm={9} md={9} lg={9}
                                              xl={9}><Typography>{item.translTypeOfAd}</Typography></Grid>
                                    </Grid>
                                    <Grid container item direction="row" justify="flex-start" alignItems="center">
                                        <Grid item xs={12} sm={3} md={3} lg={3} xl={3}><Typography
                                            style={{fontWeight: 600}}>{t("description")}:</Typography></Grid>
                                        <Grid item xs={12} sm={9} md={9} lg={9}
                                              xl={9}><Typography>{item.description}</Typography></Grid>
                                    </Grid>
                                    <Grid container item direction="row" justify="flex-start" alignItems="center">
                                        <Grid item xs={12} sm={3} md={3} lg={3} xl={3}><Typography
                                            style={{fontWeight: 600}}>{t("createdAt")}:</Typography></Grid>
                                        <Grid item xs={12} sm={9} md={9} lg={9}
                                              xl={9}><Typography>{item.createdAt}</Typography></Grid>
                                    </Grid>
                                    <Grid container item direction="row" justify="flex-start" alignItems="center">
                                        <Grid item xs={12} sm={3} md={3} lg={3} xl={3}><Typography
                                            style={{fontWeight: 600}}>{t("creatorDetails")}:</Typography></Grid>
                                        <Grid item container xs={12} sm={9} md={9} lg={9} xl={9} spacing={1}>
                                            <Grid item xs={12} sm={9} md={9} lg={9}
                                                  xl={9}><Typography>{item.nameOfCreator}</Typography></Grid>
                                            <Grid item
                                                  xs={12} sm={9} md={9} lg={9}
                                                  xl={9}><Typography>{item.emailAddressOfCreator}</Typography></Grid>
                                            <Grid item
                                                  xs={12} sm={9} md={9} lg={9}
                                                  xl={9}><Typography>{item.phoneNumberOfCreator}</Typography></Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Divider orientation="vertical" flexItem/>
                            <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                                <GridList className={classes.gridList} cols={1}>
                                    {item.images ? item.images.split("/").map((image) => (
                                        <GridListTile key={image + "_title"} cols={1}>
                                            <img src={'/api/1.0.0/images/' + image} key={image}
                                                 onClick={showsImage} className={classes.img}/>
                                        </GridListTile>
                                    )) : <NotInterestedIcon alt={"semmi nincs"}/>}
                                </GridList>
                            </Grid>
                        </Grid>
                        <Grid style={{padding: "1rem"}}>
                            <Button color={"primary"}
                                    onClick={() => handleOpen(item.id)}
                            >
                                {t('interest')}
                            </Button>
                        </Grid>
                    </Paper>
                </div>
            ))}
        </Grid>

    )
})