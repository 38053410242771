import React from "react";
import {MeetingRecord, MeetingSchema} from "../../../Data/Meeting";
import {addData, createRows, getAll, getOne, removeData, updateData} from "../util";

import {meetingsApiTest} from "../../../Api/meetings";

const record = MeetingRecord(MeetingSchema.default()).withMutations(data => {
    return data.set("date", new Date())
        .set("time", "01:00")
        .set("type", "INAUGURAL")
        .set("committee", 1)
})

const calls = [
    onResponse => getAll("/meetings", onResponse),
    onResponse => addData("/meetings", record, onResponse),
    onResponse => getOne("/meetings/1", onResponse),
    onResponse => updateData("/meetings/1", record, onResponse),
    onResponse => removeData('/meetings/2', onResponse),
    onResponse => getAll('/meetings/1/get-meeting-elements', onResponse),
    onResponse => updateData('/meetings/1/sequence/1/1', undefined, onResponse),
];

const MeetingsApis = () => {

    return (
        <React.Fragment>
            {createRows(calls, meetingsApiTest)}
        </React.Fragment>
    );
}

export default MeetingsApis;