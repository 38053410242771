import React from "react";
import AccountsApis from "./ResponseLists/AccountsApis";
import CommitteesApis from "./ResponseLists/ComitteesApis";
import DecisionsApis from "./ResponseLists/DecisionsApis";
import DocumentsApis from "./ResponseLists/DocumentsApis";
import KolorLineNewsApis from "./ResponseLists/KolorLineNewsApis";
import LanguagesApis from "./ResponseLists/LanguagesApis";
import MeetingsApis from "./ResponseLists/MeetingsApis";
import MeetingRecordsApis from "./ResponseLists/MeetingRecordsApis";
import MeetingElementsApis from "./ResponseLists/MeetingElementsApis";
import OrganisationsApis from "./ResponseLists/OrganisationsApis";
import PositionsApis from "./ResponseLists/PositionsApis";
import ReportsApis from "./ResponseLists/ReportsApis";
import SearchApis from "./ResponseLists/SearchApis";
import TemplatesApis from "./ResponseLists/TemplatesApis";
import ContentsApis from "./ResponseLists/ContentsApis";
import NavColorsApis from "./ResponseLists/NavColorsApis";
import BlockPagesApis from "./ResponseLists/BlockPagesApis";
import ListItemsApis from "./ResponseLists/ListItemsApis";
import NotificationPagesApis from "./ResponseLists/NotificationPagesApis";
import PagesApis from "./ResponseLists/PagesApis";
import TextPagesApis from "./ResponseLists/TextPagesApis";
import Grid from "@material-ui/core/Grid";
import FileUploadPagesApis from "./ResponseLists/FileUploadPagesApis";
import CityCouncilPagesApis from "./ResponseLists/CityCouncilPagesApis";
import {makeStyles} from "@material-ui/styles";
import ListHead from "./ListHead";

const useStyles = makeStyles({
    container: {
        width: '100%',
        height: '100vh',
        overflow: 'auto'
    },
    list: {
        minWidth: '100%',
        display: 'grid',
        borderCollapse: 'collapse',
        gridTemplateColumns: `
        minmax(50px, .25fr)
        minmax(50px, .25fr)
        minmax(150px, 2fr)
        minmax(50px, .25fr)
        minmax(50px, .25fr)
        minmax(50px, .5fr)`,
    },
    body: {
        display: 'contents'
    }
})

const apis = [
    <AccountsApis key="accounts"/>,

    <CommitteesApis key="committees"/>,
    <DecisionsApis key="decisions"/>,
    <OrganisationsApis key="organisations"/>,
    <PositionsApis key="positions"/>,
    <MeetingsApis key="meetings"/>,
    <MeetingRecordsApis key="meeting-records"/>,
    <MeetingElementsApis key="meeting-elements"/>,
    <DocumentsApis key="documents"/>,
    <TemplatesApis key="template"/>,

    <ContentsApis key="contents"/>,
    <TextPagesApis key="text-pages"/>,
    <BlockPagesApis key="blockpages"/>,
    <FileUploadPagesApis key="file-upload-pages"/>,
    <ListItemsApis key="listitems"/>,
    <NotificationPagesApis key="notification-pages"/>,
    <CityCouncilPagesApis key="citycouncil"/>,

    <ReportsApis key="reports"/>,
    <SearchApis key="search"/>,
    <KolorLineNewsApis key="kolorlinenews"/>,
    <LanguagesApis key="languages"/>,

    <NavColorsApis key="navcolors"/>,
    <PagesApis key="pages"/>,
];

const ApiCalls = () => {

    const classes = useStyles();

    return (
        <Grid item xs={9}>
            <div className={classes.container}>
                <div className={classes.list}>
                    <ListHead/>
                    <div className={classes.body}>
                        {apis}
                    </div>
                </div>
            </div>
        </Grid>
    )
}

export default ApiCalls;