import React from "react";
import {MeetingRecordRecord, MeetingRecordSchema} from "../../../Data/MeetingRecord";
import {addData, createRows, getOne, removeData, updateData} from "../util";

import {meetingRecordsApiTest} from "../../../Api/meeting-records";

const record = MeetingRecordRecord(MeetingRecordSchema.default()).withMutations(data => {
    return data.set("title", "Ülés_01_Jegyzőköny_01")
        .set("attachmentId", 1)
})

const calls = [
    onResponse => addData('/meetings/1/meeting-records', record, onResponse),
    onResponse => getOne("/meeting-records/1", onResponse),
    onResponse => updateData("/meeting-records/1", record, onResponse),
    onResponse => removeData('/meeting-records/3', onResponse),
    onResponse => getOne("/meeting-records/1/attachment", onResponse),
];

const MeetingRecordsApis = () => {

    return (
        <React.Fragment>
            {createRows(calls, meetingRecordsApiTest)}
        </React.Fragment>
    );
}

export default MeetingRecordsApis;