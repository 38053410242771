import React, {memo, useState} from "react";
import {blockPageStyle as style} from "./blockPageStyle";
import {makeStyles} from "@material-ui/styles";
import Grid from "@material-ui/core/Grid";
import useNav from "../../Lib/useNav";
import {useRoutes} from "hookrouter";
import ContentBox from "../../Components/ContentBox/ContentBox";
import {useBlockItemsByAmount, useBlockPage} from "../../Lib/ContentManagement/ContentEditor/Api/blockPages";
import Box from "@material-ui/core/Box";
import {Typography} from "@material-ui/core";
import TextPage from "../TextPage/TextPage";
import BlockListItem from "../../Components/BlockListItem/BlockListItem";
import LoadMoreButton from "../../Components/LoadMoreButton/LoadMoreBtn";
import {useLanguageRevision} from "../../Lib/useLanguageRevision";

const useStyles = makeStyles(style);

const subRoutes = {
    "/": () => props => <BlockPage view='list' {...props}/>,
    "/:textPageId": ({textPageId}) => props => <BlockPage view='text' textPageId={textPageId} {...props}/>
};

export default props => useRoutes(subRoutes)(props);

const Blocks = memo(({pageId}) => {

    const revision = useLanguageRevision();

    const [numberOfElements, setNumberOfElements] = useState(10);

    const {data, loadingStatus} = useBlockItemsByAmount(pageId, numberOfElements, revision);

    const handleClick = () => {
        setNumberOfElements(prev => prev + 10);
    };

    if (loadingStatus) return null;

    return (
        <Grid container>
            {data.map(block => <BlockListItem key={block.id} blockPageId={pageId} block={block}/>)}
            <Grid item container xs={12}>
                {data.length === numberOfElements &&
                <LoadMoreButton handleClick={handleClick}/>
                }
            </Grid>
        </Grid>
    );

});

const BlockPage = memo(({pageId, path, textPageId, view}) => {

    useNav({path: window.location.pathname});

    const classes = useStyles();

    const revision = useLanguageRevision();

    const {data, loadingStatus} = useBlockPage(pageId, revision);

    if (loadingStatus) return null;

    return (
        <ContentBox publicPage={true} path={path}>
            <Grid container className={classes.root} component='div'>
                <Box className={classes.blockList}>
                    <Box className={classes.header}>
                        <Typography variant='h4' className={classes.title}>
                            {data.title}
                        </Typography>
                        <Box className={classes.description + " ql-display"} dangerouslySetInnerHTML={{__html: data.description}}/>
                    </Box>
                    {view === 'list' ?
                        <Blocks pageId={pageId}/> :
                        view === 'text' ?
                            <TextPage pageId={textPageId} blockItem={true}/> :
                            null
                    }
                </Box>
            </Grid>
        </ContentBox>
    )
});
