export const blockPageStyle = theme => ({
    blockList: {
        color: theme.palette.text.primary,
    },
    header: {
        marginBottom: theme.spacing(3),
        [theme.breakpoints.up('md')]: {
            marginBottom: theme.spacing(6),
        },
    },
    title: {
        marginBottom: theme.spacing(2),
        fontWeight: 'bold',
        [theme.breakpoints.up('md')]: {
            marginBottom: theme.spacing(3),
        },
    },
    description: {
        ...theme.typography.subtitle1
    }
});