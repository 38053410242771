import React from "react";

import {makeStyles} from "@material-ui/styles";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import customInputStyle from "../../../material-dashboard/assets/jss/components/customInputStyle.jsx";
import classNames from "classnames";
import {useFormField} from "../../useFormField";

const useStyles = makeStyles(customInputStyle);

function ValidatedCheckboxGroup(props) {
  const {children} = props;

  const classes = useStyles();

  const ff = useFormField(props);

  const labelClasses = classNames(classes.labelRoot, {
    [classes.labelRootError]: ff.status === "invalid",
    [classes.labelRootSuccess]: ff.status === "valid"
  });

  const formControlClasses = classNames([
    classes.formControl
  ]);

  let helpTextClasses = classNames({
    [classes.labelRootError]: ff.status === "invalid",
    [classes.labelRootSuccess]: ff.status === "valid"
  });

  return (
      <FormControl className={formControlClasses} fullWidth>

        <InputLabel className={labelClasses} shrink={true}>{ff.labelText}</InputLabel>

        {children}

        {ff.helperText
            ? <FormHelperText className={helpTextClasses}>
              {ff.helperText}
            </FormHelperText>
            : null}

      </FormControl>
  );
}

export default ValidatedCheckboxGroup;