import baseFetch, {useFetchMany, useFetchOne} from "../../../baseFetch";
import {ActualitiesRecord} from "../Data/Actualities";
import {roles} from "../../../../Main/roles";

export function useActualitiesByAmount(number) {
    return useFetchMany('/pages/actualities/' + number, undefined, ActualitiesRecord);
}

export function useBackground() {
    return useFetchOne('/pages/main-image', null);
}

export function setBackground(background, onSuccess, onError) {
    baseFetch('POST', '/pages/main-image/' + background.picture, undefined,
        data => {
            if (onSuccess) onSuccess(data)
        },
        onError
    );
}

export function useDataPolicy() {
    return useFetchOne('/pages/data-policy', null);
}

export function setDataPolicy(dataPolicy, onSuccess, onError) {
    baseFetch('POST', '/pages/data-policy/' + dataPolicy.attachment, undefined,
        data => {
            if (onSuccess) onSuccess(data)
        },
        onError
    );
}


export const pagesApiTest = {
    '/pages/main-image': {
        'GET': {
            [roles.ANONYMOUS]: {hasResponse: true},
            [roles.SITE_MEMBER]: {hasResponse: true},
            [roles.COUNCIL_ADMIN]: {hasResponse: true},
            [roles.CM_ADMIN]: {hasResponse: true},
            [roles.UPLOAD_ADMIN]: {hasResponse: true},
            [roles.ADMIN]: {hasResponse: true},
            [roles.SECURITY_OFFICER]: {hasResponse: true}
        },
    },
    '/pages/main-image/1': {
        'POST': {
            [roles.ANONYMOUS]: {hasResponse: false},
            [roles.SITE_MEMBER]: {hasResponse: false},
            [roles.COUNCIL_ADMIN]: {hasResponse: false},
            [roles.CM_ADMIN]: {hasResponse: false},
            [roles.UPLOAD_ADMIN]: {hasResponse: false},
            [roles.ADMIN]: {hasResponse: true},
            [roles.SECURITY_OFFICER]: {hasResponse: true}
        },
    },
    '/pages/actualities/10': {
        'GET': {
            [roles.ANONYMOUS]: {hasResponse: true},
            [roles.SITE_MEMBER]: {hasResponse: true},
            [roles.COUNCIL_ADMIN]: {hasResponse: true},
            [roles.CM_ADMIN]: {hasResponse: true},
            [roles.UPLOAD_ADMIN]: {hasResponse: true},
            [roles.ADMIN]: {hasResponse: true},
            [roles.SECURITY_OFFICER]: {hasResponse: true}
        },
    },
    '/pages/data-policy': {
        'GET': {
            [roles.ANONYMOUS]: {hasResponse: true},
            [roles.SITE_MEMBER]: {hasResponse: true},
            [roles.COUNCIL_ADMIN]: {hasResponse: true},
            [roles.CM_ADMIN]: {hasResponse: true},
            [roles.UPLOAD_ADMIN]: {hasResponse: true},
            [roles.ADMIN]: {hasResponse: true},
            [roles.SECURITY_OFFICER]: {hasResponse: true}
        },
    },
    '/pages/data-policy/1': {
        'POST': {
            [roles.ANONYMOUS]: {hasResponse: false },
            [roles.SITE_MEMBER]: {hasResponse: false},
            [roles.COUNCIL_ADMIN]: {hasResponse: false},
            [roles.CM_ADMIN]: {hasResponse: false},
            [roles.UPLOAD_ADMIN]: {hasResponse: false},
            [roles.ADMIN]: {hasResponse: true},
            [roles.SECURITY_OFFICER]: {hasResponse: true}
        },
    },
}

