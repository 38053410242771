export const meetingElementsHu = {
    "titleNav": "Testületi ülések anyagai",
    "title": "Napirendi pontok",
    "meetingElement": "Napirendi pont",
    "listOfMeetingElements": "Napirendi pontok listája",
    "add": "Napirendi pont hozzáadása",
    "edit": "Napirendi pont szerkesztése",
    "remove": "Napirendi pont törlése az összes ülésről",
    "delete": "Napirendi pont törlése az összes ülésről",
    "meeting": "Testületi ülés",
    "meetings": "Testületi ülések",
    "elementSeq": "Napirend",
    "elementSeq-invalid": "Sorszám csak számokat tartalmazhat.",
    "topic": "Tárgy",
    "presenter": "Előterjesztő",
    "presenterName": "Előterjesztő",
    "committee": "Tárgyalja",
    "committeeNames": "Tárgyalja",
    "documentNames": "Dokumentumok",
    "type": "Jelleg",
    "status": "Státusz",
    "public": "Publikus",
    "OPEN": "Nyílt",
    "CLOSED": "Zárt",
    "additionalMeetings": "További ülések",
    "INAUGURAL": "Alakuló ülés",
    "SCHEDULED": "Munkaterv szerinti",
    "EXTRAORDINARY": "Rendkivüli",
    "PUBLIC_HEARING": "Közmeghallgatás",
    "back": "Vissza",
    "save": "Napirendi pont mentése",
    "documents": "Dokumentumok",
    "negotiators": "Napirendi pont közvetitők",
    "true": "Igen",
    "false": "Nem",
    "actions": "Műveletek",
};