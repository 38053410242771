import {types as authTypes} from '../Lib/auth/AuthActions';
import {types as appTypes} from '../Data/Actions/AppActions';
import {build} from "../Lib/routing";
import {roles} from "./roles";
import {createPublicRouteConfig} from "../Lib/ContentManagement/util";

export function reducer(state, action) {

    switch (action.type) {

        case authTypes.AUTH_SESSION_ADD:
            return state.merge(build(state.get('routeConfig'), action.session.get('roles')));

        case authTypes.AUTH_SESSION_REMOVE:
            return state.merge(build(state.get('routeConfig'), [roles.ANONYMOUS]));

        case appTypes.CHANGE_LANGUAGE:
            return changeLanguage(state, action);

        case appTypes.CHANGE_THEME:
            return changeTheme(state, action);

        case appTypes.ADD_ISSUE_COLLECTOR:
            return addIssueCollector(state, action);

        default:
            return state;
    }

    function changeLanguage(state, action) {

        const routeConfig = createPublicRouteConfig(action.contents);
        const {navTree, routingMap} = build(routeConfig, action.roles);

        return state.withMutations(map => {
            map.set('navTree', navTree)
                .set('routingMap', routingMap)
                .set('language', action.lang)
        })
    }

    function changeTheme(state, action) {

        const highContrastTheme = state.get('highContrastTheme');

        localStorage.setItem('highContrastTheme', highContrastTheme ? '' : 'true');

        return state.update('highContrastTheme', prevVal => !prevVal);
    }

    function addIssueCollector(state, action) {

    }
}