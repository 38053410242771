import React, {memo, useContext} from "react";
import {useTranslation} from "react-i18next";
import useForm from "../../../Lib/form/useForm";
import ContentBox from "../../../Components/ContentBox/ContentBox";
import Grid from "@material-ui/core/Grid";
import Section from "../../../Lib/form/Components/Section/Section";
import ValidatedSubmit from "../../../Lib/form/Components/ValidatedSubmit/ValidatedSubmit";
import ValidatedInput from "../../../Lib/form/Components/ValidatedInput/ValidatedInput";
import ValidatedSelect from "../../../Lib/form/Components/ValidatedSelect/ValidatedSelect";
import {useOrganisations} from "../../../Api/organisations";
import {AccountRecord, AccountSchema} from "../../../Data/Account";
import {A, navigate} from "hookrouter";
import Button from "../../../Lib/material-kit/components/CustomButtons/Button";
import {Lock} from "@material-ui/icons";
import {passwordValidator} from "../Form";
import {makeStyles} from "@material-ui/core";
import accountStyle from "../accountStyle";
import ValidatedCheckbox from "../../../Lib/form/Components/ValidatedCheckbox/ValidatedCheckbox";
import {AuthContext} from "../../../Lib/auth/AuthContext";
import {hasRole} from "../../../Lib/auth/auth";
import {roles} from "../../../Main/roles";

const useStyles = makeStyles(accountStyle);

export default memo(({id, record, path, view, api}) => {

    const {t} = useTranslation('councilMembers');

    const classes = useStyles();

    const authContext = useContext(AuthContext);
    const showActive = hasRole(authContext, roles.ADMIN) || hasRole(authContext, roles.SECURITY_OFFICER);

    const form = useForm({
        recordType: AccountRecord,
        recordSchema: AccountSchema,
        namespace: 'councilMembers',
        record: record,
        disabledForm: view === 'remove',
    });

    const onCancel = () => navigate(`/council-members`);

    return (
        <ContentBox path={path} form={form}>
            <Grid container direction="row" justify="center">
                <Section title={t(view, {context: 'title'})}>
                    <ValidatedInput name='principalName'/>
                    <ValidatedInput name='emailAddress'/>
                    {view === "add" ?
                        <React.Fragment>
                            <ValidatedInput name="credentials" password autoComplete="new-password"/>
                            <ValidatedInput name="verification" password validator={passwordValidator}/>
                        </React.Fragment>
                        :
                        <div className={classes.buttonContainer}>
                            <A href={"/admin/accounts/reset/" + id}>
                                <Button color="primary" className={classes.smallButton}>
                                    <Lock className={classes.icons}/><span
                                    style={{marginLeft: 4}}>{t('password-change')}</span>
                                </Button>
                            </A>
                        </div>
                    }
                    <ValidatedInput name='fullName'/>
                    <ValidatedInput name='displayName'/>
                    <ValidatedSelect name="organisationId"
                                     label={t('organisation')}
                                     recordFieldName='name'
                                     api={useOrganisations}
                    />
                </Section>

                {!showActive ? "" :
                    <Section title={t("status")}>
                        <ValidatedCheckbox name="active" label={t('active')}/>
                    </Section>
                }

                <ValidatedSubmit view={view} api={api} onCancel={onCancel}/>
            </Grid>
        </ContentBox>
    );
})