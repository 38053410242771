import baseFetch, {useFetchOne} from "../../../baseFetch";
import {FileUploadPageRecord, FileUploadPageSchema} from "../Data/FileUploadPage";
import {roles} from "../../../../Main/roles";
import {contentStatusTest} from "../../../testing-tools/data-validation-tests";

export function useFileUploadPage(id, revision) {
    return useFetchOne('/file-upload-pages', id, FileUploadPageRecord, FileUploadPageSchema, revision);
}

export function getFileUploadPage(id, onSuccess, onError) {
    baseFetch('GET', '/file-upload-pages/' + id, undefined,
        data => {
            if (onSuccess) onSuccess(new FileUploadPageRecord(data));
        },
        onError
    );
}

export function updateFileUploadPage(fileUploadPage, onSuccess, onError) {
    baseFetch('PATCH', '/file-upload-pages/' + fileUploadPage.get('id'), fileUploadPage, onSuccess, onError);
}


export const fileUploadPagesApiTest = {
    '/file-upload-pages/1': {
        'GET': {
            [roles.ANONYMOUS]: {
                hasResponse: true,
                validate: contentStatusTest
            },
            [roles.SITE_MEMBER]: {
                hasResponse: true,
                validate: contentStatusTest
            },
            [roles.COUNCIL_ADMIN]: {
                hasResponse: true,
                validate: contentStatusTest
            },
            [roles.CM_ADMIN]: {hasResponse: true},
            [roles.UPLOAD_ADMIN]: {hasResponse: true},
            [roles.ADMIN]: {hasResponse: true},
            [roles.SECURITY_OFFICER]: {hasResponse: true}
        },
        'PATCH': {
            [roles.ANONYMOUS]: {hasResponse: false},
            [roles.SITE_MEMBER]: {hasResponse: false},
            [roles.COUNCIL_ADMIN]: {hasResponse: false,},
            [roles.CM_ADMIN]: {hasResponse: true},
            [roles.UPLOAD_ADMIN]: {hasResponse: true},
            [roles.ADMIN]: {hasResponse: true,},
            [roles.SECURITY_OFFICER]: {hasResponse: true}
        },
    },
}

