import baseFetch, {useFetchMany, useFetchOne} from "../Lib/baseFetch";
import {MeetingRecord, MeetingSchema} from "../Data/Meeting";
import {MeetingItemRecord} from "../Data/MeetingItem";
import {roles} from "../Main/roles";
import {allIsPublicTest} from "../Lib/testing-tools/data-validation-tests";
import {tanacsUrl} from "../globals";

export function useMeetings(filter) {
    return useFetchMany('/meetings', undefined, MeetingRecord, undefined, tanacsUrl);
}

export function useMeeting(meetingId) {
    return useFetchOne('/meetings', meetingId, MeetingRecord, MeetingSchema, undefined, tanacsUrl);
}

export function addMeeting(meeting, onSuccess, onError) {
    baseFetch('POST', '/meetings', meeting,
        data => {
            if (onSuccess) onSuccess(new MeetingRecord(data))
        },
        onError
    );
}

export function updateMeeting(meeting, onSuccess, onError) {
    baseFetch('PATCH', '/meetings/' + meeting.get('id'), meeting, onSuccess, onError);
}

export function removeMeeting(meeting, onSuccess, onError) {
    baseFetch('DELETE', '/meetings/' + meeting.get('id'), undefined, onSuccess, onError);
}

export function updateSequence(meetingId, elementId, direction, onSuccess, onError) {
    baseFetch('PATCH', '/meetings/' + meetingId + '/sequence/' + elementId + '/' + direction, undefined, onSuccess, onError);
}

export const meetingsApiTest = {
    '/meetings': {
        'GET': {
            [roles.ANONYMOUS]: {hasResponse: true},
            [roles.SITE_MEMBER]: {hasResponse: true},
            [roles.COUNCIL_ADMIN]: {hasResponse: true,},
            [roles.CM_ADMIN]: {hasResponse: true},
            [roles.UPLOAD_ADMIN]: {hasResponse: true},
            [roles.ADMIN]: {hasResponse: true,},
            [roles.SECURITY_OFFICER]: {hasResponse: true}
        },
        'POST': {
            [roles.ANONYMOUS]: {hasResponse: false},
            [roles.SITE_MEMBER]: {hasResponse: false},
            [roles.COUNCIL_ADMIN]: {hasResponse: true,},
            [roles.CM_ADMIN]: {hasResponse: false},
            [roles.UPLOAD_ADMIN]: {hasResponse: false},
            [roles.ADMIN]: {hasResponse: true,},
            [roles.SECURITY_OFFICER]: {hasResponse: true}
        },
    },
    '/meetings/1': {
        'GET': {
            [roles.ANONYMOUS]: {hasResponse: true},
            [roles.SITE_MEMBER]: {hasResponse: true},
            [roles.COUNCIL_ADMIN]: {hasResponse: true,},
            [roles.CM_ADMIN]: {hasResponse: true},
            [roles.UPLOAD_ADMIN]: {hasResponse: true},
            [roles.ADMIN]: {hasResponse: true,},
            [roles.SECURITY_OFFICER]: {hasResponse: true}
        },
        'PATCH': {
            [roles.ANONYMOUS]: {hasResponse: false},
            [roles.SITE_MEMBER]: {hasResponse: false},
            [roles.COUNCIL_ADMIN]: {hasResponse: true},
            [roles.CM_ADMIN]: {hasResponse: false},
            [roles.UPLOAD_ADMIN]: {hasResponse: false},
            [roles.ADMIN]: {hasResponse: true},
            [roles.SECURITY_OFFICER]: {hasResponse: true}
        },

    },
    '/meetings/2': {
        'DELETE': {
            [roles.ANONYMOUS]: {hasResponse: false},
            [roles.SITE_MEMBER]: {hasResponse: false},
            [roles.COUNCIL_ADMIN]: {hasResponse: true},
            [roles.CM_ADMIN]: {hasResponse: false},
            [roles.UPLOAD_ADMIN]: {hasResponse: false},
            [roles.ADMIN]: {hasResponse: true},
            [roles.SECURITY_OFFICER]: {hasResponse: true}
        },
    },
    '/meetings/1/get-meeting-elements': {
        'GET':{
            [roles.ANONYMOUS]: {
                hasResponse: true,
                validate: allIsPublicTest
            },
            [roles.SITE_MEMBER]: {
                hasResponse: true,
                validate: allIsPublicTest
            },
            [roles.COUNCIL_ADMIN]: {hasResponse: true},
            [roles.CM_ADMIN]:  {
                hasResponse: true,
                validate: allIsPublicTest
            },
            [roles.UPLOAD_ADMIN]:  {
                hasResponse: true,
                validate: allIsPublicTest
            },
            [roles.ADMIN]: {hasResponse: true},
            [roles.SECURITY_OFFICER]: {hasResponse: true}
        },
    },
    '/meetings/1/sequence/1/1': {
        'PATCH': {
            [roles.ANONYMOUS]: {hasResponse: false},
            [roles.SITE_MEMBER]: {hasResponse: false},
            [roles.COUNCIL_ADMIN]: {hasResponse: true},
            [roles.CM_ADMIN]: {hasResponse: false},
            [roles.UPLOAD_ADMIN]: {hasResponse: false},
            [roles.ADMIN]: {hasResponse: true},
            [roles.SECURITY_OFFICER]: {hasResponse: true}
        },
    },
}

