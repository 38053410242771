import {Record} from 'immutable';
import {number, object, string} from "yup";

export const NavColorRecord =  Record({
    id: 0,
    lineColor: '',
    fontColor: '',
});

export const NavColorSchema = object().shape({
    id: number().min(0).default(0),
    lineColor: string().required().matches(/^#(?:[0-9a-f]{3}){1,2}$/i).default(''),
    fontColor: string().required().matches(/^#(?:[0-9a-f]{3}){1,2}$/i).default(''),
});