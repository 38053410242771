export const dataRowStyle = theme => ({
    tableBodyRow: {
        minHeight: 42,
        '&:first-child': {
            height: 46,
            '& td': {
                paddingTop: 4,
            },
        },
    },
    cell: {
        minHeight: 42,
    },
    dataCell: {
        position: 'relative',
        minHeight: 42,
    },
    data: {
        minHeight: 42,
        backgroundColor: '#fff',
        padding: '0',
        width: 224,
        color: theme.palette.text.primary,
        position: 'relative',
        cursor: 'default',
    },
    dataBox: {
        boxShadow: theme.shadows[2],
        position: 'relative',
        justifyContent: 'center',
        fontSize: 14,
        display: 'flex',
        flexDirection: 'column',
        minHeight: 40,
        padding: '0 16px',
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: theme.palette.text.secondary,
        borderRadius: 3,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        cursor: 'pointer',
        '& p': {
            margin: 0,
            textOverflow: 'ellipsis',
            height: 20,
            width: 191,
        },
        '&:hover': {
            boxShadow: theme.shadows[5]
        },
    },
    dataBoxDisabled: {
        boxShadow: 'unset',
        opacity: .6,
        cursor: 'unset',
        '&:hover': {
            boxShadow: 'unset'
        },
        '& $dataBoxSecondaryRow': {
            color: theme.palette.text.primary,
        }
    },
    dataBoxSecondaryRow: {
        ...theme.typography.caption,
        fontSize: 12,
        color: theme.palette.text.secondary,
        textTransform: 'italic',
    },
    dataCellBranch: {
        '&:before': {
            content: '""',
            position: 'absolute',
            top: -50,
            bottom: 0,
            display: 'none',
            height: 95,
            width: 228,
            zIndex: -1,
        },
    },
    dataBranch: {
        '&:after': {
            content: '""',
            position: 'absolute',
            top: -55,
            left: -112,
            display: 'block',
            height: 76,
            width: 112,
            borderRadius: '0 0 0 4px',
            borderTop: 0,
            borderRight: 0,
            borderBottomWidth: 2,
            borderBottomStyle: 'solid',
            borderBottomColor: theme.palette.grey.light,
            borderLeftWidth: 2,
            borderLeftStyle: 'solid',
            borderLeftColor: theme.palette.grey.light,
            zIndex: -1,
        },
    },
    mainBranchOnSubTwo: {
        '&:before': {
            display: 'block',
            borderLeftWidth: 2,
            borderLeftStyle: 'solid',
            borderLeftColor: theme.palette.grey.light,
            left: -337,
        }
    },
    mainBranchOnSubThree: {
        '&:before': {
            display: 'block',
            borderLeftWidth: 2,
            borderLeftStyle: 'solid',
            borderLeftColor: theme.palette.grey.light,
            left: -563,
        }
    },
    mainAndSecondaryBranch: {
        '&:before': {
            display: 'block',
            left: -754,
            borderLeftWidth: 2,
            borderLeftStyle: 'solid',
            borderLeftColor: theme.palette.grey.light,
            borderRightWidth: 2,
            borderRightStyle: 'solid',
            borderRightColor: theme.palette.grey.light
        }
    },
    secondaryBranch: {
        '&:before': {
            display: 'block',
            left: -563,
            borderLeftWidth: 2,
            borderLeftStyle: 'solid',
            borderLeftColor: theme.palette.grey.light,
        }
    },
});