import React, {useContext, useState} from "react";
import ApiCalls from "./ApiCalls";
import ValidatedInput from "../form/Components/ValidatedInput/ValidatedInput";
import Grid from "@material-ui/core/Grid";
import ValidatedSubmit from "../form/Components/ValidatedSubmit/ValidatedSubmit";
import {assume, renounce} from "../auth/Api/session";
import FormProvider from "../form/Components/FormProvider/FormProvider";
import useForm from "../form/useForm";
import recordType from "../auth/Records/AuthenticationData";
import recordSchema from "../auth/Schemas/AuthenticationData";
import {AppContext} from "../../Main/AppContext";
import {useSnackbar} from "notistack";
import Actions from "../auth/AuthActions";
import {makeStyles} from "@material-ui/styles";
import loginStyle from "../../Views/Login/loginStyle";
import {isLoggedIn} from "../auth/auth";
import {AuthContext} from "../auth/AuthContext";

import Button from "../material-dashboard/components/CustomButtons/Button";
import {Typography} from "@material-ui/core";


const useStyles = makeStyles(loginStyle);

const LoginLogout = ({clear}) => {

    // stuff specific for login  ----------------------------------

    const appContext = useContext(AppContext);
    const dispatch = appContext.get('dispatch');

    const authContext = useContext(AuthContext);

    const [loggedIn, setLoggedIn] = useState(isLoggedIn(authContext));

    const {enqueueSnackbar} = useSnackbar();

    const onSuccess = (sessionData) => {
        dispatch(Actions.sessionAdd(sessionData));
        setLoggedIn(true);
        clear();
    };

    const onError = () => {
        enqueueSnackbar('Error', {variant: 'error'});
        setLoggedIn(false);
        clear();
    };

    // noinspection JSPotentiallyInvalidConstructorUsage
    const form = useForm({
        recordType: recordType,
        recordSchema: recordSchema,
        namespace: 'login',
        record: new recordType(recordSchema.default())
    });

    const classes = useStyles();

    const handleClick = () => {
        renounce(
            () => {
                dispatch(Actions.sessionRemove());
                setLoggedIn(false)
                clear()
            },
            () => {
                dispatch(Actions.sessionRemove());
                setLoggedIn(false)
                clear()
            });
    }

    return (
        <Grid item>
            <FormProvider form={form} formClassName={classes.form}>
                <Grid container justify="flex-start" alignContent="space-between">
                    <Grid item xs={12}>
                        <ValidatedInput name="principalName" disabled={loggedIn}/>
                    </Grid>
                    <Grid item xs={12}>
                        <ValidatedInput name="password" password disabled={loggedIn}/>
                    </Grid>
                    <Grid item xs={12} container justify="space-between">
                        <Grid item>
                            <ValidatedSubmit
                                api={assume}
                                hideCancel
                                submitLabel="Bejelentkezés"
                                onSuccess={(sessionData) => onSuccess(sessionData)}
                                onError={(error) => onError(error)}
                            />
                        </Grid>
                        <Grid item>
                            <Button color={"warning"}
                                    disabled={!loggedIn}
                                    onClick={handleClick}>
                                Kijelentkezés
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </FormProvider>
        </Grid>
    )
};

const TestUserList = () => {
    return (
        <Grid item container>
            <Grid item container>
                <Grid item xs={6}><Typography>site_member_01</Typography></Grid>
                <Grid item xs={6}><Typography>sitemember</Typography></Grid>
            </Grid>
            <Grid item container>
                <Grid item xs={6}><Typography>site_member_02</Typography></Grid>
                <Grid item xs={6}><Typography>sitemember</Typography></Grid>
            </Grid>
            <Grid item container>
                <Grid item xs={6}><Typography>admin_01</Typography></Grid>
                <Grid item xs={6}><Typography>admin123</Typography></Grid>
            </Grid>
            <Grid item container>
                <Grid item xs={6}><Typography>cc_admin_01</Typography></Grid>
                <Grid item xs={6}><Typography>admin123</Typography></Grid>
            </Grid>
            <Grid item container>
                <Grid item xs={6}><Typography>cm_admin_01</Typography></Grid>
                <Grid item xs={6}><Typography>admin123</Typography></Grid>
            </Grid>
            <Grid item container>
                <Grid item xs={6}><Typography>upload_admin_01</Typography></Grid>
                <Grid item xs={6}><Typography>admin123</Typography></Grid>
            </Grid>
            <Grid item container>
                <Grid item xs={6}><Typography>all_admin_01</Typography></Grid>
                <Grid item xs={6}><Typography>admin123</Typography></Grid>
            </Grid>
        </Grid>
    );
}

const TestPage = () => {

    const [run, setRun] = useState(false);

    const handleClick = () => setRun(true);

    const handleClear = () => setRun(false);

    return (
        <Grid container>
            <Grid container item xs={3} direction="column">
                <LoginLogout clear={handleClear}/>
                <Grid item>
                    <Button onClick={handleClick}
                            color="info">
                        Futtatás
                    </Button>
                </Grid>
                <TestUserList/>
            </Grid>
            {run && <ApiCalls/>}
        </Grid>
    )
}

export default TestPage;