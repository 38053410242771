import React, {memo} from "react";
import {useTranslation} from "react-i18next";
import useForm from "../../Lib/form/useForm";
import {DecisionRecord, DecisionSchema} from '../../Data/Decision';
import ContentBox from "../../Components/ContentBox/ContentBox";
import Section from "../../Lib/form/Components/Section/Section";
import ValidatedSubmit from "../../Lib/form/Components/ValidatedSubmit/ValidatedSubmit";
import debug from "../../Lib/debug";
import ValidatedInput from "../../Lib/form/Components/ValidatedInput/ValidatedInput";
import ValidatedRadio from "../../Lib/form/Components/ValidatedRadio/ValidatedRadio";
import ValidatedRadioGroup from "../../Lib/form/Components/ValidatedRadio/ValidatedRadioGroup";
import AttachmentUpload from "../../Components/AttachmentUpload/AttachmentUpload";
import ValidatedSelect from "../../Lib/form/Components/ValidatedSelect/ValidatedSelect";
import {useMeetings} from "../../Api/meetings";
import {navigate} from "hookrouter";
import {getAttachments} from "../../Api/decisions";
import ValidatedRichTextEditor from "../../Lib/form/Components/ValidatedRichTextEditor/ValidatedRichTextEditor";


export default memo(({id, record, path, view, api}) => {

    debug("RENDER", "Decision", () => ["Form", id, record, path, view, api]);

    const {t} = useTranslation('decisions');

    const form = useForm({
        disabledForm: view === 'remove',
        recordType: DecisionRecord,
        recordSchema: DecisionSchema,
        namespace: 'decisions',
        record: record
    });

    const onCancel = () => navigate(`/decisions`);
    const onSuccess = () => navigate(`/decisions`);

    return (
        <ContentBox path={path} form={form}>
            <Section title={t(view)}>
                <ValidatedSelect name='meetingId'
                                 api={useMeetings}
                                 customItem={(record) => `${record.date} ${record.time}, ${record.committeeName}`}
                />
                <ValidatedInput name="numberOfDecision"/>
                <ValidatedInput name="title"/>
                <ValidatedInput name="presenterName"/>
                <ValidatedRichTextEditor name='decisionText'
                                         showLabel={true}
                />
                <ValidatedRadioGroup name="type">
                    <ValidatedRadio name="type" value="OPEN"/>
                    <ValidatedRadio name="type" value="CLOSED"/>
                </ValidatedRadioGroup>
                <AttachmentUpload id={record.id}
                                  ns='decisions'
                                  name='attachments'
                                  attachments={record.attachments}
                                  multiple={true}
                                  api={getAttachments}
                                  apiPath={'/attachments'}/>
            </Section>
            <ValidatedSubmit view={view} api={api} onSuccess={onSuccess} onCancel={onCancel}/>
        </ContentBox>
    );
});