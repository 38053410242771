import {createMuiTheme} from '@material-ui/core/styles';
import responsiveFontSizes from "@material-ui/core/styles/responsiveFontSizes";

export const theme = responsiveFontSizes(createMuiTheme({
        typography: {
            fontFamily: ['Gotham'].join(','),
        },
        palette: {
            primary: {
                light: '#35baf6',
                main: '#03a9f4',
                dark: '#0276aa',
                contrastText: '#000',
            },
            secondary: {
                light: '#33ab9f',
                main: '#009688',
                dark: '#00695f',
                contrastText: '#fff',
            },
            error: {
                light: '#f6685e',
                main: '#f44336',
                dark: '#aa2e25',
                contrastText: '#fff',
            },
            warning: {
                light: '#ffac33',
                main: '#ff9800',
                dark: '#b26a00',
                contrastText: '#fff',
            },
            info: {
                light: '#4dabf5',
                main: '#2196f3',
                dark: '#1769aa',
                contrastText: '#fff',
            },
            success: {
                light: '#6fbf73',
                main: '#4caf50',
                dark: '#357a38',
                contrastText: '#000',
            },
            grey: {
                light: '#b1b1b1',
                main: '#9e9e9e',
                dark: '#6e6e6e',
                contrastText: '#000',
            },
            white: '#fff',
            transparentWhite: 'rgba(255, 255, 255, .7)',
            transparentBlack: 'rgba(0, 0, 0, .8)',
            text: {
                primary: '#404040',
                secondary: '#90A4AE',
                white: '#fff',
                footer: '#fff'
            },
            kolorcity: [
                '#e91e63',          // 0
                '#9c27b0',          // 1
                '#673ab7',          // 2
                '#3f51b5',          // 3
                '#2196f3',          // 4
                '#03a9f4',          // 5
                '#00bcd4',          // 6
                '#009688',          // 7
                '#4caf50',          // 8
                '#8bc34a',          // 9
                '#cddc39',          // 10
                '#ffeb3b',          // 11
                '#ffc107',          // 12
                '#ff9800',          // 13
                '#ff5722',          // 14
                '#f44336',          // 15
            ],
            kolorcity_alt: {
                green: {
                    light: '#10ff12',
                    dark: '#05b31e',
                    overlay: '#fff',
                },
                blue: {
                    light: '#00E5FF',
                    dark: '#00A1B3',
                    overlay: '#fff',
                },
                red: {
                    light: '#FF4081',
                    dark: '#B32D59',
                    overlay: '#fff',
                },
                grey: {
                    light: '#90A4AE',
                    dark: '#505B61',
                    overlay: '#fff',
                },
                yellow: {
                    light: '#FFD740',
                    dark: '#B3952D',
                    overlay: '#fff',
                },
                overlay: 'rgba(255, 255, 255, .8)',
            },
        },
        status: {},
        gridWidths: [
            '0%',
            '8.333333%',
            '16.666667%',
            '25%',
            '33.333333%',
            '41.666667%',
            '50%',
            '58.333333%',
            '66.666667%',
            '75%',
            '83.333333%',
            '91.666667%',
            '100%',
        ],
        footerColors: {
            backgroundColor: '#00E5FF',
        },
        imgFluid: {
            maxWidth: '100%',
            height: 'auto',
        },
        // overrides: {
        //     MuiCssBaseline: {
        //         root: {
        //             fontFamily: 'Gotham',
        //         }
        //     },
        // },
    }),
    {breakpoints: ['xs', 'sm', 'md', 'lg', 'xl']}
);

export const highContrastTheme = responsiveFontSizes(createMuiTheme({
        typography: {
            fontFamily: ['Gotham'].join(','),
        },
        palette: {
            common: {
                white: '#000',
                black: '#FFFF00',
            },
            primary: {
                light: '#FFFF00',
                main: '#FFFF00',
                dark: '#FFFF00',
                contrastText: '#000',
            },
            secondary: {
                light: '#FFFF00',
                main: '#FFFF00',
                dark: '#FFFF00',
                contrastText: '#000',
            },
            error: {
                light: '#FFFF00',
                main: '#FFFF00',
                dark: '#FFFF00',
                contrastText: '#000',
            },
            warning: {
                light: '#FFFF00',
                main: '#FFFF00',
                dark: '#FFFF00',
                contrastText: '#000',
            },
            info: {
                light: '#FFFF00',
                main: '#FFFF00',
                dark: '#FFFF00',
                contrastText: '#000',
            },
            success: {
                light: '#FFFF00',
                main: '#FFFF00',
                dark: '#FFFF00',
                contrastText: '#000',
            },
            grey: {
                light: '#FFFF00',
                main: '#FFFF00',
                dark: '#FFFF00',
                contrastText: '#000',
            },
            white: '#000',
            transparentWhite: '#000',
            transparentBlack: '#FFFF00',
            background: {
                paper: '#000',
                default: '#000',
            },
            text: {
                primary: '#FFFF00',
                secondary: '#FFFF00',
                white: '#000',
                footer: '#FFFF00',
            },
            kolorcity: [
                '#e91e63',          // 0
                '#9c27b0',          // 1
                '#673ab7',          // 2
                '#3f51b5',          // 3
                '#2196f3',          // 4
                '#03a9f4',          // 5
                '#00bcd4',          // 6
                '#009688',          // 7
                '#4caf50',          // 8
                '#8bc34a',          // 9
                '#cddc39',          // 10
                '#ffeb3b',          // 11
                '#ffc107',          // 12
                '#ff9800',          // 13
                '#ff5722',          // 14
                '#f44336',          // 15
            ],
            kolorcity_alt: {
                green: {
                    light: '#FFFF00',
                    dark: '#FFFF00',
                    overlay: '#000',
                },
                blue: {
                    light: '#FFFF00',
                    dark: '#FFFF00',
                    overlay: '#000',
                },
                red: {
                    light: '#FFFF00',
                    dark: '#FFFF00',
                    overlay: '#000',
                },
                grey: {
                    light: '#FFFF00',
                    dark: '#FFFF00',
                    overlay: '#000',
                },
                yellow: {
                    light: '#FFFF00',
                    dark: '#FFFF00',
                    overlay: '#000',
                },
                overlay: 'rgba(255, 255, 255, .8)',
            },
        },
        status: {},
        gridWidths: [
            '0%',
            '8.333333%',
            '16.666667%',
            '25%',
            '33.333333%',
            '41.666667%',
            '50%',
            '58.333333%',
            '66.666667%',
            '75%',
            '83.333333%',
            '91.666667%',
            '100%',
        ],
        footerColors: {
            backgroundColor: '#000',
            color: '#FFFF00',
            borderTopWidth: 1,
            borderTopStyle: 'solid',
            borderTopColor: '#FFFF00',
        },
        imgFluid: {
            maxWidth: '100%',
            height: 'auto',
        },
        // overrides: {
        //     MuiCssBaseline: {
        //         root: {
        //             fontFamily: 'Gotham',
        //         }
        //     },
        // },
    }),
    {breakpoints: ['xs', 'sm', 'md', 'lg', 'xl']}
);