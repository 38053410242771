export const attachmentListField = (accessor, record, tableStorage) => {

    const {styles} = tableStorage.tableConfig;

    const customListClasses = styles && styles.customList ? ` class="${styles.customList}" ` : ``;
    const customLinkClasses = styles && styles.customLink ? ` class="${styles.customLink}" ` : ``;

    const files = record.attachments.map(file => {
        return `<li><a href=/api/1.0.0/attachments/${file.id} ${customLinkClasses}>${file.name}</a></li>`
    })
        .join('');

    return `<ul ${customListClasses}>${files}</ul>`
};