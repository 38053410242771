export const designEditorStyle = theme => ({
    colorSetContainer: {
        margin: '8px 0',
    },
    colorPickerFormControl: {
        paddingTop: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '& label': {
            display: 'none',
        },
        '& p': {
            display: 'none',
        },
        '& input': {
            textTransform: 'uppercase',
        },
    },
    colorPickerForm: {
        width: '100%',
    },
    colorPickerFormGrid: {
        padding: '0 8px',
    },
    header: {
        color: theme.palette.text.primary,
        margin: '8px 0',
    },
    panel: {
        paddingTop: '1.5rem',
    }
});