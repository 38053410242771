import {useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";

export const useLanguageRevision = () => {

    const [revision, setRevision] = useState(0);

    const {i18n} = useTranslation();

    const lang = useRef(i18n.language);

    useEffect(() => {
        if (i18n.language !== lang.current) {
            setRevision(prev => prev + 1);
            lang.current = i18n.language;
        }
    }, [i18n.language]);

    return revision;
};