import React from "react";
import ValidatedRadioGroup from "../../../form/Components/ValidatedRadio/ValidatedRadioGroup";
import ValidatedRadio from "../../../form/Components/ValidatedRadio/ValidatedRadio";

const StatusRadios = () => {
    return (
        <React.Fragment>
            <ValidatedRadioGroup name='status'>
                <ValidatedRadio name="status" value="HIDDEN"/>
                <ValidatedRadio name="status" value="PUBLIC"/>
                <ValidatedRadio name="status" value="ACTUALITY"/>
            </ValidatedRadioGroup>
        </React.Fragment>
    )
};

export default StatusRadios;