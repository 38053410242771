import makeStyles from "@material-ui/core/styles/makeStyles";
import React from "react";

const useStyles = makeStyles({
    row: {
        display: 'contents',
        '&:nth-child(even) $grid': {
            backgroundColor: '#f8f6ff'
        }
    },
    grid: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        padding: 4
    }
})

const ListRow = ({result}) => {

    const classes = useStyles();

    const {role, method, url, status, statusTest, dataTest} = result;

    const warningGridStyle = !(statusTest && dataTest.test) ? {
            backgroundColor: '#ffb8b6',
        } :
        {};

    return (
        <div className={classes.row}>
            <div className={classes.grid} style={warningGridStyle}>
                {role}
            </div>
            <div className={classes.grid} style={warningGridStyle}>
                {method}
            </div>
            <div className={classes.grid} style={warningGridStyle}>
                {url}
            </div>
            <div className={classes.grid} style={warningGridStyle}>
                {status}
            </div>
            <div className={classes.grid} style={warningGridStyle}>
                {statusTest ? 'OK' : 'Wrong status'}
            </div>
            <div className={classes.grid} style={warningGridStyle}>
                {dataTest.msg}
            </div>
        </div>
    )
};

export default ListRow;