import makeStyles from "@material-ui/styles/makeStyles";
import React from "react";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import {successColor} from "../Lib/material-kit/assets/jss/material-kit-pro-react";
import {useTranslation} from "react-i18next";


const useStyles = makeStyles(theme => ({
    container: {
        marginBottom: '1rem',
        marginTop: '1rem',
        display: "flex",
        flexDirection: "row",
        alignItems: 'center'
    },
    text: {
        color: successColor[0],
        fontWeight: 600,
        fontSize: '1.1rem'
    }
}));

export default function FilteringByEnum(props) {

    const {arrayValue, name} = props;
    const classes = useStyles();
    const {t} = useTranslation("general")


    return (
        <div className={classes.container}>
            <InputLabel className={classes.text} shrink={true}>{t(name)}</InputLabel>
            <Select className={classes.text} style={{width: '12rem'}} value={props.enum}
                    onChange={(event) => props.onSelected(event.target.value)}>
                {arrayValue.map(enumValue =>
                    <MenuItem
                        key={enumValue}
                        value={enumValue}>
                        {enumValue ? t(enumValue) : <br/>}
                    </MenuItem>
                )}
            </Select>
        </div>
    );
}