import React, {memo} from "react";
import {useTranslation} from "react-i18next";
import useForm from "../../Lib/form/useForm";
import {TemplateRecord, TemplateSchema} from '../../Data/Template';
import ContentBox from "../../Components/ContentBox/ContentBox";
import Section from "../../Lib/form/Components/Section/Section";
import debug from "../../Lib/debug";
import ValidatedInput from "../../Lib/form/Components/ValidatedInput/ValidatedInput";
import {navigate} from "hookrouter";
import ValidatedRichTextEditor from "../../Lib/form/Components/ValidatedRichTextEditor/ValidatedRichTextEditor";
import ValidatedSubmit from "../../Lib/form/Components/ValidatedSubmit/ValidatedSubmit";

export default memo(({id, record, path, view, api}) => {

    debug("RENDER", "Notification", () => ["Form", id, record, path, view, api]);

    const {t} = useTranslation('notifications');

    const form = useForm({
        disabledForm: view === 'remove',
        recordType: TemplateRecord,
        recordSchema: TemplateSchema,
        namespace: 'notifications',
        record: record
    });

    const onSuccess = () => navigate('/admin/notifications');
    const onCancel = () => navigate('/admin/notifications');

    return (
        <ContentBox path={path} form={form}>
            <Section title={t('edit')}>
                <ValidatedInput name="name" disabled/>
                <ValidatedInput name="title"/>
                <ValidatedRichTextEditor name={"content"} label={t("content")}/>
            </Section>
            <ValidatedSubmit view={view}
                             api={api}
                             onSuccess={onSuccess}
                             onCancel={onCancel}/>
        </ContentBox>
    );
});