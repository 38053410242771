import {Record} from 'immutable';
import {date, mixed, number, object, string} from "yup";

export const MeetingRecord = Record({
    id: 0,
    date: '',
    time: '',
    type: '',
    place: '',
    committee: 0,
    committeeName: '',
    numberOfElements: 0,
    meetingRecord: null
});

export const MeetingSchema = object().shape({
    id: number().min(0).default(0),
    date: date().required().default(''),
    time:  string().required().matches(/^$|^(([01][0-9])|(2[0-3])):[0-5][0-9]$/).length(5).default(''), //todo fix me
    type:  mixed().required().oneOf(['SCHEDULED', 'INAUGURAL', 'EXTRAORDINARY', 'PUBLIC_HEARING']),
    place: string().default(''),
    committee: number().required().min(1).default(0),
    committeeName: string().nullable().default(''),
    numberOfElements: number().min(0).default(0),
    meetingRecord: number().min(0).nullable().default(null),
});
