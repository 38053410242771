import React, {useCallback, useReducer} from 'react';
import {reducer as authReducer} from "../Lib/auth/Stores/AuthStore";
import {reducer as appReducer} from "./AppStore";
import {reducer as headerReducer} from "../Data/Stores/HeaderStore";
import {SnackbarProvider} from "notistack";

import "../Lib/material-dashboard/assets/scss/material-dashboard-pro-react.scss?v=1.7.0";

import {AppContext} from "./AppContext";
import {AuthContext} from "../Lib/auth/AuthContext";
import Immutable from "immutable";

import ThemeProvider from "@material-ui/styles/ThemeProvider";
import {highContrastTheme, theme} from "./Theme";
import CssBaseline from "@material-ui/core/CssBaseline";
import {navigate, useRoutes} from "hookrouter";

let appContext = Immutable.Map();

function App({authStoreInit, appStoreInit}) {

    const [authStore, authDispatch] = useReducer(authReducer, authStoreInit);

    const [appStore, appDispatch] = useReducer(appReducer, appStoreInit);

    const [headerStore, headerDispatch] = useReducer(headerReducer, Immutable.Map());

    const dispatch = useCallback((action) => {
        appDispatch(action);
        authDispatch(action);
        headerDispatch(action);
    }, [appDispatch, authDispatch, headerDispatch]);

    appContext = appContext.merge({
        appStore: appStore,
        headerStore: headerStore,
        dispatch: dispatch,
    });

    const content = useRoutes(appStore.get('routingMap'));

    if (!content) {
        navigate('/');
        // noinspection JSConstructorReturnsPrimitive
        return null;
    }

    return (
        <AuthContext.Provider value={authStore}>
            <AppContext.Provider value={appContext}>
                <ThemeProvider theme={appStore.get('highContrastTheme') ? highContrastTheme : theme}>
                    <CssBaseline>
                        <SnackbarProvider autoHideDuration={800}
                                          anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}>
                            {content}
                        </SnackbarProvider>
                    </CssBaseline>
                </ThemeProvider>
            </AppContext.Provider>
        </AuthContext.Provider>
    );
}

export default App;
