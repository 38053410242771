import React from "react";
import {createRows, getAll} from "../util";

import {searchApiTest} from "../../../Api/search";

const calls = [
    onResponse => getAll("/search/anything", onResponse),
    onResponse => getAll("/stored-files", onResponse),
];

const SearchApis = () => {

    return (
        <React.Fragment>
            {createRows(calls, searchApiTest)}
        </React.Fragment>
    );
}

export default SearchApis;