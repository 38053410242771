export const contentStyle = theme => ({
    navItemInnerContainer: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        boxShadow: theme.shadows[4],
        width: '100%',
        height: 54,
    },
    navItem: {
        height: 54,
        padding: '8px 0',
        width: '100%',
    },
    navLink: {
        height: '100%',
        width: '100%',
        textDecoration: 'unset',
        color: theme.palette.text.secondary,
        backgroundColor: theme.palette.text.white,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, .8)',
        },
        '&:active': {
            color: theme.palette.text.white,
            backgroundColor: 'rgba(0, 0, 0, .2)',
        }
    },
    navLinkText: {
        padding: '0 16px',
        fontWeight: 'bold',
        textTransform: 'uppercase',
    },
});
