import React, {memo, useEffect, useState} from "react";
import {textPageStyle as style} from "./textPageStyle";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Hidden from "@material-ui/core/Hidden";
import {getAttachments, useTextPage} from "../../Lib/ContentManagement/ContentEditor/Api/textPages";
import useNav from "../../Lib/useNav";
import ContentBox from "../../Components/ContentBox/ContentBox";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import {makeStyles} from "@material-ui/core";
import List from "@material-ui/core/List";
import {useTranslation} from "react-i18next";
import {useLanguageRevision} from "../../Lib/useLanguageRevision";
import moment from "moment";
import AnchorTag from "../../Components/AnchorTag/AnchorTag";

const useStyles = makeStyles(style);

const AttachmentList = ({pageData}) => {

    const classes = useStyles();

    const {t} = useTranslation('general');

    const [attachments, setAttachments] = useState([]);

    useEffect(() => {
        if (pageData.attachments) {
            getAttachments(pageData.id, data => {
                const listItems = data.map(item => <Attachment key={item.id} item={item}/>);
                setAttachments(listItems);
            });
        }
    }, [pageData.attachments, pageData.id]);

    return (
        <React.Fragment>
            {attachments.length ?
                <Grid item className={classes.attachmentsBox}>
                    <Typography variant={"body1"}
                                className={classes.attachmentBoxTitle}>
                        {t('downloadMaterials')}
                    </Typography>
                    <List>
                        {attachments.length && attachments}
                    </List>
                </Grid> :
                null
            }
        </React.Fragment>
    );
};

const Attachment = ({item}) => {

    const classes = useStyles();

    return (
        <React.Fragment>
            <ListItem>
                <ListItemText
                    primary={
                        <a href={`/api/1.0.0/attachments/${item.id}`} rel='noopener noreferrer' target='_blank'
                           className={classes.attachment}>
                            {item.name}
                        </a>
                    }
                />
            </ListItem>
        </React.Fragment>
    );
};

const TagsBox = ({tags}) => {

    const classes = useStyles();
    const {t} = useTranslation('general');

    let chips = [];

    if (tags) {
        tags.split(',').forEach(tag => {
            chips.push(<AnchorTag tag={tag} key={tag}/>)
        })
    }

    return (
        <Grid item className={classes.tagContainer}>
            <Typography variant="body1" className={classes.tagText}>
                {t('tags')}
            </Typography>
            {chips}
        </Grid>
    );
};

const TextPageContent = ({record}) => {
    const classes = useStyles();

    return (
        <Grid container
              className={classes.textPage}
              component='div'>
            <Grid item container
                  xs={12}
                  component='div'
                  className={classes.header}>
                <Grid item xs={12} sm={9} component='div'>
                    <Typography variant='h4'
                                className={classes.title}>
                        {record.title}
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <Typography variant='subtitle1'
                                className={classes.subtitle}>
                        {moment(record.createdAt).format('YYYY.MM.DD.')}
                    </Typography>
                </Grid>
            </Grid>
            <Grid item container>
                <Grid xs={12} md={3} item component='div'>
                    <Grid item className={classes.imageContainer}>
                        <img className={classes.image} src={`/api/1.0.0/images/${record.picture}`}
                             alt={record.altText}/>
                    </Grid>
                    <Hidden smDown>
                        <TagsBox tags={record.tags}/>
                        <AttachmentList pageData={record}/>
                    </Hidden>
                </Grid>
                <Grid item xs={12} md={9}>
                    {record.description &&
                        <Box className={classes.description + " ql-display"} dangerouslySetInnerHTML={{__html: record.description}}/>
                    }
                    <Box className={classes.content + " ql-display"} dangerouslySetInnerHTML={{__html: record.textContent}}/>
                </Grid>
                <Grid item>
                    <Hidden mdUp>
                        <TagsBox tags={record.tags}/>
                        <AttachmentList pageData={record}/>
                    </Hidden>
                </Grid>
            </Grid>
        </Grid>
    );
};

const TextPage = memo(({pageId, path, blockItem}) => {

    let url = window.location.pathname;

    useNav({path: url});

    const revision = useLanguageRevision();

    const {data, loadingStatus} = useTextPage(pageId, revision);

    if (loadingStatus) return null;

    return (
        <React.Fragment>
            {blockItem ?
                <TextPageContent record={data}/> :
                <ContentBox publicPage={true} path={path}>
                    <TextPageContent record={data}/>
                </ContentBox>
            }
        </React.Fragment>
    );
});

export default TextPage;