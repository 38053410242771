export const meetingsHu = {
    "titleNav": "Testületi ülések",
    "INAUGURAL": "Alakuló ülés",
    "SCHEDULED": "Munkaterv szerinti",
    "EXTRAORDINARY": "Rendkivüli",
    "PUBLIC_HEARING": "Közmeghallgatás",
    "title": "Testületi ülések",
    "meeting": "Testületi ülés",
    "meetings": "Testületi és bizottsági ülések",
    "add": "Testületi ülés hozzáadása",
    "edit": "Testületi ülés szerkesztése",
    "remove": "Testületi ülés törlése",
    "delete": "Testületi ülés törlése",
    "date": "Ülés dátuma",
    "time": "Ülés időpontja",
    "type": "Ülés típusa",
    "committee": "Tárgyalja",
    "committeeName": "Bizottság",
    "numberOfElements": "Előterjesztések száma",
    "PLANNED": "Munkaterv szerinti",
    "EXTRA": "Rendkívüli",
    "back": "Vissza",
    "save": "Testületi ülés mentése",
    "actions": "Műveletek",
    "addMeetingElements": "Előterjesztések hozzáadása",
    "meetingElements": "Előterjesztések",
    "uploadMeetingRecord": "Jegyzőkönyv feltöltése",
};