import baseFetch, {useFetchMany, useFetchOne} from "../Lib/baseFetch";
import {AccountRecord, AccountSchema} from "../Data/Account";
import {PositionRecord} from "../Data/Position";
import {roles} from "../Main/roles";
import {
    getPersonalDataTest,
    getPersonalOrSiteMemberData,
    getSiteMembersTest
} from "../Lib/testing-tools/data-validation-tests";

export function useAccounts() {
    return useFetchMany('/accounts', undefined, AccountRecord);
}

export function useAccount(id) {
    return useFetchOne('/accounts', id, AccountRecord, AccountSchema);
}

export function usePositionsByAccount(accountId) {
    return useFetchMany('/accounts/' + accountId + '/positions', undefined, PositionRecord);
}

export function addAccount(account, onSuccess, onError) {
    baseFetch('POST', '/accounts', account,
        data => {
            if (onSuccess) onSuccess(new AccountRecord(data))
        },
        onError
    );
}

export function updateAccount(account, onSuccess, onError) {
    baseFetch('PATCH', '/accounts/' + account.get('id'), account, onSuccess, onError);
}

export function removeAccount(account, onSuccess, onError) {
    baseFetch('DELETE', '/accounts/' + account.get('id'), undefined, onSuccess, onError);
}

export function activateAccount(id, ak, onSuccess, onError) {
    baseFetch('GET', '/accounts/' + id + '/activation-keys/' + ak, undefined,
        onSuccess,
        onError
    );
}

export function enableReset(reset, onSuccess, onError) {
    baseFetch('PATCH', '/accounts/reset', reset,
        onSuccess,
        onError
    );
}

export function resetCredentials(id, credentials, onSuccess, onError) {
    baseFetch('PATCH', '/accounts/' + id + '/reset', credentials,
        onSuccess,
        onError
    );
}

export function updateCredentials(id, credentials, onSuccess, onError) {
    baseFetch('PATCH', '/accounts/' + id + '/credentials', credentials,
        onSuccess,
        onError
    );
}

export const accountApiTest = {
    '/accounts': {
        'GET': {
            [roles.ANONYMOUS]: {hasResponse: false},
            [roles.SITE_MEMBER]: {hasResponse: false},
            [roles.COUNCIL_ADMIN]: {
                hasResponse: true,
                validate: getSiteMembersTest
            },
            [roles.CM_ADMIN]: {hasResponse: false},
            [roles.UPLOAD_ADMIN]: {hasResponse: false},
            [roles.ADMIN]: {hasResponse: true},
            [roles.SECURITY_OFFICER]: {hasResponse: true}
        },
        'POST': {
            [roles.ANONYMOUS]: {hasResponse: false},
            [roles.SITE_MEMBER]: {hasResponse: false},
            [roles.COUNCIL_ADMIN]: {hasResponse: true,},
            [roles.CM_ADMIN]: {hasResponse: false},
            [roles.UPLOAD_ADMIN]: {hasResponse: false},
            [roles.ADMIN]: {hasResponse: true,},
            [roles.SECURITY_OFFICER]: {hasResponse: true}
        },
    },
    '/accounts/1': {
        'GET': {
            [roles.ANONYMOUS]: {hasResponse: false},
            [roles.SITE_MEMBER]: {
                hasResponse: true,
                validate: getPersonalDataTest
            },
            [roles.COUNCIL_ADMIN]: {
                hasResponse: true,
                validate: getPersonalOrSiteMemberData
            },
            [roles.CM_ADMIN]: {
                hasResponse: true,
                validate: getPersonalDataTest
            },
            [roles.UPLOAD_ADMIN]: {
                hasResponse: true,
                validate: getPersonalDataTest
            },
            [roles.ADMIN]: {hasResponse: true},
            [roles.SECURITY_OFFICER]: {hasResponse: true}
        },
        'PATCH': {
            [roles.ANONYMOUS]: {hasResponse: false},
            [roles.SITE_MEMBER]: {hasResponse: false},
            [roles.COUNCIL_ADMIN]: {
                hasResponse: true,
                validate: getPersonalOrSiteMemberData
            },
            [roles.CM_ADMIN]: {hasResponse: false},
            [roles.UPLOAD_ADMIN]: {hasResponse: false},
            [roles.ADMIN]: {hasResponse: true},
            [roles.SECURITY_OFFICER]: {hasResponse: true}
        },
    },
    '/accounts/2': {
        'DELETE': {
            [roles.ANONYMOUS]: {hasResponse: false},
            [roles.SITE_MEMBER]: {hasResponse: false},
            [roles.COUNCIL_ADMIN]: {hasResponse: true},
            [roles.CM_ADMIN]: {hasResponse: false},
            [roles.UPLOAD_ADMIN]: {hasResponse: false},
            [roles.ADMIN]: {hasResponse: true},
            [roles.SECURITY_OFFICER]: {hasResponse: true}
        },
    },
    '/accounts/1/credentials': {
        'PATCH': {
            [roles.ANONYMOUS]: {hasResponse: false},
            [roles.SITE_MEMBER]: {hasResponse: false},
            [roles.COUNCIL_ADMIN]: {hasResponse: false},
            [roles.CM_ADMIN]: {hasResponse: false},
            [roles.UPLOAD_ADMIN]: {hasResponse: false},
            [roles.ADMIN]: {hasResponse: false},
            [roles.SECURITY_OFFICER]: {hasResponse: false}
        }
    },
    '/accounts/1/positions': {
        'GET': {
            [roles.ANONYMOUS]: {hasResponse: false},
            [roles.SITE_MEMBER]: {hasResponse: true},
            [roles.COUNCIL_ADMIN]: {hasResponse: true},
            [roles.CM_ADMIN]: {hasResponse: true},
            [roles.UPLOAD_ADMIN]: {hasResponse: true},
            [roles.ADMIN]: {hasResponse: true},
            [roles.SECURITY_OFFICER]: {hasResponse: true}
        }
    },
    "/accounts/1/reset": { // this will fail every time because it needs the reset key
        'PATCH': {
            [roles.ANONYMOUS]: {hasResponse: false},
            [roles.SITE_MEMBER]: {hasResponse: false},
            [roles.COUNCIL_ADMIN]: {hasResponse: false},
            [roles.CM_ADMIN]: {hasResponse: false},
            [roles.UPLOAD_ADMIN]: {hasResponse: false},
            [roles.ADMIN]: {hasResponse: false},
            [roles.SECURITY_OFFICER]: {hasResponse: false}
        },
    },
    '/accounts/1/activation-keys/b5ecae2bf95ea720': {
        'GET': {
            [roles.ANONYMOUS]: {hasResponse: true},
            [roles.SITE_MEMBER]: {hasResponse: true},
            [roles.COUNCIL_ADMIN]: {hasResponse: true},
            [roles.CM_ADMIN]: {hasResponse: true},
            [roles.UPLOAD_ADMIN]: {hasResponse: true},
            [roles.ADMIN]: {hasResponse: true},
            [roles.SECURITY_OFFICER]: {hasResponse: true}
        }
    },
    "/accounts/reset": {
        'PATCH': {
            [roles.ANONYMOUS]: {hasResponse: true},
            [roles.SITE_MEMBER]: {hasResponse: true},
            [roles.COUNCIL_ADMIN]: {hasResponse: true},
            [roles.CM_ADMIN]: {hasResponse: true},
            [roles.UPLOAD_ADMIN]: {hasResponse: true},
            [roles.ADMIN]: {hasResponse: true},
            [roles.SECURITY_OFFICER]: {hasResponse: true}
        },
    },
}
