import React from "react";
import Datetime from "react-datetime";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import {makeStyles} from "@material-ui/styles";
import FormHelperText from "@material-ui/core/FormHelperText";
import classNames from "classnames";

import customInputStyle from "../../../material-dashboard/assets/jss/components/customInputStyle.jsx";
import {useFormField} from "../../useFormField";

const useStyles = makeStyles(customInputStyle);

const onChange = (ff, value) => {
    if (!value || !value.isValid) {
        ff.onChange(value);
        return;
    }

    if ( ! value.isValid()) {
        ff.onChange(value);
        return;
    }

    ff.onChange(value.format("HH:mm"));
};

function ValidatedTime(props) {

    const classes = useStyles();

    const ff = useFormField(props, classes);

    const labelClasses = classNames(classes.labelRoot, {
        [classes.labelRootError]: ff.status === "invalid",
        [classes.labelRootSuccess]: ff.status === "valid"
    });

    const datetimeClasses = classNames({
        [classes.input]: true,
        [classes.whiteInput]: false,
        [classes.disabled]: ff.disabled,
        [classes.underlineError]: ff.status === "invalid",
        [classes.underlineSuccess]: ff.status === "valid",
        [classes.underline]: true,
        [classes.whiteUnderline]: false
    });

    const formControlClasses = classes.formControl;

    let helpTextClasses = classNames({
        [classes.labelRootError]: ff.status === "invalid",
        [classes.labelRootSuccess]: ff.status === "valid"
    });

    return (
        <FormControl className={formControlClasses} fullWidth>
            <InputLabel
                className={labelClasses}
                shrink={ff.value !== undefined && ff.value !== ''}
                htmlFor={ff.id}>
                {ff.labelText}
            </InputLabel>
            <Datetime
                id={ff.id}
                classNames={datetimeClasses}
                inputProps={{
                    className: "form-control " + classes.dateTimeInput,
                    disabled: ff.disabled
                }}
                dateFormat={false}
                timeFormat={'HH:mm'}
                value={ff.value}
                closeOnSelect={true}
                onChange={(v) => onChange(ff, v)}
            />
            {ff.helperText ?
                <FormHelperText id={ff.id + "-text"} className={helpTextClasses}>
                    {ff.helperText}
                </FormHelperText>
                : null}
        </FormControl>
    );
}

export default ValidatedTime;