export const fileUploadPageStyle = theme => ({
    textPage: {
        color: theme.palette.text.primary,
    },
    header: {
        alignItems: 'baseline',
        marginBottom: theme.spacing(3),
        [theme.breakpoints.up('md')]: {
            marginBottom: theme.spacing(6),
        }
    },
    title: {
        color: theme.palette.text.primary,
        fontWeight: 'bold',
    },

    subtitle: {
        fontWeight: 'bold',
        color: theme.palette.text.primary,
        [theme.breakpoints.up('md')]: {
            paddingLeft: theme.spacing(3),
            textAlign: 'right',
        },
    },

    description: {
        color: theme.palette.text.primary,
        textAlign: 'justify',
        '& *:first-child': {
            marginTop: 0,
            paddingTop: 0,
        },
        marginBottom: theme.spacing(3),
        [theme.breakpoints.up('md')]: {
            marginBottom: theme.spacing(6),
        }
    },

    itemName: {
        color: theme.palette.text.primary,
        // borderLeft: `2px solid ${theme.palette.kolorcity[5]}`,
        fontWeight: 500,
        paddingBottom: theme.spacing(.25),
    },

    uploadListText: {
        paddingLeft: theme.spacing(2),
        fontWeight: 500
    },

    uploadList: {
        marginBottom: theme.spacing(6),
    },

    attachmentList: {
        padding: 0,
        '& li:first-child': {
            paddingTop: 0,
        }
    },

    attachment: {
        display: 'flex',
        wordBreak: 'break-word',
        paddingLeft: theme.spacing(.5),
        textDecoration: 'none',
        color: theme.palette.text.primary,
        cursor: 'pointer',
        borderLeft: `2px solid ${theme.palette.kolorcity[5]}`,
        '&:active': {
            color: theme.palette.text.primary,
        },
        '&:focus': {
            color: theme.palette.text.primary,
        }
    },

});