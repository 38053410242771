export const invitationsHu = {
    "titleNav": "Meghívók",
    "name": "Név",
    "title": "Cím",
    "content": "Tartalom",
    "actions": "Műveletek",
    "add": "Meghívó generálása",
    "back": "Vissza",
    "invitationName": "Meghívó sablon",
    "positionId": "Meghívó személy",
    "generateInvitation": "Meghívó generálása",
};