export const decisionsHu = {
    "titleNav": "Publikálás",
    "meeting": "Testületi ülés",
    "meetingId": "Testületi ülés",
    "decision": "határozat",
    "decisions": "Határozatok",
    "listOfDecisions": "Határozatok listája",
    "numberOfDecision": "Határozat száma",
    "title": "Határozat címe",
    "decisionText": "Határozat szövege",
    "presenterName": "Előterjesztő",
    "type": "Jellege",
    "OPEN": "Nyílt",
    "CLOSED": "Zárt",
    "attachments": "Határozat és mellékletek",
    "attachmentNames": "Határozat és mellékletek",
    "add": "Létrehozás",
    "edit": "Szerkesztés",
    "remove": "Határozat törlése",
    "delete": "Határozat törlése",
    "back": "Vissza",
    "save": "Mentés",
    "actions": "Műveletek",

    "numberOfDecision-invalid": "Hibás: a karakterek száma 1 és 32 között kell legyen.",
    "decisionText-invalid" : "Hibás: a határozat szövege legfeljebb 10000 karakter lehet.",
    "title-invalid" : "Hibás: a karakterek száma 1 és 256 között kell legyen.",
    "presenterName-invalid" : "Hibás: a karakterek száma 1 és 100 között kell legyen."
};
