import baseFetch, {useFetchMany, useFetchOne} from "../Lib/baseFetch";
import {DecisionRecord, DecisionSchema} from "../Data/Decision";
import {AttachmentRecord} from "../Lib/ContentManagement/ContentEditor/Data/Attachment";
import {roles} from "../Main/roles";
import {decisionTypeInAllTest, decisionTypeTest} from "../Lib/testing-tools/data-validation-tests";

export function useDecisions(meetingId, revision) {
    return useFetchMany('/decisions/', undefined, DecisionRecord, revision);
}

export function useDecision(decisionId) {
    return useFetchOne('/decisions', decisionId, DecisionRecord, DecisionSchema);
}

export function useDecisionsByYear(year, revision) {
    return useFetchMany('/decisions/previous-decisions/' + year, undefined, DecisionRecord, revision);
}

export function useAttachments(id, revision) {
    return useFetchMany('/decisions/' + id + '/attachments', undefined, AttachmentRecord, revision);
}

export function addDecision(decision, onSuccess, onError) {
    baseFetch('POST', '/decisions/', decision,
        data => {
            if (onSuccess) onSuccess(new DecisionRecord(data))
        },
        onError
    );
}

export function getAttachments(id, onSuccess, onError) {
    baseFetch('GET', '/decisions/' + id + '/attachments', undefined,
        data => {
            if (onSuccess) onSuccess(data.map(r => new AttachmentRecord(r)));
        },
        onError
    );
}

export function updateDecision(decision, onSuccess, onError) {
    baseFetch('PATCH', '/decisions/' + decision.get('id'), decision, onSuccess, onError);
}

export function removeDecision(decision, onSuccess, onError) {
    baseFetch('DELETE', '/decisions/' + decision.get('id'), undefined, onSuccess, onError);
}

export const decisionApiTest = {
    '/decisions': {
        'GET': {
            [roles.ANONYMOUS]: {
                hasResponse: true,
                validate: decisionTypeInAllTest
            },
            [roles.SITE_MEMBER]: {
                hasResponse: true,
                validate: decisionTypeInAllTest
            },
            [roles.COUNCIL_ADMIN]: {hasResponse: true},
            [roles.CM_ADMIN]: {
                hasResponse: true,
                validate: decisionTypeInAllTest
            },
            [roles.UPLOAD_ADMIN]: {
                hasResponse: true,
                validate: decisionTypeInAllTest
            },
            [roles.ADMIN]: {hasResponse: true},
            [roles.SECURITY_OFFICER]: {hasResponse: true}
        },
        'POST': {
            [roles.ANONYMOUS]: {hasResponse: false},
            [roles.SITE_MEMBER]: {hasResponse: false},
            [roles.COUNCIL_ADMIN]: {hasResponse: true,},
            [roles.CM_ADMIN]: {hasResponse: false},
            [roles.UPLOAD_ADMIN]: {hasResponse: false},
            [roles.ADMIN]: {hasResponse: true,},
            [roles.SECURITY_OFFICER]: {hasResponse: true}
        },
    },
    '/decisions/1': {
        'GET': {
            [roles.ANONYMOUS]: {
                hasResponse: true,
                validate: decisionTypeTest
            },
            [roles.SITE_MEMBER]: {
                hasResponse: true,
                validate: decisionTypeTest
            },
            [roles.COUNCIL_ADMIN]: {hasResponse: true},
            [roles.CM_ADMIN]: {
                hasResponse: true,
                validate: decisionTypeTest
            },
            [roles.UPLOAD_ADMIN]: {
                hasResponse: true,
                validate: decisionTypeTest
            },
            [roles.ADMIN]: {hasResponse: true},
            [roles.SECURITY_OFFICER]: {hasResponse: true}
        },
        'PATCH': {
            [roles.ANONYMOUS]: {hasResponse: false},
            [roles.SITE_MEMBER]: {hasResponse: false},
            [roles.COUNCIL_ADMIN]: {hasResponse: true},
            [roles.CM_ADMIN]: {hasResponse: false},
            [roles.UPLOAD_ADMIN]: {hasResponse: false},
            [roles.ADMIN]: {hasResponse: true},
            [roles.SECURITY_OFFICER]: {hasResponse: true}
        },
    },
    '/decisions/3': {
        'DELETE': {
            [roles.ANONYMOUS]: {hasResponse: false},
            [roles.SITE_MEMBER]: {hasResponse: false},
            [roles.COUNCIL_ADMIN]: {hasResponse: true},
            [roles.CM_ADMIN]: {hasResponse: false},
            [roles.UPLOAD_ADMIN]: {hasResponse: false},
            [roles.ADMIN]: {hasResponse: true},
            [roles.SECURITY_OFFICER]: {hasResponse: true}
        },
    },
    '/decisions/1/attachments': {
        'GET': {
            [roles.ANONYMOUS]: {hasResponse: true},
            [roles.SITE_MEMBER]: {hasResponse: true},
            [roles.COUNCIL_ADMIN]: {hasResponse: true},
            [roles.CM_ADMIN]: {hasResponse: true},
            [roles.UPLOAD_ADMIN]: {hasResponse: true},
            [roles.ADMIN]: {hasResponse: true},
            [roles.SECURITY_OFFICER]: {hasResponse: true}
        }
    },
    '/decisions/previous-decisions/2020': {
        'GET': {
            [roles.ANONYMOUS]: {
                hasResponse: true,
                validate: decisionTypeInAllTest
            },
            [roles.SITE_MEMBER]: {
                hasResponse: true,
                validate: decisionTypeInAllTest
            },
            [roles.COUNCIL_ADMIN]: {hasResponse: true},
            [roles.CM_ADMIN]: {
                hasResponse: true,
                validate: decisionTypeInAllTest
            },
            [roles.UPLOAD_ADMIN]: {
                hasResponse: true,
                validate: decisionTypeInAllTest
            },
            [roles.ADMIN]: {hasResponse: true},
            [roles.SECURITY_OFFICER]: {hasResponse: true}
        },
    },
}
