import React from "react";
import {makeStyles} from "@material-ui/core";
import {menuBuilderStyle} from "../styles/menuBuilderStyle";
import {A} from 'hookrouter';
import AddRoundedIcon from '@material-ui/icons/AddRounded';


const useStyles = makeStyles(menuBuilderStyle);

const AddItemButton = ({newItemPosition}) => {
    const classes = useStyles();

    let {parent, itemBefore} = newItemPosition;

    itemBefore = itemBefore === -1 ? '' : '-' + itemBefore;

    return (
        <A className={classes.indicatorCellButton}
           href={`/content-management/${parent}${itemBefore}/add`}>
            <AddRoundedIcon fontSize='small'/>
        </A>
    );

};

export default AddItemButton;