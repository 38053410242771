import {Record} from 'immutable';
import {number, object, string} from "yup";

export const ReportRecord = Record({
    textContent: '',
    type: 0,
    name: '',
    emailAddress: '',
    location: '',
    pictures: '',
});

export const ReportSchema = object().shape({
    textContent: string().required().min(1).max(512).default(''),
    type: number().default(0),
    name: string().nullable().default(''),
    emailAddress: string().email().required().default(''),
    location: string().required().min(1).max(64).default(''),
    pictures: string().min(0).max(100000).default(''),
});