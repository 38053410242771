import {Record} from 'immutable';
import {array, boolean, number, object, string} from "yup";

export const CommitteeItemRecord = Record({
    name: '',
    date: '',
    time: '',
    type: '',
    place: '',
    committeeRecord : null
});