export const organisationsHu = {
    "title": "Szervezetek",
    "titleNav": "Törzsadatok - Szervezetek",
    "organisation": "Szervezet",
    "add": "Új szervezet",
    "edit": "Szervezet szerkesztése",
    "remove": "Szervezet törlése",
    "delete": "Szervezet törlése",
    "back": "Vissza",
    "name": "Név",
    "name-invalid": "A szervezet neve érvénytelen",
    "save": "Szervezet mentése",
    "actions": "Műveletek",
};

export const organisationsEn = {
    "title": "Organisations",
    "titleNav": "Master data - Organisations",
    "organisation": "Organisation",
    "add": "New organisation",
    "edit": "Edit organisation",
    "remove": "Delete organisation",
    "delete": "Delete organisation",
    "name": "Name",
    "name-invalid": "The name of the organisation is invalid",
    "save": "Save organisation",
    "actions": "Actions",
};

export const organisationsDe = {
    "title": "Organisationen",
    "titleNav": "Stammdaten - Organisationen",
    "organisation": "Organisation",
    "add": "Organisation hinzufügen",
    "edit": "Organisation bearbeiten",
    "remove": "Organisation löschen",
    "delete": "Organisation löschen",
    "name": "Name",
    "name-invalid": "Der Name der Organisation ist ungültig",
    "save": "Komitee speichern",
    "actions": "Aktionen",
};