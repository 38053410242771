import {handleTextInputUpdate} from "./text-input";
import {handleCheckBoxUpdate} from "./checkbox-input";
import {navigate} from "hookrouter";

export const updateInputField = (updateApi, validationSchema, e, tableStorage) => {

    const inputElement = e.target;
    const inputElementTag = inputElement.tagName;
    const inputElementType = inputElement.type;

    if (inputElementTag === 'INPUT') {
        switch (inputElementType) {
            case 'text':
                handleTextInputUpdate(updateApi, validationSchema, inputElement, tableStorage);
                break;
            case 'checkbox':
                e.preventDefault();
                handleCheckBoxUpdate(updateApi, inputElement, tableStorage);
                break;
            default:
                break;
        }
    }
};

export const getAncestorRow = element => {
    const parent = element.parentElement;
    const parentTag = parent.tagName;
    if (parentTag === 'TR') return parent.outerHTML;
    return getAncestorRow(parent);
};

export const getRecord = (id, data) => {
    const index = data.findIndex(record => record.id === parseInt(id));
    return {index: index, record: data[index]}
};

// tableEvent which takes a useState hook's set function as an attribute

export const selectRow = (e, tableStorage, setSelected) => {

    const table = document.getElementById(tableStorage.tableConfig.id);
    const rows = table.querySelectorAll('tr');
    rows.forEach(r => r.removeAttribute('style'));

    const target = e.target;
    const row = target ? target.parentElement : undefined;
    const recordId = row ? row.dataset.record : undefined;

    if (!recordId) return;

    setSelected(recordId);
    row.setAttribute('style', 'background-color: #e3f2fd');

};

export const navigateRow = (e, tableStorage, url) => {
    const target = e.target;
    const row = target ? target.parentElement : undefined;
    const recordId = row ? row.dataset.record : undefined;

    navigate(`${url}/${recordId}`);
};