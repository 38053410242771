export const ListOfWatchersForItemHu = {
    "item": "Elveszett tárgy",
    "items": "Meghirdetett tárgyak",
    "edit": "szerkesztés",
    "itemName": "Elveszett tárgy",
    "remove": "Törlés",
    "back": "Vissza a listához",
    "save": "Érdeklődő mentése",
    "id": "Azonosító",
    "watcher": "Érdeklődő",
    "phoneOfWatcher": "Érdeklődő telefonszáma" ,
    "emailOfWatcher": "Érdeklődő e-mail címe",
    "watcherName": "Érdeklődő",
    "numberOfWatchers": "Érdeklődők száma",
    "successfulDeletion": "Az eszközhöz tartozó érdeklődő sikeresen törölve lett.",
    "watchers": "Érdeklődők",
};