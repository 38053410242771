import baseFetch, {useFetchMany, useFetchOne} from "../Lib/baseFetch";
import {TemplateRecord, TemplateSchema} from "../Data/Template";
import {AttachmentRecord} from "../Lib/ContentManagement/ContentEditor/Data/Attachment";
import {roles} from "../Main/roles";

export function useInvitations(revision) {
    return useFetchMany('/invitations/', undefined, TemplateRecord, revision);
}

export function useInvitation(invitationId) {
    return useFetchOne('/invitations/', invitationId, TemplateRecord, TemplateSchema);
}

export function generateInvitation(invitation,  onSuccess, onError) {
    baseFetch('POST', '/invitations/generate-invitation/', invitation,
        data => {
            if (onSuccess) onSuccess(new AttachmentRecord(data))
        },
        onError
    );
}

export function useTemplates(revision) {
    return useFetchMany('/templates/', undefined, TemplateRecord, revision);
}

export function useTemplate(templateId) {
    return useFetchOne('/templates/', templateId, TemplateRecord, TemplateSchema);
}

export function addTemplate(template, onSuccess, onError) {
    baseFetch('POST', '/templates/', template,
        data => {
            if (onSuccess) onSuccess(new TemplateRecord(data))
        },
        onError
    );
}

export function updateTemplate(template, onSuccess, onError) {
    baseFetch('PATCH', '/templates/' + template.get('id'), template, onSuccess, onError);
}

export function removeTemplate(template, onSuccess, onError) {
    baseFetch('DELETE', '/templates/' + template.get('id'), undefined, onSuccess, onError);
}

export const templatesApiTest = {
    "/invitations": {
        'GET': {
            [roles.ANONYMOUS]: {hasResponse: false},
            [roles.SITE_MEMBER]: {hasResponse: false},
            [roles.COUNCIL_ADMIN]: {hasResponse: true,},
            [roles.CM_ADMIN]: {hasResponse: false},
            [roles.UPLOAD_ADMIN]: {hasResponse: false},
            [roles.ADMIN]: {hasResponse: true,},
            [roles.SECURITY_OFFICER]: {hasResponse: true}
        }
    },
    "/invitations/1": {
        'GET': {
            [roles.ANONYMOUS]: {hasResponse: false},
            [roles.SITE_MEMBER]: {hasResponse: false},
            [roles.COUNCIL_ADMIN]: {hasResponse: true,},
            [roles.CM_ADMIN]: {hasResponse: false},
            [roles.UPLOAD_ADMIN]: {hasResponse: false},
            [roles.ADMIN]: {hasResponse: true,},
            [roles.SECURITY_OFFICER]: {hasResponse: true}
        }
    },
    "/invitations/generate-invitation/": {
        'POST': {
            [roles.ANONYMOUS]: {hasResponse: false},
            [roles.SITE_MEMBER]: {hasResponse: false},
            [roles.COUNCIL_ADMIN]: {hasResponse: true,},
            [roles.CM_ADMIN]: {hasResponse: false},
            [roles.UPLOAD_ADMIN]: {hasResponse: false},
            [roles.ADMIN]: {hasResponse: true,},
            [roles.SECURITY_OFFICER]: {hasResponse: true}
        }
    },
    "/templates": {
        'GET': {
            [roles.ANONYMOUS]: {hasResponse: false},
            [roles.SITE_MEMBER]: {hasResponse: false},
            [roles.COUNCIL_ADMIN]: {hasResponse: true,},
            [roles.CM_ADMIN]: {hasResponse: false},
            [roles.UPLOAD_ADMIN]: {hasResponse: false},
            [roles.ADMIN]: {hasResponse: true,},
            [roles.SECURITY_OFFICER]: {hasResponse: true}
        },
    },
    "/templates/1": {
        'GET': {
            [roles.ANONYMOUS]: {hasResponse: false},
            [roles.SITE_MEMBER]: {hasResponse: false},
            [roles.COUNCIL_ADMIN]: {hasResponse: true,},
            [roles.CM_ADMIN]: {hasResponse: false},
            [roles.UPLOAD_ADMIN]: {hasResponse: false},
            [roles.ADMIN]: {hasResponse: true,},
            [roles.SECURITY_OFFICER]: {hasResponse: true}
        },
    },
}