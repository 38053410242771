import React, {memo, useEffect, useState} from "react";
import {fileUploadPageStyle as style} from "./fileUploadPageStyle";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import {getAttachments, useListItems} from "../../Lib/ContentManagement/ContentEditor/Api/listItems";
import useNav from "../../Lib/useNav";
import {makeStyles} from "@material-ui/core";
import {useFileUploadPage} from "../../Lib/ContentManagement/ContentEditor/Api/fileUploadPages";
import ContentBox from "../../Components/ContentBox/ContentBox";
import {SimplexionTable} from "../../Lib/simplexion-table/SimplexionTable";
import {themedTableStyles} from "../../Lib/simplexion-table/styles/themedTableStyles";
import {attachmentListField} from "../../Lib/simplexion-table/templates/attachment-list-field";
import {useLanguageRevision} from "../../Lib/useLanguageRevision";

const useStyles = makeStyles(style);
const useTableStyles = makeStyles(themedTableStyles);

const ListItems = ({pageData}) => {

    const revision = useLanguageRevision();

    const {data, loadingStatus} = useListItems(pageData.id, revision);

    if (loadingStatus) return null;

    return <ListItemsTable data={data}/>;
};

const ListItemsTable = memo(({data}) => {

    const classes = useTableStyles();

    const [attachments, setAttachments] = useState([]);

    useEffect(() => {
        for (let i = 0; i < data.length; i++) {

            const item = data[i];

            getAttachments(item.id, files => {
                setAttachments(prev => prev.concat([[item.id,files]]));
            });

        }
    }, [data]);

    if (attachments.length !== data.length) return null;

    const m = {  }
    attachments.forEach(item => m[item[0]] = item[1])

    const tableData = data.map((item, index) => {
        return item.set('attachments', m[item.id]);
    });

    const tableConfig = {
        id: 'file-uploads-table',
        nameSpace: 'general',
        stickyHeader: true,
        sortable: true,
        styles: classes,
        columns: [
            {
                accessor: 'name',
            },
            {
                accessor: 'attachments',
                renderer: (...args) => attachmentListField(...args),
            },
        ],
    };

    return <SimplexionTable tableConfig={tableConfig} data={tableData}/>;
});

const FileUploadPage = memo(({pageId, path}) => {

    let url = window.location.pathname;

    useNav({path: url});

    const classes = useStyles();

    const revision = useLanguageRevision();

    const {data, loadingStatus} = useFileUploadPage(pageId, revision);

    if (loadingStatus) return null;

    return (
        <ContentBox publicPage={true} path={path}>
            <Grid container
                  className={classes.textPage}
                  component='div'>
                <Grid item container
                      xs={12}
                      component='div'
                      className={classes.header}>
                    <Grid item xs={12} sm={9} component='div'>
                        <Typography variant='h4'
                                    className={classes.title}>
                            {data.title}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Typography variant='subtitle1'
                                    className={classes.subtitle}>
                            {data.createdAt}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item container>
                    <Grid item xs={12}>
                        <Box className={classes.description + " ql-display"} dangerouslySetInnerHTML={{__html: data.description}}/>
                    </Grid>
                    <ListItems pageData={data}/>
                </Grid>
            </Grid>
        </ContentBox>
    );
});

export default FileUploadPage;