import ContentBox from "../../Components/ContentBox/ContentBox";
import React from "react";
import Grid from "@material-ui/core/Grid";
import {makeStyles} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import {searchResultsPageStyle} from "./searchResultsPageStyle";
import {A, navigate, useRoutes} from "hookrouter";
import {useLanguageRevision} from "../../Lib/useLanguageRevision";
import useNav from "../../Lib/useNav";
import {useSearchResults} from "../../Api/search";
import Typography from "@material-ui/core/Typography";
import SearchField from "../../Components/SearchField/SearchField";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";


const useStyles = makeStyles(searchResultsPageStyle);

const subRoutes = {
    "/": () => <SearchResultsPage param={undefined}/>,
    "/:param": ({param}) => <SearchResultsPage param={param}/>
};

export default () => useRoutes(subRoutes);

const Result = ({item}) => {

    const classes = useStyles();

    return (
        <React.Fragment>
            <ListItem>
                <ListItemText
                    primary={
                        <A href={'/' + item.url} className={classes.result}>
                            {item.description}
                        </A>
                    }
                />
            </ListItem>
        </React.Fragment>
    );
};

const SearchResultsPage = ({param}) => {

    useNav({path: window.location.pathname});

    const classes = useStyles();
    const {t} = useTranslation('searchResult');
    const revision = useLanguageRevision();

    if (param) param = decodeURIComponent(param);

    const {data, loadingStatus} = useSearchResults(param || undefined, revision);

    const path = [{label: t('search'), url: '/search'}];

    if (loadingStatus) return null;

    const handleClick = input => {
        navigate('/search/' + encodeURIComponent(input.trim()));
    };

    return (
        <ContentBox publicPage={true} path={path}>
            <Grid container
                  className={classes.textPage}
                  component='div'>
                <Grid item container
                      xs={12}
                      component='div'
                      className={classes.header}>
                    <Grid item xs={12} component='div'>
                        <Typography variant='h4'
                                    className={classes.title}>
                            {t("search")}
                        </Typography>
                    </Grid>
                    <SearchField handleClick={handleClick} searchTerm={param || ''} view='page'/>
                </Grid>
                <Grid item container>
                    {!loadingStatus && data[0] &&
                    <Grid item xs={12} component='div'>
                        <Typography variant='h4'
                                    className={classes.title}>
                            {t("searchResults")}
                        </Typography>
                    </Grid>
                    }
                    {data && data.map((item, i) => <Result key={i} item={item}/>)}
                </Grid>
            </Grid>
        </ContentBox>
    );
};

