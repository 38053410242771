import useNav from "../../../Lib/useNav";
import {useTranslation} from "react-i18next";
import ContentBox from "../../../Components/ContentBox/ContentBox";
import Grid from "@material-ui/core/Grid";
import {A} from "hookrouter";
import React from "react";
import {makeStyles} from "@material-ui/styles";
import {cityCouncilPageStyle as style} from "../cityCouncilPageStyle";

const useStyles = makeStyles(style);

const MainPage = ({pageId, path}) => {

    useNav({path: window.location.pathname});

    const classes = useStyles();

    const {t} = useTranslation();

    return (
        <ContentBox publicPage={true} path={path}>
            <Grid container className={classes.header} component='div'>
                <Grid item xs={12} className={classes.councilLinkBox}>
                    <A href={`/city-council-pages/${pageId}/meetings`} className={classes.councilLink}>
                        {t('meetings:meetings')}
                    </A>
                </Grid>
                <Grid item xs={12} className={classes.councilLinkBox}>
                    <A href={`/city-council-pages/${pageId}/decisions`} className={classes.councilLink}>
                        {t('decisions:decisions')}
                    </A>
                </Grid>
            </Grid>
        </ContentBox>
    )
};

export default MainPage;