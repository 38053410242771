import {Record} from 'immutable';
import {number, object, string} from "yup";

export const NotificationPageRecord = Record({
    id: null,
    title: '',
    textContent: '',
    tags: '',
});

export const NotificationPageSchema = object().shape({
    id: number().nullable().default(''),
    title:  string().required().min(1).max(128).default(''),
    textContent: string().min(0).default(''),
    tags: string().min(0).max(512).default(''),
});