import {container} from "../../Lib/material-kit/assets/jss/material-kit-pro-react.jsx";

const loginStyle = theme => ({
    panel: {
        width: "100vw",
        height: "100vh",
        backgroundColor: theme.palette.common.white,
        marginTop: -158,
        marginLeft: -48,
        marginRight: -48,
        marginBottom: -158,
    },
    container: {
        ...container,
        backgroundColor: theme.palette.common.white,
        zIndex: "4",
        [theme.breakpoints.down("sm")]: {
            paddingBottom: "100px"
        },
        [theme.breakpoints.up("xs")]: {
            maxWidth: "500px",
        }
    },
    title: {
        marginTop: 10,
        color: theme.palette.primary.main,
        textTransform: "uppercase",
        fontWeight: 500,
        marginBottom: 10
    },
    paperContainer: {
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center center",
        backgroundSize: "cover",
        height: '100%',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    forgottenPasswordBtn: {
        color: theme.palette.text.white,
        backgroundColor: theme.palette.secondary.main,
        '&:hover': {
            color: theme.palette.text.white,
            backgroundColor: theme.palette.secondary.main,
        }
    },
});

export default loginStyle;
