export const mainPageItemStyleDense = theme => ({
    mainPageItem: {
        border: 'unset',
        boxShadow: 'unset',
    },

    header: {
        alignItems: 'baseline'
    },

    title: {
        ...theme.typography.h4,
        color: theme.palette.text.primary,
        fontWeight: 'bold',
    },

    subtitle: {
        fontWeight: 'bold',
        marginTop: '1rem',
        textAlign: 'right',
    },

    imageBox: {
        height: '22vh',
        margin: '1rem 0',
        overflowY: 'hidden',
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.up('xs')]: {
                    maxWidth: theme.gridWidths[12],
                    flexBasis: theme.gridWidths[12],
                },
        [theme.breakpoints.up('sm')]: {
            height: '13vh'
        },
    },

    image: {
        ...theme.imgFluid,
    },

    contentBox: {
        marginBottom: theme.spacing(6),
    },

    content: {
        color: theme.palette.text.primary,
        marginBottom: theme.spacing(1),
    },

    contentMoreBtn: {
        textDecoration: 'none',
        color: theme.palette.text.primary,
        borderLeft: `2px solid ${theme.palette.kolorcity[5]}`,
        paddingLeft: theme.spacing(.5),
    }
});