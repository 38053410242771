import React from "react";
import {createRows, getAll, getOne, updateData} from "../util";
import {TextPageRecord, TextPageSchema} from "../../ContentManagement/ContentEditor/Data/TextPage";

import {textPagesApiTest} from "../../ContentManagement/ContentEditor/Api/textPages";

const record = TextPageRecord(TextPageSchema.default()).withMutations(data => {
    return data.set("title", "Text_05_actuality")
        .set("id", 5)
        .set("attachment", "10")
        .set("status", "ACTUALITY")
        .set("picture", 1)
})

const calls = [
    onResponse => getOne("/text-pages/5", onResponse),
    onResponse => updateData("/text-pages/5", record, onResponse),
    onResponse => getAll("/text-pages/5/attachments", onResponse)
];

const TextPagesApis = () => {

    return (
        <React.Fragment>
            {createRows(calls, textPagesApiTest)}
        </React.Fragment>
    );
}

export default TextPagesApis;