import {primaryColor} from "../../../../material-kit/assets/jss/material-kit-pro-react";

export const cityCouncilPageEditorStyle = () => ({
    descriptionContainer: {
        '& .quill .ql-editor': {
            minHeight: 200,
        },
    },
    textContentContainer: {
        padding: '1rem 0 2rem',
        '& .quill .ql-editor': {
            minHeight: 500,
        },
    },
    title: {
        marginTop: 10,
        color: primaryColor[0],
        fontSize: "0.75rem",
        textTransform: "uppercase",
        fontWeight: 500,
    },
    formBox: {
        paddingBottom: ' 1.875rem',
    },
    dataPolicyUpload: {
        width: '100%',
    }
});