import {useTranslation} from "react-i18next";
import React, {useContext} from "react";
import {FormContext} from "../../Lib/form/useForm";
import classNames from "classnames";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import {makeStyles} from "@material-ui/core";
import customInputStyle from "../../Lib/material-dashboard/assets/jss/components/customInputStyle";

const useCustomInputStyles = makeStyles(customInputStyle);

const PreviewWrapper = ({name, ns, children}) => {

    const classes = useCustomInputStyles();

    const {t} = useTranslation(ns ? ns : 'contentManagement');

    const {validationStatuses} = useContext(FormContext);

    const formControlClasses = classes.formControl;

    const status = validationStatuses.current[name];

    let labelClasses;

    if (classes) {
        labelClasses = classNames(classes.labelRoot, {
            [classes.labelRootError]: status === "invalid",
            [classes.labelRootSuccess]: status === "valid"
        });
    }

    return (
        <FormControl className={formControlClasses} fullWidth
                     style={{marginTop: "10px", minHeight: 'inherit', width: '100%',}}>
            <InputLabel
                className={labelClasses}
                shrink={true}
                htmlFor={name}>
                {t(name)}
            </InputLabel>
            {children}
        </FormControl>
    )
};

export default PreviewWrapper;