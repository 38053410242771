import {types} from '../Actions/HeaderActions';
import UnicornCrest from "../../Assets/img/crest/unicorn_blue_transparent_bg.png";
import CityCrest from "../../Assets/img/crest/crest_transparent.png"

/**
 * This store contains header data used.
 *
 * {
s *   title  @String  title of the page displayed in the header, mandatory
 * }
 */

// initialState is missing because it is initialized in App.js

export function reducer(state, action) {
    switch (action.type) {

        case types.CREST_SET :
            return crestSet(state, action);

        default:
            return state;
    }

    function crestSet(state,action) {

        const crest = action.path === '/' ? CityCrest : UnicornCrest;

        return state.set('crest', crest);
    }

}