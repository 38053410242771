import Immutable from "immutable";
import {config} from "../../Main/routes";
import {roles as userRoles, roles} from "../../Main/roles";
import React from "react";
import BlockPage from "../../Views/BlockPage/BlockPage";
import TextPage from "../../Views/TextPage/TextPage";
import FileUploadPage from "../../Views/FileUploadPage/FileUploadPage";
import KolorApp from "../../Views/KolorApp/KolorApp";
import CityCouncilPage from "../../Views/CityCouncilPage/Public/CityCouncilPage";
import Items from "../../Views/Items/Items";

export const createPublicRouteConfig = data => {
    let views = Immutable.List();

    const roots = data.filter(record => record.parent === null);

    const map = Immutable.Map().withMutations(map => {
        data.forEach(record => map.set(record.id, record));
    });

    roots.forEach(root => {
        const route = buildHomeViews(root, map);
        views = views.push(route);
    });

    config[0].views = views;

    return config;
};


const buildHomeViews = (root, map, breadCrumbs) => {

    let currentViews = Immutable.List();

    breadCrumbs = breadCrumbs ? Immutable.List(breadCrumbs) : Immutable.List([]);

    let route = routeFactory(root, breadCrumbs);

    const children = root.children ? root.children.split(',').filter(id => id).map(id => parseInt(id)) : undefined;

    if (children) {

        route.collapse = true;

        breadCrumbs = breadCrumbs.concat([{
            label: root.title,
            url: route.path.replace('*', ''),
            color: root.lineColor
        }]);

        children.forEach(id => {

            const el = map.get(id);

            const subRoute = el.children ?
                buildHomeViews(el, map, breadCrumbs) :
                routeFactory(el, breadCrumbs);

            currentViews = currentViews.push(subRoute);
        });

        route.views = currentViews;
    }

    if (currentViews.size) route.views = currentViews;

    return route;
};

const routeFactory = (record, breadCrumbs) => {

    const renderAndPath = getRenderAndPath(record, breadCrumbs);
    const path = renderAndPath.path;
    const render = renderAndPath.render;

    return {
        path: path,
        title: record.title,
        lineColor: record.lineColor,
        fontColor: record.fontColor,
        render: render,
        roles: [
            roles.ANONYMOUS,
            roles.SITE_MEMBER,
            roles.ADMIN,
            roles.CM_ADMIN,
            roles.COUNCIL_ADMIN,
            roles.UPLOAD_ADMIN,
            roles.SECURITY_OFFICER,
            roles.KSZSZK_ADMIN
        ],
    };

};

const getRenderAndPath = (record, breadCrumbs) => {

    const pageId = record.page;
    let path = '';

    switch (record.type) {
        case 'BLOCK':
            path = `/block-pages/${pageId}/blocks*`;
            breadCrumbs = breadCrumbs.concat([{
                label: record.title,
                url: path.replace('*', ''),
                color: record.lineColor
            }]);
            return {
                path: path,
                render: () => <BlockPage pageId={pageId} path={breadCrumbs}/>
            };
        case 'TEXT':
            path = `/text-pages/${pageId}`;
            breadCrumbs = breadCrumbs.concat([{label: record.title, url: path, color: record.lineColor}]);
            return {
                path: path,
                render: () => <TextPage pageId={pageId} path={breadCrumbs}/>
            };
        case 'FILE_UPLOAD':
            path = `/file-upload-pages/${pageId}`;
            breadCrumbs = breadCrumbs.concat([{label: record.title, url: path, color: record.lineColor}]);
            return {
                path: path,
                render: () => <FileUploadPage pageId={pageId} path={breadCrumbs}/>
            };
        case  'KOLORAPP':
            path = `/kolorapp-pages/${pageId}`;
            breadCrumbs = breadCrumbs.concat([{label: record.title, url: path, color: record.lineColor}]);
            return {
                path: path,
                render: () => <KolorApp pageId={pageId} path={breadCrumbs}/>
            };
        case  'CITY_COUNCIL':
            path = `/city-council-pages/${pageId}*`;
            breadCrumbs = breadCrumbs.concat([{
                label: record.title,
                url: path.replace('*', ''),
                color: record.lineColor
            }]);
            return {
                path: path,
                render: () => <CityCouncilPage pageId={pageId} path={breadCrumbs}/>
            };
        case  'LIST_OF_ITEM':
            path = `/items*`;
            breadCrumbs = breadCrumbs.concat([{label: record.title, url: path.replace('*', ''), color: record.lineColor}]);
            return {
                path: path,
                render: () => <Items path={breadCrumbs}/>
            };
        default:
            return;
    }
};

export const checkAdminAccess = (roles, type) => {

    if (roles.includes(userRoles.ADMIN) || roles.includes(userRoles.SECURITY_OFFICER)) return true;

    switch (type) {
        case 'TEXT':
            return roles.includes(userRoles.CM_ADMIN);
        case 'BLOCK':
            return roles.includes(userRoles.CM_ADMIN)
        case 'FILE_UPLOAD':
            return roles.includes(userRoles.CM_ADMIN) || roles.includes(userRoles.UPLOAD_ADMIN)
        default:
            return false
    }
}