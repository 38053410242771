import React from "react";
import {addData, createRows, getAll, getOne, removeData, updateData} from "../util";
import {MoveRecord} from "../../ContentManagement/MenuBuilder/Data/Records/Move";
import {ContentRecord, ContentSchema} from "../../ContentManagement/MenuBuilder/Data/Records/Content";

import {contentsApiTest} from "../../ContentManagement/MenuBuilder/Api/content";

const record = ContentRecord(ContentSchema.default()).withMutations(data => {
    return data.set("title", "title")
        .set("type", "TEXT")
        .set("page", 0)
        .set("itemBefore", null)
        .set("parent", 1)
})

const recordToPatch = ContentRecord(ContentSchema.default()).withMutations(data => {
    return data.set("id", 1)
        .set("title", "Közügyek")
        .set("type", "BLOCK")
        .set("page", 1)
        .set("status", "PUBLIC")
        .set("children", "5,6")
        .set("color", 1)
        .set("itemBefore", null)
        .set("parent", null)
})

const moveRecord = new MoveRecord({
    id: 10,
    oldParent: 2,
    newParent: 2,
    itemBefore: 7,
})

const calls = [
    onResponse => addData("/contents", record, onResponse),
    onResponse => getAll("/contents", onResponse),
    onResponse => getOne("/contents/1", onResponse),
    onResponse => updateData("/contents/1", recordToPatch, onResponse),
    onResponse => removeData('/contents/16', onResponse),
    onResponse => updateData('/contents/move-content', moveRecord, onResponse),
];

const ContentsApis = () => {

    return (
        <React.Fragment>
            {createRows(calls, contentsApiTest)}
        </React.Fragment>
    );
}

export default ContentsApis;