import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import {accountHu} from "../Views/Account/i18n"
import {contentManagementHu} from "../Lib/ContentManagement/i18n";
import {committeeHu} from "../Views/Committees/i18n";
import {organisationsHu} from "../Views/Organisations/i18n";
import {councilMembersHu} from "../Views/Account/CouncilMembers/i18n";
import {kolorAppHu} from "../Views/KolorApp/i18n";
import {meetingsHu} from "../Views/Meetings/i18n";
import {invitationsHu} from "../Views/Invitations/i18n";
import {meetingElementsHu} from "../Views/MeetingElements/i18n";
import {documentsHu} from "../Views/Documents/i18n";
import {decisionsHu} from "../Views/Decisions/i18n";
import {cityCouncilPageHu} from "../Views/CityCouncilPage/i18n";
import {positionsHu} from "../Views/Positions/i18n";
import {meetingRecordHu} from "../Views/Meetings/Record/i18n";
import {storedFilesHu} from "../Views/StoredFiles/i18n";
import {notificationsHu} from "../Views/Notifications/i18n";
import {publicInformationHu} from "../Views/PublicInformation/i18n";
import {RoleHu} from "../Lib/auth/Views/Roles/i18n"
import {GrantHu} from "../Lib/auth/Views/Roles/Grants/i18n";
import {profileHu} from "../Views/Profile/i18n";
import {searchResultHU} from "../Views/SearchResultsPage/i18n";
import {filePondLabelsHu} from "../Lib/form/Components/ValidatedFileUpload/filepond-labels";
import {itemsHu} from "../Views/Items/i18n";
import {watchersHu} from "../Views/Watchers/i18n";
import {ListOfWatchersForItemHu} from "../Views/Items/ListOfWatchersForItems/i18n";
import SystemSettings from "../Lib/auth/Views/SystemSettings/i18n";

const resources = {
    hu: {
        general: {
            "actions": "Műveletek",
            "name": "Név",
            "status": "Státusz",
            "identifier": "Azonosító",
            "masterData": "Törzsadatok",
            "downloadMaterials": "Letölthető anyagok",

            "logout": "Kilépés",

            "save.success": "Sikeres mentés.",
            "save.error": "Nem sikerült a mentés!",
            "back": "Vissza",
            "back_list": "Vissza",
            "close": "Bezár",
            "prev": "Előző",
            "next": "Következő",
            "save": "Mentés",
            "edit": "Szerkesztés",
            "add": "Létrehozás",
            "remove": "Törlés",
            "remove_item": "törlése",

            'hungarian': 'magyar',
            'english': 'angol',
            'german': 'német',

            'more': 'részletek',
            'More': 'Részletek',
            'tags': 'Címkék',
            'tag': 'Címke',
            'loadMore': 'További tartalom betöltése',

            'Actualities': 'Aktualitások',
            'News': 'Hírek',
            'Announcements': 'Közlemények',
            'Privacy Policy': 'Adatvédelem',
            'Impressum': 'Impresszum',
            'Contact': 'Kapcsolat',
            'attachment': 'Csatolt fájl',
            'attachments': 'Csatolt fájlok',

            'author': 'szerző',
            'Author': 'Szerző',

            'base-data': 'Adatlap',
            'scrollToTop': 'Ugrás az oldal tetejére',
            "images": "Képek",
            "search": "Keresés",
            "hide": "menüelem elrejtése",
            "baseData": "Alapadatok",
            "typeOfAd": " Hirdetés típusa",
            "LOOK_FOR": "Keres",
            "OFFER": "Kínál",

        },

        header: {
            "brand": "Kazincbarcika",
            "information": "Információk",
            "registration": "Regisztráció",
            "login": "Belépés",
            "landing": "Kezdőlap",
            "account": "Fiók",
            "logout": "Kilépés",
            "meetings": "Testületi ülések",
            "committees": "Bizottságok",
            "positions": "Kjelöltek",
            "organisations": "Szervezetek",
        },

        login: {
            "title": "Belépés",
            "login": "Bejelentkezés",
            "principalName": "Fiók",
            "password": "Jelszó",
            "error": "Érvénytelen fiók és/vagy jelszó.",
            "registration": "Regisztráció",
            "forgottenPassword": "Elfelejtett jelszó",
            "principalName-invalid": "Érvénytelen fiók.",
            "password-invalid": "Érvénytelen jelszó"
        },

        catchException: {
            'not-acceptable': 'A müvelet sikertelen - a szerver nem tud választ előállítani',
            'unauthorized': 'Nincs jogosultsága a művelet elvégzéséhez.',
            'expired-session': 'Lejárt a munkamenet, kérjük lépjen be újra a Belépés menüpontban.',
            "already-exists": "Ez a rekord már létezik.",
            'bad-request': 'A kérés nem végrehajtható, mert rossz a rekord szintaxisa',
            'not-found': ' A kért rekord nem található.',
            'gateway-timeout': 'A müvelet sikertelen - a szerver nem kapott választ az eredeti szervertől időben.',
            "hasNoSiteMemberRole": "A web-es alkalmazást csak ’site-member’ (web-es felhasználó) jogosultsággal lehet használni!"
        },

        committees: committeeHu,
        councilMembers: councilMembersHu,
        organisations: organisationsHu,
        positions: positionsHu,

        meetings: meetingsHu,
        meetingElements: meetingElementsHu,
        meetingRecord: meetingRecordHu,
        documents: documentsHu,
        decisions: decisionsHu,
        invitations: invitationsHu,

        cityCouncilPage: cityCouncilPageHu,

        account: accountHu,
        profile: profileHu,
        searchResult: searchResultHU,

        filePondLabels: filePondLabelsHu,


        storedFiles: storedFilesHu,
        notifications: notificationsHu,
        publicInformation: publicInformationHu,
        roles: RoleHu,
        grants: GrantHu,
        items: itemsHu,
        watchers: watchersHu,

        session: {
            "expired.message": "Lejárt a munkamenet, kérjük lépjen be újra a Belépés menüpontban.",
            "expired.title": "Lejárt munkamenet"
        },

        contentManagement: contentManagementHu,
        kolorApp: kolorAppHu,

        listOfWatchersForItems: ListOfWatchersForItemHu,

        systemSettings: SystemSettings

    },
    en: {
        general: {
            "actions": "Actions",
            "name": "Name",
            "status": "Status",
            "identifier": "Identifier",
            "masterData": "Master data",
            "downloadMaterials": "Downloadable materials",


            "save.success": "Successful save.",
            "save.error": "Nem sikerült a mentés!",
            "back": "Back",
            "back_list": "Back to the list",
            "close": "Close",
            "prev": "Previous",
            "next": "Next",
            "save": "Save",

            'more': 'more',
            'More': 'More',

            'hungarian': 'hungarian',
            'english': 'english',
            'german': 'german',

            'Actualities': 'Actualities',
            'News': 'News',
            'Privacy Policy': 'Privacy Policy',
            'Impressum': 'Impressum',
            'Contact': 'Contact',

            'author': 'Author',
            'Author': 'Author',

            'base-data': 'Base data',
            "images": "Images",
            "search": "Search",
            "hide": "Hide"
        },

        councilMaterials: {
            "title": "Council materials",
        },

        committees: committeeHu,
        councilMembers: councilMembersHu,
        organisations: organisationsHu,
        positions: positionsHu,

        meetings: meetingsHu,
        meetingElements: meetingElementsHu,
        meetingRecord: meetingRecordHu,
        documents: documentsHu,
        decisions: decisionsHu,
        invitations: invitationsHu,

        cityCouncilPage: cityCouncilPageHu,

        account: accountHu,
        profile: profileHu,
        searchResult: searchResultHU,

        storedFiles: storedFilesHu,
        notifications: notificationsHu,
        publicInformation: publicInformationHu,
        roles: RoleHu,
        grants: GrantHu,

    },
    de: {
        general: {
            "actions": "Actions",
            "name": "Name",
            "status": "Status",
            "identifier": "Kennung",
            "masterData": "Basisdaten",
            "downloadMaterials": "Herunterladbare Materialien",

            "save.success": "Successful save.",
            "save.error": "Erfolgreich speichern!",
            "back": "Zurück",
            "back_list": "Zurück zur liste",
            "close": "Schließen",
            "prev": "Bisherige",
            "next": "Nächster",
            "save": "speichern",

            'more': 'Mehr',
            'More': 'Mehr',

            'hungarian': 'Ungarisch',
            'english': 'Englisch',
            'german': 'Deutsche',

            'Actualities': 'Aktualitäten',
            'News': 'Nachrichten',
            'Privacy Policy': 'Datenschutz-Bestimmungen',
            'Impressum': 'Impressum',
            'Contact': 'Kontakt',

            'author': 'Autor',
            'Author': 'Autor',

            'base-data': 'Daten',
            "images": "Fotos",
            "search": "Suche",
            "hide": "Ausblenden"

        },

        committees: committeeHu,
        councilMembers: councilMembersHu,
        organisations: organisationsHu,
        positions: positionsHu,

        meetings: meetingsHu,
        meetingElements: meetingElementsHu,
        meetingRecord: meetingRecordHu,
        documents: documentsHu,
        decisions: decisionsHu,
        invitations: invitationsHu,

        cityCouncilPage: cityCouncilPageHu,

        account: accountHu,
        profile: profileHu,
        searchResult: searchResultHU,

        storedFiles: storedFilesHu,
        notifications: notificationsHu,
        publicInformation: publicInformationHu,
        roles: RoleHu,
        grants: GrantHu,
    }
};

const languageCookie = document.cookie.split(';').filter(str => str.indexOf('language=') >= 0)[0];
const language = languageCookie ?
    languageCookie.slice(languageCookie.indexOf('=') + 1).trim().toLocaleLowerCase() :
    'hu';

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: language,

        keySeparator: false, // we do not use keys in form messages.welcome

        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;