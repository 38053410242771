export const RoleHu = {
    "role": "Jogosultság",
    "roles": "Jogosultságok",
    "id": "Id",
    "name": "Név",
    "name-invalid": "Érvénytelen mező, legalább 1, legfeljebb 100 karakternek kell lennie.",
    "grantCount": "Jogosultak száma",
    "description": "Leírás",
    "description-invalid": "Érvénytelen mező, legalább 1, legfeljebb 10000 karakternek kell lennie.",
    "locked": "Lezárt",
    "action": "Művelet",
    "back": "Vissza a listához",
    "save": "Jogosultság mentése",
    "add": "Jogosultság hozzáadása",
    "grants": "Jogosultsággal rendelkezők",

};

export const RoleEn = {
    "role": "Role",
    "roles": "Roles",
    "id": "Id",
    "name": "Name",
    "numberOfGranted": "Number of granted",
    "description": "Description",
    "locked": "Locked",
    "actions": "Actions",
    "action": "Action",
};