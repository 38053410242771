import {Record} from 'immutable';
import {number, object, string} from "yup";

export const ListItemRecord = Record({
    id: 0,
    name: '',
    attachments: '',
});

export const ListItemSchema = object().shape({
    id: number().min(0).default(0),
    name: string().required().min(1).max(128).default(''),
    attachments: string().required().min(1).default(''),
});