import {checkAll, hasValue, hasValueIn, isArray, isOwner} from "./util";
import {roles} from "../../Main/roles";

export const getSiteMembersTest = (data) => {

    if (!isArray(data)) return 'Data is not an Array';

    const callback = item => hasValueIn('roles', roles.SITE_MEMBER, item);

    const test = checkAll(callback, data);

    return {
        test: test,
        msg: test ?
            'Valid data' :
            'Found record with "role" other than SITE_MEMBER'
    };
}

export const getPersonalDataTest = (data) => {

    const test = isOwner(data);

    return {
        test: test,
        msg: test ?
            "Valid data" :
            "Should be the user's own data"
    }
}

export const getPersonalOrSiteMemberData = (data) => {

    const isPersonalData = isOwner(data);
    const isSiteMember = hasValue('roles', roles.SITE_MEMBER, data);

    return {
        test: isPersonalData || isSiteMember,
        msg: isPersonalData || isSiteMember ?
            'Valid data' :
            'The data should have "role" SITE_MEMBER or been the user\'s own data'
    }
}

export const decisionTypeTest = data => {

    const isOpen = hasValue('type', 'OPEN', data);
    const fullDto = data.decisionText !== '' || data.attachments !== '';

    const test = !isOpen && fullDto

    return {
        test: test,
        msg: test ? 'Valid data' :
            '"type" is CLOSED but it has more data'
    }
}

export const decisionTypeInAllTest = data => {

    const callback = item => {

        const isOpen = hasValue('type', 'OPEN', item);
        const fullDto = item.decisionText !== '' || item.attachments !== '';

        return (isOpen && fullDto) || (!isOpen && !fullDto);
    }

    const test = checkAll(callback, data)

    return {
        test: test,
        msg: test ?
            'Valid data' :
            'Found record with invalid values'
    }
}

export const documentsStatusTest = data => {

    const callback = item => hasValue('status', "PUBLIC", item);

    const test = checkAll(callback, data);

    return {
        test: test,
        msg: test ?
            'Valid data' :
            'Found record with "status" other than PUBLIC'
    }
}

export const isPublicTest = data => {

    const test = hasValue('public', true, data);

    return {
        test: test,
        msg: test ?
            'Valid data' :
            'Property "public" is false'
    }
}

export const allIsPublicTest = data => {

    const test = hasValue('public', true, data);

    return {
        test: test,
        msg: test ?
            'Valid data' :
            'Found record with "public" false'
    }
}

export const contentStatusTest = data => {

    const isPublic = hasValue('status', 'PUBLIC', data);
    const isActuality = hasValue('status', 'ACTUALITY', data);

    const test = isPublic || isActuality;

    return {
        test: test,
        msg: test ?
            'Valid data' :
            'Found record with "status" HIDDEN',
    }
}

export const contentsStatusTest = data => {

    const callback = item => {
        const isPublic = hasValue('status', 'PUBLIC', item);
        const isActuality = hasValue('status', 'ACTUALITY', item);
        return isPublic || isActuality;
    }

    const test = checkAll(callback, data);

    return {
        test: test,
        msg: test ?
            'Valid data' :
            'Found record with "status" HIDDEN',
    }
}

export const contentForUploadAdminTest = data => {

    const test = hasValue('type', 'FILE_UPLOAD', data)

    return {
        test: test,
        msg: test ?
            'Valid data' :
            'The type of the record is not FILE_UPLOAD',
    }
}

export const contentsForUploadAdminTest = data => {

    const callback = item => item.type === 'FILE_UPLOAD';

    const test = checkAll(callback, data);

    return {
        test: test,
        msg: test ?
            'Valid data' :
            'Found record with "type" other than FILE_UPLOAD',
    }
}

export const contentForCMAdminTest = data => {

    const test = hasValue('type', 'FILE_UPLOAD', data) ||
        hasValue('type', 'BLOCK', data) ||
        hasValue('type', 'TEXT', data);

    return {
        test: test,
        msg: test ?
            'Valid data' :
            'The type of the record is not BLOCK, TEXT or FILE_UPLOAD',
    }
}

export const contentsForCMAdminTest = data => {

    const callback = item => item.type === 'FILE_UPLOAD' || item.type === 'BLOCK' || item.type === 'TEXT';

    const test = checkAll(callback, data);

    return {
        test: test,
        msg: test ?
            'Valid data' :
            'Found record with "type" other than BLOCK, TEXT or FILE_UPLOAD',
    }
}


