import {Record} from 'immutable';
import {bool, number, object, string} from "yup";

export const CommitteeRecord = Record({
    id: 0,
    name: '',
    representative: false,
});

export const CommitteeSchema = object().shape({
    id: number().required().min(0).default(0),
    name: string().required().min(1).max(100).default(""),
    representative: bool().nullable().default(false),
});
