import {main} from "../../Lib/material-kit/assets/jss/material-kit-pro-react.jsx";
import {
  drawerMiniWidth,
  drawerWidth,
  transition
} from "../../Lib/material-dashboard/assets/jss/material-dashboard-pro-react.jsx";

export const contentBoxStyle = theme => ({
  main: {
    ...main,
    width: "100%",
    backgroundColor: theme.palette.white,
    "@media (min-width: 576px)": {
      paddingLeft: 30,
      paddingRight: 30,
    },
    height: "100vh",
    minHeight: "100vh",
    overflowY: "auto",
    display: "flex",
    flexDirection: "column",
    // width: "100%",
    // backgroundColor: theme.palette.text.secondary,
    // "@media (min-width: 576px)": {
    //   paddingLeft: 30,
    //   paddingRight: 30,
    // },
    // height: "100vh",
    // minHeight: "100vh",
    // overflowY: "auto",
    // display: "flex",
    // flexDirection: "column"
  },
  administrationLayoutWrapper: {
    backgroundColor: "#efefef",
    position: "relative",
    top: "0",
    height: "100vh",
    "&:after": {
      display: "table",
      clear: "both",
      content: '" "'
    }
  },
  mainPanel: {
    transitionProperty: "top, bottom, width",
    transitionDuration: ".2s, .2s, .35s",
    transitionTimingFunction: "linear, linear, ease",
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - ${drawerWidth}px)`
    },
    overflow: "auto",
    position: "relative",
    float: "right",
    ...transition,
    maxHeight: "100%",
    width: "100%",
    overflowScrolling: "touch"
  },
  mainPanelSidebarMini: {
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - ${drawerMiniWidth}px)`
    }
  },
  titleContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    height: "44px",
    minHeight: "44px",
    maxHeight: "44px",
    overflowY: "hidden",
    "@media (max-width: 576px)": {
      paddingLeft: 10,
    },
  },
  title : {
    color: theme.palette.text.primary,
    fontWeight: 400,
  },
  addBtn: {
    margin: "0 0 0 15px",
  },
  content : {
    flexGrow: "1",
    display: "flex",
    flexDirection: "column"
  },
  publicLayout: {
    paddingBottom: 110,
    minHeight: '100vh',
  },
  publicContainer: {
    padding: theme.spacing(2),
    paddingTop: theme.spacing(4) + 85,
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(6),
      paddingTop: theme.spacing(6) + 110,
    },
  },
});
