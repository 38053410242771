import {Record} from 'immutable';
import {number, object, string} from "yup";


export const ListOfWatchersForItemRecord = Record({
    id: 0,
    item: 0,
    itemName: "",
    watcher: 0,
    watcherName: "",
    phoneOfWatcher: "",
    emailOfWatcher: ""
});

export const ListOfWatchersForItemSchema = object().shape({
    id: number().min(0).default(0),
    item: number().required().min(1).default(0),
    itemName: string().nullable().default(""),
    watcher: number().required().min(1).default(0),
    watcherName: string().nullable().default(""),
    phoneOfWatcher: string().nullable().default(""),
    emailOfWatcher: string().nullable().default("")
});