import React, {memo} from "react";
import ContentBox from "../../Components/ContentBox/ContentBox";
import {useMeetings} from "../../Api/meetings";
import {baseTableStyles} from "../../Lib/simplexion-table/styles/baseStyle";
import {customActions, customActionsStyle} from "../../Lib/simplexion-table/templates/action-buttons";
import Section from "../../Lib/form/Components/Section/Section";
import {SimplexionTable} from "../../Lib/simplexion-table/SimplexionTable";
import {makeStyles} from "@material-ui/core";
import {translatableField} from "../../Lib/simplexion-table/templates/translatable-field";
import {linkAction} from "../../Lib/simplexion-table/templates/link-button";

const urlNs = "/meetings/";

const styles = () => {
    let classes = baseTableStyles;
    Object.entries(customActionsStyle).forEach(entry => {
        classes[entry[0]] = entry[1];
    });
    return classes;
};

const useStyles = makeStyles(styles);

const List = memo((props) => {

    const {path} = props;

    const classes = useStyles();

    const {data} = useMeetings();

    const tableConfig = {
        id: 'meetings-table',
        nameSpace: 'meetings',
        stickyHeader: true,
        sortable: true,
        styles: classes,
        columns: [
            {
                accessor: 'date',
            },
            {
                accessor: 'time',
            },
            {
                accessor: 'type',
                renderer: (...args) => translatableField(...args),
            },
            {
                accessor: 'committeeName',
            },
            {
                accessor: 'numberOfElements',
            },
            {
                accessor: 'actions',
                renderer: (...args) => customActions(...args, true, true, urlNs),
            },
            {
                accessor: '',
                renderer: (...args) => linkAction(...args, 'meetingElements', `/meeting-elements/`),
            },
        ],
    };

    return (
        <ContentBox path={path} add={urlNs + "add"}>
            <Section>
                <SimplexionTable tableConfig={tableConfig} data={data}/>
            </Section>
        </ContentBox>
    );
});

export default List;