export function downloadFile(id, type){
    let name;
    fetch(`/api/1.0.0/${type}/${id}`)
        .then(response =>{
            name = response.headers.get('Content-Disposition') ? response.headers.get('Content-Disposition').split("filename=")[1] : 'unknownFile';
            return response.blob()
        })
        .then(blob => { downloadBlob(blob, name) });
}

const downloadBlob = (blob, filename) => {
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename || 'unknownFile';
    const clickHandler = () => {
        setTimeout(() => {
            URL.revokeObjectURL(url);
        }, 150);
    };
    a.addEventListener('click', clickHandler, false);
    a.click();
    return a;
};