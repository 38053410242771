import {Record} from 'immutable';
import {date, number, object, string} from "yup";

export const BlockPageRecord = Record({
    id: 0,
    title: '',
    description: '',
    blocks: '',
    status: 'HIDDEN', // todo change this
    createdAt: '',
    modifiedAt: '',
});

export const BlockPageSchema = object().shape({
    id: number().min(0).default(0),
    title: string().required().min(1).max(200).default(''),
    description: string().min(0).max(2500).default(''),
    blocks: string().min(0).default(''),
    status: string().required().oneOf(['HIDDEN', 'PUBLIC', 'ACTUALITY']).default('HIDDEN'),
    createdAt: date().required().default(new Date()),
    modifiedAt: date().required().default(new Date()),
});