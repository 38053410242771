import Button from "../../Lib/material-dashboard/components/CustomButtons/Button";
import React from "react";
import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles({
    deleteBtn: {
        float: 'right',
    }
});

const OpenDialogButton = ({openModal, text, isHide}) => {

    const classes = useStyles();

    return (
        <Button color={isHide ? "warning" : "danger"}
                className={classes.deleteBtn}
                onClick={() => openModal()}
        >
            {text}
        </Button>
    );
};

export default OpenDialogButton;