export const blockListItemStyle = theme => ({
    root: {
        marginBottom: theme.spacing(3)
    },

    header: {
        alignItems: 'baseline',
        marginBottom: theme.spacing(2),
    },

    title: {
        ...theme.typography.h5,
        color: theme.palette.text.primary,
        fontWeight: 'bold',
    },

    subtitle: {
        fontWeight: 'bold',
        color: theme.palette.text.primary,
        textAlign: 'right',
        [theme.breakpoints.up('md')]: {
            paddingLeft: theme.spacing(3),
        },
    },

    imageBox: {
        maxHeight: '25vh',
        marginBottom: theme.spacing(2),
        overflowY: 'hidden',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '& img': {

        },
        [theme.breakpoints.up('xs')]: {
            maxWidth: theme.gridWidths[12],
            flexBasis: theme.gridWidths[12],
        },
        // [theme.breakpoints.up('sm')]: {
        //     // height: '30vh'
        // },
        // [theme.breakpoints.up('md')]: {
        //     // height: '40vh',
        //     // marginBottom: theme.spacing(6)
        // },
    },

    image: {
        ...theme.imgFluid,
    },

    content: {
        color: theme.palette.text.primary,
        textAlign: 'justify',
        marginBottom: theme.spacing(2),
        [theme.breakpoints.up('md')]: {
            paddingLeft: theme.spacing(3),
        },
    },

    contentText: {
        ...theme.typography.body1,
        marginBottom: theme.spacing(2),
        '& *:first-child': {
            marginTop: 0,
        },
    },

    contentMoreBtn: {
        textDecoration: 'none',
        color: theme.palette.text.primary,
        borderLeft: `2px solid ${theme.palette.kolorcity[5]}`,
        paddingLeft: theme.spacing(.5),
    }
});