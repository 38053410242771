import React, {useContext, useState} from "react";
import {makeStyles} from "@material-ui/styles";
import {mobileNavStyle as style} from "./mobileNavStyle";
import Box from "@material-ui/core/Box";
import {AppContext} from "../../Main/AppContext";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import {ExpandLess, ExpandMore} from "@material-ui/icons";
import LanguageSelector from "../LanguageSelector/LanguageSelector";
import {useTranslation} from "react-i18next";
import cx from "classnames";
import {A} from "hookrouter";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles(style);

function DrawerGroup(props) {
    const {item} = props;
    const classes = useStyles();

    const [open, setOpen] = useState(false);

    let subNavs = undefined;

    if (item.collapse) {
        subNavs = item.views.map((item, index) => {
            return <DrawerGroup key={`${item.title}-${index}`} item={item}/>;
        });
    }

    const handleOpen = (event) => {
        event.stopPropagation();
        setOpen(!open);
    };

    return (
        <Grid item container xs={12}>
            <MobileNavItem item={item} open={open} openHandle={handleOpen}/>
            {item.collapse &&
            <Collapse in={open} className={classes.nestedCollapse}>
                <Grid item container xs={12} className={classes.nestedList}>
                    {subNavs}
                </Grid>
            </Collapse>}
        </Grid>
    )
}

function MobileNavItem(props) {
    const {item, open, openHandle} = props;

    const classes = useStyles();

    const title = item.title;
    const collapse = item.collapse;

    const itemStyle = cx(classes.item, classes['item' + item.color]);
    const itemInnerStyle = cx(classes.itemInner, classes['itemInner' + item.color]);

    return (
        <Grid item container key={title} xs={12} className={itemStyle}>
            <Grid item container xs={12} className={itemInnerStyle}>
                <MobileNavLink item={item}/>
                {collapse &&
                <Box className={classes.expandBtnBox}>
                    <IconButton edge="end" aria-label="comments" onClick={event => openHandle(event)} size='small'
                                className={classes.expandBtn}>
                        {open ? <ExpandLess fontSize='small'/> : <ExpandMore fontSize='small'/>}
                    </IconButton>
                </Box>
                }
            </Grid>
        </Grid>
    )
}

function MobileNavLink(props) {

    const classes = useStyles();
    const {t} = useTranslation();
    const {item} = props;

    // const linkStyle = cx(classes.link, classes['link' + item.color]);

    return (
        <A href={item.path} className={classes.link}>
            <Typography variant={'subtitle1'}
                        className={classes.linkText}>
                {t(item.title)}
            </Typography>
        </A>
    )
}


function MobileNav({isDrawerOpen, setDrawerOpen}) {

    const classes = useStyles();

    const appContext = useContext(AppContext);

    const navTree = appContext.getIn(['appStore', 'navTree']);

    const navs = navTree[0].views.map((item, index) => <DrawerGroup key={`${item.title}-${index}`} item={item}/>);

    return (
        <Collapse in={isDrawerOpen}
                  onClose={() => setDrawerOpen(false)}
                  className={classes.mobileNav}>
            <Grid container onClick={() => setDrawerOpen(false)}
                  onKeyDown={() => setDrawerOpen(false)}
                  className={classes.mobileNavWrapper}>
                {navs}
                <LanguageSelector smallScreen={true}/>
            </Grid>
        </Collapse>
    );
}

export default MobileNav;