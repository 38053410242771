import React, {useEffect, useState} from "react";
import {ContentRecord} from "../../MenuBuilder/Data/Records/Content";
import {getContent} from "../../MenuBuilder/Api/content";
import MenuItemEditor from "../Components/MenuItemEditor/MenuItemEditor";
import TextPageEditor from "../Components/TextPageEditor/TextPageEditor";
import BlockPageEditor from "../Components/BlockPageEditor/BlockPageEditor";
import NotificationEditor from "../Components/NotificationEditor/NotificationEditor";
import FileUploadEditor from "../Components/FileUploadEditor/FileUploadEditor";
import CityCouncilPageEditor from "../Components/CityCouncilPageEditor/CityCouncilPageEditor";
import ContentBox from "../../../../Components/ContentBox/ContentBox";
import {useTranslation} from "react-i18next";
import NavigateToContent from "./NavigateToContent";

const ContentEditor = ({contentId}) => {

    const [editor, setEditor] = useState({
        contentRecord: new ContentRecord(),
        component: <React.Fragment/>
    });

    const {t} = useTranslation('contentManagement');

    useEffect(() => {
        getContent(contentId, (data) => {
            setEditor({...editor, contentRecord: data});
        })
        // TODO clear
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const path = [
        {label: t('titleNav', {context: 'content'}), url: '/content-management'},
        {label: editor.contentRecord.title, url: `/content-management/${contentId}/edit`},
    ];

    useEffect(() => {
        const contentRecord = editor.contentRecord;
        switch (contentRecord.type) {
            case 'BLOCK':
                setEditor({
                    ...editor,
                    component: <BlockPageEditor contentId={contentId} blockPageId={contentRecord.page}/>
                });
                break;
            case 'TEXT':
                setEditor({
                    ...editor,
                    component: <TextPageEditor view='edit' contentId={contentId} textPageId={contentRecord.page}/>
                });
                break;
            case 'FILE_UPLOAD':
                setEditor({
                    ...editor,
                    component: <FileUploadEditor contentId={contentId} fileUploadPageId={contentRecord.page}/>
                });
                break;
            case  'KOLORAPP':
                setEditor({
                    ...editor,
                    component: <NotificationEditor view='edit' contentId={contentId}
                                                   notificationPageId={contentRecord.page}/>
                });
                break;
            case  'CITY_COUNCIL':
                setEditor({
                    ...editor,
                    component: <CityCouncilPageEditor view='edit' contentId={contentId}
                                                      cityCouncilPageId={contentRecord.page}/>
                });
                break;
            case  'LIST_OF_ITEM':
                setEditor({
                    ...editor,
                    component:
                        (contentRecord.status && contentRecord.status !== "HIDDEN") ?
                            <NavigateToContent view='edit' label={t("items:editingContent")}
                                               url={"/items"}/> : undefined

                });
                break;
            default:
                break;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editor.contentRecord]);

    return (
        <ContentBox path={path}>
            <MenuItemEditor view='edit'
                            record={editor.contentRecord}
            />
            {editor.component}
        </ContentBox>
    );

};

export default ContentEditor;