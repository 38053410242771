import React from "react";
import {addData, createRows, getAll, getOne, removeData, updateData} from "../util";
import {ListItemRecord, ListItemSchema} from "../../ContentManagement/ContentEditor/Data/ListItem";

import {listItemsApiTest} from "../../ContentManagement/ContentEditor/Api/listItems";

const record = ListItemRecord(ListItemSchema.default()).withMutations(data => {
    return data.set("id", 1)
        .set("name", "Fájl_01_elem_01")
        .set("attachments", "11")

})

const calls = [
    onResponse => addData("/file-upload-pages/1/items", record, onResponse),
    onResponse => getAll("/file-upload-pages/1/items", onResponse),
    //onResponse => getOne("/list-item/1", onResponse),
    onResponse => updateData("/list-item/1", record, onResponse),
    onResponse => removeData('/file-upload-pages/1/items/1', onResponse),
    onResponse => getAll('/list-item/1/attachments', onResponse),
];

const ListItemsApis = () => {

    return (
        <React.Fragment>
            {createRows(calls, listItemsApiTest)}
        </React.Fragment>
    );
}

export default ListItemsApis;