import React from "react";

import {useRoutes} from "hookrouter";
import {useTranslation} from "react-i18next";
import MainPage from "./MainPage";
import debug from "../../../Lib/debug";
import Meetings from "./Meetings/Meetings";
import Decisions from "./Decisions/Decisions";

const subRoutes = {
    "/": () => <CityCouncilPageMembers view='main'/>,
    "/meetings*": () => <CityCouncilPageMembers view='meetings'/>,
    "/decisions*": () => <CityCouncilPageMembers view='decisions'/>,
};

export default () => useRoutes(subRoutes);

const CityCouncilPageMembers = (props) => {

    const {view} = props;

    debug("RENDER", "Documents", () => [view]);

    const {t} = useTranslation('cityCouncilPage');

    const path = [{label: t('titleNav'), url: '/city-council/'}];

    switch (view) {
        case "main" :

            return <MainPage path={path}/>;

        case "meetings" :

            path.push({label: t('meetings'), url: "/city-council/meetings"});
            return <Meetings path={path}/>;

        case "decisions" :

            path.push({label: t('decisions'), url: "/city-council/decisions"});
            return <Decisions path={path}/>;

        default :
            return <div>invalid view: {view}</div>
    }
};