export const textPageEditorStyle = theme => ({
    editorBlock: {
        padding: '1rem',
    },
    descriptionContainer: {
        '& .quill .ql-editor': {
            minHeight: 200,
        },
    },
    textContentContainer: {
        padding: '1rem 0 2rem',
        '& .quill .ql-editor': {
            minHeight: 500,
        },
    },
});