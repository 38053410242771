import React from "react";
import {makeStyles} from "@material-ui/styles";
import {mainPageItemStyleBase as styleBase} from "./mainPageItemStyleBase";
import {mainPageItemStyleDense as styleDense} from "./mainPageItemStyleDense";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import {A} from "hookrouter";
import {useTranslation} from "react-i18next";
import moment from "moment";

const useStyles = makeStyles(styleBase);
const useStylesDense = makeStyles(styleDense);

const makeUrl = (type, id) => {
    switch (type) {
        case 'BLOCK':
            return `/actuality/block-pages/${id}/blocks`;
        case 'TEXT':
            return `/actuality/text-pages/${id}`;
        case 'FILE_UPLOAD':
            return `/actuality/file-upload-pages/${id}`;
        case  'KOLORAPP':
            return `/actuality/kolorapp-pages/${id}`;
        case  'CITY_COUNCIL':
            return `/actuality/city-council-pages/${id}`;
        case  'LIST_OF_ITEM':
            return `/items`;
        default:
            return `/kolorline-news/${id}`;
    }
};

function MainPageItem({item, dense}) {

    const classesBase = useStyles();
    const classesDense = useStylesDense();

    const classes = dense ? classesDense : classesBase;

    const {t} = useTranslation('general');

    const content = dense ?
        <Typography variant='subtitle1' className={classes.content}>
            {item.lead}
        </Typography> :
        <Box className={classes.content + " ql-display"} dangerouslySetInnerHTML={{__html: item.description}}/>;
    const picture = dense ? item.image_sm : item.picture ? `/api/1.0.0/images/${item.picture}` : '';
    const href = makeUrl(item.type, item.id);
    const subtitle = dense ?
        item.posted_at :
        moment(item.createdAt).format('YYYY. MM. DD.');

    return (
        <Grid container className={classes.mainPageItem} component='div'>
            <Grid item container component='div' className={classes.header}>
                <Grid item xs={12}>
                    <Typography variant='h2' className={classes.title} dangerouslySetInnerHTML={{__html: item.title}}/>
                </Grid>
                {!dense &&
                <Grid item xs={12}>
                    <Typography variant='subtitle1' className={classes.subtitle}>
                        {subtitle}
                    </Typography>
                </Grid>
                }
            </Grid>
            {dense &&
            <Grid item>
                <Typography variant='subtitle2' className={classes.subtitle}>
                    {subtitle}
                </Typography>
            </Grid>
            }
            {picture &&
            <Grid item className={classes.imageBox}>
                <img src={picture} className={classes.image} alt={item.altText}/>
            </Grid>
            }
            <Box className={classes.contentBox}>
                {content}
                <A href={href} className={classes.contentMoreBtn}>{t('More')}...</A>
            </Box>
        </Grid>
    );
}

export default MainPageItem;