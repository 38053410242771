export const mainPageItemStyleBase = theme => ({
    mainPageItem: {
        // padding: theme.spacing(2),
        border: 'unset',
        boxShadow: 'unset',
    },

    header: {
        alignItems: 'baseline'
    },

    title: {
        color: theme.palette.text.primary,
        fontWeight: 'bold',
        fontSize: 40,
        marginBottom: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
            fontSize: 56,
        },
        [theme.breakpoints.up('md')]: {
            fontSize: 60,
            // marginBottom: theme.spacing(6),
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: 72,
        },
    },

    subtitle: {
        fontWeight: 'bold',
        paddingLeft: '.5rem',
        marginBottom: theme.spacing(2),
        [theme.breakpoints.up('md')]: {
            marginBottom: theme.spacing(4),
        },
    },

    imageBox: {
        paddingLeft: '.5rem',
        height: '29vh',
        marginBottom: theme.spacing(2),
        overflowY: 'hidden',
        display: 'flex',
        alignItems: 'center',
        '& img': {

        },
        [theme.breakpoints.up('xs')]: {
            maxWidth: theme.gridWidths[12],
            flexBasis: theme.gridWidths[12],
        },
        [theme.breakpoints.up('sm')]: {
            height: '30vh'
        },
        [theme.breakpoints.up('md')]: {
            height: '40vh',
            marginBottom: theme.spacing(6)
        },
    },

    image: {
        ...theme.imgFluid,
    },

    contentBox: {
        marginBottom: theme.spacing(6),
        paddingLeft: '.5rem',
    },

    content: {
        ...theme.typography.h5,
        color: theme.palette.text.primary,
        marginBottom: theme.spacing(2),
        '& > *:first-child': {
            marginTop: 0,
        },
        '& > *:only-child': {
            marginTop: 0,
            marginBottom: 0,
        },
        '& > *:last-child': {
            marginTop: 0,
        },
    },

    contentMoreBtn: {
        textDecoration: 'none',
        color: theme.palette.text.primary,
        borderLeft: `2px solid ${theme.palette.kolorcity[5]}`,
        paddingLeft: theme.spacing(.5),
    }
});