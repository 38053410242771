import React from "react";
import List from "./List";
import {useRoutes} from "hookrouter";
import {useTranslation} from "react-i18next";
import debug from "../../../Lib/debug";
import Form from "./Form";
import {AccountRecord, AccountSchema} from '../../../Data/Account';
import {addAccount, removeAccount, updateAccount, useAccount} from "../../../Api/accounts";

export default () => useRoutes(subRoutes);

const subRoutes = {
  "/": () => <CouncilMembers view="list"/>,
  "/add": () => <CouncilMembers view="add"/>,
  "/:id/edit": ({id}) => <CouncilMembers view="edit" id={id}/>,
  "/:id/remove": ({id}) => <CouncilMembers view="remove" id={id}/>,
};

const CouncilMembers = ({view, id}) => {

  debug("RENDER", "CouncilMembers", () => [view]);

  const {t} = useTranslation('councilMembers');

  const {loadingStatus, data} = useAccount(id);

  const viewData = (loadingStatus ? new AccountRecord(AccountSchema.default()) : data);

  const path = [{label: t('titleNav'), url: "/council-members"}];

  // noinspection JSCheckFunctionSignatures
  const label = viewData.get('fullName');

  switch (view) {
    case "list" :

      return <List path={path}/>;

    case "add" :

      path.push({label: label, url: "/CouncilMembers/add"});
      return <Form id={id} record={viewData.set('roles', ['SITE_MEMBER'])} path={path} view={view} api={addAccount}/>;

    case "remove" :

      path.push({label: label, url: "/CouncilMembers/" + id + "/remove"});
      return <Form id={id} record={viewData} path={path} view={view} api={removeAccount}/>;

    case "edit" :

      path.push({label: label, url: "/CouncilMembers/" + id + "/edit"});
      return <Form id={id} record={viewData.set('credentials', 'hipphopp123').set('verification', 'hipphopp123')} path={path} view={view} api={updateAccount}/>;

    default :
      return <div>invalid view: {view}</div>
  }
};

