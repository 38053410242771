const supportEmail = "info@simplexion.hu";

export const accountHu = {
  "titleNav": "Felhasználói fiókok",
  "titleNavPersonal": "Felhasználói fiók",

  "login": "Belépés",
  "password": "Jelszó",
  "error": "Hiba, kérlek írj a " + supportEmail + " címre.",
  "error404": "Érvénytelen fiók és/vagy jelszó.",
  "error406": "Zárolt fiók, kérlek írj a " + supportEmail + " címre, hogy feloldjuk a zárolást.",
  "error412": "Inaktív fiók, kérlek aktiváld a fiókot (ment róla e-mail) vagy írj a " + supportEmail + " címre.",

  "add": "Fiók létrehozása",
  "edit": "Fiók szerkesztése",
  "remove": "Fiók törlése",
  "delete": "Törlés",

  "invalid-fields": "Érvényelen mezők",
  "invalid-fields-explanation": "Az alábbi mezők tartalma még nem megfelelő, a regisztrációt csak akkor tudod beküldeni, ha ezeket az adatokat helyesen megadtad.",
  "existing-name": "Ez a fióknév már foglalt. Ha korábban regisztráltál egy ilyen nevű fiókot, kérlek használd az elfelejtett jelszó funkciót vagy írj a " + supportEmail + " címre.",
  "close": "bezár",

  "principalName": "Fiók név",
  "principalName-invalid": "Legalább 3, legfeljebb 50 karakter.",
  "emailAddress": "E-mail cím",
  "emailAddress-invalid": "Érvénytelen cím.",
  "credentials": "Jelszó",
  "credentials-invalid": "Legalább 8 karakter.",
  "verification": "Megerősítés",
  "verification-invalid": "A két jelszó nem azonos.",
  "forgottenPassword": "Elfelejtett jelszó",
  "password-invalid": "Érvénytelen jelszó",
  "fullName": "Teljes név",
  "fullName-invalid": "Legalább 5 karakter.",

  "status" : "Fiók állapot",
  "active" : "Aktív",
  "inactive" : "Inaktív",

  "account-fields": "Fiók adatok",
  "account-fields-explanation": "Az itt megadott fióknevet és jelszót kell majd megadnod a belépésnél. A fióknév nem látszik a többi felhasználó számára. Kérlek ne hozz létre több fiókot egy személyhez, ha nem tudsz belépni egy korábban létrehozott fiókkal, írj a " + supportEmail + " email címre.",

  "displayName" : "Megjelenítendő név",
  "displayName-invalid" : "Megjelenítendő név nem érvényes. A megjelenítendő névnek legalább 2 karakter hosszúnak kell lennie.",
  "account" : "Fiók",
  "accounts" : "Fiókok",
  "registration" : "Regisztráció",
  "language": "Nyelv",
  "language-invalid" : "A nyelv minimum és maximum kettő karakter lehet.",
  "picture" : "Kép",
  "district" : "Választó kerület",
  "organisation" : "Szervezet",
  "committee" : "Bizottság",
  "assignment" : "Kinevezés",
  "back" : "Vissza",
  "save" : "Fiók mentése",
  "changeImage" : "Kép megváltoztatása",
  "roles": "Jogosultságok",
  "ADMIN": "Admin",
  "COUNCIL_ADMIN": "Képviselő testületi anyagok szerkesztője",
  "CM_ADMIN": "Tartalomkezelő",
  "UPLOAD_ADMIN": "Fájl feltöltő",
  "profile": "Profil",

  "reset-start-title": "Jelszó megújítás",
  "reset-start-explanation": "A megújításhoz kérlek add meg fiókod nevét és az e-mail címed, majd klikkelj a Megújítás gombra. Ha az adatok megfelelőek, kapni fogsz egy e-mailt, amelyben benne lesz a megújításhoz szükséges titkos kód.",
  "reset-start-button": "Küldés",

  "reset-start-success": "Sikeresen megkezdődött a megújítás.",
  "reset-start-success-explanation": "A jelszó megújítása megkezdődött, a rendszer küldeni fog egy e-mailt a megújításhoz szükséges titkos kóddal. A titkos kód 8 órán át érvényes, utána újat kell kérned.",
  "reset-start-error": "Nem sikerült elindítani a megújítást.",

  "password-change-title": "Jelszóváltozatás",
  "password-change-explanation": "Kérlek add a régi és az új jelszót.",
  "password-change-button": "Végrehajtás",

  "reset-finish-title": "Új jelszó megadása",
  "reset-finish-explanation": "Kérlek add az új jelszót.",
  "reset-finish-button": "Beállítás",

  "secret": "Titkos kód",
  "oldPassword": "Régi jelszó",
  "newPassword": "Új jelszó",
  "newPassword-invalid": "Az új jelszónak legalább 8 karakternek kell lennie.",

  "reset-finish-success": "Sikeres jelszó megújítás",
  "reset-finish-success-explanation": "Az új jelszó beállítása sikerült, erre a gombra kattintva be tudsz lépni a rendszerbe:",
  "reset-finish-error": "Az új jelszó beállítása nem sikerült, kérlek vedd fel velünk a kapcsolatot a " + supportEmail + " címen.",

  "activate-title": "Fiók aktiválása",
  "activate-in-progress": "A fiók aktiválása folyamatban.",
  "activate-success": "A fiók aktiválása sikerült, erre a gombra kattintva be tudsz lépni a rendszerbe:",
  "activate-error": "A fiók aktiválása nem sikerült, kérlek vedd fel velünk a kapcsolatot a " + supportEmail + " címen.",

  "password-change" : "Jelszó változtatás",
  "password-change-error" : "Nem sikerült megváltoztatni a jelszót.",
  "password-change-success" : "Sikeres jelszó változtatás",
  "password-change-success-explanation": "Az új jelszó beállítása megtörtént. A következő bejelentkezés alkalmával a most megadott új jelszót kell használnod.",

  "expired-session" : "Lejárt munkamenet",
  "expired-session-explanation": "A munkamenet lejárt, ezért újból be kell jelentkezned: "
};


export const accountEn = {
  "titleNav": "Felhasználói fiókok",

  "login": "Belépés",
  "password": "Jelszó",
  "error": "Hiba, kérlek írj a " + supportEmail + " címre.",
  "error404": "Érvénytelen fiók és/vagy jelszó.",
  "error406": "Zárolt fiók, kérlek írj a " + supportEmail + " címre, hogy feloldjuk a zárolást.",
  "error412": "Inaktív fiók, kérlek aktiváld a fiókot (ment róla e-mail) vagy írj a " + supportEmail + " címre.",

  "add": "Regisztráció",
  "edit": "Fiók szerkesztése",

  "invalid-fields": "Érvényelen mezők",
  "invalid-fields-explanation": "Az alábbi mezők tartalma még nem megfelelő, a regisztrációt csak akkor tudod beküldeni, ha ezeket az adatokat helyesen megadtad.",
  "existing-name": "Ez a fióknév már foglalt. Ha korábban regisztráltál egy ilyen nevű fiókot, kérlek használd az elfelejtett jelszó funkciót vagy írj a " + supportEmail + " címre.",
  "close": "bezár",

  "principalName": "Fiók név",
  "principalName-invalid": "Legalább 3, legfeljebb 50 karakter.",
  "emailAddress": "E-mail cím",
  "emailAddress-invalid": "Érvénytelen cím.",
  "credentials": "Jelszó",
  "credentials-invalid": "Legalább 8 karakter.",
  "verification": "Megerősítés",
  "verification-invalid": "A két jelszó nem azonos.",
  "forgottenPassword": "Elfelejtett jelszó",
  "password-invalid": "Érvénytelen jelszó",
  "fullName": "Teljes név",
  "fullName-invalid": "Legalább 5 karakter.",

  "account-fields": "Fiók adatok",
  "account-fields-explanation": "Az itt megadott fióknevet és jelszót kell majd megadnod a belépésnél. A fióknév nem látszik a többi felhasználó számára. Kérlek ne hozz létre több fiókot egy személyhez, ha nem tudsz belépni egy korábban létrehozott fiókkal, írj a " + supportEmail + " email címre.",

  "displayName" : "Megjelenítendő név",
  "displayName-invalid" : "Megjelenítendő név nem érvényes. A megjelenítendő névnek legalább 2 karakter hosszúnak kell lennie.",
  "account" : "Fiók",
  "accounts" : "Fiókok",
  "registration" : "Regisztráció",
  "language": "Nyelv",
  "language-invalid" : "A nyelv minimum és maximum kettő karakter lehet.",
  "picture" : "Kép",
  "district" : "Választó kerület",
  "organisation" : "Szervezet",
  "committee" : "Bizottság",
  "assignment" : "Kinevezés",
  "back" : "Vissza",
  "save" : "Fiók mentése",
  "changeImage" : "Kép megváltoztatása",

  "reset-start-title": "Jelszó megújítás",
  "reset-start-explanation": "A megújításhoz kérlek add meg fiókod nevét és az e-mail címed, majd klikkelj a Megújítás gombra. Ha az adatok megfelelőek, kapni fogsz egy e-mailt, amelyben benne lesz a megújításhoz szükséges titkos kód.",
  "reset-start-button": "Megújítás",

  "reset-start-success": "Sikeresen megkezdődött a megújítás.",
  "reset-start-success-explanation": "A jelszó megújítása megkezdődött, a rendszer küldeni fog egy e-mailt a megújításhoz szükséges titkos kóddal. A titkos kód 8 órán át érvényes, utána újat kell kérned.",
  "reset-start-error": "Nem sikerült elindítani a megújítást.",

  "password-change-title": "Jelszóváltozatás",
  "password-change-explanation": "Kérlek add a régi és az új jelszót.",
  "password-change-button": "Végrehajtás",

  "reset-finish-title": "Új jelszó megadása",
  "reset-finish-explanation": "Kérlek add az új jelszót.",
  "reset-finish-button": "Beállítás",

  "secret": "Titkos kód",
  "oldPassword": "Régi jelszó",
  "newPassword": "Új jelszó",
  "newPassword-invalid": "Az új jelszónak legalább 8 karakternek kell lennie.",

  "reset-finish-success": "Sikeres jelszó megújítás",
  "reset-finish-success-explanation": "Az új jelszó beállítása sikerült, erre a gombra kattintva be tudsz lépni a rendszerbe:",
  "reset-finish-error": "Az új jelszó beállítása nem sikerült, kérlek vedd fel velünk a kapcsolatot a " + supportEmail + " címen.",

  "activate-title": "Fiók aktiválása",
  "activate-in-progress": "A fiók aktiválása folyamatban.",
  "activate-success": "A fiók aktiválása sikerült, erre a gombra kattintva be tudsz lépni a rendszerbe:",
  "activate-error": "A fiók aktiválása nem sikerült, kérlek vedd fel velünk a kapcsolatot a " + supportEmail + " címen.",

  "password-change" : "Jelszó változtatás",
  "password-change-error" : "Nem sikerült megváltoztatni a jelszót.",
  "password-change-success" : "Sikeres jelszó változtatás",
  "password-change-success-explanation": "Az új jelszó beállítása megtörtént. A következő bejelentkezés alkalmával a most megadott új jelszót kell használnod.",

  "expired-session" : "Lejárt munkamenet",
  "expired-session-explanation": "A munkamenet lejárt, ezért újból be kell jelentkezned: "
};

export const accountDe = {
  "titleNav": "Felhasználói fiókok",

  "login": "Belépés",
  "password": "Jelszó",
  "error": "Hiba, kérlek írj a " + supportEmail + " címre.",
  "error404": "Érvénytelen fiók és/vagy jelszó.",
  "error406": "Zárolt fiók, kérlek írj a " + supportEmail + " címre, hogy feloldjuk a zárolást.",
  "error412": "Inaktív fiók, kérlek aktiváld a fiókot (ment róla e-mail) vagy írj a " + supportEmail + " címre.",

  "add": "Regisztráció",
  "edit": "Fiók szerkesztése",

  "invalid-fields": "Érvényelen mezők",
  "invalid-fields-explanation": "Az alábbi mezők tartalma még nem megfelelő, a regisztrációt csak akkor tudod beküldeni, ha ezeket az adatokat helyesen megadtad.",
  "existing-name": "Ez a fióknév már foglalt. Ha korábban regisztráltál egy ilyen nevű fiókot, kérlek használd az elfelejtett jelszó funkciót vagy írj a " + supportEmail + " címre.",
  "close": "bezár",

  "principalName": "Fiók név",
  "principalName-invalid": "Legalább 3, legfeljebb 50 karakter.",
  "emailAddress": "E-mail cím",
  "emailAddress-invalid": "Érvénytelen cím.",
  "credentials": "Jelszó",
  "credentials-invalid": "Legalább 8 karakter.",
  "verification": "Megerősítés",
  "verification-invalid": "A két jelszó nem azonos.",
  "forgottenPassword": "Elfelejtett jelszó",
  "password-invalid": "Érvénytelen jelszó",
  "fullName": "Teljes név",
  "fullName-invalid": "Legalább 5 karakter.",

  "account-fields": "Fiók adatok",
  "account-fields-explanation": "Az itt megadott fióknevet és jelszót kell majd megadnod a belépésnél. A fióknév nem látszik a többi felhasználó számára. Kérlek ne hozz létre több fiókot egy személyhez, ha nem tudsz belépni egy korábban létrehozott fiókkal, írj a " + supportEmail + " email címre.",

  "displayName" : "Megjelenítendő név",
  "displayName-invalid" : "Megjelenítendő név nem érvényes. A megjelenítendő névnek legalább 2 karakter hosszúnak kell lennie.",
  "account" : "Fiók",
  "accounts" : "Fiókok",
  "registration" : "Regisztráció",
  "language": "Nyelv",
  "language-invalid" : "A nyelv minimum és maximum kettő karakter lehet.",
  "picture" : "Kép",
  "district" : "Választó kerület",
  "organisation" : "Szervezet",
  "committee" : "Bizottság",
  "assignment" : "Kinevezés",
  "back" : "Vissza",
  "save" : "Fiók mentése",
  "changeImage" : "Kép megváltoztatása",

  "reset-start-title": "Jelszó megújítás",
  "reset-start-explanation": "A megújításhoz kérlek add meg fiókod nevét és az e-mail címed, majd klikkelj a Megújítás gombra. Ha az adatok megfelelőek, kapni fogsz egy e-mailt, amelyben benne lesz a megújításhoz szükséges titkos kód.",
  "reset-start-button": "Megújítás",

  "reset-start-success": "Sikeresen megkezdődött a megújítás.",
  "reset-start-success-explanation": "A jelszó megújítása megkezdődött, a rendszer küldeni fog egy e-mailt a megújításhoz szükséges titkos kóddal. A titkos kód 8 órán át érvényes, utána újat kell kérned.",
  "reset-start-error": "Nem sikerült elindítani a megújítást.",

  "password-change-title": "Jelszóváltozatás",
  "password-change-explanation": "Kérlek add a régi és az új jelszót.",
  "password-change-button": "Végrehajtás",

  "reset-finish-title": "Új jelszó megadása",
  "reset-finish-explanation": "Kérlek add az új jelszót.",
  "reset-finish-button": "Beállítás",

  "secret": "Titkos kód",
  "oldPassword": "Régi jelszó",
  "newPassword": "Új jelszó",
  "newPassword-invalid": "Az új jelszónak legalább 8 karakternek kell lennie.",

  "reset-finish-success": "Sikeres jelszó megújítás",
  "reset-finish-success-explanation": "Az új jelszó beállítása sikerült, erre a gombra kattintva be tudsz lépni a rendszerbe:",
  "reset-finish-error": "Az új jelszó beállítása nem sikerült, kérlek vedd fel velünk a kapcsolatot a " + supportEmail + " címen.",

  "activate-title": "Fiók aktiválása",
  "activate-in-progress": "A fiók aktiválása folyamatban.",
  "activate-success": "A fiók aktiválása sikerült, erre a gombra kattintva be tudsz lépni a rendszerbe:",
  "activate-error": "A fiók aktiválása nem sikerült, kérlek vedd fel velünk a kapcsolatot a " + supportEmail + " címen.",

  "password-change" : "Jelszó változtatás",
  "password-change-error" : "Nem sikerült megváltoztatni a jelszót.",
  "password-change-success" : "Sikeres jelszó változtatás",
  "password-change-success-explanation": "Az új jelszó beállítása megtörtént. A következő bejelentkezés alkalmával a most megadott új jelszót kell használnod.",

  "expired-session" : "Lejárt munkamenet",
  "expired-session-explanation": "A munkamenet lejárt, ezért újból be kell jelentkezned: "
};

