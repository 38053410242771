import React from "react";
import useNav from "../../../Lib/useNav";
import {useRoutes} from "hookrouter";
import {useTranslation} from "react-i18next";
import debug from "../../../Lib/debug";
import MainPage from "./MainPage";
import Meetings from "./Meetings/Meetings";
import Decisions from "./Decisions/Decisions";

const subRoutes = {
    "/": () => props => <CityCouncilPage view='main' {...props}/>,
    "/meetings*": () => props => <CityCouncilPage view='meetings' {...props}/>,
    "/decisions*": () => props => <CityCouncilPage view='decisions' {...props}/>,
};

export default (props) => useRoutes(subRoutes)(props);

const CityCouncilPage = props => {

    useNav({path: window.location.pathname});

    const {view, pageId, path} = props;

    debug("RENDER", "Documents", () => [view]);

    const lineColor = path.getIn([0, "color"]);

    const {t} = useTranslation('cityCouncilPage');

    const updatedPath = path.withMutations(p => {

        const url = `/city-council-pages/${pageId}/${view}`;

        if (view !== 'main' && !path.find(p => p.url === url)) {
            p.push({label: t(view), url: url, color: lineColor});
        }
    });

    switch (view) {
        case "main" :

            return <MainPage path={updatedPath} pageId={pageId}/>;

        case "meetings" :

            return <Meetings path={updatedPath} pageId={pageId}/>;

        case "decisions" :

            return <Decisions path={updatedPath} pageId={pageId}/>;

        default :
            return <div>invalid view: {view}</div>
    }
};
