import React from "react";
import CustomInput from "../../../material-dashboard/components/CustomInput/CustomInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import {useFormField} from "../../useFormField";

function ValidatedInput(props) {
    const {name, adornment, multiline, rows, password, compose, decompose, autoComplete, formControlClassName} = props;

    const ff = useFormField(props);

    return <CustomInput

        id={ff.id}

        labelText={ff.labelText}
        helperText={ff.helperText}

        success={ff.status === "valid"}
        error={ff.status === "invalid"}

        disabled={ff.disabled}

        formControlProps={{
            fullWidth: true,
            className: formControlClassName ? formControlClassName : '',
        }}

        inputProps={{
            name: name,
            disabled: ff.disabled,
            value: compose ? compose(ff.value) : ff.value,
            multiline: multiline,
            rows: rows,
            type: password ? 'password' : 'text',
            autoComplete: autoComplete,
            startAdornment: adornment ? <InputAdornment position="start">{adornment}</InputAdornment> : undefined,
            onChange: (e) => ff.onChange(decompose ? decompose(e.target.value) : e.target.value)
        }}
    />
}

export default ValidatedInput;