import React from "react";
import {roles} from "./roles";
import MainPage from "../Views/MainPage/MainPage";
import Committees from "../Views/Committees/Committees";
import Organisations from "../Views/Organisations/Organisations";
import Positions from "../Views/Positions/Positions";
import Meeting from "../Views/Meetings/Meetings";
import Accounts from "../Views/Account/Accounts";
import ExpiredSession from "../Views/Account/ExpiredSession";
import Login from "../Views/Login/Login";
import Logout from "../Views/Logout/Logout";
import Admin from "../Views/Admin/Admin";
import KolorLineNews from "../Views/KolorLineNews/KolorLineNews";
import Persons from "../Views/Account/CouncilMembers/CouncilMembers";
import MeetingElements from "../Views/MeetingElements/MeetingElements";
import Decisions from "../Views/Decisions/Decisions";
import CityCouncilPageMembers from "../Views/CityCouncilPage/Members/CityCouncilPageMembers"
import Invitations from "../Views/Invitations/Invitations";
import Profile from "../Views/Profile/Profile";
import ContentManager from "../Lib/ContentManagement/ContentManager";
import DesignEditor from "../Lib/ContentManagement/DesignEditor/Views/DesignEditor";
import PublicInformation from "../Views/PublicInformation/PublicInformation";
import Impressum from "../Views/Impressum/Impressum";
import Roles from "../Lib/auth/Views/Roles/Roles";
import Contact from "../Views/Contact/Contact";
import Actuality from "../Views/Actuality/Actuality";
import Notifications from "../Views/Notifications/Notifications";
import SearchResultsPage from "../Views/SearchResultsPage/SearchResultsPage";
import StoredFiles from "../Views/StoredFiles/StoredFiles"
import TestPage from "../Lib/testing-tools/TestPage";
import SystemSettings from "../Lib/auth/Views/SystemSettings/SystemSettings";

/**
 * Configuration of the navigation tree and site routing, filtered by methods below
 * at the time of initial page load and login/logout.
 */

export const config = [
    {
        path: '/',
        title: 'Home',
        render: () => <MainPage/>,
        roles: [roles.ANONYMOUS, roles.SITE_MEMBER, roles.ADMIN, roles.CM_ADMIN, roles.COUNCIL_ADMIN, roles.UPLOAD_ADMIN, roles.SECURITY_OFFICER],
    },
    {
        path: "/admin*",
        render: () => <Admin/>,
        roles: [roles.SITE_MEMBER, roles.ADMIN, roles.CM_ADMIN, roles.COUNCIL_ADMIN, roles.UPLOAD_ADMIN, roles.SECURITY_OFFICER],
        views: [
            {
                path: "/content-management*",
                title: "contentManagement:titleNav_content",
                render: () => <ContentManager/>,
                roles: [roles.ADMIN, roles.CM_ADMIN, roles.UPLOAD_ADMIN, roles.SECURITY_OFFICER],
            },
            {
                path: "/city-council*",
                title: "cityCouncilPage:titleNav",
                render: () => <CityCouncilPageMembers/>,
                roles: [roles.SITE_MEMBER, roles.COUNCIL_ADMIN, roles.ADMIN,],
            },
            {
                path: "/profile*",
                title: "account:titleNavPersonal",
                render: () => <Profile/>,
                roles: [roles.SITE_MEMBER, roles.ADMIN, roles.CM_ADMIN, roles.COUNCIL_ADMIN, roles.UPLOAD_ADMIN],
            },
            {
                path: "/council*",
                title: "Testületi anyagok",
                collapse: true,
                state: "councilCollapse",
                roles: [roles.ADMIN, roles.COUNCIL_ADMIN, roles.SECURITY_OFFICER],
                views: [
                    {
                        path: "/committees*",
                        title: "committees:titleNav",
                        render: () => <Committees/>,
                        roles: [roles.ADMIN, roles.COUNCIL_ADMIN, roles.SECURITY_OFFICER]
                    },
                    {
                        path: "/council-members*",
                        title: "councilMembers:titleNav",
                        render: () => <Persons/>,
                        roles: [roles.ADMIN, roles.COUNCIL_ADMIN, roles.SECURITY_OFFICER]
                    },
                    {
                        path: "/organisations*",
                        title: "organisations:titleNav",
                        render: () => <Organisations/>,
                        roles: [roles.ADMIN, roles.COUNCIL_ADMIN, roles.SECURITY_OFFICER]
                    },
                    {
                        path: "/positions*",
                        title: "positions:titleNav",
                        render: () => <Positions/>,
                        roles: [roles.ADMIN, roles.COUNCIL_ADMIN, roles.SECURITY_OFFICER]
                    },
                    {
                        path: "/meetings*",
                        title: "meetings:titleNav",
                        render: () => <Meeting/>,
                        roles: [roles.ADMIN, roles.COUNCIL_ADMIN, roles.SECURITY_OFFICER]
                    },
                    {
                        path: "/meeting-elements*",
                        title: "meetingElements:titleNav",
                        render: () => <MeetingElements/>,
                        roles: [roles.ADMIN, roles.COUNCIL_ADMIN, roles.SECURITY_OFFICER]
                    },
                    {
                        path: "/decisions*",
                        title: "decisions:titleNav",
                        render: () => <Decisions/>,
                        roles: [roles.ADMIN, roles.COUNCIL_ADMIN, roles.SECURITY_OFFICER]
                    },
                    {
                        path: "/invitations*",
                        title: "invitations:titleNav",
                        render: () => <Invitations/>,
                        roles: [roles.ADMIN, roles.COUNCIL_ADMIN, roles.SECURITY_OFFICER]
                    },
                ],
            },
            {
                path: "/stored-files",
                title: "storedFiles:titleNav",
                render: () => <StoredFiles/>,
                roles: [roles.ADMIN, roles.SECURITY_OFFICER],
            },
            {
                path: "/site-administration*",
                title: "Admin",
                collapse: true,
                state: "adminCollapse",
                roles: [roles.ADMIN, roles.SECURITY_OFFICER],
                views: [
                    {
                        path: "/admin/accounts*",
                        title: "account:titleNav",
                        render: () => <Accounts/>,
                        roles: [roles.ADMIN, roles.SECURITY_OFFICER]
                    },
                    {
                        path: "/admin/roles*",
                        title: "roles:roles",
                        render: () => <Roles/>,
                        roles: [roles.ADMIN, roles.SECURITY_OFFICER]
                    },
                    {
                        path: "/admin/design",
                        title: "contentManagement:titleNav_design",
                        render: () => <DesignEditor/>,
                        roles: [roles.ADMIN, roles.CM_ADMIN, roles.SECURITY_OFFICER],
                    },
                    {
                        path: "/admin/public-information",
                        title: "publicInformation:titleNav",
                        render: () => <PublicInformation/>,
                        roles: [roles.ADMIN, roles.CM_ADMIN, roles.SECURITY_OFFICER],
                    },
                    {
                        path: "/admin/notifications*",
                        title: "notifications:titleNav",
                        render: () => <Notifications/>,
                        roles: [roles.ADMIN, roles.SECURITY_OFFICER]
                    },
                    {
                        path: "/admin/system-settings*",
                        title: "systemSettings:systemSettings",
                        render: () => <SystemSettings/>,
                        roles: [roles.SECURITY_OFFICER]
                    },
                ],
            },
            {
                path: "/logout",
                title: "general:logout",
                color: 'Yellow',
                render: () => <Logout/>,
                roles: [roles.SITE_MEMBER, roles.ADMIN, roles.CM_ADMIN, roles.COUNCIL_ADMIN, roles.UPLOAD_ADMIN, roles.SECURITY_OFFICER]
            },
        ]
    },
    {
        path: '/actuality*',
        title: 'Actualities',
        render: () => <Actuality/>,
        roles: [roles.ANONYMOUS, roles.SITE_MEMBER, roles.ADMIN, roles.CM_ADMIN, roles.COUNCIL_ADMIN, roles.UPLOAD_ADMIN, roles.SECURITY_OFFICER],
    },
    {
        path: '/kolorline-news*',
        title: 'general:kolorLineNews',
        render: () => <KolorLineNews/>,
        roles: [roles.ANONYMOUS, roles.SITE_MEMBER, roles.ADMIN, roles.CM_ADMIN, roles.COUNCIL_ADMIN, roles.UPLOAD_ADMIN, roles.SECURITY_OFFICER],
    },
    {
        path: '/contact',
        title: 'general:Contact',
        render: () => <Contact/>,
        roles: [roles.ANONYMOUS, roles.SITE_MEMBER, roles.ADMIN, roles.CM_ADMIN, roles.COUNCIL_ADMIN, roles.UPLOAD_ADMIN, roles.SECURITY_OFFICER],
    },
    {
        path: '/impressum',
        title: 'general:Impressum',
        render: () => <Impressum/>,
        roles: [roles.ANONYMOUS, roles.SITE_MEMBER, roles.ADMIN, roles.CM_ADMIN, roles.COUNCIL_ADMIN, roles.UPLOAD_ADMIN, roles.SECURITY_OFFICER],
    },
    {
        path: "/test-page",
        title: "general:Test",
        render: () => <TestPage/>,
        roles: [roles.ANONYMOUS, roles.SITE_MEMBER, roles.ADMIN, roles.CM_ADMIN, roles.COUNCIL_ADMIN, roles.UPLOAD_ADMIN, roles.SECURITY_OFFICER],
    },
    {
        path: "/accounts*",
        title: "account:titleNav",
        render: () => <Accounts/>,
        roles: [roles.ANONYMOUS]
    },
    {
        path: "/search*",
        title: "search:titleNav",
        render: () => <SearchResultsPage/>,
        roles: [roles.ANONYMOUS, roles.SITE_MEMBER, roles.ADMIN, roles.CM_ADMIN, roles.COUNCIL_ADMIN, roles.UPLOAD_ADMIN, roles.SECURITY_OFFICER]
    },
    {
        path: "/expired-session",
        render: () => <ExpiredSession/>,
        roles: [roles.ANONYMOUS, roles.SITE_MEMBER]
    },
    {
        path: "/login",
        title: "Login",
        color: 'Blue',
        render: () => <Login/>,
        roles: [roles.ANONYMOUS]
    },
];