import React, {memo} from "react";
import ContentBox from "../../Components/ContentBox/ContentBox";
import {useDocuments} from "../../Api/documents";
import {customActions, customActionsStyle} from "../../Lib/simplexion-table/templates/action-buttons";
import {baseTableStyles} from "../../Lib/simplexion-table/styles/baseStyle";
import {makeStyles} from "@material-ui/core";
import Section from "../../Lib/form/Components/Section/Section";
import {SimplexionTable} from "../../Lib/simplexion-table/SimplexionTable";
import {translatableField} from "../../Lib/simplexion-table/templates/translatable-field";
import {Navigation} from "../MeetingElements/Navigation";

const styles = () => {
    let classes = baseTableStyles;
    Object.entries(customActionsStyle).forEach(entry => {
        classes[entry[0]] = entry[1];
    });
    return classes;
};

const useStyles = makeStyles(styles);

const List = memo((props) => {

    const {meetingId, elementId, path} = props;

    const classes = useStyles();

    const urlNs = "/meeting-elements/" + meetingId + "/element/" + elementId + "/documents/";

    const {data} = useDocuments(elementId);

    const tableConfig = {
        id: 'documents-table',
        nameSpace: 'documents',
        stickyHeader: true,
        sortable: true,
        styles: classes,
        columns: [
            {
                accessor: 'title',
            },
            {
                accessor: 'status',
                renderer: (...args) => translatableField(...args),
            },
            {
                accessor: 'actions',
                renderer: (...args) => customActions(...args, true, true, urlNs),
            },
        ],
    };

    return (
        <ContentBox path={path} add={urlNs + "add"}>
            <Navigation meetingId={meetingId} elementId={elementId}/>
            <Section>
                <SimplexionTable tableConfig={tableConfig} data={data}/>
            </Section>
        </ContentBox>
    );
});

export default List;