import React from "react";
import {useRoutes} from "hookrouter";
import {useMeeting} from "../../../../Api/meetings";
import {MeetingRecord, MeetingSchema} from "../../../../Data/Meeting";
import MeetingDetails from "./MeetingDetails";
import ItemDetails from "./ItemDetails";
import debug from "../../../../Lib/debug";
import MeetingList from "./MeetingList";

const subRoutes = {
    "/": () => props => <Meetings view='meetingList' {...props}/>,
    "/:meetingId": ({meetingId}) => props => <Meetings view='meetingDetails' meetingId={meetingId} {...props}/>,
    "/:meetingId/items/:itemId": ({meetingId, itemId}) => props => <Meetings view='itemDetails'
                                                                                      meetingId={meetingId}
                                                                                      itemId={itemId}
                                                                                      {...props}/>
};

export default props => useRoutes(subRoutes)(props);

const Meetings = (props) => {

    const {view, path, pageId, meetingId, itemId} = props;

    debug("RENDER", "Documents", () => [view]);

    const {data, loadingStatus} = useMeeting(meetingId);

    const viewData = (loadingStatus ? new MeetingRecord(MeetingSchema.default()) : data);

    const lineColor = path.getIn([0, "color"]);

    if (loadingStatus) return null;

    let updatedPath = path;

    const url = `/city-council-pages/${pageId}/meetings/${meetingId}`;

    if (view !== 'meetingList' && !path.find(p => p.url === url)) {

        updatedPath = path.withMutations(p => {

            const currentPath = {
                label: `${viewData.date}`,
                url: "/city-council-pages/" + pageId + "/meetings/" + meetingId,
                color: lineColor,
            };

            p.push(currentPath);

        });
    }

    switch (view) {
        case "meetingList" :
            return <MeetingList path={path} pageId={pageId}/>;

        case "meetingDetails" :

            return <MeetingDetails view='meetingDetails'
                                   pageId={pageId}
                                   meetingId={meetingId}
                                   data={data}
                                   path={updatedPath}
            />;

        case "itemDetails" :

            return <ItemDetails view='itemDetails'
                                pageId={pageId}
                                meetingId={meetingId}
                                itemId={itemId}
                                path={updatedPath}
            />;

        default :
            return <div>invalid view: {view}</div>
    }
};