import React from "react";
import {cityCouncilPageEditorStyle} from "../../Lib/ContentManagement/ContentEditor/Components/CityCouncilPageEditor/cityCouncilPageEditorStyle";
import {makeStyles} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import {useCityCouncilPages} from "../../Lib/ContentManagement/ContentEditor/Api/cityCouncilPages";
import Section from "../../Lib/form/Components/Section/Section";
import Grid from "@material-ui/core/Grid";
import Form from "../../Lib/ContentManagement/ContentEditor/Components/CityCouncilPageEditor/Form";
import ContentBox from "../../Components/ContentBox/ContentBox";
import {setDataPolicy, useDataPolicy} from "../../Lib/ContentManagement/ContentEditor/Api/pages";
import {DataPolicyRecord, DataPolicySchema} from "../../Lib/ContentManagement/ContentEditor/Data/DataPolicy";
import useForm from "../../Lib/form/useForm";
import FormProvider from "../../Lib/form/Components/FormProvider/FormProvider";
import ValidatedSubmit from "../../Lib/form/Components/ValidatedSubmit/ValidatedSubmit";
import AttachmentUpload from "../../Components/AttachmentUpload/AttachmentUpload";
import Box from "@material-ui/core/Box";
import {useLanguageRevision} from "../../Lib/useLanguageRevision";


const useStyles = makeStyles(cityCouncilPageEditorStyle);

const DataPolicyUpload = () => {

    const classes = useStyles();

    const {data, loadingStatus} = useDataPolicy();

    const {t} = useTranslation('contentManagement');

    const form = useForm({
        recordType: DataPolicyRecord,
        recordSchema: DataPolicySchema,
        namespace: 'contentManagement',
        record: new DataPolicyRecord(DataPolicySchema.default()).set('attachment', loadingStatus ? data : 0),
    });

    const api = setDataPolicy;

    if (loadingStatus) return null;

    return (
        <Grid container item xs={12} className={classes.formBox}>
            <FormProvider form={form} className={classes.dataPolicyUpload}>
                <Grid item xs={12}>
                    <Box className={classes.title}>{t('dataPolicy')}</Box>
                </Grid>
                <Grid container>
                    <AttachmentUpload id={data.id}
                                      ns='contentManagement'
                                      name='attachment'
                                      attachments={data.toString()}
                                      api={useDataPolicy}
                                      apiPath={'/attachments'}/>
                    <Grid item xs={12} container>
                        <ValidatedSubmit view='edit'
                                         api={api}
                                         hideCancel={true}
                        />
                    </Grid>
                </Grid>
            </FormProvider>
        </Grid>
    );
};

const PublicInformation = () => {
    const classes = useStyles();

    const {t} = useTranslation("publicInformation");

    const revision = useLanguageRevision();

    const {data, loadingStatus} = useCityCouncilPages(revision);

    if (loadingStatus) return null;

    const forms = data.filter(record => (record.type === 'IMPRESSUM' || record.type === 'CONTACT'))
        .map(record => <Form data={record} key={record.id} view='edit'/>);

    const path = [{label: t('titleNav'), url: `/public-information`}];

    return (
        <ContentBox path={path}>
            <Section>
                <Grid container>
                    <Grid item xs={12} className={classes.editorBlock}>
                        {forms}
                    </Grid>
                    <Grid item xs={12} className={classes.editorBlock}>
                        <DataPolicyUpload/>
                    </Grid>
                </Grid>
            </Section>
        </ContentBox>
    );

};

export default PublicInformation;