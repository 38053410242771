export const watchersHu = {
    "watchers": "Érdeklődők",
    "watcher": "Érdeklődő",
    "add": "Jelentkezés",
    "edit": "Érdeklődő szerkesztése",
    "remove": "Érdeklődő törlése",
    "back": "Vissza a listához",
    "save": "Érdeklődő mentése",
    "action": "Műveletek",
    "id": "Azonosító",
    "name": "Név",
    "name-invalid": "Érvénytelen mező, legalább 5, és legfeljebb 100 karakternek kell lennie.",
    "emailAddress": "E-mail cím",
    "emailAddress-invalid": "Érvénytelen email cím.",
    "phoneNumber": "Telefonszám",
    "phoneNumber-invalid": "Érvénytelen mező, legalább 7, és legfeljebb 30 karakternek kell lennie.",
    "type": "Tipusa",
    "translType": "Státusz",
    "ADMIN": "Adminisztrátor",
    "PUBLIC_USER": "Nyilvános felhasználó",
    "close": "Mégse",
    "applicationForm": "Jelentkezési űrlap",
    'termsAccepted': 'Elolvastam és elfogadom az ',
    "policy": 'Adatvédelmi szabályzatot',
};