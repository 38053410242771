import {useTranslation} from "react-i18next";
import React, {useContext, useEffect, useState} from "react";
import {FormContext} from "../../Lib/form/useForm";
import Grid from "@material-ui/core/Grid";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from '@material-ui/icons/Delete';
import {makeStyles} from "@material-ui/core";
import {attachmentUploadStyle} from "./attachmentUploadStyle";
import PreviewWrapper from "../PreviewWrapper/PreviewWrapper";
import List from "@material-ui/core/List";
import ValidatedFileUploader from "../../Lib/form/Components/ValidatedFileUpload/ValidatedFileUpload";
import {AttachmentRecord} from "../../Lib/ContentManagement/ContentEditor/Data/Attachment";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles(attachmentUploadStyle);

const AttachmentItem = ({attachment, handleRemove}) => {

    const classes = useStyles();

    return (
        <ListItem>
            <ListItemText
                primary={
                    <a href={`/api/1.0.0/attachments/${attachment.id}`} target='_blank' rel='noopener noreferrer'
                       className={classes.attachmentItem}>
                        {attachment.name}
                    </a>
                }
            />
            <ListItemSecondaryAction>
                <IconButton edge="end" aria-label="delete" onClick={() => handleRemove(attachment.id)}>
                    <DeleteIcon/>
                </IconButton>
            </ListItemSecondaryAction>
        </ListItem>
    );
};

const AddAttachmentItem = ({handlePreview}) => {

    const {t} = useTranslation('contentManagement');

    return (
        <ListItem button>
            <ListItemText
                primary={t('addAttachment')}
                onClick={() => handlePreview()}
            />
        </ListItem>
    );
};

const AttachmentUpload = ({id, name, ns, attachments, multiple, maxUploads, api, apiPath}) => {

    const classes = useStyles();

    const {t} = useTranslation(ns);

    const {fieldValues} = useContext(FormContext);

    const [state, setState] = useState({
        showPond: false,
        ids: attachments ? attachments.split('/') : [],
        attachments: [],
        numberOfUploads: multiple ? maxUploads : 1
    });

    useEffect(() => {
        if (attachments && id) {
            api(id, data => {
                setState(prevState => ({
                    ...prevState,
                    attachments: data,
                }))
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [attachments, id]);

    const onSuccess = (fileId, file) => {

        const newAttachment = new AttachmentRecord({
            id: fileId,
            name: file.name,
            type: file.type,
            size: file.size,
        });

        setState(prevState => ({
                ...prevState,
                showPond: false,
                ids: prevState.ids.concat([fileId]),
                attachments: prevState.attachments.concat([newAttachment]),
            })
        );

    };

    const handleRemove = fileId => {

        const newIds = state.ids.filter(oldId => oldId !== fileId.toString());
        const newAttachments = state.attachments.filter(a => a.id !== fileId);

        fieldValues.current.attachments = newIds.length ? newIds.join('/') : multiple ? '' : null;

        setState(prevState => ({
                ...prevState,
                ids: newIds,
                attachments: newAttachments,
            })
        );
    };

    const handlePreview = () => {
        setState(prevState => ({
            ...prevState,
            showPond: true,
        }));
    };

    return (
        <Grid item xs={12} className={classes.attachmentContainer}>
            <PreviewWrapper name={name} ns={ns}>
                {state.showPond ?
                    <Grid container>
                        <Grid item xs={12}>
                            <ValidatedFileUploader name={name}
                                                   apiPath={apiPath}
                                                   multiple={multiple}
                                                   onSuccess={onSuccess}/>
                        </Grid>
                        <Grid item container xs={12} justify='center'>
                            <Button onClick={() => setState(prevState => ({...prevState, showPond: false}))}
                                    className={classes.changePreviewButton}
                                    color='secondary'
                                    variant='contained'>
                                {t('cancel')}
                            </Button>
                        </Grid>
                    </Grid> :
                    <List>
                        {state.attachments.map(a => <AttachmentItem key={a.id} attachment={a}
                                                                    handleRemove={handleRemove}/>)}
                        {(state.attachments.length < state.numberOfUploads || !state.numberOfUploads) &&
                        <AddAttachmentItem handlePreview={handlePreview}/>
                        }
                    </List>
                }
            </PreviewWrapper>
        </Grid>
    )
};

export default AttachmentUpload;