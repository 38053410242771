import baseFetch, {useFetchMany} from "../Lib/baseFetch";
import React from "react";
import {ListOfWatchersForItemRecord} from "../Data/ListOfWatchersForItem";

export function useListOfWatchersForItems(itemId, revision) {
    return useFetchMany('/items/' + itemId + '/list-of-watchers-for-items', undefined, ListOfWatchersForItemRecord, revision);
}

export function addListOfWatchersForItem(listOfWatchersForItem, onSuccess, onError) {
    baseFetch('POST', '/list-of-watchers-for-items', listOfWatchersForItem,
        data => {
            if (onSuccess) onSuccess(new ListOfWatchersForItemRecord(data))
        },
        onError
    );
}

export function removeListOfWatchersForItem(id, onSuccess, onError) {
    baseFetch('DELETE', '/list-of-watchers-for-items/' + id, undefined, onSuccess, onError);
}

