import useForm from "../../../../form/useForm";
import React, {memo} from "react";
import ValidatedSubmit from "../../../../form/Components/ValidatedSubmit/ValidatedSubmit";
import {useTranslation} from "react-i18next";
import ContentBox from "../../../../../Components/ContentBox/ContentBox";
import Section from "../../../../form/Components/Section/Section";
import {usePrincipals} from "../../../Api/principals";
import ValidatedSelect from "../../../../form/Components/ValidatedSelect/ValidatedSelect";
import {GrantRecord, GrantSchema} from "../../../Data/Grant";
import debug from "../../../../debug";
import {useSnackbar} from "notistack";
import {closeSnack} from "../../../../../Main/util";


export default memo(({id, record, path, view, api}) => {
    const {t} = useTranslation('grants');
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();

    if (view === "add") {
        const roleName = path[0].url.split("role-name=")[1].split('\/')[0]
        path[1].label = roleName.toUpperCase()
    } else {
        path[1].label = record.roleName ? record.roleName.toUpperCase() : null
    }

    const form = useForm({
        disabledForm: view === 'remove',
        recordType: GrantRecord,
        recordSchema: GrantSchema,
        namespace: 'grants',
        record: record
    });

    debug("RENDER", "useForm", () => [" -> Form_Of_Role_Grants", id, record, path, view]);

    const processing = (record, onSuccess, onError) => {
        const error = (error) => {
            if (error && error.status === 400) {
                enqueueSnackbar(
                    t('badRequest'), {
                        variant: 'error',
                        autoHideDuration: 10000,
                        action: (key) => closeSnack(t, closeSnackbar, key)
                    }
                );
                onError({status: 422}, true);
            } else {
                onError(error, true);
            }
        };

        api(record, onSuccess, error);
    };

    return (
        <ContentBox path={path} form={form}>
            <Section title={t("grant")}>
                <ValidatedSelect name="principalId" recordFieldName={"fullName"} api={usePrincipals}/>
                <ValidatedSubmit view={view} api={processing}/>
            </Section>
        </ContentBox>
    );
})
