import {Record} from 'immutable';
import {mixed, number, object, string} from "yup";

export const DocumentRecord = Record({
    id: 0,
    title: '',
    status: '',
    meetingElementId: 0,
    attachment: '',
});

export const DocumentSchema = object().shape({
    id: number().min(0).default(0),
    title: string().required().min(1).max(100),
    status: mixed().required().oneOf(['DRAFT', 'PUBLIC']).default('PUBLIC'),
    meetingElementId: number().required().min(1),
    attachment: number().required().min(1),
});
