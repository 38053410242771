export const versionStyle = theme => ({
    version: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        paddingLeft: theme.spacing(2),
        position: 'relative',
        zIndex: 200
    },
    versionText: {
        ...theme.typography.caption,
        color: theme.palette.text.secondary,
        fontWeight: 300,
    }
});