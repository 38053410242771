import React, {memo, useRef} from 'react';
import {FilePond, registerPlugin} from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import baseFetch from "../../../baseFetch";
import {useFormField} from "../../useFormField";
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import Box from "@material-ui/core/Box";
import {labelFactory} from "./filepond-labels";

/**
 * apiPath = /images
 */

registerPlugin(FilePondPluginFileValidateSize, FilePondPluginFileValidateType, FilePondPluginImagePreview);

const ValidatedFileUploader = memo((props) => {

    const {
        apiPath,
        multiple,
        acceptedFileTypes,
        sizeValidation,
        maxFileSize,
        maxTotalFileSize,
        imagePreviewProps,
        onSuccess,
        onAbort,
    } = props;

    let allowImagePreview;
    let imagePreviewHeight;

    if (imagePreviewProps) {
        allowImagePreview = imagePreviewProps.allowImagePreview;
        imagePreviewHeight = imagePreviewProps.imagePreviewHeight;
    }

    const ff = useFormField(props);

    const ref = useRef(null);

    const labels = labelFactory();

    let fileIds = ff.value ? (Array.isArray(ff.value) ? ff.value.split("/") : [ff.value]) : [];

    const serverConfig = {
        process: (fieldName, file, metadata, load, error, progress, abort) => {
            // fieldName is the name of the input field
            // file is the actual file object to send
            const formData = new FormData();
            formData.append(fieldName, file, file.name);

            const request = new XMLHttpRequest();
            request.open('POST', '/api/1.0.0' + apiPath);

            // Should call the progress method to update the progress to 100% before calling load
            // Setting computable to false switches the loading indicator to infinite mode
            request.upload.onprogress = (e) => {
                progress(e.lengthComputable, e.loaded, e.total);
            };

            // Should call the load method when done and pass the returned server file id
            // this server file id is then used later on when reverting or restoring a file
            // so your server knows which file to return without exposing that info to the client
            request.onload = function () {
                if (request.status >= 200 && request.status < 300) {
                    // the load method accepts either a string (id) or an object
                    if (multiple) fileIds.push(request.response);
                    else fileIds = [request.response];

                    ff.onChange(fileIds.length ? fileIds.join("/") : multiple ? '' : null);

                    if (onSuccess) onSuccess(request.response, file);

                    load(request.responseText);
                } else {
                    if (request.status === 409 || request.status === 410 || request.status === 417) {
                        window.location = "/expired-session";
                    }
                    error();
                }
            };

            request.send(formData);

            // Should expose an abort method so the request can be cancelled
            return {
                abort: () => {
                    // This function is entered if the user has tapped the cancel button
                    request.abort();

                    // Let FilePond know the request has been cancelled
                    abort();
                }
            };
        },
        revert: (uniqueFileId, load, error) => {
            // don't
            baseFetch('DELETE', apiPath + "/" + uniqueFileId, undefined, () => load());

            const filtered = fileIds.filter(id => id !== uniqueFileId);

            ff.onChange(!Array.isArray(ff) ? filtered.join("/") : multiple ? '' : null);
            if (onAbort) onAbort(uniqueFileId)

        },
    };

    const removeOnList = (uniqueFileId) => { // todo
        const onLoad = () => console.log("törlése", uniqueFileId)
        serverConfig.revert(uniqueFileId, onLoad)
    }

    return (
        <Box>
            <FilePond name="file"
                      ref={ref}
                      allowMultiple={multiple}
                      {...labels}

                      server={serverConfig}

                      allowFileTypeValidation={Boolean(acceptedFileTypes)}
                      acceptedFileTypes={acceptedFileTypes ? acceptedFileTypes : ['*/*']}

                      allowFileSizeValidation={sizeValidation}
                      maxFileSize={maxFileSize || null}
                      maxTotalFileSize={maxTotalFileSize || null}

                      allowImagePreview={allowImagePreview}
                      imagePreviewHeight={imagePreviewHeight}
            />
        </Box>
    );
});

export default ValidatedFileUploader;