import React from "react";
import {addData, createRows, getAll, getOne} from "../util";

import {pagesApiTest} from "../../ContentManagement/ContentEditor/Api/pages";

const calls = [
    onResponse => getOne("/pages/main-image", onResponse),
    onResponse => addData("/pages/main-image/1", undefined, onResponse),
    onResponse => getAll("/pages/actualities/10", onResponse),
    onResponse => getOne("/pages/data-policy", onResponse),
    onResponse => addData("/pages/data-policy/1", undefined, onResponse),

];

const PagesApis = () => {

    return (
        <React.Fragment>
            {createRows(calls, pagesApiTest)}
        </React.Fragment>
    );
}

export default PagesApis;