import React from "react";
import List from "./List";
import {useRoutes} from "hookrouter";
import {addMeeting, removeMeeting, updateMeeting, useMeeting} from "../../Api/meetings";
import {useTranslation} from "react-i18next";
import debug from "../../Lib/debug";
import Form from "./Form";
import {MeetingRecord, MeetingSchema} from '../../Data/Meeting';
import Record from "./Record/Record";

export default () => useRoutes(subRoutes);

const subRoutes = {
    "/": () => <Meetings view="list"/>,
    "/add": () => <Meetings view="add"/>,
    "/:meetingId/edit": ({meetingId}) => <Meetings view="edit" meetingId={meetingId}/>,
    "/:meetingId/remove": ({meetingId}) => <Meetings view="remove" meetingId={meetingId}/>,
    "/:meetingId/records*": ({meetingId}) => <Meetings view='meetingRecord' meetingId={meetingId}/>,
};

const Meetings = ({view, meetingId}) => {

    debug("RENDER", "Meetings", () => [view]);

    const {t} = useTranslation('meetings');

    const {loadingStatus, data} = useMeeting(meetingId);

    const viewData = (loadingStatus ? new MeetingRecord(MeetingSchema.default()) : data);

    const path = [{label: t('meeting'), url: "/meetings"}];

    // noinspection JSCheckFunctionSignatures
    const label = `${viewData.committeeName} ${viewData.date}`;

    switch (view) {
        case "list" :

            return <List path={path}/>;

        case "add" :

            path.push({label: label, url: "/meetings/add"});
            return <Form meetingId={meetingId} record={viewData} path={path} view={view} api={addMeeting}/>;

        case "remove" :

            path.push({label: label, url: "/meetings/" + meetingId + "/remove"});
            return <Form meetingId={meetingId} record={viewData} path={path} view={view} api={removeMeeting}/>;

        case "edit" :

            path.push({label: label, url: "/meetings/" + meetingId + "/edit"});
            return <Form meetingId={meetingId} record={viewData} path={path} view={view} api={updateMeeting}/>;

        case "meetingRecord" :

            path.push({label: label, url: "/meetings/" + meetingId + "/edit"});
            return <Record meetingId={meetingId} path={path}/>;

        default :
            return <div>invalid view: {view}</div>
    }
};

