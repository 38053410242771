import React from "react";
import List from "./List";
import {useRoutes} from "hookrouter";
import {addPosition, removePosition, updatePosition, usePosition} from "../../Api/position";
import {useTranslation} from "react-i18next";
import debug from "../../Lib/debug";
import Form from "./Form";
import {PositionRecord, PositionSchema} from '../../Data/Position';

export default (props) => useRoutes(subRoutes)(props);

const subRoutes = {
  "/": () => props => <Positions view="list" {...props}/>,
  "/add": () => props => <Positions view="add" {...props}/>,
  "/:positionId/edit": ({positionId}) => props => <Positions view="edit" positionId={positionId} {...props}/>,
  "/:positionId/remove": ({positionId}) => props => <Positions view="remove" positionId={positionId} {...props}/>,
};

const Positions = ({view, positionId}) => {

  debug("RENDER", "Positions", () => [view]);

  const {t} = useTranslation('positions');

  const {loadingStatus, data} = usePosition(positionId);

  const viewData = (loadingStatus ? new PositionRecord(PositionSchema.default()) : data);

  const path = [{label: t('titleNav'), url: "/positions"}];

  // noinspection JSCheckFunctionSignatures
  const label = viewData.get('name');

  switch (view) {
    case "list" :

      return <List path={path}/>;

    case "add" :

      path.push({label: label, url: "/positions/add"});
      return <Form positionId={positionId} record={viewData} path={path} view={view} api={addPosition}/>;

    case "remove" :

      path.push({label: label, url: "/positions/" + positionId + "/remove"});
      return <Form positionId={positionId} record={viewData} path={path} view={view} api={removePosition}/>;

    case "edit" :

      path.push({label: label, url: "/positions/" + positionId + "/edit"});
      return <Form positionId={positionId} record={viewData} path={path} view={view} api={updatePosition}/>;


    default :
      return <div>invalid view: {view}</div>
  }
};

