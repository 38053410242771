import React from "react";
import Grid from "@material-ui/core/Grid";
import {makeStyles, Typography} from "@material-ui/core";
import ContentBox from "../../../../Components/ContentBox/ContentBox";
import {useMeetings} from "../../../../Api/meetings";
import {useTranslation} from "react-i18next";
import {translatableField} from "../../../../Lib/simplexion-table/templates/translatable-field";
import {SimplexionTable} from "../../../../Lib/simplexion-table/SimplexionTable";
import {baseTableStyles} from "../../../../Lib/simplexion-table/styles/baseStyle";
import {customActionsStyle} from "../../../../Lib/simplexion-table/templates/action-buttons";
import {linkAction} from "../../../../Lib/simplexion-table/templates/link-button";
import {cityCouncilPageStyle} from "../../cityCouncilPageStyle";
import Section from "../../../../Lib/form/Components/Section/Section";

const styles = () => {
    let classes = baseTableStyles;
    Object.entries(customActionsStyle).forEach(entry => {
        classes[entry[0]] = entry[1];
    });
    return classes;
};

const useTableStyles = makeStyles(styles);
const useStyles = makeStyles(cityCouncilPageStyle);

const MeetingsTable = () => {

    const classes = useTableStyles();

    const {data} = useMeetings();

    const tableConfig = {
        id: 'meetings',
        nameSpace: 'cityCouncilPage',
        sortable: true,
        styles: classes,
        columns: [
            {
                accessor: 'date',
            },
            {
                accessor: 'time',
            },
            {
                accessor: 'type',
                renderer: (...args) => translatableField(...args),
            },
            {
                accessor: 'committeeName',
            },
            {
                accessor: 'numberOfElements',
            },
            {
                accessor: '',
                renderer: (...args) => linkAction(...args, 'meetingElements', `/city-council/meetings/`)
            }
        ],
    };

    return <SimplexionTable tableConfig={tableConfig} data={data}/>;
};

const MeetingList = ({pageId}) => {

    const classes = useStyles();
    const {t} = useTranslation('cityCouncilPage');

    const path = [
        {label: t('titleNav'), url: '/city-council'},
        {label: t('meetings'), url: '/city-council/meetings'},
    ];

    return (
        <ContentBox path={path}>
            <Section>
                <Grid container className={classes.root} component='div'>
                    <Grid item xs={12} className={classes.header}>
                        <Typography variant='h4' className={classes.title}>
                            {t('meetings')}
                        </Typography>
                    </Grid>
                    <Grid container className={classes.root} component='div'>
                        <MeetingsTable pageId={pageId}/>
                    </Grid>
                </Grid>
            </Section>
        </ContentBox>

    );
};

export default MeetingList;