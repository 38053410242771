import React from "react";
import {createRows, getOne, updateData} from "../util";
import {FileUploadPageRecord, FileUploadPageSchema} from "../../ContentManagement/ContentEditor/Data/FileUploadPage";

import {fileUploadPagesApiTest} from "../../ContentManagement/ContentEditor/Api/fileUploadPages";

const record = FileUploadPageRecord(FileUploadPageSchema.default()).withMutations(data => {
    return data.set("text", "text")
})

const calls = [
    onResponse => getOne("/file-upload-pages/1", onResponse),
    onResponse => updateData("/file-upload-pages/1", record, onResponse),
];

const FileUploadPagesApis = () => {

    return (
        <React.Fragment>
            {createRows(calls, fileUploadPagesApiTest)}
        </React.Fragment>
    );
}

export default FileUploadPagesApis;