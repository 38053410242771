import React from "react";
import {DocumentRecord, DocumentSchema} from "../../../Data/Document";
import {addData, createRows, getAll, getOne, removeData, updateData} from "../util";

import {documentApiTest} from "../../../Api/documents";

const record = DocumentRecord(DocumentSchema.default()).withMutations(data => {
    return data.set("title", "Pont_01_Dok_01")
        .set("status", "PUBLIC")
        .set("meetingElementId", 1)
        .set("attachment", 1)
})

const calls = [
    onResponse => addData('/meeting-elements/1/documents', record, onResponse),
    onResponse => getAll('/meeting-elements/1/documents', onResponse),
    onResponse => getOne('/meeting-elements/1/documents/1', onResponse),
    onResponse => getOne('/documents/1/get-attachment', onResponse),
    onResponse => updateData('/meeting-elements/1/documents/1', record, onResponse),
    onResponse => removeData('/meeting-elements/6/documents/3', onResponse),
];

const DocumentsApis = () => {

    return (
        <React.Fragment>
            {createRows(calls, documentApiTest)}
        </React.Fragment>
    );
}

export default DocumentsApis;