export const attachmentUploadStyle = theme => ({
    attachmentContainer: {
        padding: '1rem 0 2rem',
        minHeight: 280,
        // '&:hover button': {
        //     display: 'initial',
        // }
    },
    attachmentItem: {
        textDecoration: 'unset',
        color: theme.palette.text.primary,
        '&:active': {
            color: theme.palette.text.primary,
        },
        '&:focus': {
            color: theme.palette.text.primary,
        }
    },
    changePreviewButton: {
        // display: 'none',
        position: 'absolute',
        margin: 'auto',
        color: theme.palette.text.white,
        marginTop: 27
    },
});