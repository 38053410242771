import React from "react";

import {useRoutes} from "hookrouter";
import debug from "../../../../Lib/debug";
import DecisionList from "./DecisionList";
import DecisionDetails from "./DecisionDetails";

const subRoutes = {
    "/": () => props => <Decisions view='decisionList' {...props}/>,
    "/:decisionId": ({decisionId}) => props => <Decisions view='decisionDetails' decisionId={decisionId} {...props}/>,
};

export default props => useRoutes(subRoutes)(props);

const Decisions = (props) => {

    const {view, decisionId} = props;

    debug("RENDER", "Documents", () => [view]);

    switch (view) {
        case "decisionList" :

            return <DecisionList {...props}/>;

        case "decisionDetails" :

            return <DecisionDetails decisionId={decisionId} {...props}/>;

        default :
            return <div>invalid view: {view}</div>
    }
};