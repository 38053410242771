import {makeStyles} from "@material-ui/core";
import customSelectStyle from "../../Lib/material-dashboard/assets/jss/customSelectStyle";
import {useMeetings} from "../../Api/meetings";
import {useTranslation} from "react-i18next";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import React from "react";
import Section from "../../Lib/form/Components/Section/Section";

const useStyles = makeStyles(customSelectStyle);

const MeetingSelect = ({meetingId, handleSelect}) => {

    const {data, loadingStatus} = useMeetings();

    const classes = useStyles();
    const {t} = useTranslation('meetings');

    return (
        <Section title={t('meeting')}>
            <Grid container>
                <Grid item xs={12} md={4}>
                    <FormControl
                        fullWidth
                        className={classes.selectFormControl}>
                        <Select MenuProps={{className: classes.selectMenu}}
                                classes={{select: classes.select}}
                                disabled={Boolean(loadingStatus)}
                                value={meetingId}
                                onChange={(e) => handleSelect(e.target.value)}
                                inputProps={{
                                    name: t('data'),
                                    id: 'meetingDate',
                                    value: meetingId,
                                }}>
                            {data.map(item =>
                                <MenuItem
                                    classes={{
                                        root: classes.selectMenuItem,
                                        selected: classes.selectMenuItemSelected
                                    }}
                                    key={item.get('id')}
                                    value={item.get('id')}>
                                    {`${item.get('date')}, ${t(item.get('type'))}, ${item.get('committeeName')}`}
                                </MenuItem>
                            )}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
        </Section>
    );
};

export default MeetingSelect;