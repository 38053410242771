import React from "react";
import {makeStyles} from "@material-ui/core";
import {szechenyi2024} from "./szechenyi2024Style";
import {useTranslation} from "react-i18next";
import image from "./unios-palyazatokplusz.jpg"

const useStyles = makeStyles(szechenyi2024);

const Szechenyi2024 = () => {

    const classes = useStyles();

    const {t} = useTranslation('general');

    return <img className={classes.szechenyi2024} src={image} alt={"Széchényi 2024"}
                onClick={() => window.location.href="/block-pages/63/blocks"} />

};

export default Szechenyi2024;