import React from "react";

export const itemsHu = {
    "items": "Meghirdetett tárgyak",
    "item": "Meghirdetett tárgy",
    "add": "Hirdetés hozzáadása",
    "newItem": "Hirdetés feladása",
    "edit": "Meghirdetett tárgy szerkesztése",
    "remove": "Meghirdetett tárgy törlése",
    "delete": "Meghirdetett tárgy törlése",
    "back": "Vissza a listához",
    "save": "Meghirdetett tárgy mentése",
    "saveMessage": "A hirdetés sikeresen el lett mentve. Amint jóvá lesz hagyva megjelenítjük a listában.",
    "action": "Műveletek",
    "id": "Azonosító",
    "name": "Megnevezés",
    "name-invalid": "Érvénytelen mező, legalább 5, legfeljebb 100 karakternek kell lennie.",
    "category": "Kategória",
    "translCategory": "Kategória",
    "CLOTHES": "Ruha",
    "FURNITURE": "Bútor",
    "BABY": "Baba-mama",
    "GAME": "Sport / Játék",
    "SCHOOL_EQUIPMENT": "Könyv és iskolai felszerelés",
    "HOUSEHOLD_GOODS": "Háztartási eszközök",
    "OTHER": "Egyéb",
    "category-invalid": "Érvénytelen mező, legalább 5, legfeljebb 100 karakternek kell lennie.",
    "description": "Leírás",
    "images": "Képek (max 2db)",
    "createdAt": "Meghirdetés időpontja",
    "watcherIds": "Érdeklődő",
    "watchers": "Érdeklődők",
    "numberOfWatchers": "Érdeklődők száma",
    "nameOfCreator": "Név",
    "nameOfCreator-invalid": "Érvénytelen mező, legalább 5, legfeljebb 100 karakternek kell lennie.",
    "emailAddressOfCreator": "E-mail cím",
    "emailAddressOfCreator-invalid": "Érvénytelen email cím.",
    "phoneNumberOfCreator": "Telefonszám",
    "phoneNumberOfCreator-invalid": "Érvénytelen mező, legalább 7, legfeljebb 30 karakternek kell lennie.",
    "status": "Státusz",
    "type": "Tipusa",
    "translStatus": "Tipusa",
    "translType": "Státusz",
    "PENDING": "Tervezés alatt",
    "APPROVED": "Jóváhagyott",
    "HIDDEN": "Rejtett",
    "ADMIN": "Adminisztrátor",
    "PUBLIC_USER": "Nyilvános felhasználó",
    'termsAccepted': 'Elolvastam és elfogadom az ',
    "policy": 'Adatvédelmi szabályzatot',
    "interest": "Érdekel",
    "close": "Mégse",
    "**unknown-name**": "Ismeretlen Meghirdetett tárgy",
    "confirmHideText": "Biztosan elszeretné rejteni a publikus felületről ezt az elemet? ",
    "cancel": "Mégse",
    "confirmHide": "Elrejtés",
    "editingContent": "Tartalom szerkesztése",
    "creatorDetails": "Hirdető adatai",
    "visible": `Ez az meghirdetett tárgy látható a publikus felületen.`,
    "invisible": `Ez az meghirdetett tárgy el van rejtve a publikus felületről, csak az adminisztrációs feladatkörrel rendelkezők láthatják.`,
    "images-invalid": "Legfeljebb 2db képet lehet csatolni.",
    "typeOfAd": " Hirdetés típusa",
    "translTypeOfAd": " Hirdetés típusa",
    "LOOK_FOR": "Keres",
    "OFFER": "Kínál",
    "delivery": "Szállítási mód",
    "translDelivery": "Szállítási mód",
    "GO_FOR": "Átvevő elmegy érte",
    "UNDERTAKE_DELIVERY": "Hirdető vállalja a szállítást"
}