import {Record} from 'immutable';
import {number, object, string} from "yup";

export const ContentRecord = Record({
    id: 0,
    title: '',
    type: '',
    lineColor: '',
    fontColor: '',
    page: 0,
    itemBefore: 0,
    parent: 0,
    children: '',
    status: 'HIDDEN'
});

export const ContentSchema = object().shape({
    id: number().min(0).default(0),
    title: string().required().min(1).max(30).default(''),
    type: string().required().oneOf(['BLOCK', 'TEXT', 'FILE_UPLOAD', 'KOLORAPP', 'CITY_COUNCIL', 'LIST_OF_ITEM']).default(''),
    lineColor: string().default(''),
    fontColor: string().default(''),
    page: number().min(0).default(0),
    itemBefore: number().min(0).nullable().default(0),
    parent: number().min(0).default(0),
    children: string().default(''),
    status: string().nullable().default('HIDDEN')
});