import React, {useContext, useRef, useState} from "react";
import Box from "@material-ui/core/Box";
import {Add, NavigateNext} from "@material-ui/icons";
import {A} from "hookrouter";
import Button from "../../Lib/material-kit/components/CustomButtons/Button";
import {makeStyles} from "@material-ui/styles";
import {contentBoxStyle} from "./contentBoxStyle";
import FormProvider from "../../Lib/form/Components/FormProvider/FormProvider";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import AppFooter from "../Footer/AppFooter";
import AppNavBar from "../Navbars/AppNavBar";
import Sidebar from "../Sidebar/Sidebar";
import Immutable from "immutable";
import cx from "classnames";
import {AppContext} from "../../Main/AppContext";
import ScrollToTopBtn from "../FloatingActionButton/ScrollToTopBtn";


export const useStyles = makeStyles(contentBoxStyle);

const ContentBreadCrumbs = ({path, add}) => {

    const classes = useStyles();

    const appContext = useContext(AppContext);
    const highContrastTheme = appContext.getIn(['appStore', 'highContrastTheme']);

    const breadcrumbs = path ? path.map(p => {

        const style = p.color ?
            {
                borderBottomColor: highContrastTheme ? '#FFFF00' : p.color,
                borderBottomWidth: 2,
                borderBottomStyle: 'solid',
                paddingLeft: 4,
                paddingRight: 4,
            } :
            {};

        return (
            <A href={p.url} key={p.url} className={classes.title} style={style}>
                {p.label}
            </A>
        );
    }) : [];

    return (
        <React.Fragment>
            <Box className={classes.titleContainer}>
                <Breadcrumbs className={classes.title} separator={<NavigateNext fontSize="small"/>}
                             aria-label="breadcrumb">
                    {breadcrumbs}
                </Breadcrumbs>
                {add ?
                    <A href={add} className={classes.addBtn}>
                        <Button justIcon color={"success"} size={"sm"}><Add/></Button>
                    </A>
                    : null
                }
            </Box>
        </React.Fragment>
    );
};

const PublicLayout = ({path, children, add, publicPage, form}) => {

    const classes = useStyles();

    return (
        <React.Fragment>
            <Box className={classes.publicLayout}>
                <AppNavBar/>
                <Box className={classes.publicContainer}>
                    {path &&
                    <ContentBreadCrumbs path={path} add={add} publicPage={publicPage}/>
                    }
                    {form ?
                        <FormProvider form={form} formClassName={classes.form}>
                            {children}
                        </FormProvider> :
                        children
                    }
                </Box>
                <ScrollToTopBtn/>
            </Box>
            <AppFooter/>
        </React.Fragment>
    );
};

const AdministrationLayout = ({path, children, add, form}) => {

    const classes = useStyles();

    const mainPanelRef = useRef(null);

    const [state, setState] = useState(Immutable.Map({
        mobileOpen: false,
        miniActive: false,
    }));

    const handleDrawerToggle = () => {
        setState(state.update('mobileOpen', mobileOpen => !mobileOpen));
    };

    const sidebarMinimize = () => {
        setState(state.update('miniActive', miniActive => !miniActive));
    };

    const mainPanelStyle = cx(classes.mainPanel, {[classes.mainPanelSidebarMini]: state.get('miniActive')});

    return (
        <Box className={classes.administrationLayoutWrapper}>
            <Sidebar handleDrowerToggle={handleDrawerToggle}
                     sidebarMinimize={sidebarMinimize}
                     open={state.get('mobileOpen')}
                     mini={state.get('miniActive')}
            />
            <Box className={mainPanelStyle} ref={mainPanelRef}>
                <Box className={classes.main}>
                    {path &&
                    <ContentBreadCrumbs path={path} add={add}/>
                    }
                    <Box>
                        {form ?
                            <FormProvider form={form} formClassName={classes.form}>
                                {children}
                            </FormProvider> :
                            children
                        }
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

function ContentBox(props) {

    const {children, publicPage} = props;

    return (
        <React.Fragment>
            {publicPage ?
                <PublicLayout {...props}>
                    {children}
                </PublicLayout>
                :
                <AdministrationLayout {...props}>
                    {children}
                </AdministrationLayout>}
        </React.Fragment>
    );
}

export default ContentBox;
