import React from "react";
import {PositionRecord, PositionSchema} from "../../../Data/Position";
import {addData, createRows, getAll, getOne, removeData, updateData} from "../util";

import {positionsApiTest} from "../../../Api/position";

const record = PositionRecord(PositionSchema.default()).withMutations(data => {
    return data.set("name", "Beosztás_01")
        .set("committeeId", 1)
        .set("accountId", 1)
})

const calls = [
    onResponse => addData("/positions", record, onResponse),
    onResponse => getAll("/positions", onResponse),
    onResponse => getOne("/positions/1", onResponse),
    onResponse => updateData("/positions/1", record, onResponse),
    onResponse => removeData('/positions/2', onResponse),
];

const PositionsApis = () => {

    return (
        <React.Fragment>
            {createRows(calls, positionsApiTest)}
        </React.Fragment>
    );
}

export default PositionsApis;