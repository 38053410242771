export const szechenyi2020 = theme => ({
    szechenyi2020: {
        position: "fixed",
        bottom: 0,
        right: 0,
        width: "215px",
        height: "150px",
        zIndex: 1200,
        cursor: "pointer",
        pointerEvents: "all",
        [theme.breakpoints.down('md')]: {
            width: "129px",
            height: "90px",
        },
    }
});


