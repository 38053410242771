import React, {memo, useContext} from "react";
import DataRow from "../DataRow/DataRow";
import IndicatorRow from "../IndicatorRow/IndicatorRow";
import {MenuBuilderContext} from "../../Context/MenuBuilderContext";
import Immutable from "immutable";

const propsEquality = (prevProps, nextProps) => {
    return prevProps['branchPath'].equals(nextProps['branchPath'])
        && prevProps['record'].equals(nextProps['record'])
        && prevProps['mainBranchEnded'] === nextProps['mainBranchEnded']
        && prevProps['secondaryBranchEnded'] === nextProps['secondaryBranchEnded'];
};

const RowPair = memo(function RowPair(props) {
    return (
        <React.Fragment>
            <DataRow {...props}/>
            <IndicatorRow {...props}/>
        </React.Fragment>
    )
}, propsEquality);


const createRows = (map) => {
    const roots = map.filter(record => record.parent === null);

    const rowPairs = [];

    roots.forEach(record => {
        const tree = createRowPairs(map, record, []);
        rowPairs.push(tree);
    });

    return rowPairs.reduce((acc, current) => acc.concat(current), [])
};


const createRowPairs = (map, record, tree, branchPath, mainBranchEnded, secondaryBranchEnded) => {

    if (!branchPath) branchPath = Immutable.List();

    const children = record.children;

    tree.push(<RowPair record={record}
                       branchPath={branchPath}
                       key={record.id}
                       mainBranchEnded={mainBranchEnded}
                       secondaryBranchEnded={secondaryBranchEnded}/>);

    if (children) {

        branchPath = branchPath.push(record.id);

        children.forEach((childId, index) => {

            const childRecord = map.get(childId);

            if (index === children.length - 1) {
                if (branchPath.size === 1) mainBranchEnded = true;
                else if (branchPath.size === 2) secondaryBranchEnded = true;
            }

            createRowPairs(map, childRecord, tree, branchPath, mainBranchEnded, secondaryBranchEnded)// :

        });
    }

    return tree;
};

const TBody = () => {

    const {store} = useContext(MenuBuilderContext);

    const map = store.get('map');

    const rows = createRows(map);

    return <tbody>{rows}</tbody>;
};

export default TBody;
