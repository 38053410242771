import React, {memo, useContext, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import useForm from "../../Lib/form/useForm";
import {Delivery, ItemCategory, ItemRecord, ItemSchema, ItemStatus, TypeOfAd} from "../../Data/Item";
import debug from "../../Lib/debug";
import ContentBox from "../../Components/ContentBox/ContentBox";
import Section from "../../Lib/form/Components/Section/Section";
import ValidatedInput from "../../Lib/form/Components/ValidatedInput/ValidatedInput";
import ValidatedRadioGroup from "../../Lib/form/Components/ValidatedRadio/ValidatedRadioGroup";
import Grid from "@material-ui/core/Grid";
import ValidatedRadio from "../../Lib/form/Components/ValidatedRadio/ValidatedRadio";
import {navigate} from "hookrouter";
import {Gallery} from "../../Components/ImageGallery";
import {WatcherType} from "../../Data/Watcher";
import ValidatedCheckbox from "../../Lib/form/Components/ValidatedCheckbox/ValidatedCheckbox";
import {roles} from "../../Main/roles";
import {AuthContext} from "../../Lib/auth/AuthContext";
import Watcher from "../Watchers/Watchers";
import {Navigation} from "./Navigation";
import {useDataPolicy} from "../../Lib/ContentManagement/ContentEditor/Api/pages";
import {useSnackbar} from "notistack";
import {throwsExceptionMessage} from "../../Api/catchException";
import {closeSnack} from "../../Main/util";
import ValidatedReCAPTCHA from "../../Lib/form/Components/ValidatedReCAPTCHA/ValidatedReCAPTCHA";
import ValidatedSubmit from "../../Lib/form/Components/ValidatedSubmit/ValidatedSubmit";
import Button from "../../Lib/material-kit/components/CustomButtons/Button";
import ValidatedSelectForEnum from "../../Lib/form/Components/ValidatedSelect/ValidatedSelectForEnum";
import Card from "../../Lib/material-kit/components/Card/Card";
import CardBody from "../../Lib/material-kit/components/Card/CardBody";
import Box from "@material-ui/core/Box";
import {makeStyles} from "@material-ui/core/styles";


const useStyles = makeStyles((theme) => ({
    card: {
        [theme.breakpoints.up("md")]: {
            height: "96%"
        },
        margin: "8px 0",
        paddingBottom: "8px",
    },
    title: {
        marginTop: 10,
        color: theme.palette.primary.main,
        fontSize: "0.75rem",
        textTransform: "uppercase",
        fontWeight: 500
    },
    container: {
        height: "100%"
    }
}));

export default memo(({id, record, path, view, api}) => {

    const {t} = useTranslation("items");

    const classes = useStyles();

    if (path && path[1]) path[1].label = t("newItem")

    const form = useForm({
        recordType: ItemRecord,
        recordSchema: ItemSchema,
        namespace: 'items',
        record: record

    });

    const [popup, setPopup] = useState(false)

    const [num, setNum] = useState(false)

    const {enqueueSnackbar, closeSnackbar} = useSnackbar();

    const authContext = useContext(AuthContext);

    const policy = useDataPolicy().data

    const hasAdminRoles = authContext.getIn(['session', 'roles']).some(role => [roles.KSZSZK_ADMIN.toLowerCase(), roles.SECURITY_OFFICER.toLowerCase()].indexOf(role) !== -1);

    debug("RENDER", "useForm", () => [" -> Form_Of_Item", view, id, path, record]);

    const handleClose = () => {
        setPopup(false)
    };

    const handleOpen = () => {
        setPopup(true)
    };

    useEffect(() => {
    }, [num])

    const nameOfParent = record.name ? (record.name.replace(/%20/g, "").length > 1 ?
        record.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/\//g, "") : t("**unknown-name**")) : t("**unknown-name**");

    const processing = (record) => {

        const success = () => {
            enqueueSnackbar(t(view === "add" ? "saveMessage" : 'general:save.success'), {
                variant: 'success',
                autoHideDuration: 3000,
                action: (key) => closeSnack(t, closeSnackbar, key)
            });
            navigate(view === "add" ? "./" : "../")
        };

        const error = (error) => {
            enqueueSnackbar(t('catchException:' + throwsExceptionMessage(error)), {
                variant: 'error',
                autoHideDuration: 5000,
                action: (key) => closeSnack(t, closeSnackbar, key)
            });
        };

        api(record, success, error);
    };

    return (
        <ContentBox publicPage={!hasAdminRoles} path={path} form={form}>

            {hasAdminRoles && (view !== "add") ?
                <Navigation parentId={id} path={path} parentName={nameOfParent}/> : null}
            {view !== "add" ?
                <Watcher hasAdminRoles={hasAdminRoles} itemId={form.fieldValues.current.id} closeModal={handleClose}
                         open={popup}/>
                : null}

            <Grid container direction="row" justify="space-between" alignItems="stretch" spacing={2}>
                <Grid container item direction="row" justify="space-between" alignItems="center" spacing={3}>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <Section title={t('item')}>
                            <ValidatedInput name="name"/>
                            <ValidatedSelectForEnum name="typeOfAd" enumArray={TypeOfAd}/>
                            <ValidatedSelectForEnum name="category" enumArray={ItemCategory}/>
                            <ValidatedSelectForEnum name="delivery" enumArray={Delivery}/>
                            <ValidatedInput name="description" multiline={true} rows={4}/>
                        </Section>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6} className={classes.container}>
                        <Card className={classes.card}>
                            <CardBody>
                                <Box className={classes.title}>{t('creatorDetails')}</Box>
                                <Grid container direction="column" alignItems={"stretch"} spacing={3}
                                      style={{height: "100%"}}>
                                    <Grid item>
                                        <ValidatedInput name="nameOfCreator"/>
                                    </Grid>
                                    <Grid item>
                                        <ValidatedInput name="emailAddressOfCreator"/>
                                    </Grid>
                                    <Grid item>
                                        <ValidatedInput name="phoneNumberOfCreator"/>
                                    </Grid>
                                    {hasAdminRoles &&
                                    <Grid item>
                                        <ValidatedRadioGroup name="type">
                                            <Grid container>
                                                {WatcherType.map(a => <ValidatedRadio name="type" value={a} key={a}/>)}
                                            </Grid>
                                        </ValidatedRadioGroup>
                                    </Grid>
                                    }
                                </Grid>
                            </CardBody>
                        </Card>
                    </Grid>
                </Grid>
                <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} direction="column" spacing={2}>
                    <Section>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} onClick={event => setNum(num => num + 1)}>
                            <Gallery name="images" disabled={view !== "add"}/>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{paddingTop: "0.8rem"}}>
                            {hasAdminRoles &&
                            <Grid item xs={6}>
                                <ValidatedRadioGroup name="status">
                                    <Grid container>
                                        {ItemStatus.map(a => <ValidatedRadio name="status" value={a} key={a}/>)}
                                    </Grid>
                                </ValidatedRadioGroup>
                            </Grid>}
                        </Grid>
                        <Grid item item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Grid container direction="row" justify="space-around" alignItems="center" spacing={2}>
                                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}
                                      style={{maxWidth: "30%", paddingTop: "1rem"}}>
                                    <ValidatedCheckbox name="termsAccepted" customLabel={<span>{t("termsAccepted")}<a
                                        href={policy === 0 ? '/#' : `/api/1.0.0/attachments/${policy}`} target='_blank'
                                        rel='noopener noreferrer'>{t("policy")}</a></span>}/>
                                </Grid>
                                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                    <ValidatedReCAPTCHA name="recaptchaInstance" hasAdminRoles={hasAdminRoles}/>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Section>
                </Grid>
            </Grid>

            <Grid container spacing={2}>
                <Grid item>
                    <ValidatedSubmit view={view} api={processing} hideSave={view === "add" ? false : !hasAdminRoles}
                                     onSuccess={() => navigate(view === "add" ? "./" : "../")}
                                     onCancel={() => navigate(view === "add" ? "./" : "../")}/>
                </Grid>
                {view !== "add" ? <Grid item>
                    <Button
                        color="primary"
                        onClick={() => handleOpen()}
                    >
                        {t('interest')}
                    </Button>
                </Grid> : null}
            </Grid>

        </ContentBox>
    );
})