export const positionsHu = {
    "title": "Beosztások",
    "titleNav": "Törzsadatok - Beosztások",
    "position": "Beosztás",
    "add": "Új beosztás",
    "edit": "Beosztás szerkesztése",
    "remove": "Beosztás törlése",
    "delete": "Beosztás törlése",
    "back": "Vissza",
    "name": "Beosztás",
    "name-invalid": "A beosztás neve érvénytelen",
    "accountId": "Személy",
    "accountFullName": "Személy",
    "committeeId": "Bizottság",
    "committeeName": "Bizottság",
    "save": "Beosztás mentése",
    "actions": "Műveletek",
};
