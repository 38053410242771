export const menuBuilderStyle = theme => ({
    table: {
        borderCollapse: 'separate',
        borderSpacing: 0,
        position: 'relative',
        zIndex: 5,
        // width: '100%',
        width: 900,
    },
    tableHead: {},
    tableHeadRow: {
        height: 44,
    },
    tableHeadCell: {
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: theme.palette.text.secondary,
        borderRightWidth: 0,
        width: 224,
        '&:last-child': {
            borderRightWidth: 1,
            borderRightStyle: 'solid',
            borderRightColor: theme.palette.text.secondary,
        },
        padding: '0 16px',
        color: theme.palette.text.primary,
    },
    tableBody: {},
    indicatorCellButton: {
        display: 'none',
        backgroundColor: 'lightgreen',
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: 'yellowgreen',
        cursor: 'pointer',
    },
    editItemButton: {
        display: 'none',
        cursor: 'pointer',
    },
});