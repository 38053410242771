import {useCallback, useEffect, useState} from "react";
import LoadingStates from "./LoadingStates";
import debug from "./debug"

export function useQueryOne(url, filter) {
  debug("START", "useQueryOne", () => [url, filter]);

  const initialState = (filter === undefined) ? {
    loadingStatus: LoadingStates.READY,
    data: {  },
  } : {
    loadingStatus: LoadingStates.LOADING,
    data: undefined,
  };

  const [state, setState] = useState(initialState);

  const filterJson = JSON.stringify(filter);

  useEffect(() => {
    let aborted = false;
    if (filter === undefined) return;

    baseFetch('GET', url, filter,
        response => {
          if (aborted) return;
          debug("SUCCESS", "useQueryOne", () => [url, filter, response]);
          setState({
            loadingStatus: LoadingStates.READY,
            data: response,
          });
        },
        (error) => {
          if (aborted) return;
          debug("ERROR", "useQueryOne", () => [url, filter, error]);
          setState({
            loadingStatus: LoadingStates.ERROR,
            data: undefined,
          });
        }
    );

    return () => {
      aborted = true;
      debug("ABORT", "useQueryOne", () => [url, filter]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url, filterJson]);

  debug("END", "useQueryOne", () => [url, filter, state]);
  return state;

}

export function useFetchOne(url, id, recordType, recordSchema, revision, host) {
  debug("START", "useFetchOne", () => [url, id, revision]);

  const initialState = (id === undefined) ? {
    loadingStatus: LoadingStates.READY,
    data: recordType === undefined ? {  } : new recordType(recordSchema ? recordSchema.default() : {  }),
  } : {
    loadingStatus: LoadingStates.LOADING,
    data: undefined,
  };

  const [state, setState] = useState(initialState);

  useEffect(() => {
    if (id === undefined) return;

    let aborted = false;

    baseFetch('GET', id === null ? url : url + "/" + id, undefined,
        response => {
          if (aborted) return;
          debug("SUCCESS", "useFetchOne", () => [url, id, response]);
          setState({
            loadingStatus: LoadingStates.READY,
            data: recordType === undefined ? response : new recordType(response),
          });
        },
        (error) => {
          if (aborted) return;
          debug("ERROR", "useFetchOne", () => [url, id, error]);
          setState({
            loadingStatus: LoadingStates.ERROR,
            data: undefined,
          });
        },
        undefined,
        host
    );

    return () => {
      aborted = true;
      debug("ABORT", "useFetchOne", () => [url, id]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, revision === undefined ? [url, id] : [url, id, revision]);

  debug("END", "useFetchOne", () => [url, id, state]);
  return state;

}

/**
 * Fetches an array of records from the server. When filter is null it is a no-op,
 * no server request will be sent.
 *
 * @param url
 * @param filter
 * @param recordType
 * @param revision
 * @param host
 * @returns {{loadingStatus: (*), data: []}|{setData: *}}
 */
export function useFetchMany(url, filter, recordType, revision, host) {
  debug("START", "useFetchMany", () => [url, filter, revision]);

  const [state, setState] = useState({
    loadingStatus: filter === null ? LoadingStates.READY : LoadingStates.LOADING,
    data: []
  });

  // using json to prevent constant re-rendering because of inline objects
  const filterJson = JSON.stringify(filter);

  useEffect(() => {
    let aborted = false;
    if (filter === null) return;

    debug("EFFECT", "useFetchMany", () => [url, filter]);

    baseFetch('GET', url, filter,
        response => {
          if (aborted) return;
          debug("SUCCESS", "useFetchMany", () => [url, filter, response]);
          setState({
            loadingStatus: LoadingStates.READY,
            data: response.map(r => recordType === undefined ? r : new recordType(r)),
          });
        },
        (error) => {
          if (aborted) return;
          debug("ERROR", "useFetchMany", () => [url, filter, error]);
          setState({
            loadingStatus: LoadingStates.ERROR,
            data: [],
          });
        },
        undefined,
        host
    );

    return () => {
      debug("ABORT", "useFetchMany", () => [url, filter]);
      aborted = true;
    }
    // we use filterJson instead of filter as filter usually changes between renders
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, revision === undefined ? [url, filterJson, recordType] : [url, filterJson, recordType, revision]);

  debug("END", "useFetchMany", () => [url, filter, state]);

  // This callback may be used to modify the data. Table inline editors use it
  // to change the given field of the given row without any special hacking.
  // Using setData means a state change that in turn means re-render.
  // Which is actually quite stupid IMHO, but that's how table works.

  const setData = useCallback((data) => {
    setState({
      loadingStatus : state.loadingStatus,
      data,
    })
  }, [state, setState]);

  return {
    ...state,
    setData
  };
}

function baseFetch(method, url, data, onSuccess, onError, unauthorized, host) {

  let fullUrl = host === undefined ? '/api/1.0.0' + url : host + url;

  if (method === 'GET' && data !== undefined) {
    let params = new URLSearchParams();
    // eslint-disable-next-line no-unused-vars
    for (let key in data) {
      if (data.hasOwnProperty(key)) {
        let value = data[key];
        if (value === undefined) continue;
        if (value instanceof Array) {
          for (let i = 0; i < value.length; i++) { // intentional, to avoid function-in-loop
            params.append(key, value[i]);
          }
        } else {
          params.append(key, value);
        }
      }
    }

    fullUrl += "?" + params;
  }

  let fetchParams = {
    method: method,
    credentials: "same-origin",
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: (method === 'POST' || method === 'PUT' || method === 'PATCH') ? JSON.stringify(data) : undefined
  };

  fetch(fullUrl, fetchParams)

      .then((response) => {

        if (response.ok) {

          const contentType = response.headers.get('content-type');
          const isJSON = contentType ? contentType.indexOf("application/json") : -1;

          return (isJSON !== -1 ? response.json() : response);

        }

        console.log("RESP", response);

        if (response.status === 409 || response.status === 410 || response.status === 417) {

          // Callback is used in getSession which is called from index.js to fetch the session
          // if it exists , everything else goes to /expired-session

          if (unauthorized === "callback") {
            onError(response);
          } else {
            window.location = '/expired-session';
          }

          return null;

        } else if (response.status === 401) {

          window.location = '/unauthorized';
          return null;

        } else {

          throw response;

        }

      })

      .then(data => {

        if (data === null) return; // this happens when there is an invalid session

        if (onSuccess) onSuccess(data);

      })

      .catch(error => {

        console.log("ERR", error);
        // wth is an unknown error thrown by the navigate method of hookrouter, but it doesn't cause any problem
        if (error === "wth") return;
        if (onError) onError(error);

      });
}

export default baseFetch;