import React, {memo} from "react";
import {useTranslation} from "react-i18next";
import useForm from "../../Lib/form/useForm";
import {DocumentRecord, DocumentSchema} from '../../Data/Document';
import ContentBox from "../../Components/ContentBox/ContentBox";
import Section from "../../Lib/form/Components/Section/Section";
import ValidatedSubmit from "../../Lib/form/Components/ValidatedSubmit/ValidatedSubmit";
import ValidatedInput from "../../Lib/form/Components/ValidatedInput/ValidatedInput";
import ValidatedRadioGroup from "../../Lib/form/Components/ValidatedRadio/ValidatedRadioGroup";
import ValidatedRadio from "../../Lib/form/Components/ValidatedRadio/ValidatedRadio";
import {navigate} from "hookrouter";
import AttachmentUpload from "../../Components/AttachmentUpload/AttachmentUpload";
import {getAttachment} from "../../Api/documents";

export default memo(({meetingId, elementId, record, path, view, api}) => {

    const {t} = useTranslation('documents');

    const form = useForm({
        disabledForm: view === 'remove',
        recordType: DocumentRecord,
        recordSchema: DocumentSchema,
        namespace: 'documents',
        record: record
    });

    const onCancel = () => navigate(`/meeting-elements/${meetingId}/element/${elementId}/documents`);
    const onSuccess = () => navigate(`/meeting-elements/${meetingId}/element/${elementId}/documents`);

    return (
        <ContentBox path={path} form={form}>
            <Section title={t(view)}>
                <ValidatedInput name='title'/>
                <ValidatedRadioGroup name="status">
                    <ValidatedRadio name="status" value="DRAFT"/>
                    <ValidatedRadio name="status" value="PUBLIC"/>
                </ValidatedRadioGroup>
                <AttachmentUpload id={record.id}
                                  ns='documents'
                                  name='attachment'
                                  attachments={record.attachment}
                                  api={getAttachment}
                                  apiPath={'/attachments'}/>
            </Section>
            <ValidatedSubmit view={view}
                             api={api}
                             onSuccess={onSuccess}
                             onCancel={onCancel}/>
        </ContentBox>
    );
})