import {useContext, useEffect} from "react";
import Actions from "../Data/Actions/HeaderActions"
import {AppContext} from "../Main/AppContext";

export default function useNav(options) {
    const {path} = options;

    const appContext = useContext(AppContext);

    const dispatch = appContext.get('dispatch');

    window.scrollTo({top: 0, left: 0, behavior: "auto"});

    useEffect(() => dispatch(Actions.crestSet(path)),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [path]
    );
}