/*!

=========================================================
* Material Dashboard PRO React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

const buttonStyle = theme => ({
    button: {
        minHeight: "auto",
        minWidth: "auto",
        backgroundColor: theme.palette.grey.main,
        color: theme.palette.common.white,
        boxShadow:
            "0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12)",
        border: "none",
        borderRadius: "3px",
        position: "relative",
        padding: "12px 30px",
        margin: ".3125rem 1px",
        fontSize: "12px",
        fontWeight: "400",
        textTransform: "uppercase",
        letterSpacing: "0",
        willChange: "box-shadow, transform",
        transition:
            "box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1), background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1)",
        lineHeight: "1.42857143",
        textAlign: "center",
        whiteSpace: "nowrap",
        verticalAlign: "middle",
        touchAction: "manipulation",
        cursor: "pointer",
        "&:hover,&:focus": {
            color: theme.palette.text.white,
            backgroundColor: theme.palette.grey.light,
            boxShadow:
                "0 14px 26px -12px rgba(0, 0, 0, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"
        },
        "& .fab,& .fas,& .far,& .fal,& .material-icons": {
            position: "relative",
            display: "inline-block",
            top: "0",
            marginTop: "-1em",
            marginBottom: "-1em",
            fontSize: "1.1rem",
            marginRight: "4px",
            verticalAlign: "middle"
        },
        "& svg": {
            position: "relative",
            display: "inline-block",
            top: "0",
            width: "18px",
            height: "18px",
            marginRight: "4px",
            verticalAlign: "middle"
        },
        "&$justIcon": {
            "& .fab,& .fas,& .far,& .fal,& .material-icons": {
                marginTop: "0px",
                position: "absolute",
                width: "100%",
                transform: "none",
                left: "0px",
                top: "0px",
                height: "100%",
                lineHeight: "41px",
                fontSize: "20px"
            }
        }
    },
    fullWidth: {
        width: "100%"
    },
    primary: {
        backgroundColor: theme.palette.primary.main,
        boxShadow: "0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12)",
        "&:hover,&:focus": {
            backgroundColor: theme.palette.primary.main,
            boxShadow: "0 14px 26px -12px rgba(0, 0, 0, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"
        }
    },
    info: {
        backgroundColor: theme.palette.info.light,
        boxShadow: "0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12)",

        "&:hover,&:focus": {
            backgroundColor: theme.palette.info.light,
            boxShadow: "0 14px 26px -12px rgba(0, 0, 0, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"

        }
    },
    success: {
        backgroundColor: theme.palette.success.main,
        boxShadow: "0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12)",

        "&:hover,&:focus": {
            backgroundColor: theme.palette.success.main,
            boxShadow: "0 14px 26px -12px rgba(0, 0, 0, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"

        }
    },
    warning: {
        backgroundColor: theme.palette.warning.light,
        boxShadow: "0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12)",

        "&:hover,&:focus": {
            backgroundColor: theme.palette.warning.light,
            boxShadow: "0 14px 26px -12px rgba(0, 0, 0, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"

        }
    },
    danger: {
        backgroundColor: theme.palette.error.main,
        boxShadow: "0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12)",

        "&:hover,&:focus": {
            backgroundColor: theme.palette.error.main,
            boxShadow: "0 14px 26px -12px rgba(0, 0, 0, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"

        }
    },
    rose: {
        backgroundColor: theme.palette.error.light,
        boxShadow: "0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12)",

        "&:hover,&:focus": {
            backgroundColor: theme.palette.error.light,
            boxShadow: "0 14px 26px -12px rgba(0, 0, 0, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"
        }
    },
    white: {
        "&,&:focus,&:hover": {
            backgroundColor: theme.palette.common.white,
            color: theme.palette.text.primary
        }
    },
    twitter: {
        backgroundColor: theme.palette.info.light,
        color: theme.palette.text.white,
        boxShadow: "0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12)",

        "&:hover,&:focus,&:visited": {
            backgroundColor: theme.palette.info.light,
            color: theme.palette.text.white,
            boxShadow: "0 14px 26px -12px rgba(0, 0, 0, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"
        }
    },
    facebook: {
        backgroundColor: theme.palette.error.light,
        color: theme.palette.text.white,
        boxShadow: "0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12)",

        "&:hover,&:focus": {
            backgroundColor: theme.palette.error.light,
            color: theme.palette.text.white,
            boxShadow: "0 14px 26px -12px rgba(0, 0, 0, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"
        }
    },
    google: {
        backgroundColor: theme.palette.error.light,
        color: theme.palette.text.white,
        boxShadow: "0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12)",

        "&:hover,&:focus": {
            backgroundColor: theme.palette.error.light,
            color: theme.palette.text.white,
            boxShadow: "0 14px 26px -12px rgba(0, 0, 0, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"
        }
    },
    linkedin: {
        backgroundColor: theme.palette.info.main,
        color: theme.palette.text.white,
        boxShadow: "0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12)",

        "&:hover,&:focus": {
            backgroundColor: theme.palette.info.main,
            color: theme.palette.text.white,
            boxShadow: "0 14px 26px -12px rgba(0, 0, 0, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"
        }
    },
    pinterest: {
        backgroundColor: theme.palette.error.dark,
        color: theme.palette.text.white,
        boxShadow: "0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12)",

        "&:hover,&:focus": {
            backgroundColor: theme.palette.error.dark,
            color: theme.palette.text.white,
            boxShadow: "0 14px 26px -12px rgba(0, 0, 0, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"
        }
    },
    youtube: {
        backgroundColor: theme.palette.error.light,
        color: theme.palette.text.white,
        boxShadow: "0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12)",

        "&:hover,&:focus": {
            backgroundColor: theme.palette.error.light,
            color: theme.palette.text.white,
            boxShadow: "0 14px 26px -12px rgba(0, 0, 0, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"
        }
    },
    tumblr: {
        backgroundColor: theme.palette.secondary.dark,
        color: theme.palette.text.white,
        boxShadow: "0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12)",

        "&:hover,&:focus": {
            backgroundColor: theme.palette.secondary.dark,
            color: theme.palette.text.white,
            boxShadow: "0 14px 26px -12px rgba(0, 0, 0, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"
        }
    },
    github: {
        backgroundColor: theme.palette.grey.dark,
        color: theme.palette.text.white,
        boxShadow: "0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12)",

        "&:hover,&:focus": {
            backgroundColor: theme.palette.grey.dark,
            color: theme.palette.text.white,
            boxShadow: "0 14px 26px -12px rgba(0, 0, 0, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"
        }
    },
    behance: {
        backgroundColor: theme.palette.primary.dark,
        color: theme.palette.text.white,
        boxShadow: "0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12)",

        "&:hover,&:focus": {
            backgroundColor: theme.palette.primary.dark,
            color: theme.palette.text.white,
            boxShadow: "0 14px 26px -12px rgba(0, 0, 0, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"
        }
    },
    dribbble: {
        backgroundColor: theme.palette.warning.dark,
        color: theme.palette.text.white,
        boxShadow: "0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12)",

        "&:hover,&:focus": {
            backgroundColor: theme.palette.warning.dark,
            color: theme.palette.text.white,
            boxShadow: "0 14px 26px -12px rgba(0, 0, 0, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"
        }
    },
    reddit: {
        backgroundColor: theme.palette.warning.dark,
        color: theme.palette.text.white,
        boxShadow: "0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12)",

        "&:hover,&:focus": {
            backgroundColor: theme.palette.warning.dark,
            color: theme.palette.text.white,
            boxShadow: "0 14px 26px -12px rgba(0, 0, 0, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"
        }
    },
    simple: {
        "&,&:focus,&:hover": {
            color: theme.palette.text.white,
            background: "transparent",
            boxShadow: "none"
        },
        "&$primary": {
            "&,&:focus,&:hover,&:visited": {
                color: theme.palette.primary.main
            }
        },
        "&$info": {
            "&,&:focus,&:hover,&:visited": {
                color: theme.palette.info.main
            }
        },
        "&$success": {
            "&,&:focus,&:hover,&:visited": {
                color: theme.palette.success.main
            }
        },
        "&$warning": {
            "&,&:focus,&:hover,&:visited": {
                color: theme.palette.primary.main
            }
        },
        "&$rose": {
            "&,&:focus,&:hover,&:visited": {
                color: theme.palette.error.light
            }
        },
        "&$danger": {
            "&,&:focus,&:hover,&:visited": {
                color: theme.palette.error.main
            }
        },
        "&$twitter": {
            "&,&:focus,&:hover,&:visited": {
                color: theme.palette.info.light
            }
        },
        "&$facebook": {
            "&,&:focus,&:hover,&:visited": {
                color: theme.palette.info.main
            }
        },
        "&$google": {
            "&,&:focus,&:hover,&:visited": {
                color: theme.palette.error.light
            }
        },
        "&$linkedin": {
            "&,&:focus,&:hover,&:visited": {
                color: theme.palette.info.main
            }
        },
        "&$pinterest": {
            "&,&:focus,&:hover,&:visited": {
                color: theme.palette.error.dark
            }
        },
        "&$youtube": {
            "&,&:focus,&:hover,&:visited": {
                color: theme.palette.error.light
            }
        },
        "&$tumblr": {
            "&,&:focus,&:hover,&:visited": {
                color: theme.palette.secondary.dark
            }
        },
        "&$github": {
            "&,&:focus,&:hover,&:visited": {
                color: theme.palette.grey.dark
            }
        },
        "&$behance": {
            "&,&:focus,&:hover,&:visited": {
                color: theme.palette.primary.dark
            }
        },
        "&$dribbble": {
            "&,&:focus,&:hover,&:visited": {
                color: theme.palette.warning.dark,
            }
        },
        "&$reddit": {
            "&,&:focus,&:hover,&:visited": {
                color: theme.palette.warning.dark,
            }
        }
    },
    transparent: {
        "&,&:focus,&:hover": {
            color: "inherit",
            background: "transparent",
            boxShadow: "none"
        }
    },
    disabled: {
        opacity: "0.65",
        pointerEvents: "none"
    },
    lg: {
        "&$justIcon": {
            "& .fab,& .fas,& .far,& .fal,& svg,& .material-icons": {
                marginTop: "-4px"
            }
        },
        padding: "1.125rem 2.25rem",
        fontSize: "0.875rem",
        lineHeight: "1.333333",
        borderRadius: "0.2rem"
    },
    sm: {
        "&$justIcon": {
            "& .fab,& .fas,& .far,& .fal,& svg,& .material-icons": {
                marginTop: "1px"
            }
        },
        padding: "0.40625rem 1.25rem",
        fontSize: "0.6875rem",
        lineHeight: "1.5",
        borderRadius: "0.2rem"
    },
    round: {
        borderRadius: "30px"
    },
    block: {
        width: "100% !important"
    },
    link: {
        "&,&:hover,&:focus": {
            backgroundColor: "transparent",
            color: theme.palette.text.primary,
            boxShadow: "none"
        }
    },
    justIcon: {
        paddingLeft: "12px",
        paddingRight: "12px",
        fontSize: "20px",
        height: "41px",
        minWidth: "41px",
        width: "41px",
        "& .fab,& .fas,& .far,& .fal,& svg,& .material-icons": {
            marginRight: "0px"
        },
        "&$lg": {
            height: "57px",
            minWidth: "57px",
            width: "57px",
            lineHeight: "56px",
            "& .fab,& .fas,& .far,& .fal,& .material-icons": {
                fontSize: "32px",
                lineHeight: "56px"
            },
            "& svg": {
                width: "32px",
                height: "32px"
            }
        },
        "&$sm": {
            height: "30px",
            minWidth: "30px",
            width: "30px",
            "& .fab,& .fas,& .far,& .fal,& .material-icons": {
                fontSize: "17px",
                lineHeight: "29px"
            },
            "& svg": {
                width: "17px",
                height: "17px"
            }
        }
    }
});

export default buttonStyle;
