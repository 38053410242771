export const imageUploadStyle = theme => ({
    imageContainer: {
        minHeight: 280,
        '&:hover button': {
            display: 'initial',
        }
    },
    changePreviewButton: {
        display: 'none',
        position: 'absolute',
        margin: 'auto',
        color: theme.palette.text.white,
        marginTop: 27
    },
    imagePreview: {
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        // borderWidth: 1,
        // borderStyle: 'solid',
        // borderColor: '#d2d2d2'
    },
    image: {
        ...theme.imgFluid,
    },
});