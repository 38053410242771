import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import useForm from "../../Lib/form/useForm";
import ValidatedInput from "../../Lib/form/Components/ValidatedInput/ValidatedInput";
import Card from "../../Lib/material-dashboard/components/Card/Card";
import CardBody from "../../Lib/material-dashboard/components/Card/CardBody";
import ValidatedSubmit from "../../Lib/form/Components/ValidatedSubmit/ValidatedSubmit";
import {useSnackbar} from "notistack";
import Grid from "@material-ui/core/Grid";
import accountStyle from "./accountStyle";
import {makeStyles} from "@material-ui/styles";
import {ResetRecord, ResetSchema} from "../../Data/Account";
import {enableReset, useAccount} from "../../Api/accounts";
import Section from "../../Lib/form/Components/Section/Section";
import ContentBox from "../../Components/ContentBox/ContentBox";
import {closeSnack} from "../../Main/util";
import Box from "@material-ui/core/Box";
import {useBackground} from "../../Lib/ContentManagement/ContentEditor/Api/pages";
import {navigate, useRoutes} from "hookrouter";

const subRoutes = {
    "/public": () => <ResetStart publicPage={true}/>,
    "/:id": ({id}) => <ResetStart accountId={id} publicPage={false}/>,
};

export default () => useRoutes(subRoutes);

const useStyles = makeStyles(accountStyle);

const PublicResetStart = ({onSuccess, onCancel, onError}) => {

    const classes = useStyles();

    const {t} = useTranslation('account');

    const {data, loadingStatus} = useBackground();

    return (
        <Box className={classes.panel}>
            <Box className={classes.paperContainer}
                 style={loadingStatus ? {} : {backgroundImage: `url(/api/1.0.0/images/${data})`}}>
                <Card className={classes.container}>
                    <CardBody>
                        <Grid container justify="center">
                            <Grid item xs={12}>
                                <ValidatedInput name="principalName" autocomplete="username"/>
                                <ValidatedInput name="emailAddress" autocomplete="username"/>
                                <Grid item container justify="flex-end">
                                    <Grid item style={{marginTop: 40}}>
                                        <ValidatedSubmit
                                            api={enableReset}
                                            onCancel={onCancel}
                                            submitLabel={t('reset-start-button')}
                                            onSuccess={onSuccess}
                                            onError={onError}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>

                        </Grid>
                    </CardBody>
                </Card>
            </Box>
        </Box>
    )
};

const AdminResetStart = ({onSuccess, onError, onCancel}) => {

    const {t} = useTranslation('account');

    return (
        <Grid container direction="row" justify="center">
            <Section title={t('reset-start-title')}>
                <ValidatedInput name="principalName" autocomplete="username"/>
                <ValidatedInput name="emailAddress" autocomplete="username"/>
            </Section>
            <ValidatedSubmit
                api={enableReset}
                onCancel={onCancel}
                submitLabel={t('reset-start-button')}
                onSuccess={onSuccess}
                onError={onError}
            />
        </Grid>
    );
};

const ResetStart = ({accountId, publicPage}) => {

    const [success, setSuccess] = useState(false);

    const [path, setPath] = useState([]);

    const {enqueueSnackbar, closeSnackbar} = useSnackbar();

    const {data, loadingStatus} = useAccount(accountId);

    useEffect(() => {
        if (!loadingStatus) {

            const pathItems = [];

            pathItems.push({label: t('titleNav'), url: '/admin/accounts'});
            if (data.fullName) pathItems.push({
                label: data.fullName,
                url: `/admin/accounts/${accountId}/edit`
            });
            pathItems.push({label: t('reset-start-title'), url: `/admin/accounts/reset/${accountId}`});

            setPath(pathItems);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingStatus]);

    const onSuccess = () => {
        setSuccess(true);
    };

    const onError = () => {
        enqueueSnackbar(
            t('reset-start-error'),
            {variant: 'error', persist: true, action: (key) => closeSnack(t, closeSnackbar, key)});

    };

    const onCancel = publicPage ?
        () => navigate('/login') :
        () => navigate('/admin/accounts/' + accountId + '/edit');

    // usual form stuff  ----------------------------------

    const {t} = useTranslation("account");

    // noinspection JSPotentiallyInvalidConstructorUsage
    const form = useForm({
        recordType: ResetRecord,
        recordSchema: ResetSchema,
        namespace: 'account',
        record: new ResetRecord(ResetSchema.default())
    });

    if (success) {
        return (
            <ContentBox title={t("reset-start-success")} publicPage={publicPage}>
                <Section title={t("reset-start-success")}>
                    <br/>
                    {t('reset-start-success-explanation')}
                </Section>
            </ContentBox>
        )
    }

    return (
        <ContentBox publicPage={publicPage} path={path} form={form}>
            {publicPage ?
                <PublicResetStart onSuccess={onSuccess} onCancel={onCancel} onError={onError}/> :
                <AdminResetStart onSuccess={onSuccess} onError={onError} onCancel={onCancel}/>
            }
        </ContentBox>
    );
};