import React, {useContext, useEffect} from "react";
import {renounce} from "../../Lib/auth/Api/session";
import {AppContext} from "../../Main/AppContext";
import Actions from "../../Lib/auth/AuthActions";
import {navigate} from "hookrouter";
import {AuthContext} from "../../Lib/auth/AuthContext";
import {isLoggedIn} from "../../Lib/auth/auth";

const Logout = () => {

  const authContext = useContext(AuthContext);
  const loggedIn = isLoggedIn(authContext);

  const appContext = useContext(AppContext);
  const dispatch = appContext.get('dispatch');

  useEffect(() => {
    if (loggedIn) {
      renounce(
          () => {
            dispatch(Actions.sessionRemove());
          },
          () => {
            dispatch(Actions.sessionRemove());
          });
    }
    // TODO check if this suppress is OK
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedIn]);

  // if (!loggedIn) navigate('/');
   navigate('/login');

  return <div>&nbsp;</div>;

};

export default Logout;