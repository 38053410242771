import {Record} from 'immutable';
import {number, object, string} from "yup";

export const SearchResultRecord = Record({
    id: 0,
    description: '',
    url: '',
});

export const SearchResultSchema = object().shape({
    id: number().nullable().default(0),
    description: string().min(0).max(512).default(''),
    url: string().min(0).default(''),
});