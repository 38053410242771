import React, {useContext, useEffect, useState} from "react";
import {AuthContext} from "../auth/AuthContext";
import ListRow from "./ListRow";

const ListGroup = ({apiCall, apiTests}) => {

    const context = useContext(AuthContext)

    const roles = context.getIn(['session', 'roles']);

    const [results, setResults] = useState([]);

    useEffect(() => {
        apiCall((response) => {

            const {method, url, ok, data} = response;

            const tests = [];

            roles.forEach(role => {

                const test = apiTests[url][method][role];

                let dataTestResult = {
                    test: true,
                    msg: '-',
                };

                if (test.validate && data) dataTestResult = test.validate(data);

                const result = {
                    ...response,
                    role: role,
                    statusTest: test.hasResponse === ok,
                    dataTest: dataTestResult,
                };

                tests.push(result);
            })

            setResults(tests)
        })
    }, [])

    if (!results.length) return <div/>;

    return (
        <React.Fragment>
            {results.map(r => <ListRow key={r.role} result={r}/>)}
        </React.Fragment>
    )
}

export default ListGroup;