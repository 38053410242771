import React, {memo} from "react";
import ContentBox from "../../Components/ContentBox/ContentBox";
import {useDecisions} from "../../Api/decisions";
import {baseTableStyles} from "../../Lib/simplexion-table/styles/baseStyle";
import {customActions, customActionsStyle} from "../../Lib/simplexion-table/templates/action-buttons";
import Section from "../../Lib/form/Components/Section/Section";
import {SimplexionTable} from "../../Lib/simplexion-table/SimplexionTable";
import {makeStyles} from "@material-ui/core";
import {translatableField} from "../../Lib/simplexion-table/templates/translatable-field";
import {useTranslation} from "react-i18next";

const styles = () => {
    let classes = baseTableStyles;
    Object.entries(customActionsStyle).forEach(entry => {
        classes[entry[0]] = entry[1];
    });
    return classes;
};

const useStyles = makeStyles(styles);

const attachmentList = (accessor, record) =>  record.get(accessor).map(str => `<span>${str}</span>`).join('<br/>');

const Decisions = ({urlNs}) => {

    const classes = useStyles();

    const {data} = useDecisions();

    const tableConfig = {
        id: 'decisions-table',
        nameSpace: 'decisions',
        stickyHeader: true,
        sortable: true,
        styles: classes,
        columns: [
            {
                accessor: 'numberOfDecision',
            },
            {
                accessor: 'title',
            },
            {
                accessor: 'presenterName',
            },
            {
                accessor: 'type',
                renderer: (...args) => translatableField(...args),
            },
            {
                accessor: 'attachmentNames',
                renderer: (...args) => attachmentList(...args)
            },
            {
                accessor: 'actions',
                renderer: (...args) => customActions(...args, true, true, urlNs),
            }
        ],
    };

    return <SimplexionTable tableConfig={tableConfig} data={data}/>;
};

const List = memo(({path}) => {

    const {t} = useTranslation('decisions');

    const urlNs = "/decisions/";

    return (
        <ContentBox path={path} add={urlNs + 'add'}>
            <Section title={t('listOfDecisions')}>
                <Decisions urlNs={urlNs}/>
            </Section>
        </ContentBox>
    );
});

export default List;