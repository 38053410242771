import baseFetch, {useFetchMany, useFetchOne} from "../../../baseFetch";
import {ListItemRecord, ListItemSchema} from "../Data/ListItem";
import {AttachmentRecord} from "../Data/Attachment";
import {roles} from "../../../../Main/roles";


export function useListItems(pageId, filter, revision) {
    return useFetchMany('/file-upload-pages/' + pageId + '/items', undefined, ListItemRecord, revision);
}

export function useListItem(id, revision) {
    return useFetchOne('/list-item', id, ListItemRecord, ListItemSchema, revision);
}

export function getListItems(id, onSuccess, onError) {
    baseFetch('GET', '/file-upload-pages/' + id + '/items', undefined,
        data => {
            if (onSuccess) onSuccess(data.map(r => new ListItemRecord(r)));
        },
        onError
    );
}

export function addListItem(id, listItem, onSuccess, onError) {
    baseFetch('POST', '/file-upload-pages/' + id + '/items', listItem,
        data => {
            if (onSuccess) onSuccess(new ListItemRecord(data))
        },
        onError
    );
}

export function updateListItem(listItem, onSuccess, onError) {
    baseFetch('PATCH', '/list-item/' + listItem.id, listItem, onSuccess, onError);
}

export function removeListItem(fileUploadPageId, listItemId, onSuccess, onError) {
    baseFetch('DELETE', '/file-upload-pages/' + fileUploadPageId + '/items/' + listItemId, undefined, onSuccess, onError);
}

export function getAttachments(id, onSuccess, onError) {
    baseFetch('GET', '/list-item/' + id + '/attachments', undefined,
        data => {
            if (onSuccess) onSuccess(data.map(r => new AttachmentRecord(r)))
        },
        onError
    );
}


export const listItemsApiTest = {
    '/file-upload-pages/1/items': {
        'GET': {
            [roles.ANONYMOUS]: {hasResponse: true},
            [roles.SITE_MEMBER]: {hasResponse: true},
            [roles.COUNCIL_ADMIN]: {hasResponse: true},
            [roles.CM_ADMIN]: {hasResponse: true},
            [roles.UPLOAD_ADMIN]: {hasResponse: true},
            [roles.ADMIN]: {hasResponse: true},
            [roles.SECURITY_OFFICER]: {hasResponse: true}
        },
        'POST': {
            [roles.ANONYMOUS]: {hasResponse: false},
            [roles.SITE_MEMBER]: {hasResponse: false},
            [roles.COUNCIL_ADMIN]: {hasResponse: false,},
            [roles.CM_ADMIN]: {hasResponse: true},
            [roles.UPLOAD_ADMIN]: {hasResponse: true},
            [roles.ADMIN]: {hasResponse: true,},
            [roles.SECURITY_OFFICER]: {hasResponse: true}
        },
    },
    '/file-upload-pages/1/items/1': {
        'DELETE': {
            [roles.ANONYMOUS]: {hasResponse: false},
            [roles.SITE_MEMBER]: {hasResponse: false},
            [roles.COUNCIL_ADMIN]: {hasResponse: false},
            [roles.CM_ADMIN]: {hasResponse: true},
            [roles.UPLOAD_ADMIN]: {hasResponse: true},
            [roles.ADMIN]: {hasResponse: true},
            [roles.SECURITY_OFFICER]: {hasResponse: true}
        },
    },
    '/list-item/1': {
        'GET': {
            [roles.ANONYMOUS]: {hasResponse: false},
            [roles.SITE_MEMBER]: {hasResponse: false},
            [roles.COUNCIL_ADMIN]: {hasResponse: false},
            [roles.CM_ADMIN]: {hasResponse: true},
            [roles.UPLOAD_ADMIN]: {hasResponse: true},
            [roles.ADMIN]: {hasResponse: true},
            [roles.SECURITY_OFFICER]: {hasResponse: true}
        },
        'PATCH': {
            [roles.ANONYMOUS]: {hasResponse: false},
            [roles.SITE_MEMBER]: {hasResponse: false},
            [roles.COUNCIL_ADMIN]: {hasResponse: false},
            [roles.CM_ADMIN]: {hasResponse: true},
            [roles.UPLOAD_ADMIN]: {hasResponse: true},
            [roles.ADMIN]: {hasResponse: true},
            [roles.SECURITY_OFFICER]: {hasResponse: true}
        }
    },
    '/list-item/1/attachments': {
        'GET': {
            [roles.ANONYMOUS]: {hasResponse: true},
            [roles.SITE_MEMBER]: {hasResponse: true},
            [roles.COUNCIL_ADMIN]: {hasResponse: true},
            [roles.CM_ADMIN]: {hasResponse: true},
            [roles.UPLOAD_ADMIN]: {hasResponse: true},
            [roles.ADMIN]: {hasResponse: true},
            [roles.SECURITY_OFFICER]: {hasResponse: true}
        }
    }
}