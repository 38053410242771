import baseFetch, {useFetchMany, useFetchOne} from "../Lib/baseFetch";
import {ItemRecord, ItemSchema} from "../Data/Item";

export function useItems(filter, revision) {
    return useFetchMany('/items', filter, ItemRecord, revision);
}

export function useItem(id) {
    return useFetchOne('/items', id, ItemRecord, ItemSchema);
}

export function addItem(item, onSuccess, onError) {
    baseFetch('POST', '/items', item,
        data => {
            if (onSuccess) onSuccess(new ItemRecord(data))
        },
        onError
    );
}

export function updateItem(item, onSuccess, onError) {
    baseFetch('PATCH', '/items/' + item.get('id'), item, onSuccess, onError);
}

export function showUpItem(id,status, onSuccess, onError) {
    baseFetch('PATCH', '/items/' + id + "/show-up?status=" + status, undefined, onSuccess, onError);
}

export function removeItem(item, onSuccess, onError) {
    baseFetch('DELETE', '/items/' + item.get('id'), undefined, onSuccess, onError);
}

