import {useTranslation} from "react-i18next";
import React, {useState} from "react";

import Grid from "@material-ui/core/Grid";
import CustomInput from "../../Lib/material-dashboard/components/CustomInput/CustomInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from '@material-ui/icons/Search';
import {makeStyles} from "@material-ui/core";
import {searchFieldStyle} from "./searchFieldStyle";

const useStyles = makeStyles(searchFieldStyle);

const SearchField = ({handleClick, searchTerm}) => {

    const classes = useStyles();

    const {t} = useTranslation('searchResult');

    const [input, setInput] = useState(searchTerm || '');

    const onClick = () => {
        handleClick(input);
    };

    const onKeyDown = e => {
        if (e.keyCode === 13) {
            onClick()
        }
    };

    const adornment = (
        <InputAdornment position="end">
            <IconButton variant='standard'
                        size='small'
                        aria-label={t('startSearch')}
                        onClick={onClick}>
                <SearchIcon/>
            </IconButton>
        </InputAdornment>
    );

    return (
        <Grid item container xs={12} lg={4} xl={2} onKeyDown={onKeyDown} className={classes.searchFieldBox}>
            <CustomInput labelText={t('term')}
                         formControlProps={{
                             fullWidth: true,
                         }}
                         inputProps={{
                             name: 'searchTerm',
                             value: input,
                             type: 'text',
                             endAdornment: adornment,
                             onChange: (e) => setInput(e.target.value),
                         }}
            />
        </Grid>
    );
};

export default SearchField