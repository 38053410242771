import {Record} from 'immutable';
import {array, boolean, number, object, string} from "yup";

export const MeetingItemRecord = Record({
    id: 0,
    meeting: 0,
    order: '',
    subject: '',
    type: '',
    presenter1: '',
    presenter2: '',
    itemRecord : 0
});

export const MeetingItemSchema = object().shape({
    id: number().required().min(0).default(0),
    elementSeq: number().default(0),                        // fixme not sure that it's needed anymore
    topic: string().required().min(1).max(2000).default(''),
    place: string().default(''),
    presenter: number().required().default(0),
    presenterName: string().default(''),
    meetings: array().required().of(number().min(1)).min(1).nullable(),
    committeeNames: array().of(string()).min(0).nullable(),
    documents: array().of(number().min(1)).min(0).nullable(),
    documentNames: array().of(string()).min(0).nullable(),
    type: string().required().oneOf(['OPEN', 'CLOSED']).default("CLOSED"),
    public: boolean().default(false),
});
