import {Record} from 'immutable';
import {array, boolean, mixed, number, object, string} from "yup";

export const AccountRecord = Record({
    id: 0,
    principalId: 0,
    principalName: '',
    credentials: '',
    verification: '',
    emailAddress: '',
    fullName: '',
    displayName: '',

    active: true,

    roles: [],

    organisationId: 0,
    organisationName: '',
});

export const AccountSchema = object().shape({
    id: number().min(0).default(0),
    principalId: number().min(0).default(0),
    principalName: string().required().min(3).max(100).default(""),
    credentials: string().required().min(8).max(50).default(""),
    verification: string().required().min(8).max(50).default(""),
    emailAddress: string().required().email().default(""),
    fullName: string().required().min(5).max(100).default(""),
    displayName: string().required().min(2).max(100).default(""),

    active : mixed().required().oneOf([true, false]).default(true),

    roles: array().required().of(string().min(1)).min(1).default([]),

    organisationId: number().min(0).default(0),
    organisationName: string().nullable().min(0).default(''),
});


export const ResetRecord = Record({
    principalName: '',
    emailAddress: ''
});

export const ResetSchema = object().shape({
    principalName: string().required().min(3).max(50),
    emailAddress: string().required().email().default("")
});

export const PasswordRecord = Record({
    oldPassword: '',
    newPassword: '',
    verification: ''
});

export const PasswordSchema = object().shape({
    oldPassword: string(),
    newPassword: string().required().min(8).max(50).default(""),
    verification: string().required().min(8).max(50).default("")
});