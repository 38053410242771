import React from "react";
import {addData, createRows, getAll, getOne, removeData, updateData} from "../util";
import {BlockPageRecord, BlockPageSchema} from "../../ContentManagement/ContentEditor/Data/BlockPage";
import {TextPageRecord, TextPageSchema} from "../../ContentManagement/ContentEditor/Data/TextPage";

import {blockPagesApiTest} from "../../ContentManagement/ContentEditor/Api/blockPages";

const record = BlockPageRecord(BlockPageSchema.default()).withMutations(data => {
    return data.set("title", "Blokk_05_actuality")
        .set("status", "PUBLIC")
})

const textPageRecord = TextPageRecord(TextPageSchema.default()).withMutations(data => {
    return data.set("title", "title")
        .set("status", "PUBLIC")
})

const calls = [
    onResponse => getOne("/block-pages/5", onResponse),
    onResponse => updateData("/block-pages/5", record, onResponse),
    onResponse => getAll("/block-pages/5/blocks", onResponse),
    onResponse => addData("/block-pages/5/blocks", textPageRecord, onResponse),
    onResponse => removeData('/block-pages/5/blocks/4', onResponse),
    onResponse => getAll("/block-pages/5/blocks/10", onResponse),
];

const BlockPagesApis = () => {

    return (
        <React.Fragment>
            {createRows(calls, blockPagesApiTest)}
        </React.Fragment>
    );
}

export default BlockPagesApis;