export const style = theme => ({
    root: {
        backgroundColor: theme.palette.transparentWhite,
        width: '100vw',
        height: 110,
        [theme.breakpoints.down('sm')]: {
            height: 85,
        },
    },
    navToolbar: {
        height: '100%',
    },
    navToolbarInnerContainer: {
        height: '100%'
    },

    //
    // Logo
    //

    crest: {
        position: 'absolute',
        height: 100,
        borderRadius: 'unset',
        width: 'auto',
        margin: 'auto',
        marginTop: 5,
        marginBottom: 5,
        zIndex: 1101,
        bottom: 0,
        [theme.breakpoints.down('sm')]: {
            width: 'auto',
            height: 75,
        },
    },
    crestLink: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
    },
    crestUnderLayer: {
        borderRadius: '50%',
        position: 'absolute',
        height: 100,
        width: 100,
        margin: 'auto',
        boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)',
        zIndex: -1,
        top: 0
    },
    crestImage: {
        ...theme.imgFluid,
        height: '100%',
    },

    //
    //  Hamburger icon for mobile nav
    //

    menuIcon: {
        color: theme.palette.text.secondary
    },

    //
    //  High contrast
    //

    highContrastIcon: {
        height: 20,
        width: 20,
        float: 'right',
        marginRight: 8,
    },

    //
    //  Login
    //

    loginIcon: {
        height: 20,
        width: 20,
        float: 'right',
        marginRight: 8,
        color: theme.palette.text.secondary,
    },


    //
    //  Nav items
    //

    navItem: {
        height: 54,
        padding: '8px 0',
    },
    navItemInnerContainer: {
        marginLeft: 8,
        marginRight: 8,
        boxShadow: theme.shadows[4],
        [theme.breakpoints.down('sm')]: {
            marginLeft: 0,
            marginRight: 0,
        },
    },

    //
    //  Dropdown
    //

    navDropdownGroup: {
        zIndex: 1100,
        boxShadow: theme.shadows[4],
    },
    navDropdownItem: {
        padding: '1px 0',
        height: 40,
        '&:first-child': {
            paddingTop: 2,
            height: 41,
        },
        '&:last-child': {
            paddingBottom: 2,
            height: 41
        },
        // '&:only-child': {
        //     paddingTop: 2,
        //     paddingBottom: 2,
        //     height: 42,
        // }
    },

    //
    // Styles for the <a> inner component
    //

    navLink: {
        height: '100%',
        width: '100%',
        textDecoration: 'unset',
        color: theme.palette.text.secondary,
        backgroundColor: theme.palette.white,
        display: 'flex',
        alignItems: 'center',
        '&:hover': {
            backgroundColor: theme.palette.transparentWhite,
        },
        '&:active': {
            color: theme.palette.text.white,
        }
    },
    navLinkMain: {
        justifyContent: 'center',
    },
    navLinkSub: {
        justifyContent: 'space-between',
    },
    navLinkText: {
        padding: '0 16px',
        margin: 0,
        whiteSpace: 'nowrap'
    },
    navLinkTextMain: {
        padding: '0 16px',
        fontWeight: 'bold',
        textTransform: 'uppercase',
    },
    navLinkIcon: {
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
        paddingRight: 16
    },

    //
    //  Color styles
    //

    navItemBlue: {
        backgroundColor: theme.palette.kolorcity_alt.blue.light,
    },
    navItemRed: {
        backgroundColor: theme.palette.kolorcity_alt.red.light,
    },
    navItemGrey: {
        backgroundColor: theme.palette.kolorcity_alt.grey.light,
    },
    navItemYellow: {
        backgroundColor: theme.palette.kolorcity_alt.yellow.light,
    },
    navLinkBlue: {
        '&:active': {
            backgroundColor: theme.palette.kolorcity_alt.blue.dark,
        }
    },
    navLinkRed: {
        '&:active': {
            backgroundColor: theme.palette.kolorcity_alt.red.dark,
        }
    },
    navLinkGrey: {
        '&:active': {
            backgroundColor: theme.palette.kolorcity_alt.grey.dark,
        }
    },
    navLinkYellow: {
        '&:active': {
            backgroundColor: theme.palette.kolorcity_alt.yellow.dark,
        }
    },
    navLinkGreen: {
        '&:active': {
            backgroundColor: theme.palette.kolorcity_alt.green.dark,
        }
    },
});