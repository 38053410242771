import React from "react";
import {MeetingItemRecord, MeetingItemSchema} from "../../../Data/MeetingItem";
import {addData, createRows, getOne, removeData, updateData} from "../util";

import {meetingElementsApiTest} from "../../../Api/meeting-items";

const record = MeetingItemRecord(MeetingItemSchema.default()).withMutations(data => {
    return data.set("topic", "Nyílt & Publikus")
        .set("presenter", 1)
        .set("meetings", [1])
        .set("documents", [1])
        .set("type", "OPEN")
        .set("public", true)
})

const calls = [
    onResponse => addData("/meeting-elements", record, onResponse),
    onResponse => getOne("/meeting-elements/1", onResponse),
    onResponse => updateData("/meeting-elements/1", record, onResponse),
    onResponse => removeData('/meeting-elements/2', onResponse),
];

const MeetingElementsApis = () => {

    return (
        <React.Fragment>
            {createRows(calls, meetingElementsApiTest)}
        </React.Fragment>
    );
}

export default MeetingElementsApis;