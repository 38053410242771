import React from "react";
import ContentBox from "../../../../Components/ContentBox/ContentBox";
import Grid from "@material-ui/core/Grid";
import {makeStyles, Typography} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import {cityCouncilPageStyle} from "../../cityCouncilPageStyle";
import {useMeetingItem} from "../../../../Api/meeting-items";
import Section from "../../../../Lib/form/Components/Section/Section";
import {useDocuments} from "../../../../Api/documents";

// "/:meetingId/elements/:elementId": ({meetingId, elementId}) => props => <ElementDetails view='elementDetails'/>

const useStyles = makeStyles(cityCouncilPageStyle);

const DownloadItem = ({title, attachment}) => {

    const classes = useStyles();

    return (
        <Grid item xs={12}>
            <a href={`/api/1.0.0/attachments/${attachment}`} rel='noopener noreferrer' target='_blank' className={classes.docItem}>
                {title}
            </a>
        </Grid>
    );
};

const DownloadList = ({elementId}) => {

    const {data, loadingStatus} = useDocuments(elementId);

    if (loadingStatus) return null;

    return (
        <Grid container direction='column'>
            {data.map(record => <DownloadItem key={record.id} title={record.title} attachment={record.attachment}/>)}
        </Grid>
    );
};

const ElementDetails = ({path, meetingId, elementId}) => {

    const {t} = useTranslation('cityCouncilPage');

    const classes = useStyles();

    const {data, loadingStatus} = useMeetingItem(elementId);

    if (loadingStatus) return null;

    path.push({label: data.elementSeq, url: `/city-council/meetings/${meetingId}/elements/${elementId}`});

    return (
        <ContentBox path={path}>
            <Section>
                <Grid container>
                    <Grid container item xs={12} md={6} xl={4}>
                        <Grid item container className={classes.dataLineBox}>
                            <Grid item xs={12} md={4}>
                                <Typography variant='body1' className={classes.detailName}>
                                    {t('presenterName')}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={8}>
                                <Typography>
                                    {data.presenterName}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item container className={classes.dataLineBox}>
                            <Grid item xs={12} md={4}>
                                <Typography variant='body1' className={classes.detailName}>
                                    {t('topic')}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={8}>
                                <Typography>
                                    {data.topic}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item container className={classes.dataLineBox}>
                            <Grid item xs={12} md={4}>
                                <Typography variant='body1' className={classes.detailName}>
                                    {t('type')}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={8}>
                                <Typography>
                                    {t(data.type)}
                                </Typography>
                            </Grid>
                        </Grid>
                        {data.type === 'OPEN' &&
                        <Grid item container className={classes.dataLineBox}>
                            <Grid item xs={12} md={4}>
                                <Typography variant='body1' className={classes.detailName}>
                                    {t('documents')}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={8}>
                                <DownloadList elementId={elementId}/>
                            </Grid>
                        </Grid>
                        }
                    </Grid>
                </Grid>
            </Section>
        </ContentBox>
    );
};

export default ElementDetails;