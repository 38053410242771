import {container} from "../../Lib/material-kit/assets/jss/material-kit-pro-react.jsx";

const accountStyle = theme => ({
  panel: {
    width: "100vw",
    height: "100vh",
    backgroundColor: theme.palette.common.white,
    marginTop: -158,
    marginLeft: -48,
    marginRight: -48,
    marginBottom: -158,
  },
  container: {
    ...container,
    backgroundColor: theme.palette.common.white,
    zIndex: "4",
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "100px"
    },
    [theme.breakpoints.up("xs")]: {
      maxWidth: "500px",
    }
  },
  paperContainer: {
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    backgroundSize: "cover",
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  title: {
    marginTop: 10,
    color:  theme.palette.primary.main,
    textTransform: "uppercase",
    fontWeight: 500,
    marginBottom: 10
  },
  icons: {
    width: "20px",
    height: "20px",
    marginRight: "14px"
  },
  button: {
    position: "relative",
    fontWeight: "400",
    fontSize: "12px",
    textTransform: "uppercase",
    lineHeight: "20px",
    textDecoration: "none",
    marginLeft: "20px",
    display: "inline-flex",
    paddingLeft: "14px",
    paddingRight: "14px"
  },
  // TODO migrate this into button
  smallButton: {
    position: "relative",
    fontWeight: "400",
    fontSize: "12px",
    textTransform: "uppercase",
    lineHeight: "20px",
    textDecoration: "none",
    display: "inline-flex",
    paddingLeft: "14px",
    paddingRight: "14px",
    marginTop: "8px",
    paddingTop: "4px",
    paddingBottom: "4px",
    height: "30px",
    maxHeight: "30px"
  },
  buttonContainer: {
    paddingTop: "20px"
  }
});

export default accountStyle;
