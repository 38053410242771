
// Change the source according to the project
// Add the next css style to the index.css:
// #atlwdg-trigger {
//   z-index: 1000
// }

const source = "https://spxbhuhb.atlassian.net/s/d41d8cd98f00b204e9800998ecf8427e-T/-egccmf/b/24/a44af77267a987a660377e5c46e0fb64/_/download/batch/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector.js?locale=en-US&collectorId=0f44f31e";

export const addIssueCollector = () => {

    const body = document.getElementsByTagName('body')[0];

    const script = document.createElement('script');

    script.id = 'atl-issue-collector';

    script.type = "text/javascript";

    script.src = source;

    body.appendChild(script);

};

export const cleanUpIssueCollector = () => {

    const script = document.getElementById('atl-issue-collector');
    const trigger = document.getElementById('atlwdg-trigger');
    const container = document.getElementById('atlwdg-container');
    const blanket = document.getElementById('atlwdg-blanket')

    if (!script || !trigger || !container || !blanket) return;

    script.remove();
    trigger.remove();
    container.remove();
    blanket.remove();

};