import {useFetchMany, useFetchOne} from "../Lib/baseFetch";
import {KolorLineNewsThumbnailRecord} from "../Data/KolorLineNewsThumbnail";
import {KolorLineNewsRecord, KolorLineNewsSchema} from "../Data/KolorLineNews";
import {roles} from "../Main/roles";


export function useKolorLineNewsByPage(page) {
    return useFetchMany('/get-news-page/' + page, undefined, KolorLineNewsThumbnailRecord);
}

export function useKolorLineAnnouncements() {
    return useFetchMany('/get-announcements/', undefined, KolorLineNewsThumbnailRecord);
}

export function useKolorLineNewsOne(id) {
    return useFetchOne('/get-news', id, KolorLineNewsRecord, KolorLineNewsSchema);
}

export const kolorLineNewsApiTest = {
    '/get-news': {
        'GET': {
            [roles.ANONYMOUS]: {hasResponse: true},
            [roles.SITE_MEMBER]: {hasResponse: true},
            [roles.COUNCIL_ADMIN]: {hasResponse: true},
            [roles.CM_ADMIN]: {hasResponse: true},
            [roles.UPLOAD_ADMIN]: {hasResponse: true},
            [roles.ADMIN]: {hasResponse: true},
            [roles.SECURITY_OFFICER]: {hasResponse: true},
        },
    },
    '/get-news/1': {
        'GET': {
            [roles.ANONYMOUS]: {hasResponse: true},
            [roles.SITE_MEMBER]: {hasResponse: true},
            [roles.COUNCIL_ADMIN]: {hasResponse: true},
            [roles.CM_ADMIN]:{hasResponse: true},
            [roles.UPLOAD_ADMIN]: {hasResponse: true},
            [roles.ADMIN]: {hasResponse: true},
            [roles.SECURITY_OFFICER]: {hasResponse: true},
        },
    },
    '/get-announcements': {
        'GET': {
            [roles.ANONYMOUS]: {hasResponse: true},
            [roles.SITE_MEMBER]: {hasResponse: true},
            [roles.COUNCIL_ADMIN]: {hasResponse: true},
            [roles.CM_ADMIN]: {hasResponse: true},
            [roles.UPLOAD_ADMIN]: {hasResponse: true},
            [roles.ADMIN]: {hasResponse: true},
            [roles.SECURITY_OFFICER]: {hasResponse: true}
        }
    },
    '/get-news-page/1': {
        'GET': {
            [roles.ANONYMOUS]: {hasResponse: true},
            [roles.SITE_MEMBER]: {hasResponse: true},
            [roles.COUNCIL_ADMIN]: {hasResponse: true},
            [roles.CM_ADMIN]: {hasResponse: true},
            [roles.UPLOAD_ADMIN]: {hasResponse: true},
            [roles.ADMIN]: {hasResponse: true},
            [roles.SECURITY_OFFICER]: {hasResponse: true}
        }
    }
}


