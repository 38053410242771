import baseFetch, {useFetchMany, useFetchOne} from "../Lib/baseFetch";
import {PositionRecord, PositionSchema} from "../Data/Position";
import {roles} from "../Main/roles";

export function usePositions(filter) {
    return useFetchMany('/positions', undefined, PositionRecord);
}

export function usePosition(id) {
    return useFetchOne('/positions', id, PositionRecord, PositionSchema);
}

export function addPosition(position, onSuccess, onError) {
    baseFetch('POST', '/positions', position,
        data => {
            if (onSuccess) onSuccess(new PositionRecord(data))
        },
        onError
    );
}

export function updatePosition(position, onSuccess, onError) {
    baseFetch('PATCH', '/positions/' + position.get('id'), position, onSuccess, onError);
}

export function removePosition(position, onSuccess, onError) {
    baseFetch('DELETE', '/positions/' + position.get('id'), undefined, onSuccess, onError);
}


export const positionsApiTest = {
    '/positions': {
        'GET': {
            [roles.ANONYMOUS]: {hasResponse: false},
            [roles.SITE_MEMBER]: {hasResponse: false},
            [roles.COUNCIL_ADMIN]: {hasResponse: true,},
            [roles.CM_ADMIN]: {hasResponse: false},
            [roles.UPLOAD_ADMIN]: {hasResponse: false},
            [roles.ADMIN]: {hasResponse: true,},
            [roles.SECURITY_OFFICER]: {hasResponse: true}
        },
        'POST': {
            [roles.ANONYMOUS]: {hasResponse: false},
            [roles.SITE_MEMBER]: {hasResponse: false},
            [roles.COUNCIL_ADMIN]: {hasResponse: true,},
            [roles.CM_ADMIN]: {hasResponse: false},
            [roles.UPLOAD_ADMIN]: {hasResponse: false},
            [roles.ADMIN]: {hasResponse: true,},
            [roles.SECURITY_OFFICER]: {hasResponse: true}
        },
    },
    '/positions/1': {
        'GET': {
            [roles.ANONYMOUS]: {hasResponse: false},
            [roles.SITE_MEMBER]: {hasResponse: false},
            [roles.COUNCIL_ADMIN]: {hasResponse: true,},
            [roles.CM_ADMIN]: {hasResponse: false},
            [roles.UPLOAD_ADMIN]: {hasResponse: false},
            [roles.ADMIN]: {hasResponse: true,},
            [roles.SECURITY_OFFICER]: {hasResponse: true}
        },
        'PATCH': {
            [roles.ANONYMOUS]: {hasResponse: false},
            [roles.SITE_MEMBER]: {hasResponse: false},
            [roles.COUNCIL_ADMIN]: {hasResponse: true},
            [roles.CM_ADMIN]: {hasResponse: false},
            [roles.UPLOAD_ADMIN]: {hasResponse: false},
            [roles.ADMIN]: {hasResponse: true},
            [roles.SECURITY_OFFICER]: {hasResponse: true}
        },
    },
    '/positions/2': {
        'DELETE': {
            [roles.ANONYMOUS]: {hasResponse: false},
            [roles.SITE_MEMBER]: {hasResponse: false},
            [roles.COUNCIL_ADMIN]: {hasResponse: true},
            [roles.CM_ADMIN]: {hasResponse: false},
            [roles.UPLOAD_ADMIN]: {hasResponse: false},
            [roles.ADMIN]: {hasResponse: true},
            [roles.SECURITY_OFFICER]: {hasResponse: true}
        },
    },
};

