import React from "react";
import {addData, createRows, getOne, updateData} from "../util";
import {
    NotificationPageRecord,
    NotificationPageSchema
} from "../../ContentManagement/ContentEditor/Data/NotificationPage";

import {notificationPagesApiTest} from "../../ContentManagement/ContentEditor/Api/notificationPages";

const record = NotificationPageRecord(NotificationPageSchema.default()).withMutations(data => {
    return data.set("title", "title")
})

const calls = [
    onResponse => getOne("/kolorapp-page/1", onResponse),
    onResponse => addData("/kolorapp-page/get-report-types", undefined, onResponse),
    onResponse => updateData("/kolorapp-page/1", record, onResponse),
];

const NotificationPagesApis = () => {

    return (
        <React.Fragment>
            {createRows(calls, notificationPagesApiTest)}
        </React.Fragment>
    );
}

export default NotificationPagesApis;