export const profileStyle = theme => ({
    data: {
        paddingTop: theme.spacing(2),
    },
    fieldName: {
        // display: 'inline-block',
        fontWeight: 500,
    },
    fieldData: {
        // paddingLeft: '1rem',
        // display: 'inline-block',
    },
    dataRow: {
        // borderBottomStyle: 'solid',
        // borderBottomColor: theme.palette.text.secondary,
        // borderBottomWidth: 1,
        padding: theme.spacing(1),
        // '&:first-child': {
        //     borderTopWidth: 1,
        //     borderTopStyle: 'solid',
        //     borderTopColor: theme.palette.text.secondary,
        // }
    },
    header: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        [theme.breakpoints.up('md')]: {
            marginBottom: theme.spacing(6),
        },
    },
    title: {
        marginBottom: theme.spacing(2),
        fontWeight: 'bold',
        [theme.breakpoints.up('md')]: {
            marginBottom: theme.spacing(3),
        },
    },
    selectContainer: {
        marginBottom: theme.spacing(3),
        [theme.breakpoints.up('md')] :{
            marginBottom: theme.spacing(6),
        }
    },
    description: {
        ...theme.typography.subtitle1
    },
    buttonContainer: {
        marginTop: theme.spacing(2),
    }
});