import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './ql-display.css'
import './Main/i18n';
import App from './Main/App';
import * as serviceWorker from './serviceWorker';
import {get} from './Lib/auth/Api/session';
import SessionData from "./Lib/auth/Records/SessionData";
import Immutable from "immutable";
import {build} from "./Lib/routing";
import {getContents} from "./Lib/ContentManagement/MenuBuilder/Api/content";
import {createPublicRouteConfig} from "./Lib/ContentManagement/util";
import {roles} from "./Main/roles";
import {addIssueCollector} from "./Lib/issue-collector";

function render(sessionData, contentData) {

    const routeConfig = createPublicRouteConfig(contentData);

    const {navTree, routingMap} = build(routeConfig, sessionData.get('roles'));

    const authStoreInit = Immutable.Map({
        session: sessionData
    });

    if (!sessionData.get('roles').includes(roles.ANONYMOUS)) addIssueCollector();

    const highContrastTheme = localStorage.getItem('highContrastTheme');

    if (highContrastTheme === undefined) {
        localStorage.setItem('highContrastTheme', '');
    }

    const appStoreInit = Immutable.Map({
        routingMap: routingMap,
        navTree: navTree,
        routeConfig: routeConfig,
        highContrastTheme: Boolean(localStorage.getItem('highContrastTheme')),
    });

    ReactDOM.render(
        <App authStoreInit={authStoreInit} appStoreInit={appStoreInit}/>,
        document.getElementById('root')
    );
}

// Get the session from the server if there is one. Will create anonymous
// session data in case there is an expired server session.

get(
    sessionData => {
        getContents(undefined, contentData => {
            render(sessionData, contentData)
        });
    },
    () => {
        getContents(undefined, contentData => {
            render(new SessionData(), contentData)
        });

    }
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
