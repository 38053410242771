import {useTranslation} from "react-i18next";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "../../Lib/material-dashboard/components/CustomButtons/Button";
import React from "react";

const HideOrActivationItemDialog = ({title, ns, api, open, closeModal, isHide}) => {

    const {t} = useTranslation(ns);

    const handleHide = () => {
        api();
    };

    const label = `${title} ${t('general:hide')}`;

    return (
        <Dialog onClose={closeModal} aria-labelledby="hide-item-dialog" open={open}>
            <DialogTitle id="hide-item-dialog" onClose={closeModal}>
                {label}
            </DialogTitle>
            <DialogContent dividers>
                <Typography gutterBottom>
                    {t('confirmHideText', {context: 'warning'})}
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button autoFocus
                        onClick={closeModal}
                        color="success">
                    {t('cancel')}
                </Button>
                <Button onClick={handleHide}
                        color="warning">
                    {t('confirmHide')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default HideOrActivationItemDialog;