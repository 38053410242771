import {Record} from 'immutable';
import {date, number, object, string} from "yup";

export const FileUploadPageRecord = Record({
    id: 0,
    title: '',
    description: '',
    items: '',
    status: '',
    createdAt: '',
    modifiedAt: '',
});

export const FileUploadPageSchema = object().shape({
    id: number().min(0).default(0),
    title:  string().required().min(1).max(64).default(''),
    description: string().min(0).max(2500).default(''),
    items: string().nullable().default(''),
    status: string().required().oneOf(['HIDDEN', 'PUBLIC', 'ACTUALITY']).default('HIDDEN'),
    createdAt: date().required().default(new Date()),
    modifiedAt: date().required().default(new Date()),
});