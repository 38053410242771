import i18next from "i18next";
import {tanacsUrl} from "../../../globals";

export const downloadAction = (accessor, record, tableStorage, label, fieldName) => {

    const {styles} = tableStorage.tableConfig;

    const lang = i18next.language;
    const labelTranslate = i18next.getResource(lang, tableStorage.tableConfig.nameSpace, label);
    const labelText = labelTranslate ? labelTranslate : label;

    const id = record.get(fieldName);
    if (id === undefined || id === null) return '<div></div>';

    const href = `${tanacsUrl}/blob/${id}`;
    const customActionsClasses = styles && styles.customActions ? ` class="${styles.customActions}" ` : ``;
    const customLinkClasses = styles && styles.customLink ? ` class="${styles.customLink}" ` : ``;
    const customActionLabelClasses = styles && styles.customActionLabel ? ` class="${styles.customActionLabel}" ` : ``;

    const linkButton = `
        <a ${customLinkClasses} href=${href}>
            <span${customActionLabelClasses}>
                ${labelText}
            </span>
        </a>`;

    return `<div ${customActionsClasses}>${linkButton}</div>`
};

export const customActionsStyle = {
    customLongCell: {},
    customActions: {
        display: 'flex',
    },
    customEditIconBtn: {
        borderStyle: 'none',
        display: 'flex',
        margin: '.25rem',
        height: 30,
        width: 30,
        color: '#fff',
        backgroundColor: '#4caf50',
        borderRadius: '.2rem',
        fontSize: '.875rem',
        fontWeight: 500,
        textTransform: 'uppercase',
        lineHeight: 'inherit',
        textDecoration: 'none',
        '&:hover': {
            backgroundColor: '#357a38',
            color: '#fff',
        }
    },
    customEdit: {
        borderStyle: 'none',
        display: 'flex',
        margin: '.25rem',
        height: 30,
        minWidth: 30,
        color: '#fff',
        backgroundColor: '#4caf50',
        borderRadius: '.2rem',
        fontSize: '.875rem',
        textTransform: 'uppercase',
        lineHeight: 'inherit',
        textDecoration: 'none',
        '&:hover': {
            backgroundColor: '#357a38',
            color: '#fff',
        }
    },
    customActionLabel: {
        margin: 'auto',
        display: 'inherit',
        alignItems: 'inherit',
        justifyContent: 'inherit',
        padding: '.25rem .5rem'
    },
    customDelete: {
        borderStyle: 'none',
        display: 'flex',
        margin: '.25rem',
        height: 30,
        minWidth: 30,
        color: '#fff',
        backgroundColor: '#f44336',
        borderRadius: '.2rem',
        fontSize: '.875rem',
        // fontWeight: 500,
        textTransform: 'uppercase',
        lineHeight: 'inherit',
        textDecoration: 'none',
        '&:hover': {
            backgroundColor: '#aa2e25',
            color: '#fff',
        },
        '&:active': {
            color: '#fff',
        }
    },
    svgIconRoot: {
        fill: 'currentColor',
        width: '1em',
        height: '1em',
        display: 'inline-block',
        fontSize: '1.25rem',
        transition: 'fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        flexShrink: 0,
        userSelect: 'none',
    },
};