import React, {useEffect, useState} from "react";
import {mainPageStyle as style} from "./mainPageStyle";
import {makeStyles} from "@material-ui/styles";
import Grid from "@material-ui/core/Grid";
import MainPageItem from "../../Components/MainPageItem/MainPageItem";
import {useTranslation} from "react-i18next";
import useNav from "../../Lib/useNav";
import Hidden from "@material-ui/core/Hidden";
import AppNavBar, {NavRow} from "../../Components/Navbars/AppNavBar";
import {Typography} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import AppFooter from "../../Components/Footer/AppFooter";
import {useKolorLineAnnouncements, useKolorLineNewsByPage} from "../../Api/kolorline-news";
import {useActualitiesByAmount, useBackground} from "../../Lib/ContentManagement/ContentEditor/Api/pages";
import ScrollToTopBtn from "../../Components/FloatingActionButton/ScrollToTopBtn";
import LoadMoreBtn from "../../Components/LoadMoreButton/LoadMoreBtn";
import Szechenyi2020 from "../../Components/Szechenyi/Szechenyi2020";
import Szechenyi2024 from "../../Components/Szechenyi/Szechenyi2024";


const useStyles = makeStyles(style);

function LandingPage({children}) {
    const classes = useStyles();

    return (
        <Grid container item className={classes.landing} component='div'>
            <BackgroundContainer>
                {children}
            </BackgroundContainer>
        </Grid>
    );
}

function BackgroundContainer({children}) {

    const classes = useStyles();

    const {data, loadingStatus} = useBackground();

    if (loadingStatus) return null;

    const style = {backgroundImage: `url(/api/1.0.0/images/${data})`};

    return (
        <Grid container item component='div' className={classes.bgContainer} style={style} justify='center'>
            {children}
        </Grid>
    );
}

const KolorLineNewsColumn = ({numberOfElements}) => {
    const classes = useStyles();

    const {t} = useTranslation('general');

    const [news, setNews] = useState([]);

    const {data, loadingStatus} = useKolorLineNewsByPage(numberOfElements / 10);

    useEffect(() => {

        if (!loadingStatus) {
            setNews(prev => prev.concat(data));
        }

    }, [numberOfElements, loadingStatus, data]);

    if (loadingStatus) return null;

    const newsItems = news.map(item => <MainPageItem item={item} key={item.id} dense={true}/>);

    return (
        <Grid item xs={12}>
            <Typography variant='h5' className={classes.title}>
                {t('News')}
            </Typography>
            {newsItems}
        </Grid>
    );
};

const AnnouncementColumn = () => {
    const classes = useStyles();

    const {t} = useTranslation('general');

    const {data, loadingStatus} = useKolorLineAnnouncements();

    if (loadingStatus) return null;

    const announcements = data.slice(0, 3).map(item => <MainPageItem item={item} key={item.id} dense={true}/>);

    return (
        <Grid item xs={12}>
            <Typography variant='h5' className={classes.title}>
                {t('Announcements')}
            </Typography>
            {announcements}
        </Grid>
    );
};

const ActualitiesColumn = ({numberOfElements}) => {

    const classes = useStyles();

    const {t} = useTranslation('general');

    const {data} = useActualitiesByAmount(numberOfElements);

    return (
        <Grid item xs={12}>
            <Typography variant='h5' className={classes.title}>
                {t('Actualities')}
            </Typography>
            {data.map(item => <MainPageItem key={`${item.type}-${item.id}`} item={item}/>)}
        </Grid>
    );
};

function MainPageContent() {

    const classes = useStyles();

    const [numberOfElements, setNumberOfElements] = useState(10);

    const handleClick = () => {
        setNumberOfElements(prev => prev + 10);
    };

    return (
        <Grid container item component='div' className={classes.content}>
            <Grid item xs={12} sm={12} md={8} className={classes.contentInnerContainerActualities}
                  component='div'>
                <ActualitiesColumn numberOfElements={numberOfElements}/>
            </Grid>
            <Grid container item xs={12} sm={12} md={4} className={classes.contentInnerContainerNews}
                  component='div'>
                <AnnouncementColumn/>
                <KolorLineNewsColumn numberOfElements={numberOfElements}/>
            </Grid>
            <LoadMoreBtn handleClick={handleClick}/>
        </Grid>
    );
}

function MainPage() {

    useNav({path: window.location.pathname});

    const classes = useStyles();

    return (
        <React.Fragment>
            <Box className={classes.publicLayout}>
                <AppNavBar/>
                <Grid container className={classes.main} component='div'>
                    <LandingPage/>
                    <Hidden mdUp>
                        <NavRow isSmallScreen={true}/>
                    </Hidden>
                    <MainPageContent/>
                </Grid>
            </Box>
            <ScrollToTopBtn/>
            <Szechenyi2024/>
            <Szechenyi2020/>
            <AppFooter/>
        </React.Fragment>
    )
}

export default MainPage;
