export const mainPageStyle = theme => ({
    main: {
        padding: 0,
        margin: 0,
        width: '100%',
        paddingTop: 85,
        [theme.breakpoints.up('md')]: {
            paddingTop: 0,
        },
    },
    landing: {
        height: '35vh',
        [theme.breakpoints.up('sm')]: {
            height: '45vh',
        },
        [theme.breakpoints.up('md')]: {
            height: '100vh',
        },
    },
    bgContainer: {
        position: 'relative',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        [theme.breakpoints.up('md')]: {
            backgroundSize: 'cover',
            maxHeight: '99%'
        },
    },
    landingNav: {
        position: 'absolute',
        bottom: -210,
    },
    title: {
        marginBottom: theme.spacing(6),
        fontWeight: 'bold',
        color: theme.palette.text.secondary,
        textTransform: 'uppercase',
    },
    content: {
        padding: theme.spacing(2),
        paddingTop: theme.spacing(6),
        paddingBottom: theme.spacing(6),
    },
    contentInnerContainerActualities: {
        [theme.breakpoints.up('md')]: {
            padding: theme.spacing(6),
            borderRight: '1px solid',
            borderRightColor: theme.palette.text.secondary,
        },
    },
    contentInnerContainerNews: {
        [theme.breakpoints.up('md')]: {
            padding: theme.spacing(6),
        },
    },
    publicLayout: {
        paddingBottom: 110,
        minHeight: '100vh',
    },
});


