import React, {useContext} from "react";

import styles from "../../../material-dashboard/assets/jss/customCheckboxRadioSwitch.jsx";
import {makeStyles} from "@material-ui/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import {FiberManualRecord} from "@material-ui/icons";
import {useFormField} from "../../useFormField";
import {RadioGroupContext} from "./ValidatedRadioGroup";

const useStyles = makeStyles(styles);

function ValidatedRadio(props) {
  const {name, value} = props;

  const classes = useStyles();

  const ff = useFormField(props);

  const labelText = ff.t(value);

  useContext(RadioGroupContext);

  return (
      <FormControlLabel
          label={labelText}
          value={value}
          control={
            <Radio
                onChange={(e) => ff.onChange(e.target.value)}
                name={name}
                aria-label={labelText}
                disabled={ff.disabled}
                icon={<FiberManualRecord className={classes.radioUnchecked}/>}
                checkedIcon={<FiberManualRecord className={classes.radioChecked}/>}
            />
          }
          classes={{
            label: classes.label,
          }}
      />
  );
}

export default ValidatedRadio;