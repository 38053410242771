import React from "react";
import {CommitteeRecord, CommitteeSchema} from "../../../Data/Committee";
import {addData, createRows, getAll, getOne, removeData, updateData} from "../util";

import {committeeApiTest} from "../../../Api/committees";

const record = CommitteeRecord(CommitteeSchema.default()).withMutations(data => {
    return data.set("name", "Bizottság_01")
        .set("representative", false)
})

const calls = [
    onResponse => getAll("/committees", onResponse),
    onResponse => addData("/committees", record, onResponse),
    onResponse => getOne("/committees/1", onResponse),
    onResponse => updateData("/committees/1", record, onResponse),
    onResponse => removeData('/committees/2', onResponse),
    onResponse => getAll("/committees/1/positions", onResponse),

];

const CommitteesApis = () => {

    return (
        <React.Fragment>
            {createRows(calls, committeeApiTest)}
        </React.Fragment>
    );
}

export default CommitteesApis;