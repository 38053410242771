import baseFetch, {useFetchMany, useFetchOne} from "../../../baseFetch";
import {BlockPageRecord, BlockPageSchema} from "../Data/BlockPage";
import {TextPageRecord} from "../Data/TextPage";
import {roles} from "../../../../Main/roles";
import {contentsStatusTest, contentStatusTest} from "../../../testing-tools/data-validation-tests";

export function useBlockPage(id, revision) {
    return useFetchOne('/block-pages', id, BlockPageRecord, BlockPageSchema, revision);
}

export function getBlockPage(id, onSuccess, onError) {
    baseFetch('GET', '/block-pages/' + id, undefined,
        data => {
            if (onSuccess) onSuccess(new BlockPageRecord(data));
        },
        onError
    );
}

export function updateBlockPage(blockPage, onSuccess, onError) {
    baseFetch('PATCH', '/block-pages/' + blockPage.get('id'), blockPage, onSuccess, onError);
}

export function useBlockItems(id, revision) {
    return useFetchMany('/block-pages/' + id + '/blocks', undefined, TextPageRecord, revision);
}

export function useBlockItemsByAmount(id, amount, revision) {
    return useFetchMany('/block-pages/' + id + '/blocks/' + amount, undefined, TextPageRecord, revision);
}

export function addBlockItem(id, blockItem, onSuccess, onError) {
    baseFetch('POST', '/block-pages/' + id + '/blocks', blockItem,
        data => {
            if (onSuccess) onSuccess(new TextPageRecord(data))
        },
        onError
    );
}

export function removeBlockItem(blockPageId, blockItemId, onSuccess, onError) {
    baseFetch('DELETE', '/block-pages/' + blockPageId + '/blocks/' + blockItemId, undefined, onSuccess, onError);
}

export const blockPagesApiTest = {
    '/block-pages/5': {
        'GET': {
            [roles.ANONYMOUS]: {
                hasResponse: true,
                validate: contentStatusTest
            },
            [roles.SITE_MEMBER]: {
                hasResponse: true,
                validate: contentStatusTest
            },
            [roles.COUNCIL_ADMIN]: {
                hasResponse: true,
                validate: contentStatusTest
            },
            [roles.CM_ADMIN]: {hasResponse: true},
            [roles.UPLOAD_ADMIN]: {
                hasResponse: true,
                validate: contentStatusTest
            },
            [roles.ADMIN]: {hasResponse: true},
            [roles.SECURITY_OFFICER]: {hasResponse: true}
        },
        'PATCH': {
            [roles.ANONYMOUS]: {hasResponse: false},
            [roles.SITE_MEMBER]: {hasResponse: false},
            [roles.COUNCIL_ADMIN]: {hasResponse: false,},
            [roles.CM_ADMIN]: {hasResponse: true},
            [roles.UPLOAD_ADMIN]: {hasResponse: true},
            [roles.ADMIN]: {hasResponse: true,},
            [roles.SECURITY_OFFICER]: {hasResponse: true}
        },
    },
    '/block-pages/5/blocks': {
        'GET': {
            [roles.ANONYMOUS]: {
                hasResponse: true,
                validate: contentStatusTest
            },
            [roles.SITE_MEMBER]: {
                hasResponse: true,
                validate: contentStatusTest
            },
            [roles.COUNCIL_ADMIN]: {
                hasResponse: true,
                validate: contentStatusTest
            },
            [roles.CM_ADMIN]: {hasResponse: true},
            [roles.UPLOAD_ADMIN]: {
                hasResponse: true,
                validate: contentStatusTest
            },
            [roles.ADMIN]: {hasResponse: true},
            [roles.SECURITY_OFFICER]: {hasResponse: true}
        },
        'POST': {
            [roles.ANONYMOUS]: {hasResponse: false},
            [roles.SITE_MEMBER]: {hasResponse: false},
            [roles.COUNCIL_ADMIN]: {hasResponse: false},
            [roles.CM_ADMIN]: {hasResponse: true},
            [roles.UPLOAD_ADMIN]: {hasResponse: true},
            [roles.ADMIN]: {hasResponse: true},
            [roles.SECURITY_OFFICER]: {hasResponse: true}
        },
    },
    '/block-pages/5/blocks/4': {
        'DELETE': {
            [roles.ANONYMOUS]: {hasResponse: false},
            [roles.SITE_MEMBER]: {hasResponse: false},
            [roles.COUNCIL_ADMIN]: {hasResponse: false},
            [roles.CM_ADMIN]: {hasResponse: true},
            [roles.UPLOAD_ADMIN]: {hasResponse: true},
            [roles.ADMIN]: {hasResponse: true},
            [roles.SECURITY_OFFICER]: {hasResponse: true}
        }
    },
    '/block-pages/5/blocks/10': {
        'GET': {
            [roles.ANONYMOUS]: {
                hasResponse: true,
                validate: contentsStatusTest
            },
            [roles.SITE_MEMBER]: {
                hasResponse: true,
                validate: contentsStatusTest
            },
            [roles.COUNCIL_ADMIN]: {
                hasResponse: true,
                validate: contentsStatusTest
            },
            [roles.CM_ADMIN]: {hasResponse: true},
            [roles.UPLOAD_ADMIN]: {
                hasResponse: true,
                validate: contentsStatusTest
            },
            [roles.ADMIN]: {hasResponse: true},
            [roles.SECURITY_OFFICER]: {hasResponse: true}
        }
    }
}