import i18next from "i18next";

export const customActions = (accessor, record, tableStorage, showEdit, showDelete, urlNs, idFieldName) => {

    const {styles} = tableStorage.tableConfig;

    const lang = i18next.language;
    const editLabel = i18next.getResource(lang, 'general', 'edit');
    const removeLabel = i18next.getResource(lang, 'general', 'remove');

    const hrefBase = urlNs + record.get(idFieldName ? idFieldName : 'id');
    const customActionsClasses = styles && styles.customActions ? ` class="${styles.customActions}" ` : ``;
    const customEditClasses = styles && styles.customEdit ? ` class="${styles.customEdit}" ` : ``;
    const customActionLabelClasses = styles && styles.customActionLabel ? ` class="${styles.customActionLabel}" ` : ``;
    const customDeleteClasses = styles && styles.customDelete ? ` class="${styles.customDelete}" ` : ``;

    const editButton = showEdit ? `<a ${customEditClasses} href=${hrefBase + "/edit"}><span${customActionLabelClasses}>${editLabel}</span></a>` : '';
    const deleteButton = showDelete ? `<a ${customDeleteClasses} href=${hrefBase + "/remove"}><span${customActionLabelClasses}>${removeLabel}</span></a>` : '';

    const actions = editButton + deleteButton;

    return `<div ${customActionsClasses}>${actions}</div>`
};

export const customActionsStyle = {
    customLongCell: {},
    customActions: {
        display: 'flex',
        '& a:active': {
            color: '#fff',
        }
    },
    customEditIconBtn: {
        borderStyle: 'none',
        display: 'flex',
        margin: '.25rem',
        height: 30,
        width: 30,
        color: '#fff',
        backgroundColor: '#4caf50',
        borderRadius: '.2rem',
        fontSize: '.875rem',
        fontWeight: 500,
        textTransform: 'uppercase',
        lineHeight: 'inherit',
        textDecoration: 'none',
        '&:hover': {
            backgroundColor: '#357a38',
            color: '#fff',
        }
    },
    customEdit: {
        borderStyle: 'none',
        display: 'flex',
        margin: '.25rem',
        height: 30,
        minWidth: 30,
        color: '#fff',
        backgroundColor: '#2196f3',
        borderRadius: '.2rem',
        fontSize: '.875rem',
        textTransform: 'uppercase',
        lineHeight: 'inherit',
        textDecoration: 'none',
        '&:hover': {
            backgroundColor: '#1769aa',
            color: '#fff',
        }
    },
    customActionLabel: {
        margin: 'auto',
        display: 'inherit',
        alignItems: 'inherit',
        justifyContent: 'inherit',
        padding: '.25rem .5rem'
    },
    customDelete: {
        borderStyle: 'none',
        display: 'flex',
        margin: '.25rem',
        height: 30,
        minWidth: 30,
        color: '#fff',
        backgroundColor: '#f44336',
        borderRadius: '.2rem',
        fontSize: '.875rem',
        // fontWeight: 500,
        textTransform: 'uppercase',
        lineHeight: 'inherit',
        textDecoration: 'none',
        '&:hover': {
            backgroundColor: '#aa2e25',
            color: '#fff',
        }
    },
    svgIconRoot: {
        fill: 'currentColor',
        width: '1em',
        height: '1em',
        display: 'inline-block',
        fontSize: '1.25rem',
        transition: 'fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        flexShrink: 0,
        userSelect: 'none',
    },
    customLink: {
        borderStyle: 'none',
        display: 'flex',
        margin: '.25rem',
        height: 30,
        minWidth: 30,
        backgroundColor: '#4caf50 ',
        borderRadius: '.2rem',
        textDecoration: 'none',
        fontSize: '.875rem',
        textTransform: 'uppercase',
        lineHeight: 'inherit',
        color: '#fff',
        '&:hover': {
            backgroundColor: '#357a38',
            color: '#fff',
        },
    },
};