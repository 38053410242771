export const baseTableStyles = {
    stWrapper: {
        perspective: '1px',
        position: 'relative',
        top: 0,
        left: 0,
        overflowX: 'auto',
        overflowY: 'hidden',
        height: '67vh',
        "@media (min-height: 650px)": {
            height: "75vh"
        },
        "@media (min-height: 750px)": {
            height: "77vh"
        },
        "@media (min-height: 850px)": {
            height: "79vh"
        },
        "@media (min-height: 950px)": {
            height: "81vh"
        },
        "@media (min-height: 1050px)": {
            height: "83vh"
        },
        "@media (min-height: 1250px)": {
            height: "85vh"
        },
        "@media (min-height: 1550px)": {
            height: "87vh"
        },
    },
    stTableWrapper: {
        position: 'relative',
        top: 0,
        left: 0,
        overflowY: 'auto',
        height: '67vh',
        "@media (min-height: 650px)": {
            height: "75vh"
        },
        "@media (min-height: 750px)": {
            height: "77vh"
        },
        "@media (min-height: 850px)": {
            height: "79vh"
        },
        "@media (min-height: 950px)": {
            height: "81vh"
        },
        "@media (min-height: 1050px)": {
            height: "83vh"
        },
        "@media (min-height: 1250px)": {
            height: "85vh"
        },
        "@media (min-height: 1550px)": {
            height: "87vh"
        }
    },
    stStickyHeaderWrapper: {
        width: '100%',
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: 100
    },
    stSticky: {
        borderBottomLeftRadius: 'unset',
        borderBottomRightRadius: 'unset',
    },
    stTable: {
        width: '100%',
        textAlign: 'left',
        color: '#2e2e2e',
        backgroundColor: '#fff',
        borderCollapse: 'separate',
        borderSpacing: 0,
    },
    stCaption: {
        textAlign: 'center',
        padding: '1.25rem',
        backgroundColor: '#fff',
        fontSize: '1.125rem',
        fontWeight: 600,
        color: '#2e2e2e',
    },
    stHeader: {
        fontWeight: 'medium',
    },
    stBody: {},
    stHeaderRow: {
        width: '99%',
        height: '2rem',
    },
    stRow: {
        height: '3.25rem',
        '&:hover': {
            backgroundColor: '#f5f5f5'
        }
    },
    stHeaderCell: {
        padding: '0 1rem',
        display: 'relative',
        borderTop: '1px solid #ecedef',
        borderBottom: '1px solid #ecedef',
        '&.stSortable': {
            cursor: 'pointer',
        }
    },
    stCell: {
        padding: '0 1rem',
        // whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        borderTop: '1px solid #ecedef',
        borderBottom: '1px solid #ecedef',
    },
};