import React, {memo} from "react";
import {useTranslation} from "react-i18next";
import useForm from "../../Lib/form/useForm";
import {PositionRecord, PositionSchema} from '../../Data/Position';
import ContentBox from "../../Components/ContentBox/ContentBox";
import Section from "../../Lib/form/Components/Section/Section";
import ValidatedSubmit from "../../Lib/form/Components/ValidatedSubmit/ValidatedSubmit";
import ValidatedInput from "../../Lib/form/Components/ValidatedInput/ValidatedInput";
import ValidatedSelect from "../../Lib/form/Components/ValidatedSelect/ValidatedSelect";
import {useAccounts} from "../../Api/accounts";
import {useCommittees} from "../../Api/committees";
import {navigate} from "hookrouter";

export default memo(({record, path, view, api}) => {

    const {t} = useTranslation('positions');

    const form = useForm({
        disabledForm: view === 'remove',
        recordType: PositionRecord,
        recordSchema: PositionSchema,
        namespace: 'positions',
        record: record
    });

    const onSuccess = () => navigate('/positions');
    const onCancel = () => navigate('/positions');

    return (
        <ContentBox path={path} form={form}>
            <Section title={t(view)}>
                <ValidatedInput name='name'/>
                <ValidatedSelect name="committeeId" recordFieldName={"name"} api={useCommittees}/>
                <ValidatedSelect name="accountId" recordFieldName={"fullName"} specialId='id'
                                 api={useAccounts}/>
            </Section>
            <ValidatedSubmit onSuccess={onSuccess} onCancel={onCancel} view={view} api={api}/>
        </ContentBox>
    );
})