import {Record} from 'immutable';
import {boolean, date, number, object, string} from "yup";

export const TextPageRecord = Record({
    id: 0,
    title: '',
    textContent: '',
    description: '',
    attachments: '',
    picture: 0,
    altText: '',
    tags: '',
    status: '',
    createdAt: '',
    modifiedAt: '',
});

export const TextPageSchema = object().shape({
    id: number().min(0).default(0),
    title: string().required().min(1).max(100).default(''),
    textContent: string().min(0).default(''),
    description: string().min(0).max(2500).default(''),
    attachments: string().nullable().default(''),
    picture: number().min(0).default(0).nullable(),
    altText: string().min(0).max(200).default(''),
    preparation: boolean(),
    public: boolean(),
    actuality: boolean(),
    tags: string().min(0).max(256).default(''),
    status: string().required().oneOf(['HIDDEN', 'PUBLIC', 'ACTUALITY']).default('HIDDEN'),
    createdAt: date().required().default(new Date()),
    modifiedAt: date().required().default(new Date()),
});

export const TagSchema = object().shape({
    tag: string().min(1).max(24),
});