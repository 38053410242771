const supportEmail = "info@simplexion.hu";

export const councilMembersHu = {
    "title": "Személyek",
    "titleNav": "Törzsadatok - Személyek",
    "person": "Személy",
    "assignment": "Beosztás",
    "committee": "Bizottság",
    "organisation": "Jelölő szervezet",
    "assignmentName": "Beosztás",
    "committeeName": "Bizottság",
    "organisationName": "Jelölő szervezet",
    "add": "Mentés",
    "add_title": "Új Személy",
    "edit": "Mentés",
    "edit_title": "Személy Szerkesztése",
    "back": "Vissza",
    "remove": "Törlés",
    "delete": "Törlés",
    "remove_title": "Személy Törlése",
    "delete_title": "Személy Törlése",
    "name": "Név",
    "name-invalid": "A beosztás neve érvénytelen",
    "displayName": "Megjelenítendő név",
    "save": "Személy mentése",
    "actions": "Műveletek",

    "status" : "Fiók állapot",
    "active" : "Aktív",

    "email": "E-mail",
    "position": "Beosztás",
    "principalName": "Fiók név",
    "principalName-invalid": "Legalább 3, legfeljebb 50 karakter.",
    "emailAddress": "E-mail cím",
    "emailAddress-invalid": "Érvénytelen cím.",
    "credentials": "Jelszó",
    "credentials-invalid": "Legalább 8 karakter.",
    "verification": "Megerősítés",
    "verification-invalid": "A két jelszó nem azonos.",
    "forgottenPassword": "Elfelejtett jelszó",
    "password-invalid": "Érvénytelen jelszó",
    "fullName": "Teljes név",
    "fullName-invalid": "Legalább 5 karakter.",

    "personalData": "Személyes adatok",
    "positionsByCommittee": "Beosztások",

    "reset-start-title": "Jelszó megújítás",
    "reset-start-explanation": "A megújításhoz kérlek add meg fiókod nevét és az e-mail címed, majd klikkelj a Megújítás gombra. Ha az adatok megfelelőek, kapni fogsz egy e-mailt, amelyben benne lesz a megújításhoz szükséges titkos kód.",
    "reset-start-button": "Küldés",

    "reset-start-success": "Sikeresen megkezdődött a megújítás.",
    "reset-start-success-explanation": "A jelszó megújítása megkezdődött, a rendszer küldeni fog egy e-mailt a megújításhoz szükséges titkos kóddal. A titkos kód 8 órán át érvényes, utána újat kell kérned.",
    "reset-start-error": "Nem sikerült elindítani a megújítást.",

    "password-change-title": "Jelszóváltozatás",
    "password-change-explanation": "Kérlek add a régi és az új jelszót.",
    "password-change-button": "Végrehajtás",

    "reset-finish-title": "Új jelszó megadása",
    "reset-finish-explanation": "Kérlek add az új jelszót.",
    "reset-finish-button": "Beállítás",

    "secret": "Titkos kód",
    "oldPassword": "Régi jelszó",
    "newPassword": "Új jelszó",
    "newPassword-invalid": "Az új jelszónak legalább 8 karakternek kell lennie.",

    "reset-finish-success": "Sikeres jelszó megújítás",
    "reset-finish-success-explanation": "Az új jelszó beállítása sikerült, erre a gombra kattintva be tudsz lépni a rendszerbe:",
    "reset-finish-error": "Az új jelszó beállítása nem sikerült, kérlek vedd fel velünk a kapcsolatot a " + supportEmail + " címen.",

    "activate-title": "Fiók aktiválása",
    "activate-in-progress": "A fiók aktiválása folyamatban.",
    "activate-success": "A fiók aktiválása sikerült, erre a gombra kattintva be tudsz lépni a rendszerbe:",
    "activate-error": "A fiók aktiválása nem sikerült, kérlek vedd fel velünk a kapcsolatot a " + supportEmail + " címen.",

    "password-change" : "Jelszó változtatás",
    "password-change-error" : "Nem sikerült megváltoztatni a jelszót.",
    "password-change-success" : "Sikeres jelszó változtatás",
    "password-change-success-explanation": "Az új jelszó beállítása megtörtént. A következő bejelentkezés alkalmával a most megadott új jelszót kell használnod.",

    "expired-session" : "Lejárt munkamenet",
    "expired-session-explanation": "A munkamenet lejárt, ezért újból be kell jelentkezned: "
};