    export const languageSelectorDrawerStyle = theme => ({

        languageSelector: {
            ...theme.typography.caption,
            color: theme.palette.text.secondary,
            fontWeight: 300,
            margin: theme.spacing(.5),
            paddingLeft: theme.spacing(1),
            borderLeft: `solid 1px ${theme.palette.text.secondary}`,
            cursor: 'pointer',
            '&:hover': {
                backgroundColor: 'unset',
            },
            '&:active': {
                backgroundColor: 'unset',
            },
            '&:first-child': {
                borderLeftColor: 'rgba(0,0,0,0)',
                paddingLeft: 0,
                marginLeft: 0,
            },
            '&:last-child': {
                paddingLeft: theme.spacing(1),
            },
        },
        languageSelectorWrapper: {
            justifyContent:'flex-start',
            alignItems:'center',
            paddingTop: theme.spacing(1),
            paddingBottom: theme.spacing(1),
            paddingLeft: theme.spacing(2),
        },
    });
