export const szechenyi2024 = theme => ({
    szechenyi2024: {
        position: "fixed",
        bottom: 0,
        left: 0,
        width: "500px",
        height: "150px",
        zIndex: 1200,
        cursor: "pointer",
        pointerEvents: "all",
        [theme.breakpoints.down('md')]: {
            width: "214px",
            height: "65px",
        },
    }
});


