import React from "react";
import List from "./List";
import {useRoutes} from "hookrouter";
import {useInvitation} from "../../Api/templates";
import {useTranslation} from "react-i18next";
import debug from "../../Lib/debug";
import Form from "./Form";
import {TemplateRecord, TemplateSchema} from '../../Data/Template';

export default () => useRoutes(subRoutes);

const subRoutes = {
    "/": () => <Invitations view="list"/>,
    "/:invitationId/edit": ({invitationId}) => <Invitations view="edit" invitationId={invitationId}/>,
};

const Invitations = ({view, invitationId}) => {

    debug("RENDER", "Invitations", () => [view]);

    const {t} = useTranslation('invitations');

    const {loadingStatus, data} = useInvitation(invitationId);

    const viewData = (loadingStatus ? new TemplateRecord(TemplateSchema.default()) : data);

    const path = [{label: t('titleNav'), url: "/invitations"}];

    // noinspection JSCheckFunctionSignatures
    const label = viewData.get('name');

    switch (view) {
        case "list" :

            return <List path={path}/>;

        case "edit" :

            path.push({label: label, url: "/invitations/" + invitationId + "/edit"});
            return <Form invitationId={invitationId} record={viewData} path={path} view={view}/>;

        default :
            return <div>invalid view: {view}</div>
    }
};

