import React, {memo} from "react";
import {useTranslation} from "react-i18next";
import useForm from "../../Lib/form/useForm";
import {TemplateRecord, TemplateSchema} from '../../Data/Template';
import ContentBox from "../../Components/ContentBox/ContentBox";
import Section from "../../Lib/form/Components/Section/Section";
import debug from "../../Lib/debug";
import ValidatedInput from "../../Lib/form/Components/ValidatedInput/ValidatedInput";
import {navigate} from "hookrouter";
import ValidatedRichTextEditor from "../../Lib/form/Components/ValidatedRichTextEditor/ValidatedRichTextEditor";
import Button from "../../Lib/material-dashboard/components/CustomButtons/Button";

export default memo(({id, record, path, view}) => {

    debug("RENDER", "Invitation", () => ["Form", id, record, path, view]);

    const {t} = useTranslation('invitations');

    const form = useForm({
        disabledForm: view === 'remove',
        recordType: TemplateRecord,
        recordSchema: TemplateSchema,
        namespace: 'invitations',
        record: record
    });

    const onClick = () => navigate('/invitations');

    return (
        <ContentBox path={path} form={form}>
            <Section title={t('edit')}>
                <ValidatedInput name="name" disabled/>
                <ValidatedInput name="title" disabled/>
                <ValidatedRichTextEditor name={"content"} label={t("content")} disabled/>
            </Section>
            <Button color='primary'
                    variant='contained'
                    onClick={onClick}>
                {t('back')}
            </Button>
        </ContentBox>
    );
});