import baseFetch, {useFetchMany, useFetchOne} from "../../baseFetch";
import {RoleRecord, RoleSchema} from "../Data/Role";
import {GrantRecord} from "../Data/Grant";

export function useRoles(filter) {
    return useFetchMany('/roles', undefined, RoleRecord);
}

export function useRole(id) {
    return useFetchOne('/roles', id, RoleRecord, RoleSchema);
}

export function addRole(role, onSuccess, onError) {
    baseFetch('POST', '/roles', role,
        data => {
            if (onSuccess) onSuccess(new RoleRecord(data))
        },
        onError
    );
}

export function getRoles(filter, onSuccess, onError) {
    baseFetch('GET', '/roles', filter,
        data => {
            if (onSuccess) onSuccess(data.map(r => new RoleRecord(r)))
        },
        onError
    );
}

export function getRole(id, onSuccess, onError) {
    baseFetch('GET', '/roles/' + id, undefined,
        data => {
            if (onSuccess) onSuccess(new RoleRecord(data))
        },
        onError
    );
}

export function updateRole(role, onSuccess, onError) {
    baseFetch('PATCH', '/roles/' + role.get('id'), role, onSuccess, onError);
}

export function removeRole(role, onSuccess, onError) {
    baseFetch('DELETE', '/roles/' + role.get('id'), undefined, onSuccess, onError);
}

// RoleGrants

export function useGrants(parentId) {
    return useFetchMany('/roles/' + parentId + '/grants', undefined, GrantRecord);
}

export function useGrant(parentId, id) {
    return useFetchOne('/roles/' + parentId + '/grants', id, GrantRecord, RoleSchema);
}

export function addGrant(grant, onSuccess, onError) {
    baseFetch('POST', '/roles/' + grant.get("roleId") + '/grants', grant,
        data => {
            if (onSuccess) onSuccess(new GrantRecord(data))
        },
        onError
    );
}

export function removeGrant(grant, onSuccess, onError) {
    baseFetch('DELETE', '/roles/' + grant.get("roleId") + '/grants/' + grant.get("principalId"), undefined, onSuccess, onError);
}
