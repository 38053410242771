import React from "react";
import {useRoutes} from "hookrouter";
import {
    addMeetingRecord,
    removeMeetingRecord,
    updateMeetingRecord,
    useMeetingRecord
} from "../../../Api/meeting-records";
import {useTranslation} from "react-i18next";
import debug from "../../../Lib/debug";
import Form from "./Form";
import {MeetingRecordRecord, MeetingRecordSchema} from '../../../Data/MeetingRecord';

export default props => useRoutes(subRoutes)(props);

const subRoutes = {
    "/add": () => props => <Record view="add" {...props}/>,
    "/:recordId/edit": ({recordId}) => props => <Record view="edit" recordId={recordId} {...props}/>,
    "/:recordId/remove": ({recordId}) => props => <Record view="remove" recordId={recordId} {...props}/>,

};

const Record = ({path, view, meetingId, recordId}) => {

    debug("RENDER", "MeetingRecord", () => [view]);

    const {t} = useTranslation('meetingRecord');

    const {loadingStatus, data} = useMeetingRecord(recordId);

    const viewData = loadingStatus ? new MeetingRecordRecord(MeetingRecordSchema.default()) : data;

    switch (view) {

        case "add" :

            path.push({label: t('add'), url: ""});
            return <Form meetingId={meetingId} record={viewData} path={path} view={view} api={(...args) => addMeetingRecord(meetingId, ...args)}/>;

        case "edit" :

            path.push({label: t('meetingRecord'), url: ""});
            return <Form meetingId={meetingId} record={viewData} path={path} view={view} api={updateMeetingRecord}/>;

        case "remove" :

            path.push({label: t('meetingRecord'), url: ""});
            return <Form meetingId={meetingId} record={viewData} path={path} view={view} api={removeMeetingRecord}/>;

        default :
            return <div>invalid view: {view}</div>
    }
};

