import React from "react";
import {ReportRecord, ReportSchema} from "../../../Data/Report";
import {addData, createRows} from "../util";

import {reportsApiTest} from "../../../Api/reports";

const record = ReportRecord(ReportSchema.default()).withMutations(data => {
    return data.set("emailAddress", "email@addr.ess")
        .set("textContent", "textContent")
        .set("location", "location")
        .set("type", 0)
})

const calls = [
    onResponse => addData("/post-issue", record, onResponse),
];

const ReportsApis = () => {

    return (
        <React.Fragment>
            {createRows(calls, reportsApiTest)}
        </React.Fragment>
    );
}

export default ReportsApis;