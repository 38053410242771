export const fileUploadEditorStyle = theme => ({
    editorBlock: {
        padding: '1rem',
    },
    descriptionContainer: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        '& .quill .ql-editor': {
            minHeight: 200,
        },
    },
    textContentContainer: {
        padding: '1rem 0 2rem',
            '& .quill .ql-editor': {
            minHeight: 500,
        },
    },
    addItemBtn: {
        margin: '11px 0',
        color: theme.palette.text.white,
    }
});