import baseFetch, {useFetchOne} from "../../../baseFetch";
import {NotificationPageRecord, NotificationPageSchema} from "../Data/NotificationPage";
import {useEffect, useState} from "react";
import {Record} from "immutable";
import {roles} from "../../../../Main/roles";

export function useNotificationPage(id, revision) {
    return useFetchOne('/kolorapp-page', id, NotificationPageRecord, NotificationPageSchema, revision);
}

export function useNotificationTypes(filter, revision) {

    const [types, setTypes] = useState([]);

    useEffect(() => {
        getNotificationTypes(data => {
            setTypes(data.data.map(type => new Record({
                    id: type.id,
                    name: type.label
                })()
            ));
        })
    }, []);

    return {data: types}
}

export function getNotificationTypes(onSuccess, onError) {
    baseFetch('POST', '/kolorapp-page/get-report-types', undefined,
        data => {
            if (onSuccess) onSuccess(data)
        },
        onError
    );
}

export function getNotificationPage(id, onSuccess, onError) {
    baseFetch('GET', '/kolorapp-page/' + id, undefined,
        data => {
            if (onSuccess) onSuccess(new NotificationPageRecord(data))
        },
        onError
    );
}

export function updateNotificationPage(page, onSuccess, onError) {
    baseFetch('PATCH', '/kolorapp-page/' + page.get('id'), page, onSuccess, onError);
}

export const notificationPagesApiTest = {
    '/kolorapp-page/1': {
        'GET': {
            [roles.ANONYMOUS]: {hasResponse: true},
            [roles.SITE_MEMBER]: {hasResponse: true},
            [roles.COUNCIL_ADMIN]: {hasResponse: true},
            [roles.CM_ADMIN]:{hasResponse: true},
            [roles.UPLOAD_ADMIN]: {hasResponse: true},
            [roles.ADMIN]: {hasResponse: true},
            [roles.SECURITY_OFFICER]: {hasResponse: true},
        },
        'PATCH': {
            [roles.ANONYMOUS]: {hasResponse: false},
            [roles.SITE_MEMBER]: {hasResponse: false},
            [roles.COUNCIL_ADMIN]: {hasResponse: false},
            [roles.CM_ADMIN]: {hasResponse: true},
            [roles.UPLOAD_ADMIN]: {hasResponse: true},
            [roles.ADMIN]: {hasResponse: true},
            [roles.SECURITY_OFFICER]: {hasResponse: true}
        }
    },
    '/kolorapp-page/get-report-types': {
        'POST': {
            [roles.ANONYMOUS]: {hasResponse: true},
            [roles.SITE_MEMBER]: {hasResponse: true},
            [roles.COUNCIL_ADMIN]: {hasResponse: true},
            [roles.CM_ADMIN]: {hasResponse: true},
            [roles.UPLOAD_ADMIN]: {hasResponse: true},
            [roles.ADMIN]: {hasResponse: true},
            [roles.SECURITY_OFFICER]: {hasResponse: true}
        }
    },
}

