import React from "react";
import List from "./List";
import {A, useRoutes} from "hookrouter";
import Activate from "./Activate";
import Logout from "../Logout/Logout.jsx";
import ResetStart from "./ResetStart";
import {Form} from "./Form";
import AddSuccess from "./AddSuccess";
import ResetFinish from "./ResetFinish";
import Button from "../../Lib/material-kit/components/CustomButtons/Button";
import {Face} from "@material-ui/icons";
import debug from "../../Lib/debug";
import {useTranslation} from "react-i18next";
import {addAccount, removeAccount, updateAccount, useAccount} from "../../Api/accounts";
import {AccountRecord, AccountSchema} from "../../Data/Account";
import PasswordChange from "./PasswordChange";
import Login from "../Login/Login";

const subRoutes = {
    "/": () => <Accounts view="list"/>,
    "/assume": () => <Login/>,
    "/renounce": () => <Logout/>,
    "/reset*": () => <ResetStart/>,
    "/add": () => <Accounts view="add"/>,
    "/add/success": () => <AddSuccess/>,
    "/:id/password": ({id}) => <PasswordChange id={id}/>,
    "/:id/reset/:token": ({id, token}) => <ResetFinish id={id} token={token}/>,
    "/:id/edit": ({id}) => <Accounts view="edit" id={id}/>,
    "/:id/remove": ({id}) => <Accounts view="remove" id={id}/>,
    "/:id/activation-keys/:ak": ({id, ak}) => <Activate id={id} ak={ak}/>
};

export default () => useRoutes(subRoutes);

// noinspection HtmlUnknownTarget
export const LoginButton = ({classes, t}) => (
    <A href="/accounts/assume">
        <Button color="success" className={classes.button}>
            <Face className={classes.icons}/><span style={{marginLeft: 4}}>{t('header:login')}</span>
        </Button>
    </A>
);

export const Accounts = ({view, id}) => {
    debug("RENDER", "Accounts", () => [view]);

    const {t} = useTranslation('account');

    const {loadingStatus, data} = useAccount(id);

    // this hack with hello bello is here, so the password will be valid and form
    // send button is enabled, the server does not update the password with account
    // PATCH, so it doesn't actually matters

    const path = [{label: t('titleNav'), url: "/admin/accounts"}];

    const viewData = (loadingStatus ? new AccountRecord(AccountSchema.default()) : data);

    switch (view) {
        case "list" :

            return <List path={path}/>;

        case "add" : {

            path.push({label: t('add'), url: "/admin/accounts/add"});
            return <Form id={id} record={viewData} path={path} view={view} api={addAccount}/>;

        }

        case "edit" : {

            if (viewData.fullName) path.push({label: viewData.fullName, url: "/admin/accounts/" + id + "/edit"});
            return <Form id={id}
                         api={updateAccount}
                         record={viewData.merge({'credentials': 'Hello.Bello.12', 'verification': 'Hello.Bello.12'})}
                         path={path} view={view}/>;

        }

        case "remove" : {

            path.push({label: viewData.fullName, url: "/admin/accounts/" + id + "/remove"});
            return <Form id={id}
                         api={removeAccount}
                         record={viewData.merge({'credentials': 'Hello.Bello.12', 'verification': 'Hello.Bello.12'})}
                         path={path} view={view}/>;

        }

        default :
            return <div>invalid view: {view}</div>
    }
};