import {useFetchMany} from "../Lib/baseFetch";
import {SearchResultRecord, SearchResultSchema} from "../Data/SearchResult";
import {StoredFileRecord, StoredFileSchema} from "../Data/StoredFile";
import {roles} from "../Main/roles";

export function useSearchResults(str) {
    return useFetchMany('/search' + (str ? `/${str}` : ''), str ? undefined : null, SearchResultRecord, SearchResultSchema);
}

export function useStoredFiles() {
    return useFetchMany('/stored-files', undefined, StoredFileRecord, StoredFileSchema);
}

export const searchApiTest = {
    '/search/anything': {
        'GET': {
            [roles.ANONYMOUS]: {hasResponse: true},
            [roles.SITE_MEMBER]: {hasResponse: true},
            [roles.COUNCIL_ADMIN]: {hasResponse: true,},
            [roles.CM_ADMIN]: {hasResponse: true},
            [roles.UPLOAD_ADMIN]: {hasResponse: true},
            [roles.ADMIN]: {hasResponse: true,},
            [roles.SECURITY_OFFICER]: {hasResponse: true}
        },
    },
    '/stored-files': {
        'GET': {
            [roles.ANONYMOUS]: {hasResponse: false},
            [roles.SITE_MEMBER]: {hasResponse: false},
            [roles.COUNCIL_ADMIN]: {hasResponse: true,},
            [roles.CM_ADMIN]: {hasResponse: true},
            [roles.UPLOAD_ADMIN]: {hasResponse: true},
            [roles.ADMIN]: {hasResponse: true,},
            [roles.SECURITY_OFFICER]: {hasResponse: true}
        },
    },
};

