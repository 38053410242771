import baseFetch from "../Lib/baseFetch";
import {WatcherRecord} from "../Data/Watcher";


export function addWatcher(watcher, onSuccess, onError) {
    baseFetch('POST', '/watchers', watcher,
        data => {
            if (onSuccess) onSuccess(new WatcherRecord(data))
        },
        onError
    );
}

