import {Record} from 'immutable';
import {number, object, string} from "yup";

export const OrganisationRecord = Record({
    id: 0,
    name: '',
});

export const OrganisationSchema = object().shape({
    id: number().required().min(0).default(0),
    name: string().required().min(1).max(100).default(""),
});
