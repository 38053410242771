import React from "react";
import {CreatePdfTemplateRecord, CreatePdfTemplateSchema} from "../../../Data/Template";
import {addData, createRows, getAll, getOne} from "../util";

import {templatesApiTest} from "../../../Api/templates";

const invitationRecord = CreatePdfTemplateRecord(CreatePdfTemplateSchema.default()).withMutations(data => {
    return data.set("invitationName", "invitationName")
        .set("meetingId", 1)
        .set("positionId", 1)
})

const calls = [
    onResponse => addData("/invitations/generate-invitation/", invitationRecord, onResponse),
    onResponse => getAll("/invitations", onResponse),
    onResponse => getOne("/invitations/1", onResponse),
    onResponse => getAll("/templates", onResponse),
    onResponse => getOne("/templates/1", onResponse),
];

const TemplatesApis = () => {

    return (
        <React.Fragment>
            {createRows(calls, templatesApiTest)}
        </React.Fragment>
    );
}

export default TemplatesApis;