import React from "react";
import {makeStyles} from "@material-ui/core";
import {szechenyi2020} from "./szechenyi2020Style";
import {useTranslation} from "react-i18next";
import image from "./unios-palyazatok.png"

const useStyles = makeStyles(szechenyi2020);

const Szechenyi2020 = () => {

    const classes = useStyles();

    const {t} = useTranslation('general');

    return <img className={classes.szechenyi2020} src={image} alt={"Széchényi 2020"}
                onClick={() => window.location.href="/block-pages/54/blocks"} />

};

export default Szechenyi2020;