import baseFetch, {useFetchMany, useFetchOne} from "../Lib/baseFetch";
import {MeetingItemRecord, MeetingItemSchema} from "../Data/MeetingItem";
import {roles} from "../Main/roles";
import {isPublicTest} from "../Lib/testing-tools/data-validation-tests";
import {tanacsUrl} from "../globals";
import {CommitteeItemRecord} from "../Data/CommitteeItem";
import {DocumentRecord} from "../Data/Document";

export function useMeetingItem(meetingId, itemId) {
    return useFetchOne(`/meetings/${meetingId}/items`, itemId, MeetingItemRecord, MeetingItemSchema, undefined, tanacsUrl);
}

export function useMeetingItems(meetingId, revision) {
    return useFetchMany('/meetings/' + meetingId + '/items', undefined, MeetingItemRecord, revision, tanacsUrl);
}

export function useMeetingItemCommittees(meetingId, itemId, revision) {
    return useFetchMany(`/meetings/${meetingId}/items/${itemId}/committees`, undefined, CommitteeItemRecord, revision, tanacsUrl);
}

export function useMeetingItemDocuments(meetingId, itemId) {
    return useFetchMany(`/meetings/${meetingId}/items/${itemId}/documents`, undefined, DocumentRecord, undefined, tanacsUrl);
}

export function addMeetingElement(meetingElement, onSuccess, onError) {
    baseFetch('POST', '/meeting-elements', meetingElement,
        data => {
            if (onSuccess) onSuccess(new MeetingItemRecord(data))
        },
        onError
    );
}

export function updateMeetingElement(meetingElement, onSuccess, onError) {
    baseFetch('PATCH', '/meeting-elements/' + meetingElement.get('id'), meetingElement, onSuccess, onError);
}

export function removeMeetingElement(meetingElement, onSuccess, onError) {
    baseFetch('DELETE', '/meeting-elements/' + meetingElement.get('id'), undefined, onSuccess, onError);
}

export const meetingElementsApiTest = {
    '/meeting-elements': {
        'POST': {
            [roles.ANONYMOUS]: {hasResponse: false},
            [roles.SITE_MEMBER]: {hasResponse: false},
            [roles.COUNCIL_ADMIN]: {hasResponse: true},
            [roles.CM_ADMIN]: {hasResponse: false},
            [roles.UPLOAD_ADMIN]: {hasResponse: false},
            [roles.ADMIN]: {hasResponse: true},
            [roles.SECURITY_OFFICER]: {hasResponse: true}
        },
    },
    '/meeting-elements/1': {
        'GET': {
            [roles.ANONYMOUS]: {
                hasResponse: true,
                validate: isPublicTest
            },
            [roles.SITE_MEMBER]: {
                hasResponse: true,
                validate: isPublicTest
            },
            [roles.COUNCIL_ADMIN]: {hasResponse: true},
            [roles.CM_ADMIN]: {
                hasResponse: true,
                validate: isPublicTest
            },
            [roles.UPLOAD_ADMIN]: {
                hasResponse: true,
                validate: isPublicTest
            },
            [roles.ADMIN]: {hasResponse: true},
            [roles.SECURITY_OFFICER]: {hasResponse: true}
        },
        'PATCH': {
            [roles.ANONYMOUS]: {hasResponse: false},
            [roles.SITE_MEMBER]: {hasResponse: false},
            [roles.COUNCIL_ADMIN]: {hasResponse: true},
            [roles.CM_ADMIN]: {hasResponse: false},
            [roles.UPLOAD_ADMIN]: {hasResponse: false},
            [roles.ADMIN]: {hasResponse: true},
            [roles.SECURITY_OFFICER]: {hasResponse: true}
        },

    },
    '/meeting-elements/2': {
        'DELETE': {
            [roles.ANONYMOUS]: {hasResponse: false},
            [roles.SITE_MEMBER]: {hasResponse: false},
            [roles.COUNCIL_ADMIN]: {hasResponse: true},
            [roles.CM_ADMIN]: {hasResponse: false},
            [roles.UPLOAD_ADMIN]: {hasResponse: false},
            [roles.ADMIN]: {hasResponse: true},
            [roles.SECURITY_OFFICER]: {hasResponse: true}
        },
    }
}

