import React from "react";
import {createRows, getAll, getOne} from "../util";

import {kolorLineNewsApiTest} from "../../../Api/kolorline-news";

const calls = [
    onResponse => getAll("/get-news", onResponse),
    onResponse => getOne("/get-news/1", onResponse),
    onResponse => getAll("/get-announcements", onResponse),
    onResponse => getOne("/get-news-page/1", onResponse),

];

const KolorLineNewsApis = () => {

    return (
        <React.Fragment>
            {createRows(calls, kolorLineNewsApiTest)}
        </React.Fragment>
    );
}

export default KolorLineNewsApis;