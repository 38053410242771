import baseFetch, {useFetchMany, useFetchOne} from "../Lib/baseFetch";
import {DocumentRecord, DocumentSchema} from "../Data/Document";
import {AttachmentRecord} from "../Lib/ContentManagement/ContentEditor/Data/Attachment";
import {roles} from "../Main/roles";
import {documentsStatusTest} from "../Lib/testing-tools/data-validation-tests";

export function useDocuments(eId, revision) {
    return useFetchMany('/meeting-elements/' + eId + '/documents', undefined, DocumentRecord, revision);
}

export function useDocument(eId, id, revision) {
    return useFetchOne('/meeting-elements/' + eId + '/documents', id, DocumentRecord, DocumentSchema, revision);
}

export function addDocument(document, onSuccess, onError) {
    baseFetch('POST', '/meeting-elements/' + document.get('meetingElementId') + '/documents', document,        //Todo -> Inserting meeting id
        data => {
            if (onSuccess) onSuccess(new DocumentRecord(data))
        },
        onError
    );
}

export function updateDocument(document, onSuccess, onError) {
    baseFetch('PATCH', '/meeting-elements/' + document.get('meetingElementId') + '/documents/' + document.get('id'), document, onSuccess, (...args) => onError(...args, true));
}

export function removeDocument(document, onSuccess, onError) {
    baseFetch('DELETE', '/meeting-elements/' + document.get('meetingElementId') + '/documents/' + document.get('id'), undefined, onSuccess, onError);
}

export function getAttachment(id, onSuccess, onError) {
    baseFetch('GET', '/documents/' + id + '/get-attachment', undefined,
        data => {
            const record = new AttachmentRecord(data[0]);
            if (onSuccess) onSuccess([record])
        },
        onError
    );
}


export const documentApiTest = {
    '/meeting-elements/1/documents': {
        'GET': {
            [roles.ANONYMOUS]: {
                hasResponse: true,
                validate: documentsStatusTest
            },
            [roles.SITE_MEMBER]: {
                hasResponse: true,
                validate: documentsStatusTest
            },
            [roles.COUNCIL_ADMIN]: {hasResponse: true},
            [roles.CM_ADMIN]: {
                hasResponse: true,
                validate: documentsStatusTest
            },
            [roles.UPLOAD_ADMIN]: {
                hasResponse: true,
                validate: documentsStatusTest
            },
            [roles.ADMIN]: {hasResponse: true},
            [roles.SECURITY_OFFICER]: {hasResponse: true}
        },
        'POST': {
            [roles.ANONYMOUS]: {hasResponse: false},
            [roles.SITE_MEMBER]: {hasResponse: false},
            [roles.COUNCIL_ADMIN]: {hasResponse: true,},
            [roles.CM_ADMIN]: {hasResponse: false},
            [roles.UPLOAD_ADMIN]: {hasResponse: false},
            [roles.ADMIN]: {hasResponse: true,},
            [roles.SECURITY_OFFICER]: {hasResponse: true}
        },
    },
    '/meeting-elements/1/documents/1': {
        'GET': {
            [roles.ANONYMOUS]: {
                hasResponse: true,
                validate: documentsStatusTest
            },
            [roles.SITE_MEMBER]: {
                hasResponse: true,
                validate: documentsStatusTest
            },
            [roles.COUNCIL_ADMIN]: {hasResponse: true},
            [roles.CM_ADMIN]: {
                hasResponse: true,
                validate: documentsStatusTest
            },
            [roles.UPLOAD_ADMIN]: {
                hasResponse: true,
                validate: documentsStatusTest
            },
            [roles.ADMIN]: {hasResponse: true},
            [roles.SECURITY_OFFICER]: {hasResponse: true}
        },
        'PATCH': {
            [roles.ANONYMOUS]: {hasResponse: false},
            [roles.SITE_MEMBER]: {hasResponse: false},
            [roles.COUNCIL_ADMIN]: {hasResponse: true},
            [roles.CM_ADMIN]: {hasResponse: false},
            [roles.UPLOAD_ADMIN]: {hasResponse: false},
            [roles.ADMIN]: {hasResponse: true},
            [roles.SECURITY_OFFICER]: {hasResponse: true}
        },
    },
    '/meeting-elements/6/documents/3': {
        'DELETE': {
            [roles.ANONYMOUS]: {hasResponse: false},
            [roles.SITE_MEMBER]: {hasResponse: false},
            [roles.COUNCIL_ADMIN]: {hasResponse: true},
            [roles.CM_ADMIN]: {hasResponse: false},
            [roles.UPLOAD_ADMIN]: {hasResponse: false},
            [roles.ADMIN]: {hasResponse: true},
            [roles.SECURITY_OFFICER]: {hasResponse: true}
        },
    },
    '/documents/1/get-attachment': {
        'GET': {
            [roles.ANONYMOUS]: {hasResponse: true},
            [roles.SITE_MEMBER]: {hasResponse: true},
            [roles.COUNCIL_ADMIN]: {hasResponse: true},
            [roles.CM_ADMIN]: {hasResponse: true},
            [roles.UPLOAD_ADMIN]: {hasResponse: true},
            [roles.ADMIN]: {hasResponse: true},
            [roles.SECURITY_OFFICER]: {hasResponse: true}
        }
    }
}
