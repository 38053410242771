export const filterTable = (tableStorage) => {

    const table = document.getElementById(tableStorage.tableConfig.id);
    const body = table.getElementsByTagName('tbody')[0];
    const rows = tableStorage.rows;
    const filterValue = tableStorage.filterValue || '';
    const data = tableStorage.data;

    let newTableBody = '';

    data.forEach(record => {
        const obj = record.toObject();
        const isIncludes = Object.values(obj).some(val => val ? val.toString().includes(filterValue) : false);
        const id = record.get('id');

        if (isIncludes) {
            newTableBody = newTableBody.concat(rows[id] || '');
        }
    });

    body.innerHTML = newTableBody;
};

