import {useTranslation} from "react-i18next";
import React, {useState} from "react";
import useForm from "../../../../form/useForm";
import {addListItem, getAttachments, removeListItem, updateListItem, useListItem} from "../../Api/listItems";
import Section from "../../../../form/Components/Section/Section";
import Grid from "@material-ui/core/Grid";
import ValidatedInput from "../../../../form/Components/ValidatedInput/ValidatedInput";
import ValidatedSubmit from "../../../../form/Components/ValidatedSubmit/ValidatedSubmit";
import {ListItemRecord, ListItemSchema} from "../../Data/ListItem";
import AttachmentUpload from "../../../../../Components/AttachmentUpload/AttachmentUpload";
import {navigate, useRoutes} from "hookrouter";
import RemoveItemDialog from "../../../../../Components/RemoveItemDialog/RemoveItemDialog";
import OpenDialogButton from "../../../../../Components/RemoveItemDialog/OpenDialogButton";
import ContentBox from "../../../../../Components/ContentBox/ContentBox";
import {useContent} from "../../../MenuBuilder/Api/content";

const subRoutes = {
    "/:fileUploadPageId/items/add": ({fileUploadPageId}) => props => {
        return <ListItemEditor view='add'
                               pageId={fileUploadPageId}
                               handleSubmit={() => {
                                   navigate(`/content-management/${props.contentId}/edit`)
                               }}
                               {...props}/>
    },
    "/:fileUploadPageId/items/:itemId/edit": ({fileUploadPageId, itemId}) => props => {
        return <ListItemEditor view='edit'
                               pageId={fileUploadPageId}
                               itemId={itemId}
                               {...props}/>
    },
};

export default (props) => useRoutes(subRoutes)(props);

const Form = ({contentId, pageId, itemId, path, view}) => {

    const {t} = useTranslation("contentManagement");

    const [open, setOpen] = useState(false);

    const {data, loadingStatus} = useListItem(itemId);

    const handleClose = () => {
        setOpen(false)
    };

    const handleOpen = () => {
        setOpen(true)
    };

    const form = useForm({
        disabledForm: view === 'remove',
        recordType: ListItemRecord,
        recordSchema: ListItemSchema,
        namespace: 'contentManagement',
        record: data ? data : new ListItemRecord(ListItemSchema.default()),
    });

    const api = view === 'add' ? (...args) => addListItem(pageId, ...args) : updateListItem;

    const removeApi = () => {
        removeListItem(pageId, data.id, () => {
            handleClose();
            navigate(`/content-management/${contentId}/edit`)
        })
    };

    if (loadingStatus) return null;

    if (view === 'edit') {
        path.push({label: data.name, url: ''});
    }
    else if (view === 'add') {
        path.push({label: t('add'), url: ''});
    }

    const onCancel = () => navigate(`/content-management/${contentId}/edit`);

    const onSuccess = () => navigate(`/content-management/${contentId}/edit`);


    return (
        <ContentBox path={path} form={form}>
            <Section title={`${t('subpage')} ${t(view)}`}>
                <Grid container>
                    <Grid item xs={12}>
                        <ValidatedInput name='name'
                                        label={t('name')}
                        />
                        <AttachmentUpload id={itemId}
                                          ns='contentManagement'
                                          name='attachments'
                                          attachments={data.attachments}
                                          multiple={true}
                                          api={getAttachments}
                                          apiPath={'/attachments'}/>
                    </Grid>
                    <Grid item xs={12} container>
                        <Grid item xs={8}>
                            <ValidatedSubmit view={view}
                                             api={api}
                                             onCancel={onCancel}
                                             onSuccess={onSuccess}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            {view === 'edit' &&
                            <OpenDialogButton openModal={handleOpen}
                                              text={t('remove')}
                            />
                            }
                        </Grid>
                    </Grid>
                    {view === 'edit' &&
                    <RemoveItemDialog closeModal={handleClose}
                                      title={data.title}
                                      api={removeApi}
                                      ns={'contentManagement'}
                                      open={open}
                    />
                    }
                </Grid>
            </Section>
        </ContentBox>
    )
};

const ListItemEditor = (props) => {

    const {contentId} = props;

    const {t} = useTranslation('contentManagement');

    const {data, loadingStatus} = useContent(props.contentId);

    if (loadingStatus) return null;

    let path = [
        {label: t('titleNav', {context: 'content'}), url: '/content-management'},
        {label: data.title, url: `/content-management/${contentId}/edit`},
    ];

    return (
        <Form path={path}{...props}/>
    )

};