import React, {useContext} from "react";
import ContentBox from "../../Components/ContentBox/ContentBox";
import {AuthContext} from "../../Lib/auth/AuthContext";
import {hasRole} from "../../Lib/auth/auth";
import {roles} from "../../Main/roles";
import {navigate} from "hookrouter";

function Admin() {

    const authContext = useContext(AuthContext);

    if (hasRole(authContext, roles.ADMIN) || hasRole(authContext, roles.CM_ADMIN) || hasRole(authContext, roles.UPLOAD_ADMIN) || hasRole(authContext, roles.SECURITY_OFFICER)) {
        navigate('/content-management');
    } else if (hasRole(authContext, roles.COUNCIL_ADMIN)) {
        navigate('/meetings');
    }  else if (hasRole(authContext, roles.KSZSZK_ADMIN)) {
        navigate('/items');
    } else if (hasRole(authContext, roles.SITE_MEMBER)) {
        navigate('/profile');
    }

    return (
        <ContentBox/>
    );
}

export default Admin;
