import React from "react";
import List from "./List";
import {useRoutes} from "hookrouter";
import {addDecision, removeDecision, updateDecision, useDecision} from "../../Api/decisions";
import {useTranslation} from "react-i18next";
import debug from "../../Lib/debug";
import Form from "./Form";
import {DecisionRecord, DecisionSchema} from '../../Data/Decision';

export default () => useRoutes(subRoutes);

const subRoutes = {
    "/": () => <Decisions view="list"/>,
    "/add": () => <Decisions view="add"/>,
    "/:decisionId/edit": ({decisionId}) => <Decisions view="edit" decisionId={decisionId}/>,
    "/:decisionId/remove": ({decisionId}) => <Decisions view="remove" decisionId={decisionId}/>,
};

const Decisions = ({view, decisionId}) => {

    debug("RENDER", "Decisions", () => [view]);

    const {t} = useTranslation('decisions');

    const {loadingStatus, data} = useDecision(decisionId);

    const viewData = (loadingStatus ? new DecisionRecord(DecisionSchema.default()) : data);

    const path = [{label: t('titleNav'), url: "/decisions"}];

    // noinspection JSCheckFunctionSignatures
    const label = viewData.get('numberOfDecision');

    switch (view) {
        case "list" :

            return <List path={path}/>;

        case "add" :

            path.push({label: label, url: "/decisions/add"});
            return <Form decisionId={decisionId}
                         record={viewData}    // fixme name of meeting/meetingId
                         path={path}
                         view={view}
                         api={addDecision}/>;

        case "remove" :

            path.push({label: label, url: "/decisions/" + decisionId + "/remove"});
            return <Form decisionId={decisionId}
                         record={viewData}
                         path={path}
                         view={view}
                         api={removeDecision}/>;

        case "edit" :

            path.push({label: label, url: "/decisions/" + decisionId + "/edit"});
            return <Form decisionId={decisionId}
                         record={viewData}
                         path={path}
                         view={view}
                         api={updateDecision}/>;

        default :
            return <div>invalid view: {view}</div>
    }
};

