import {useContext, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
// noinspection ES6CheckImport
import classNames from "classnames";
import debug from "../debug.js";
import {FormContext} from "./useForm";


export function useFormField(props, classes) {

    const {name, disabled, validator, label, dependentOn} = props;

    const {
        recordSchema,
        namespace,
        fieldValues,
        validationStatuses,
        validationCallbacks,
        dependencyCallbacks,
        disabledForm
    } = useContext(FormContext);

    const t = useTranslation(namespace).t;

    const fieldValue = fieldValues.current[name];

    const setRevision = useState(0)[1];

    // Adding additional control over components with dependency
    const [isDisabled, setDisabled] = useState(Boolean(dependentOn) ?
        true :
        (disabledForm ?
            Boolean(disabledForm) :
            Boolean(disabled))
    );

    useEffect(() => {
        setDisabled(disabledForm);
    }, [disabledForm]);

    useEffect(() => {
        setDisabled(disabled);
    }, [disabled]);

    let callback;

    if (dependentOn) {
        callback = () => {
            setRevision(revision => (revision + 1));
            setDisabled(!Boolean(fieldValues.current[dependentOn]));
        };
    }

    useEffect(() => {
        if (dependentOn) {
            let dependency = dependencyCallbacks.current[dependentOn];
            let cbs = dependency || {};

            if (!cbs.hasOwnProperty(name)) cbs[name] = callback;

            dependencyCallbacks.current[dependentOn] = cbs;
            return () => {
                delete cbs[name];
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [callback]);

    debug("DATA", "useFormField", () => [name, fieldValue, validationStatus]);

    const onChange = (value) => {
        // validate the value, update form context errors accordingly
        let errors = undefined;
        let status = "valid";
        const validationStatus = validationStatuses.current[name];

        try {
            recordSchema.validateSyncAt(name, {[name]: value});
            if (validator) status = validator(name, value, fieldValues.current) ? "valid" : "invalid";
        } catch (validationErrors) {
            errors = validationErrors;
            status = "invalid";
        }

        // save the value into the form context, this won't trigger re-render for other fields

        fieldValues.current[name] = value;

        // if validation generalStatus changed save it to form context and trigger a validation event

        if (status !== validationStatus) {
            debug("VAL..ON", "useFormField", () => [name, value, status, errors]);
            validationStatuses.current[name] = status;
            validationCallbacks.current.forEach(cb => cb({name: name, status: status}));
        }

        // if there is an other form field which dependents on this form field's data, it triggers a render on the
        // dependant component

        if (dependencyCallbacks.current[name]) Object.values(dependencyCallbacks.current[name]).forEach(cb => cb());

        // increment revision, so the component will re-render
        setRevision((revision) => revision + 1);
    };

    const validationStatus = validationStatuses.current[name];

    let helperText = null;
    if (validationStatus === "invalid") helperText = t(name + '-invalid', {options: {defaultValue: null}});
    if (validationStatus === "default-invalid") helperText = undefined;

    const ff = {
        value: fieldValue,
        status: validationStatus,
        t: t,
        id: name + "-input",
        labelText: t(label ? label : name),
        helperText: helperText || undefined,
        values: fieldValues, // For fields that have to share data. Use it CAREFULLY!
        // this might to be added as a ref or something... errors: state.errors,
        disabled: isDisabled,
        onChange: onChange
    };

    if (classes) {
        ff.labelClasses = classNames(classes.labelRoot, {
            [classes.labelRootError]: ff.status === "invalid",
            [classes.labelRootSuccess]: ff.status === "valid"
        });

        ff.helpTextClasses = classNames({
            [classes.labelRootError]: ff.status === "invalid",
            [classes.labelRootSuccess]: ff.status === "valid"
        });
    }

    return ff;
}