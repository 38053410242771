import {makeStyles, Typography} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import React from "react";
import {versionStyle} from "./versionStyle";
import pjson from "../../../package.json"

const useStyles = makeStyles(versionStyle);

const Version = () => {

    const classes = useStyles();

    return (
        <Box className={classes.version}>
            <Typography  variant='caption' className={classes.versionText}>
                {pjson.version ? pjson.version : "noVersion"}
            </Typography>
        </Box>
    )
}

export default Version;