import {Record} from 'immutable';
import {number, object, string} from "yup";

export const CityCouncilPageRecord = Record({
    id: 0,
    text: '',
    type: ''
});

export const CityCouncilPageSchema = object().shape({
    id: number().min(0).default(0),
    text: string().min(0).default(''),
    type: string().oneOf(['COUNCIL_MEMBER_NON_PUBLIC', 'CITY_COUNCIL_NON_PUBLIC', 'DECISIONS_PUBLIC', 'MEETINGS_PUBLIC', 'IMPRESSUM', 'CONTACT']).default('')
});