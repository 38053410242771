import React from "react";
import ContentBox from "../../../../Components/ContentBox/ContentBox";
import Grid from "@material-ui/core/Grid";
import {makeStyles, Typography} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import {cityCouncilPageStyle} from "../../cityCouncilPageStyle";
import {useMeetingItem, useMeetingItemCommittees, useMeetingItemDocuments} from "../../../../Api/meeting-items";
import useNav from "../../../../Lib/useNav";
import {tanacsUrl} from "../../../../globals";
import {themedTableStyles} from "../../../../Lib/simplexion-table/styles/themedTableStyles";
import {linkActionDirect} from "../../../../Lib/simplexion-table/templates/link-button";
import Box from "@material-ui/core/Box";
import {SimplexionTable} from "../../../../Lib/simplexion-table/SimplexionTable";
import {downloadAction} from "../../../../Lib/simplexion-table/templates/download-button";

const useStyles = makeStyles(cityCouncilPageStyle);
const useTableStyles = makeStyles(themedTableStyles);

const List = ({itemId, meetingId, pageId}) => {

    const {t} = useTranslation('cityCouncilPage');

    const tableClasses = useTableStyles();
    const classes = useStyles();

    const {data} = useMeetingItemCommittees(meetingId, itemId);

    const tableConfig = {
        id: 'meeting-elements',
        nameSpace: 'cityCouncilPage',
        sortable: true,
        styles: tableClasses,
        columns: [
            {
                accessor: 'name',
                header: t('committeeName')
            },
            {
                accessor: 'date',
                renderer: (accessor, record) => `<span style="white-space: nowrap">${t(record[accessor])}</span>`
            },
            {
                accessor: 'time',
            },
            {
                accessor: 'type',
                renderer: (accessor, record) => `<span style="white-space: nowrap">${t(record[accessor])}</span>`
            },
            {
                accessor: 'place',
            },
            {
                accessor: 'committeeRecord',
                renderer: (...args) => downloadAction(...args, 'committeeRecord', 'committeeRecord')
            }
        ],
    };

    return (
        <Box>
            <Box className={classes.subtitleBox}>
                <Typography variant='body1'>
                    {t('committeeMeetings')}
                </Typography>
            </Box>
            <SimplexionTable tableConfig={tableConfig} data={data}/>
        </Box>
    );
};

const DownloadItem = ({title, blobId}) => {

    const classes = useStyles();

    return (
        <Grid item xs={12}>
            <a href={`${tanacsUrl}/item-blob/${blobId}`} rel='noopener noreferrer' target='_blank'
               className={classes.docItem}>
                {title}
            </a>
        </Grid>
    );
};

const DownloadList = ({meetingId, itemId}) => {

    const {data, loadingStatus} = useMeetingItemDocuments(meetingId, itemId);

    if (loadingStatus) return null;

    return (
        <Grid container direction='column'>
            {data.map(record => <DownloadItem key={record.id} title={record.title} blobId={record.id}/>)}
        </Grid>
    );
};

const ItemDetails = ({path, pageId, meetingId, itemId}) => {

    const {t} = useTranslation('cityCouncilPage');

    const classes = useStyles();

    useNav({path: window.location.pathname});

    const {data, loadingStatus} = useMeetingItem(meetingId, itemId);

    if (loadingStatus) return null;

    const lineColor = path.getIn([0, "color"]);

    const updatedPath = path.withMutations(p => {
        p.push({
            label: data.order + " " + t('item'),
            url: `/city-council-pages/${pageId}/meetings/${meetingId}/items/${itemId}`,
            color: lineColor,
        })
    });

    return (
        <ContentBox publicPage={true} path={updatedPath}>
            <div>
                <div style={{marginTop: "24px"}}>
                    <Typography variant='h5' className={classes.title}>
                        {data.order} {t('item')}
                    </Typography>
                </div>

                <div style={{
                    "display": "grid",
                    "gridTemplateColumns": "minmax(150px,min-content) minmax(min-content,600px)",
                    "gridAutoRows": "min-content",
                    "gridGap": "12px"
                }}>
                    <div><b>{t("order")}</b></div>
                    <div>{data.order}</div>
                    <div><b>{t("topic")}</b></div>
                    <div>{data.subject}</div>
                    <div><b>{t("type")}</b></div>
                    <div>{t(data.type)}</div>
                    <div><b>{t("presenterName")} 1</b></div>
                    <div>{data.presenter1}</div>
                    <div><b>{t("presenterName")} 2</b></div>
                    <div>{data.presenter2}</div>
                </div>

                {data.type === 'OPEN' &&
                    <div>
                        <div style={{marginTop: "24px"}}>
                            <Typography variant='body1'>
                                {t('documents')}
                            </Typography>
                        </div>

                        <Grid container item xs={12} md={6} xl={4} style={{marginTop: "16px"}}>
                            <Grid item container className={classes.dataBox}>
                                <Grid item xs={12} md={8}>
                                    <DownloadList meetingId={meetingId} itemId={itemId}/>
                                </Grid>
                            </Grid>

                        </Grid>

                    </div>
                }

                <div style={{
                    "marginTop": "32px",
                    "display": "grid",
                    "gridTemplateColumns": "minmax(min-content,1000px)",
                    "gridTemplateRows": "min-content",
                    overflowX: "auto"
                }}>
                    <List itemId={itemId} meetingId={meetingId} pageId={pageId}/>
                </div>
            </div>
        </ContentBox>
    );
};

export default ItemDetails;