import React from "react";
import {useTranslation} from "react-i18next";
import Grid from "@material-ui/core/Grid";
import Button from "../../Lib/material-dashboard/components/CustomButtons/Button";
import {A} from 'hookrouter';

export function Navigation(props) {

  const {meetingId, elementId} = props;

  const {t} = useTranslation('meetingElements');

  return (
      <Grid container direction="column">
        <Grid item xs={12}>
          <A href={"/meeting-elements/" + meetingId + "/element/" + elementId + "/edit"}>
            <Button color={"primary"} size={"sm"}>{t('general:base-data')}</Button>
          </A>&nbsp;
          <A href={"/meeting-elements/" + meetingId + "/element/" + elementId + "/documents"}>
            <Button color={"primary"} size={"sm"}>{t('documents')}</Button>
          </A>&nbsp;
        </Grid>
      </Grid>
  );
}