import React from "react";
import {useTranslation} from "react-i18next";
import useForm from "../../../form/useForm";
import {SystemSettingRecord, SystemSettingSchema} from "../../Data/SystemSetting";
import ContentBox from "../../../../Components/ContentBox/ContentBox";
import Section from "../../../form/Components/Section/Section";
import ValidatedInput from "../../../form/Components/ValidatedInput/ValidatedInput";
import ValidatedSubmit from "../../../form/Components/ValidatedSubmit/ValidatedSubmit";
import debug from "../../../debug";
import ValidatedRichTextEditor from "../../../form/Components/ValidatedRichTextEditor/ValidatedRichTextEditor";


export default ({id, record, path, view, api, hasAdminRoles}) => {

    const {t} = useTranslation("systemSettings");

    const form = useForm({
        disabledForm: view === 'remove',
        recordType: SystemSettingRecord,
        recordSchema: SystemSettingSchema,
        namespace: 'systemSettings',
        record
    });

    debug("RENDER", "useForm", () => [" -> Form_Of_System_Settings", view, id, path, record]);

    return (
        <ContentBox path={path} form={form}>

            <Section title={t('systemSettings')}>
                <ValidatedInput name="name" disabled={view !== "add"}/>
                <ValidatedInput name="value"/>
                <ValidatedRichTextEditor name="description" showLabel={t("description")}/>
            </Section>

            <ValidatedSubmit view={view} api={api}/>

        </ContentBox>
    );
};