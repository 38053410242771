import React from "react";
import Grid from "@material-ui/core/Grid";
import {makeStyles, Typography} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import ContentBox from "../../../../Components/ContentBox/ContentBox";
import {useMeetings} from "../../../../Api/meetings";
import {useTranslation} from "react-i18next";
import useNav from "../../../../Lib/useNav";
import {SimplexionTable} from "../../../../Lib/simplexion-table/SimplexionTable";
import {linkAction} from "../../../../Lib/simplexion-table/templates/link-button";
import {cityCouncilPageStyle} from "../../cityCouncilPageStyle";
import {useCityCouncilPageByType} from "../../../../Lib/ContentManagement/ContentEditor/Api/cityCouncilPages";
import {themedTableStyles} from "../../../../Lib/simplexion-table/styles/themedTableStyles";
import {useLanguageRevision} from "../../../../Lib/useLanguageRevision";
import {downloadAction} from "../../../../Lib/simplexion-table/templates/download-button";

const useTableStyles = makeStyles(themedTableStyles);
const useStyles = makeStyles(cityCouncilPageStyle);

const MeetingsTable = ({pageId}) => {

    const classes = useTableStyles();

    useNav({path: window.location.pathname});

    const {data} = useMeetings();

    const tableConfig = {
        id: 'meetings-table',
        nameSpace: 'cityCouncilPage',
        sortable: true,
        styles: classes,
        columns: [
            {
                accessor: 'date',
            },
            {
                accessor: 'time',
            },
            {
                accessor: 'type'
            },
            // {
            //     accessor: 'numberOfElements',
            // },
            {
                accessor: 'meetingRecord',
                renderer: (...args) => downloadAction(...args, 'meetingRecord', 'meetingRecord')
            },
            {
                accessor: 'details',
                renderer: (...args) => linkAction(...args, 'details', `/city-council-pages/${pageId}/meetings/`)
            }
        ],
    };

    return <Box><SimplexionTable tableConfig={tableConfig} data={data}/></Box>;
};

const MeetingList = ({pageId, path}) => {

    useNav({path: window.location.pathname});

    const classes = useStyles();
    const {t} = useTranslation('cityCouncilPage');

    const revision = useLanguageRevision();

    const {data, loadingStatus} = useCityCouncilPageByType('MEETINGS_PUBLIC', revision);

    if (loadingStatus) return null;

    return (
        <ContentBox publicPage={true} path={path}>
            <div style={{marginTop: "16px"}}>
                <Typography variant='h5' className={classes.title}>
                    {t('councilMeetings')}
                </Typography>
                {data.text === "MEETINGS_PUBLIC" ? null :
                    <Box className={classes.description + " ql-display"} dangerouslySetInnerHTML={{__html: data.text}}/>
                }
            </div>

            <Grid container component='div' className={classes.detailsContainer}>

                <div style={{
                    "display": "grid",
                    "gridTemplateColumns": "1fr",
                    "gridTemplateRows": "min-content",
                    overflowX: "auto"
                }}>
                    <MeetingsTable pageId={pageId}/>
                </div>
            </Grid>
        </ContentBox>
    );
};

export default MeetingList;