export const tagsStyle = theme => ({
    tagContainer: {
        marginTop: 0,
        marginBottom: '.3125rem',
    },
    tagContainerCardBody: {
        minHeight: '7rem',
    },
    tag: {
        margin: 8,
        color: theme.palette.text.white,
        '& .MuiChip-deleteIcon': {
            color: theme.palette.text.white,
        }
    },
    newTagInputBox: {
        marginBottom: theme.spacing(2)
    },
    // addNewTag: {
    //     margin: 8,
    //     width: 32,
    //     height: 32,
    //     background: 'transparent',
    //     '& span': {
    //         display: 'none',
    //     },
    //     '& svg': {
    //         margin: 0,
    //     }
    // },
    // inputTag: {
    //     margin: 8,
    //     borderColor: theme.palette.primary[500],
    //     borderWidth: 1,
    //     borderStyle: 'solid',
    //     color: theme.palette.text.secondary,
    //     cursor: 'default',
    //     height: 32,
    //     display: 'inline-flex',
    //     outline: 0,
    //     padding: '0 16px',
    //     fontSize: '0.8125rem',
    //     boxSizing: 'border-box',
    //     alignItems: 'center',
    //     fontFamily: 'Gotham',
    //     whiteSpace: 'nowrap',
    //     borderRadius: 16,
    //     verticalAlign: 'middle',
    //     justifyContent: 'center',
    //     textDecoration: 'none',
    // },
    // tagInput: {
    //     marginRight: 16,
    //     '& button': {
    //         marginLeft: 10,
    //     }
    // },
    // tagInputBase: {
    //     color: theme.palette.text.primary,
    //     minWidth: 'calc(100% - 16px)',
    //     padding: '2px 0',
    //     fontSize: '.875rem',
    // },
});