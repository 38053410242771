import React from "react";
import List from "./List";
import {useRoutes} from "hookrouter";
import {addOrganisation, removeOrganisation, updateOrganisation, useOrganisation} from "../../Api/organisations";
import {useTranslation} from "react-i18next";
import debug from "../../Lib/debug";
import Form from "./Form";
import {OrganisationRecord, OrganisationSchema} from '../../Data/Organisation';

export default () => useRoutes(subRoutes);

const subRoutes = {
  "/": () => <Organisations view="list"/>,
  "/add": () => <Organisations view="add"/>,
  "/:id/edit": ({id}) => <Organisations view="edit" id={id}/>,
  "/:id/remove": ({id}) => <Organisations view="remove" id={id}/>,
};

const Organisations = ({view, id}) => {

  debug("RENDER", "Organisations", () => [view]);

  const {t} = useTranslation('organisations');

  const {loadingStatus, data} = useOrganisation(id);

  const viewData = (loadingStatus ? new OrganisationRecord(OrganisationSchema.default()) : data);

  const path = [{label: t('titleNav'), url: "/organisations"}];

  // noinspection JSCheckFunctionSignatures
  const label = viewData.get('name');

  switch (view) {
    case "list" :

      return <List path={path}/>;

    case "add" :

      path.push({label: label, url: "/organisations/add"});
      return <Form id={id} record={viewData} path={path} view={view} api={addOrganisation}/>;

    case "remove" :

      path.push({label: label, url: "/organisations/" + id + "/remove"});
      return <Form id={id} record={viewData} path={path} view={view} api={removeOrganisation}/>;

    case "edit" :

      path.push({label: label, url: "/organisations/" + id + "/edit"});
      return <Form id={id} record={viewData} path={path} view={view} api={updateOrganisation}/>;

    default :
      return <div>invalid view: {view}</div>
  }
};

