import React from "react";
import {OrganisationRecord, OrganisationSchema} from "../../../Data/Organisation";
import {addData, createRows, getAll, getOne, removeData, updateData} from "../util";

import {organisationsApiTest} from "../../../Api/organisations";

const record = OrganisationRecord(OrganisationSchema.default()).withMutations(data => {
    return data.set("name", "name")
})

const calls = [
    onResponse => addData("/organisations", record, onResponse),
    onResponse => getAll("/organisations", onResponse),
    onResponse => getOne("/organisations/1", onResponse),
    onResponse => getOne("/organisations/2", onResponse),
    onResponse => updateData("/organisations/1", record, onResponse),
    onResponse => removeData('/organisations/2', onResponse),
];

const OrganisationsApis = () => {

    return (
        <React.Fragment>
            {createRows(calls, organisationsApiTest)}
        </React.Fragment>
    );
}

export default OrganisationsApis;