import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles({
    row: {
        display: 'contents'
    },
    grid: {
        position: 'sticky',
        top: 0,
        textAlign: 'left',
        fontWeight: 'normal',
        fontSize: ' 1.1rem',
        color: '#fff',
        backgroundColor: '#99e',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        padding: 4
    }
})

const ListHead = () => {

    const classes = useStyles();

    return (
        <div className={classes.row}>
            <div className={classes.grid}>
                Role
            </div>
            <div className={classes.grid}>
                Method
            </div>
            <div className={classes.grid}>
                URL
            </div>
            <div className={classes.grid}>
                Status
            </div>
            <div className={classes.grid}>
                Status test
            </div>
            <div className={classes.grid}>
                Data test
            </div>
        </div>
    )
}

export default ListHead;